/* eslint-disable react/display-name */
import React from 'react';
import SvgIcon from './SvgIcon';

export default ({ containerProps = {}, paths, height, width }) => rest => (
  <SvgIcon
    {...rest}
    {...containerProps}
    paths={paths}
    viewBox={`0 0 ${width} ${height}`}
  />
);
