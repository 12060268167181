import hotkeys from 'hotkeys-js';
import { useCallback, useEffect } from 'react';

export default function useHotkeys({ keys, handler, options = {} }, deps = []) {
  const memoizedCallback = useCallback(handler, deps);

  useEffect(() => {
    hotkeys(keys, options, memoizedCallback);

    return () => hotkeys.unbind(keys);
  }, [memoizedCallback]);
}
