export const isValidDrag = ({
  inside,
  circleWidth,
  drag,
  checked,
  boxWidth,
}) => {
  const difference = boxWidth - circleWidth;
  return checked
    ? !inside && -drag > difference / 2
    : !inside && drag > boxWidth / 3;
};

export const cleanDrag = () => ({
  startPoint: null,
  isDragging: false,
});

// eslint-disable-next-line complexity
export const calculateDrag = (
  clientX,
  { startPoint, checked, isDragging, boxWidth, circleWidth },
) => {
  if (!isDragging) return null;
  const move = startPoint - clientX;
  const drag = !checked ? clientX - startPoint : boxWidth - circleWidth - move;
  if (!checked && clientX > 0 && clientX > startPoint && drag < boxWidth * 0.7)
    return drag;
  if (checked && clientX > 0 && clientX < startPoint && move < boxWidth * 0.7)
    return drag;
  return null;
};

export const dragStartingPoints = (checked) => ({ circleWidth, boxWidth }) => {
  if (checked) return { drag: boxWidth - circleWidth };
  return {
    drag: 0,
  };
};
