import { isFunction } from '@elliemae/ds-utilities/utils';
import useExpandState from '@elliemae/ds-utilities/hooks/useExpandState';
import { useState } from 'react';

export const useExpandGridState = grid => {
  const {
    composedRows,
    props: {
      expandedRows: expandedRowsProp = {},
      rowKey = 'id',
      onExpandRow,
      onToggleShowAllRows,
      onExpandChange,
      onExpandAll,
      onToggleExpand,
      onCollapseRow,
    },
  } = grid;

  const { state, actions } = useExpandState({
    items: composedRows,
    itemKey: rowKey,
    expanded: expandedRowsProp,
    onExpand: onExpandRow,
    onExpandChange,
    onExpandAll,
    onToggleExpand,
    onCollapse: onCollapseRow,
  });

  const [shouldRefocus, setShouldReFocus] = useState(false);
  const expandRow = row => actions.expand(row, { showAllRows: false });

  const toggleExpandAllRows = actions.toggleExpandAll;

  const collapseRow = actions.collapse;

  const toggleExpand = row => {
    setShouldReFocus(false);
    actions.toggleExpand(row, { showAllRows: false });
  };

  const toggleShowAllRows = row => {
    if (isFunction(onToggleShowAllRows)) {
      onToggleShowAllRows(row);
    } else {
      const key = row[rowKey];
      actions.expand(row, {
        showAllRows: state.expanded[key] && !state.expanded[key].showAllRows,
      });
    }
  };

  return {
    state: {
      expandedRows: state.expanded,
      isExpandedAll: state.isExpandedAll,
      shouldRefocus,
    },
    actions: {
      expandRow,
      toggleExpandAllRows,
      toggleExpand,
      toggleShowAllRows,
      collapseRow,
    },
  };
};
