/* eslint-disable max-lines */
import React, { useCallback, useMemo, useState } from 'react';
import { useCancellableDelayedCallback } from '@elliemae/ds-utilities/hooks';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DSPopover, { PopperPositions as positions } from '../Popover';

const isEllipsisActive = ({ offsetWidth, scrollWidth }) =>
  offsetWidth < scrollWidth;

const initialTooltipState = (value = '', options = {}) => ({
  reference: null,
  visible: false,
  value,
  options,
});
// reduce the possibility of error showing the tooltip (text-overflow: ellipsis) https://jira.elliemae.io/browse/PUI-1755
const Text = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  max-width: 100%;
`;

const SimpleTruncatedTooltipText = (props) => {
  const {
    containerProps,
    tooltipDelay,
    placement,
    value,
    zIndex,
    tooltipOptions,
    textOptions,
  } = props;

  // not using "usePopoverProviderState" because usePopoverState has memory leak issues
  const [tooltipState, setTooltipState] = useState(
    initialTooltipState(value, tooltipOptions),
  );
  const show = useCallback(
    (newState) => {
      setTooltipState({ ...tooltipState, ...newState, visible: true });
    },
    [tooltipState],
  );
  const hideTooltip = useCallback(
    (newState) => {
      setTooltipState({ ...tooltipState, ...newState, visible: false });
    },
    [tooltipState],
  );
  const [showTooltip, cancelShowTooltip] = useCancellableDelayedCallback(
    show,
    tooltipDelay,
  );

  const handleMouseEnter = useCallback(
    (e) => {
      const { target } = e;
      if (target && isEllipsisActive(target, target.getBoundingClientRect())) {
        showTooltip({ value, reference: target });
      }
    },
    [showTooltip, value],
  );

  const handleMouseLeave = useCallback(() => {
    cancelShowTooltip();
    hideTooltip({ reference: null });
  }, [hideTooltip, cancelShowTooltip]);

  const handlers = useMemo(() => {
    if (!showTooltip) return {};
    return { onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave };
  }, [showTooltip, handleMouseEnter, handleMouseLeave]);

  const PurePopover = useMemo(
    () => (
      <>
        {tooltipState.visible ? (
          <DSPopover
            boundaries="window"
            style={{ pointerEvents: 'none', zIndex }}
            placement={placement}
            content={tooltipState.value}
            referenceEl={tooltipState.reference}
            visible={tooltipState.visible}
            showArrow
          />
        ) : null}
      </>
    ),
    [tooltipState, placement, tooltipOptions, zIndex],
  );
  const PureText = useMemo(
    () => (
      <>
        <Text
          {...(containerProps && { ...containerProps })}
          {...(textOptions && { ...textOptions })}
          {...(handlers && { ...handlers })}
        >
          {value}
        </Text>
      </>
    ),
    [containerProps, textOptions, handlers],
  );

  const PureSimpleTruncatedTooltipText = useMemo(
    () => (
      <>
        {PureText}
        {PurePopover}
      </>
    ),
    [PureText, PurePopover],
  );

  return PureSimpleTruncatedTooltipText;
};

SimpleTruncatedTooltipText.propTypes = {
  containerProps: PropTypes.object,
  tooltipOptions: PropTypes.object,
  textOptions: PropTypes.object,
  /** Text that when truncated will trigger the tooltip interaction */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  /** Position of the tooltip */
  placement: PropTypes.oneOf([
    positions.AUTO_START,
    positions.AUTO_END,
    positions.AUTO,
    positions.TOP_START,
    positions.TOP,
    positions.TOP_END,
    positions.RIGHT_START,
    positions.RIGHT,
    positions.RIGHT_END,
    positions.BOTTOM_START,
    positions.BOTTOM,
    positions.BOTTOM_END,
    positions.LEFT_START,
    positions.LEFT,
    positions.LEFT_END,
  ]),
  /** Delay to show the tooltip */
  tooltipDelay: PropTypes.number,
  /** override default zIndex */
  zIndex: PropTypes.number,
};

SimpleTruncatedTooltipText.defaultProps = {
  containerProps: {},
  tooltipOptions: {},
  textOptions: {},
  value: '',
  placement: positions.TOP,
  tooltipDelay: 200,
  zIndex: 110,
};

export default SimpleTruncatedTooltipText;
