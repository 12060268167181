import React from 'react';
import { describe, PropTypes } from 'react-desc';
import { convertPropToCssClassName } from '@elliemae/ds-classnames';
import DSButton from '@elliemae/ds-basic/Button';
import Close from '@elliemae/ds-icons/Close';
import CheckmarkCircleFill from '@elliemae/ds-icons/CheckmarkCircleFill';
import ExclamationPoint from '@elliemae/ds-icons/ExclamationPoint';
import InfoCircleFill from '@elliemae/ds-icons/InfoCircleFill';
import WarningCircleFill from '@elliemae/ds-icons/WarningCircleFill';

export const BANNER_TYPES = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
};
export const BANNER_TYPES_LIST = Object.values(BANNER_TYPES);

const Banner = ({
  containerProps = {},
  label = '',
  body = '',
  type = BANNER_TYPES.INFO,
  className = '',
  isOpen = true,
  onClose = () => null,
  showCloseButton = true,
  ...rest
}) => {
  if (!isOpen) return null;
  const { cssClassName, prefixBlockName } = convertPropToCssClassName(
    'banner',
    className,
    {},
  );

  let icon;
  switch (type) {
    case BANNER_TYPES.DANGER:
      icon = <ExclamationPoint />;
      break;
    case BANNER_TYPES.SUCCESS:
      icon = <CheckmarkCircleFill />;
      break;
    case BANNER_TYPES.WARNING:
      icon = <WarningCircleFill />;
      break;
    case BANNER_TYPES.INFO:
      icon = <InfoCircleFill />;
      break;
    default:
      icon = <InfoCircleFill />;
      break;
  }

  return (
    <div
      className={`${cssClassName} ${prefixBlockName}--${type}`}
      {...rest}
      {...containerProps}
    >
      <div className={`${prefixBlockName}__icon`}>{icon}</div>
      <div className={`${prefixBlockName}__label`}>{label}</div>
      <div className={`${prefixBlockName}__body`}>{body}</div>
      {showCloseButton && (
        <div className={`${prefixBlockName}__close`}>
          <DSButton
            aria-label="Close Icon"
            buttonType="link"
            icon={<Close aria-label="Close Icon" />}
            onClick={onClose}
          />
        </div>
      )}
    </div>
  );
};

const bannerProps = {
  containerProps: PropTypes.object.description(
    'Set of Properties attached to the main container',
  ),
  label: PropTypes.string.description('banner s label'),
  body: PropTypes.string.description('banner s body content'),
  type: PropTypes.oneOf(['info', 'warning', 'danger'])
    .description('banner s type')
    .defaultValue(BANNER_TYPES.INFO),
  onClose: PropTypes.func.description(
    'function executed when the banner closes',
  ),
  isOpen: PropTypes.bool
    .description('whether the banner is open or closed')
    .defaultValue(true),
  showCloseButton: PropTypes.bool
    .description('whether to show close button or not')
    .defaultValue(true),
  className: PropTypes.string.description('html class attribute'),
};

Banner.propTypes = bannerProps;

const BannerWithSchema = describe(Banner);
BannerWithSchema.propTypes = bannerProps;

export { BannerWithSchema };
export default Banner;
