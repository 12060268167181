import { isEqual } from '@elliemae/ds-utilities/utils';
import { omit } from 'lodash';

export const hasOptionChanged = (prevOptions, options) => {
  if (prevOptions && options) {
    const prevOptionsOmited = prevOptions.map(option =>
      omit(option, ['input', 'onChangeInput', 'mask']),
    );
    const optionsOmited = options.map(option =>
      omit(option, ['input', 'onChangeInput', 'mask']),
    );

    return isEqual(prevOptionsOmited, optionsOmited);
  }

  return false;
};
