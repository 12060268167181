export const ToastType = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  // TODO remove from types, it's not in spec
  DEFAULT: 'default',
};

export const toastTypes = Object.values(ToastType);
