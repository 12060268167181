/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M13.071 13.289l-2.828-2.828 2.828-2.828-.707-.707-2.828 2.828-2.828-2.828-.707.707 2.828 2.828-2.828 2.828.707.707 2.828-2.828 2.828 2.828z',
  ],
  height: 20,
  width: 20,
});
