/* eslint-disable max-lines */
/* eslint-disable import/no-cycle */
import React, { useRef, useMemo, useContext } from 'react';
import { PropTypes, describe } from 'react-desc';
import { omit } from 'lodash';
import { aggregatedClasses } from '@elliemae/ds-classnames';
import { mergeRefs } from '@elliemae/ds-utilities/system';
import { useFocusGroupItem } from '@elliemae/ds-shared/FocusGroup';
import { runAll } from '@elliemae/ds-utilities/utils';
import DSTruncatedTooltipText from '../../TruncatedTooltipText';
import { renderMenuItems } from './menuItemFactory';
import SubMenu from './SubMenu';
import MenuContext from '../MenuContext';

const noop = () => null;

const blockName = 'menu-item';

const Content = aggregatedClasses('div')(blockName, 'content');
const Addon = aggregatedClasses('div')(
  blockName,
  'addon',
  ({ empty, fixedItem }) => ({ empty, fixedItem }),
);

const renderAddon = (addon, fixedItem, addonId) => (
  <Addon key={addonId} classProps={{ fixedItem }}>
    {addon}
  </Addon>
);

const renderAddons = (addons, fixedItem) =>
  Array.isArray(addons)
    ? addons.map(renderAddon)
    : renderAddon(addons, fixedItem);

const Wrapper = aggregatedClasses('li')(
  blockName,
  null,
  ({ disabled, disabledAddonInactive, customRenderer, fixedItem, type }) => ({
    disabled,
    disabledAddonInactive,
    customRenderer,
    fixedItem,
    [`menu-type-${type}`]: type,
  }),
);
const calculateSize = (length = 0) => {
  if (length > 45) return 45 * 7;
  return length * 7;
};
function MenuItem(props) {
  const {
    innerRef,
    as = 'li',
    role = 'menuitem',
    leftAddon = null,
    rightAddon = undefined,
    disabledAddonInactive = undefined,
    label = undefined,
    children,
    tabIndex = -1,
    onKeyDown = noop,
    fixedItem = false,
    noAddon,
    disabled = false,
    customRenderer,
    maxOption,
    closeMenu,
    style,
    ...otherProps
  } = props;

  const menuCxt = useContext(MenuContext);
  const ref = useRef(null);
  useFocusGroupItem(ref);
  if (otherProps.subItems) {
    return (
      <SubMenu {...props} closeMenu={menuCxt.closeMenu}>
        {renderMenuItems(otherProps.subItems)}
      </SubMenu>
    );
  }

  const wrapperProps = useMemo(() => omit(otherProps, ['icon']), [otherProps]);

  const onClick = (...args) => {
    if (wrapperProps.onClick) wrapperProps.onClick(...args);
    if (wrapperProps.closeOnClick && menuCxt.closeMenu) {
      menuCxt.closeMenu(...args);
    }
  };

  return (
    <Wrapper
      as={as}
      classProps={{
        disabled,
        disabledAddonInactive,
        customRenderer,
        fixedItem,
        type: otherProps.type,
      }}
      id={otherProps.id}
      innerRef={
        otherProps.type !== 'disabled' ? mergeRefs(innerRef, ref) : innerRef
      }
      onKeyDown={
        !fixedItem &&
        runAll((e) => {
          if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            e.target.dispatchEvent(
              new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: false,
              }),
            );
          }
        }, onKeyDown)
      }
      role={role}
      style={style}
      tabIndex={tabIndex}
      {...wrapperProps}
      onClick={onClick}
    >
      {!noAddon && renderAddons(leftAddon, fixedItem)}
      {label && (
        <Content
          style={
            maxOption > 0
              ? {
                  width: `${calculateSize(maxOption)}px`,
                  maxWidth: `${calculateSize(maxOption)}px`,
                }
              : undefined
          }
        >
          <DSTruncatedTooltipText value={label} />
        </Content>
      )}
      {customRenderer}
      {rightAddon && renderAddons(rightAddon)}
      {children}
    </Wrapper>
  );
}

const props = {
  /** Renders the MenuItem with a specific html element */
  as: PropTypes.element.description(
    'Renders the MenuItem with a specific html element',
  ),
  /**
   * reference to the component
   */
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).description('reference to the component'),
  /** Renders the passed element to the left */
  leftAddon: PropTypes.element.description(
    'Renders the passed element to the left',
  ),
  /** Renders the passed element to the right */
  rightAddon: PropTypes.element.description(
    'Renders the passed element to the right',
  ),
  /** Label for the menu item */
  label: PropTypes.string.description('Label for the menu item'),
  /** style object for menu item wrapper */
  style: PropTypes.object.description('style object for menu item wrapper'),
  /** disable menu item */
  disabled: PropTypes.bool.description('disable menu item'),
  /** HTML tabindex to manage focus order */
  tabindex: PropTypes.number.description('HTML tabindex to manage focus order'),
  /** a11y role */
  role: PropTypes.string.description('a11y role'),
};

MenuItem.propTypes = props;

const DSMenuItemWithSchema = describe(MenuItem);
DSMenuItemWithSchema.propTypes = props;

export default MenuItem;

export { DSMenuItemWithSchema };
