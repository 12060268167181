import React from 'react';
import PropTypes from 'prop-types';
import DSFilterBar from '@elliemae/ds-filterbar';

const addOptionalHeaderComponents = ({ grid }) => {
  const {
    props: { showFilterBar, renderFilterToolbar, filterBarOptions },
    state: { filters },
    actions: { removeFilter, removeAllFilters },
  } = grid.getInstance();

  const { maxWidth, minWidth } = filterBarOptions || {};
  return (
    <>
      {filters && !!filters.length && showFilterBar && (
        <DSFilterBar
          filters={filters}
          maxWidth={maxWidth}
          minWidth={minWidth}
          onRemoveAllFilters={removeAllFilters}
          onRemoveFilter={removeFilter}
          renderToolbar={renderFilterToolbar}
        />
      )}
    </>
  );
};

addOptionalHeaderComponents.propTypes = {
  grid: PropTypes.any,
};

export default addOptionalHeaderComponents;
