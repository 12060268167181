import React from 'react';
import { aggregatedClasses } from '@elliemae/ds-classnames';
import ArrowheadDown from '@elliemae/ds-icons/ArrowheadDown';
import ArrowheadUp from '@elliemae/ds-icons/ArrowheadUp';

const blockName = 'data-grid-sort-header';

const SortHeaderContainer = aggregatedClasses('div')(blockName);

const iconColor = ['brand-primary', 800];

const SortHeader = React.memo(({ value, onClick, sortState }) => {
  const sortIndicator =
    sortState === 'asc' ? (
      <ArrowheadUp color={iconColor} />
    ) : (
      <ArrowheadDown color={iconColor} />
    );
  return (
    <SortHeaderContainer onClick={onClick}>
      <span className="header-value">{value}</span>
      {sortState && sortIndicator}
    </SortHeaderContainer>
  );
});

const sortHeaderFormatter = (sortable, grid) => (
  value,
  { columnIndex, property },
) => {
  const {
    state: { sortingColumns },
  } = grid.getInstance();
  const { onClick, className } = sortable(value, { columnIndex, property });
  return (
    <SortHeader
      className={className}
      onClick={onClick}
      sortState={sortingColumns[property] && sortingColumns[property].direction}
      value={value}
    />
  );
};

export default sortHeaderFormatter;
