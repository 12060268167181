/* eslint-disable max-params */
/* eslint-disable complexity */
/* eslint-disable max-lines */
import { addLeadingZeros } from '@elliemae/ds-utilities/timeUtils';
import { parseInt, isNaN } from '@elliemae/ds-utilities/utils';
import moment from 'moment';

const currentYear = new Date().getFullYear();

export const isArrowIncrementDecrement = (key) =>
  key === 'ArrowDown' || key === 'ArrowUp';

// TODO remove and keep `true` behavior ( PUI-4141 )
export const setNativeValue = (element, value) => {
  if (!Object.getOwnPropertyDescriptor(element, 'value')) return;
  const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
  const prototype = Object.getPrototypeOf(element);
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(
    prototype,
    'value',
  ).set;

  if (valueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter.call(element, value);
  } else {
    valueSetter.call(element, value);
  }
  element.dispatchEvent(new Event('input', { bubbles: true }));
};

const formatTimeNumber = (value, shouldAddLeadingZeros, leadingZeros) => {
  if (value === null || value === undefined) return '';
  return shouldAddLeadingZeros
    ? addLeadingZeros(leadingZeros)(value)
    : String(value);
};

export const formatDay = (format, value) => {
  const shouldAddLeadingZeros = format.indexOf('DD') > -1;
  return formatTimeNumber(value, shouldAddLeadingZeros, 2);
};
export const formatYear = (format, value) => {
  const shouldAddLeadingZeros = format.indexOf('YYYY') > -1;
  return formatTimeNumber(value, shouldAddLeadingZeros, 4);
};
export const formatMonth = (format, value) => {
  const shouldAddLeadingZeros = format.indexOf('MM') > -1;
  return formatTimeNumber(value, shouldAddLeadingZeros, 2);
};

export const parseTimeNumberFromText = (stringValue) => {
  const parsedInt = parseInt(stringValue);

  if (isNaN(parsedInt)) return null;

  return parsedInt;
};

// trigger onBlur for entire DateInput control if focus is not in day, month, year
export const triggerOnBlur = (blurEvent, onBlur = () => {}) => {
  const { target: currentEl = {}, relatedTarget } = blurEvent || {};
  const nextElement = currentEl.nextElementSibling;
  const { nextElementSibling } = nextElement || {};
  // relatedTarget points to the element that is currently having focus
  if (!nextElementSibling || nextElementSibling !== relatedTarget) {
    onBlur(blurEvent);
  }
};

export const focusNextInput = (currentEl) => {
  const nextElement = currentEl.nextElementSibling;
  if (nextElement && nextElement.nextElementSibling) {
    nextElement.nextElementSibling.focus();
  }
};

export const focusPreviousInput = (currentEl) => {
  const previousElement = currentEl.previousElementSibling;
  if (previousElement && previousElement.previousElementSibling) {
    previousElement.previousElementSibling.focus();
  }
};

export const getValidTimeNumber = (
  { min, max },
  number,
  valueString = '',
  onError = () => null,
) => {
  if (valueString === '0' || number === 0) return null;
  if (number === undefined || number === null) return null;
  if (number > max) {
    onError();
    return max;
  }
  if (number < min) {
    onError();
    return min;
  }
  return number;
};

export const shouldFocusNextInput = (max, number = 0, stringValue) => {
  if (stringValue.length > 1 && !stringValue.startsWith('0')) return true;
  return number * 10 > max;
};

export const getDateValuesFromTime = (time, format) => {
  if (!time || !time.format) return {};
  const month = time.format('MM');
  const day = time.format('DD');
  const year = time.format('YYYY');
  return {
    month: formatMonth(format, month),
    day: formatDay(format, day),
    year: formatYear(format, year),
  };
};

export const resetTimeValues = () => ({
  month: '',
  day: '',
  year: '',
});

export const onInputFocus = (e) => {
  e.preventDefault();
  e.stopPropagation();
  e.target.select();
};

export const getNextTimeValue = (
  value,
  target,
  yearMinRange,
  yearMaxRange,
  step = 1,
  incrementing = true,
) => {
  const parsedValue = parseTimeNumberFromText(value);
  let result = incrementing ? parsedValue + step : parsedValue - step;
  if (target === 'year') {
    if (result < yearMinRange) result = currentYear;
    if (result > yearMaxRange) result = currentYear;
  }
  return result;
};

export const focusNextInputIfNeeded = (
  currentEl,
  max,
  number,
  stringValue,
  currentKey,
) => {
  if (
    shouldFocusNextInput(max, number, stringValue) &&
    !isArrowIncrementDecrement(currentKey)
  ) {
    focusNextInput(currentEl);
  }
};

export const commonInputProps = (digits = 2, onKeyDown, placeholder) => {
  const props = {
    pattern: '[0-9]*',
    placeholder,
    type: 'text',
    onKeyDown,
    onFocus: onInputFocus,
  };
  if (digits === 4) props.placeholder = 'YYYY';
  return props;
};

export const handleCompletedDate = (
  time,
  date,
  show,
  INTERNAL_V2_NO_MUTATION,
) => {
  // clone time to avoid mutation when INTERNAL_V2_NO_MUTATION is true
  const newDate = INTERNAL_V2_NO_MUTATION
    ? (time && moment(time)) || moment()
    : time || moment();

  const { year, month, day } = date;
  const { showYear, showMonth, showDay } = show;

  if (time && !year && !month && !day) return '';

  newDate.value = `${year}-${month}-${day}`;

  if (showMonth && month) newDate.month(parseInt(month - 1, 10));
  if (showDay && day) newDate.date(parseInt(day, 10));
  if (showYear && year) newDate.year(parseInt(year, 10));

  return newDate;
};

export const isTimeCompletelySet = (date, show, range) => {
  let neededValues = [];
  const { year, month, day } = date;
  if (day.length < 1 || month.length < 1) return false;
  const { showYear, showMonth, showDay } = show;
  const { yearMinRange, yearMaxRange } = range;

  if (showMonth) neededValues = [...neededValues, month || null];
  if (showDay) neededValues = [...neededValues, day || null];
  if (showYear) neededValues = [...neededValues, year || null];

  const isValidYear = year < yearMaxRange && year > yearMinRange;

  return !neededValues.some((value) => !value) && isValidYear;
};
