import { MODAL_TYPE_V2, MODAL_SUB_TYPE_V2 } from './components/types';

export function showDecision({ modalType }) {
  return (
    [MODAL_TYPE_V2.INFORMATION, MODAL_TYPE_V2.DECISION].indexOf(modalType) > -1
  );
}

export function showFeedBack({ modalType, modalSubType }) {
  return (
    showDecision({ modalType }) &&
    Object.values(MODAL_SUB_TYPE_V2).indexOf(modalSubType) > -1
  );
}

export function showFooter({ modalType }) {
  return modalType !== MODAL_TYPE_V2.INFORMATION;
}

export function showSelection({ modalType }) {
  return modalType === MODAL_TYPE_V2.SELECTION;
}
export function showForm({ modalType }) {
  return modalType === MODAL_TYPE_V2.FORM;
}
