/* eslint-disable max-lines */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DayPickerRangeController } from '@elliemae/ds-date-range-picker';
import { DatePickerRangeHeader } from '@elliemae/ds-date-picker';
import DSPopper, {
  PopperPositions as Position,
} from '@elliemae/ds-basic/Popper';
import DatePickerIcon from '@elliemae/ds-icons/DatePicker2';
import DSButton from '@elliemae/ds-basic/Button';
import moment from 'moment';
import { aggregatedClasses } from '@elliemae/ds-classnames';
import { DEFAULT_DATE_FORMAT } from './defaultDateFormat';

const START_DATE = 'startDate';
const END_DATE = 'endDate';

const blockname = 'date-range';
const Wrapper = aggregatedClasses('div')(blockname, 'wrapper');
const Header = aggregatedClasses('div')(blockname, 'header');

const DateRangePickerContainer = ({
  onChange = () => null,
  showHeader,
  ...otherProps
}) => {
  const [focusedInputHeader, setFocusedInputHeader] = useState(START_DATE);
  const [focusedInputPicker, setFocusedInputPicker] = useState(START_DATE);
  const [pickerRange, setPickerRange] = useState({});

  const handleHeaderFocusChange = (inputType) => {
    setFocusedInputHeader(inputType);
  };

  const handlePickerFocusChange = () => {
    setFocusedInputPicker(
      focusedInputPicker === START_DATE ? END_DATE : START_DATE,
    );
  };

  const handleDatesChange = (dates) => {
    if (dates.endDate) {
      setTimeout(() => {
        onChange({
          startDate: dates.startDate,
          endDate: dates.endDate,
        });
      }, 500);
    }
    setPickerRange({
      startDate: dates.startDate,
      endDate: pickerRange.endDate,
    });
  };

  const handlePickerChange = (dates) => {
    if (dates.endDate) {
      setTimeout(() => {
        onChange({
          startDate: dates.startDate,
          endDate: dates.endDate,
        });
      }, 500);
    }
    if (dates.startDate) handleHeaderFocusChange();
    setPickerRange(dates);
  };

  const handleStartInputChange = (newDate) => {
    setPickerRange({ ...pickerRange, startDate: newDate });
  };

  const handleEndInputChange = (newDate) => {
    setPickerRange({ ...pickerRange, endDate: newDate });
  };

  return (
    <Wrapper data-testid="date-range__wrapper">
      {showHeader && (
        <Header>
          <DatePickerRangeHeader
            autoJumpOnEdit={false}
            focusedInput={focusedInputHeader}
            handleDatesChange={handleDatesChange}
            onEndInputChange={handleEndInputChange}
            onStartInputChange={handleStartInputChange}
            selectedEndDate={pickerRange.endDate}
            selectedStartDate={pickerRange.startDate}
            onFocusChange={handleHeaderFocusChange}
          />
        </Header>
      )}
      {
        <DayPickerRangeController
          {...otherProps}
          startDate={pickerRange.startDate}
          endDate={pickerRange.endDate}
          enableOutsideDays
          focusedInput={focusedInputPicker}
          hideKeyboardShortcutsPanel
          onDatesChange={handlePickerChange}
          onFocusChange={handlePickerFocusChange}
        />
      }
    </Wrapper>
  );
};

DateRangePickerContainer.propTypes = {
  onChange: PropTypes.func,
  showHeader: PropTypes.bool,
};

const formatRangeDate = (
  { startDate, endDate },
  format = DEFAULT_DATE_FORMAT,
) => {
  const sameDay = startDate.isSame(endDate, 'day');
  if (sameDay) return `${endDate.format(format)}`;
  return `${startDate.format(format)} - ${endDate.format(format)}`;
};

function DateRangeFilterMenu({
  column,
  className,
  isMenuOpened = false,
  onOpenMenu = () => null,
  placement = Position.BOTTOM_END,
  onAddFilter = () => null,
  dateFormatter = formatRangeDate,
  showHeader = true,
  menuProps,
}) {
  return (
    <DSPopper
      contentComponent={
        <div className={className}>
          <DateRangePickerContainer
            minimumNights={0}
            onChange={({ startDate, endDate }) => {
              startDate.startOf('day');
              endDate.endOf('day');
              onAddFilter(
                {
                  group: column.property,
                  label: column.label,
                  value: dateFormatter({ startDate, endDate }),
                  type: 'date', // todo: create constants for filter types
                  filterParams: { start: startDate, end: endDate },
                  transformRowValue: (value) =>
                    moment(value, column.dateFormat || DEFAULT_DATE_FORMAT),
                  operator: 'range',
                },
                column,
              );
              onOpenMenu(false);
            }}
            showHeader={showHeader}
          />
        </div>
      }
      isOpen={isMenuOpened}
      onOpen={onOpenMenu}
      placement={placement}
      showArrow={false}
      triggerComponent={
        <DSButton
          buttonType="text"
          icon={<DatePickerIcon />}
          onClick={() => (!isMenuOpened ? onOpenMenu(true) : onOpenMenu(false))}
          size="s"
        />
      }
      zIndex={menuProps.zIndex}
    />
  );
}

DateRangeFilterMenu.propTypes = {
  column: PropTypes.any,
  className: PropTypes.string,
  isMenuOpened: PropTypes.bool,
  onOpenMenu: PropTypes.func,
  placement: PropTypes.string,
  onAddFilter: PropTypes.func,
  dateFormatter: PropTypes.any,
  showHeader: PropTypes.bool,
  menuProps: PropTypes.any,
};

export default DateRangeFilterMenu;
