/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable max-statements */
import React from 'react';
import { describe, PropTypes } from 'react-desc';
import { convertPropToCssClassName } from '@elliemae/ds-classnames';

const { classNameBlock, classNameElement } = convertPropToCssClassName(
  'circular-progress-indicator',
);

const CircularProgressIndicator = ({
  size = 'm',
  showLabel = false,
  showTooltip = false,
  waiting = false,
  loading = false,
}) => {
  const waitingLabel = 'Waiting...';
  const loadingLabel = 'Loading...';
  const currentLabel = waiting && !loading ? waitingLabel : loadingLabel;
  let sizePx;
  let sizeLabel;
  let strokeWidth;
  let trackWidth;
  let markerHeight = '0.7';
  let markerRefY = '4.8';
  let grayArcStrokeDasharray = '45 170';
  let grayArcStrokeDashoffset = '127.5';

  switch (size.toUpperCase()) {
    case 'XS':
      sizePx = 8;
      sizeLabel = 12;
      strokeWidth = 10;
      trackWidth = 3;
      markerHeight = '1';
      grayArcStrokeDasharray = '46 174';
      grayArcStrokeDashoffset = '133';
      break;
    case 'S':
      sizePx = 16;
      sizeLabel = 12;
      strokeWidth = 8;
      trackWidth = 3;
      markerHeight = '1';
      grayArcStrokeDasharray = '46 174';
      grayArcStrokeDashoffset = '133';
      break;
    case 'M':
      sizePx = 24;
      sizeLabel = 12;
      strokeWidth = 7;
      trackWidth = 3;
      markerHeight = '1';
      markerRefY = '5.5';
      grayArcStrokeDasharray = '46 174';
      grayArcStrokeDashoffset = '133';
      break;
    case 'L':
      sizePx = 32;
      sizeLabel = 13;
      strokeWidth = 6;
      trackWidth = 3;
      markerRefY = '5';
      break;
    case 'XL':
      sizePx = 48;
      sizeLabel = 14;
      strokeWidth = 5;
      trackWidth = 1;
      break;
    case 'XXL':
      sizePx = 56;
      sizeLabel = 16;
      strokeWidth = 4;
      trackWidth = 1;
      break;
    case 'XXXL':
      sizePx = 64;
      sizeLabel = 16;
      strokeWidth = 5;
      trackWidth = 2;
      break;
    default:
      break;
  }

  const labelText = (
    <p
      data-testid="circular-indicator-label"
      className={classNameElement('label')}
      style={{ fontSize: `${sizeLabel}px` }}
    >
      {currentLabel}
    </p>
  );

  const title = (
    <title
      id="ds-circular-progress-indicator"
      data-testid="circular-indicator-title"
    >
      {currentLabel}
    </title>
  );

  const grayTrack = (
    <circle
      className={classNameElement('track')}
      cx="50%"
      cy="50%"
      fill="none"
      r="28"
      strokeWidth={`${trackWidth}px`}
    />
  );

  const grayArc = (
    <circle
      className={classNameElement('arc-gray')}
      stroke="#E0E3E8"
      strokeDasharray={`${grayArcStrokeDasharray}`}
      strokeDashoffset={`${grayArcStrokeDashoffset}`}
      cx="50%"
      cy="50%"
      fill="none"
      r="28"
      strokeWidth={`${trackWidth}px`}
    />
  );

  const indicator = (
    <svg
      height={`${sizePx}px`}
      version="1.1"
      viewBox="0 0 66 66"
      width={`${sizePx}px`}
      data-testid="circular-indicator"
    >
      {(sizePx < 17 || showTooltip) && title}
      <defs>
        <linearGradient id="grad1" x1="0%" x2="100%" y1="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: '#E0E3E8', stopOpacity: 1 }} />
          <stop offset="89%" style={{ stopColor: '#5594e2', stopOpacity: 1 }} />
          <stop
            offset="100%"
            style={{ stopColor: '#5594e2', stopOpacity: 1 }}
          />
        </linearGradient>
        <linearGradient id="grad2" x1="0%" x2="100%" y1="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: '#5594e2', stopOpacity: 1 }} />
          <stop offset="11%" style={{ stopColor: '#5594e2', stopOpacity: 1 }} />
          <stop
            offset="100%"
            style={{ stopColor: '#E0E3E8', stopOpacity: 1 }}
          />
        </linearGradient>
        <marker
          id="inverseL"
          viewBox="0 0 5 10"
          refX="0.5"
          refY={`${markerRefY}`}
          markerUnits="strokeWidth"
          markerWidth="0.5"
          markerHeight={`${markerHeight}`}
          orient="auto"
        >
          <path d="M 0 0 L 6 0 A 5 5 0 0 0 6 10 L 0 10 z" fill="#FFF" />
        </marker>
        <marker
          id="inverseR"
          viewBox="0 0 5 10"
          refX="0"
          refY="5"
          markerUnits="strokeWidth"
          markerWidth="0.7"
          markerHeight={`${markerHeight}`}
        >
          <path d="M 0 0 L 6 0 A 5 5 0 0 0 6 10 L 0 10 z" fill="#FFF" />
        </marker>
      </defs>
      {grayTrack}
      {!waiting && (
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            className={classNameElement('arc-blue')}
            d="M30,5 C17.536025,6 6,17.536027 5,31"
            stroke="#5594e2"
            strokeWidth={`${strokeWidth - 0.5}px`}
            strokeLinecap="round"
            data-testid="circular-indicator-blue-arc"
          />
          <path
            className={classNameElement('arc-white')}
            d="M33,5 C17.536027,5 5,17.536027 5,33"
            stroke="#FFF"
            strokeWidth={`${strokeWidth + 2}px`}
            markerStart="url(#inverseR)"
            markerEnd="url(#inverseL)"
          />
          {grayArc}
        </g>
      )}
    </svg>
  );

  return (
    <div className={classNameBlock('wrapper')}>
      {indicator}
      {showLabel && labelText}
    </div>
  );
};

const circularProgressIndicatorProps = {
  size: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', 'xxl'])
    .description('Defines the size of the indicator')
    .defaultValue('m'),
  showLabel: PropTypes.bool
    .description('Wheter the indicator displays its state on a label or not')
    .defaultValue(false),
  showTooltip: PropTypes.bool
    .description('Wheter the indicator displays its state on a tooltip or not')
    .defaultValue(false),
  waiting: PropTypes.bool
    .description(
      'Defines the state of the indicator as Waiting and only displays the gray track',
    )
    .defaultValue(false),
  loading: PropTypes.bool
    .description(
      'Defines the state of the indicator as Loading and displays a blue spinner animation',
    )
    .defaultValue(false),
};

CircularProgressIndicator.propTypes = circularProgressIndicatorProps;

const CircularProgressIndicatorWithSchema = describe(CircularProgressIndicator);
CircularProgressIndicatorWithSchema.propTypes = circularProgressIndicatorProps;

export { CircularProgressIndicatorWithSchema };
export default CircularProgressIndicator;
