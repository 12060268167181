/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import { components } from 'react-select';

export function ValueContainer({ children, ...rest }) {
  return (
    <div className="combobox-value-container">
      <components.ValueContainer {...rest}>
        {children}
      </components.ValueContainer>
    </div>
  );
}

export default ValueContainer;
