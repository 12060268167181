import React from 'react';
import memoize from 'memoize-one';
import { range } from '@elliemae/ds-utilities/utils';
import DSComboBox, { components } from '@elliemae/ds-basic/form/ComboBox';

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    {children} <span>per page</span>
  </components.SingleValue>
);

const generateOption = value => ({
  value,
  label: value,
});
const getOptions = memoize((step, min, max) => {
  const options = range(min, max + step, step).map(generateOption);
  return min === 0 ? options.slice(1, options.length) : options;
});

function PerPageDropdown({
  min = 0,
  max = 100,
  step = 5,
  value = 0,
  onChange = () => null,
}) {
  const options = getOptions(step, min, max);
  return (
    <DSComboBox
      className="per-page-dropdown"
      components={{
        SingleValue,
      }}
      maxOptions={10}
      onChange={onChange}
      options={options}
      searchable={false}
      value={value}
    />
  );
}

export default PerPageDropdown;
