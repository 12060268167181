/* eslint-disable max-lines */
import React from 'react';
import { PropTypes, describe } from 'react-desc';
import ReactModal from 'react-modal';
import { convertPropToCssClassName } from '@elliemae/ds-classnames';
import { MODAL_TYPE_V2, MODAL_SUB_TYPE_V2 } from './components/types';
import { DSModalContent } from './components/ModalContent';

const DSModal = ({
  containerProps = {},
  className = '',
  style = {},
  size = 'default',
  //
  modalType = MODAL_TYPE_V2.INFORMATION,
  modalSubType = null,
  modalTitle = '',
  centered = false,
  showRejectButton = false,
  showClose = true,
  searchProps = {},
  // override
  actionsRef = () => null,
  additionalFooterCssClass,
  overridePropsConfirmButton = {},
  overridePropsRejectButton = {},
  // react modal
  children,
  isOpen = true,
  onClose = () => null,
  onAfterOpen = () => null,
  onConfirm = () => null,
  onReject = () => null,
  rejectLabel = 'Discard',
  confirmLabel = 'Save',
  shouldCloseOnOverlayClick = false,
  appElement = '#root',
  zIndex = 10,
  removePadding = false,
}) => {
  if (
    typeof document !== 'undefined' &&
    process.env.NODE_ENV !== 'test' &&
    document &&
    ReactModal.setAppElement
  ) {
    ReactModal.setAppElement(appElement);
  }

  const {
    cssClassName,
    classNameBlock,
    classNameModifier,
  } = convertPropToCssClassName('modal-v2', className, { size });

  return (
    <ReactModal
      className={`${cssClassName} ${classNameBlock(`type-${modalType}`)}`}
      contentLabel={modalTitle}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onClose}
      overlayClassName={`${classNameBlock('overlay')} ${classNameModifier(
        centered ? 'center' : 'top',
      )}`}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={{
        content: {
          ...style,
        },
        overlay: {
          // position: 'absolute', https://jira.elliemae.io/browse/PUI-1471, the overlay should be positioned based on the browser window rather positioned absolute to its parent
          zIndex,
        },
      }}
    >
      <DSModalContent
        actionsRef={actionsRef}
        additionalFooterCssClass={additionalFooterCssClass}
        confirmLabel={confirmLabel}
        containerProps={containerProps}
        modalSubType={modalSubType}
        modalTitle={modalTitle}
        modalType={modalType}
        onClose={onClose}
        onConfirm={onConfirm}
        onReject={onReject}
        overridePropsConfirmButton={overridePropsConfirmButton}
        overridePropsRejectButton={overridePropsRejectButton}
        rejectLabel={rejectLabel}
        searchProps={searchProps}
        showClose={showClose}
        showRejectButton={showRejectButton}
        removePadding={removePadding}
      >
        {children}
      </DSModalContent>
    </ReactModal>
  );
};

const props = {
  /** inject props to container wrapper */
  containerProps: PropTypes.object.description(
    'inject props to container wrapper',
  ),
  /** css class */
  className: PropTypes.string.description('css class'),
  /** style object for container wrapper */
  style: PropTypes.object.description('style object for container wrapper'),
  /** modal container title */
  modalTitle: PropTypes.string.description('modal container title'),
  /** center container */
  centered: PropTypes.bool.description('center container'),
  /** show reject button */
  showRejectButton: PropTypes.bool.description('show reject button'),
  /** show close button */
  showClose: PropTypes.bool.description('show close button'),
  /*
    Props for search component
  */
  searchProps: PropTypes.object.description('Props for search component'),
  /** css class for footer */
  additionalFooterCssClass: PropTypes.string.description(
    'css class for footer',
  ),
  /**  override props confirm button */
  overridePropsConfirmButton: PropTypes.object.description(
    'override props confirm button',
  ),
  /** override props reject button */
  overridePropsRejectButton: PropTypes.object.description(
    'override props reject button',
  ),
  /** controlled isOpen flag */
  isOpen: PropTypes.bool.description('controlled isOpen flag'),
  /** close callback */
  onClose: PropTypes.func.description('close callback'),
  /** after open callback */
  onAfterOpen: PropTypes.func.description('after open callback'),
  /** confirm callback */
  onConfirm: PropTypes.func.description('confirm callback'),
  /** reject callback */
  onReject: PropTypes.func.description('reject callback'),
  /** reject label */
  rejectLabel: PropTypes.string.description('reject label'),
  /** confirm text string */
  confirmLabel: PropTypes.string.description('confirm text string'),
  /** modal container size */
  size: PropTypes.oneOf([
    'default',
    'xx-large',
    'x-large',
    'large',
    'medium',
    'small',
  ]).description('modal container size'),
  /** modal sub type */
  modalSubType: PropTypes.any.description('modal sub type'),
  /** modal type */
  modalType: PropTypes.any.description('modal type'),
  /** ref for modal footer actions */
  actionsRef: PropTypes.any.description('ref for modal footer actions'),
  /** modal container children */
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf([PropTypes.element]),
  ]).description('modal container children'),
  /** close on click outside container */
  shouldCloseOnOverlayClick: PropTypes.bool.description(
    'close on click outside container',
  ),
  /*
    App element ID to inject the modal
  */
  appElement: PropTypes.string.description(
    'App element ID to inject the modal',
  ),
  /** zindex for modal container */
  zIndex: PropTypes.number.description('zindex for modal container'),
  /** remove modal builtin padding */
  removePadding: PropTypes.bool.description('remove modal builtin padding'),
};

DSModal.propTypes = props;

const DSModalWithSchema = describe(DSModal);
DSModalWithSchema.propTypes = props;

export { MODAL_TYPE_V2, MODAL_SUB_TYPE_V2, DSModalContent, DSModalWithSchema };
export default DSModal;
