import React, { useEffect, useContext, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FocusGridContext } from '@elliemae/ds-shared/FocusGroup/FocusGrid';
import {
  evaluateFormatters,
  evaluateTransforms,
} from '@elliemae/ds-shared/createDataInstance/utils';
import DSTruncatedTooltipText from '@elliemae/ds-basic/TruncatedTooltipText';

function BodyCell({
  component: Component,
  classProps,
  rowProps,
  column,
  columnIndex,
  rowIndex,
  grid,
}) {
  const { rowData, rowKey, isScrolling, ...extraRowProps } = rowProps;
  const { property, cell, props } = column;
  const evaluatedProperty = property || (cell && cell.property);

  const ref = useRef(null);
  const { register } = useContext(FocusGridContext);

  const registerFocus = useCallback((node) => {
    register(node, rowIndex, columnIndex);
  }, []);

  useEffect(() => {
    registerFocus(ref.current);
  }, [ref.current]);

  const {
    transforms = [],
    formatters = [],
    fixedFormatters = [], // useful for the toolbar trigger at the last column
    scrollingFormatters = [],
  } = cell || {};

  const extraParameters = {
    columnIndex,
    property: evaluatedProperty,
    column,
    rowData,
    rowIndex,
    rowKey,
    isScrolling, // improve performance on scroll rendering a lightweight component instead
    registerFocus,
    ...extraRowProps,
  };
  const withTooltip = (v) =>
    classProps && classProps.wrapText ? (
      v
    ) : (
      <DSTruncatedTooltipText value={v} />
    );
  const value = rowData[`_${evaluatedProperty}`] || rowData[evaluatedProperty];
  const transformed = evaluateTransforms(transforms, value, extraParameters);
  return (
    <Component
      ref={ref}
      data-testid={`column-${property}`}
      tabIndex={-1}
      classProps={classProps}
      {...props}
      {...(cell && cell.props)}
      {...transformed}
    >
      {transformed.children ||
        evaluateFormatters(
          !isScrolling
            ? [withTooltip, ...formatters, ...fixedFormatters]
            : [...scrollingFormatters, ...fixedFormatters],
          value,
          extraParameters,
          grid,
        )}
    </Component>
  );
}

BodyCell.propTypes = {
  component: PropTypes.element,
  classProps: PropTypes.shape({
    wrapText: PropTypes.bool,
  }),
  rowProps: PropTypes.shape({
    rowData: PropTypes.shape({}),
    rowKey: PropTypes.string,
    isScrolling: PropTypes.bool,
  }),
  column: PropTypes.shape({
    property: PropTypes.string,
    cell: PropTypes.any,
    props: PropTypes.shape({}),
  }),
  columnIndex: PropTypes.number,
  rowIndex: PropTypes.number,
  grid: PropTypes.any,
};

export default BodyCell;
