/* eslint-disable no-nested-ternary */
import React from 'react';

export default function getComponentFromProps(Component, props, children = []) {
  if (!Component) return null;
  const componentChildren = Component.props
    ? Array.isArray(Component.props.children)
      ? Component.props.children
      : [Component.props.children]
    : null;
  return typeof Component === 'function' ? (
    <Component {...props}>{children}</Component>
  ) : (
    React.cloneElement(
      Component,
      {
        ...Component.props,
        ...props,
      },
      componentChildren ? [...componentChildren, ...children] : [...children],
    )
  );
}
