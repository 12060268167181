import React from 'react';
import PropTypes from 'prop-types';

const Day = ({ className = 'date-picker-content-day', date = '' }) => (
  <div className={className} data-testid={`datepicker-day-${date}`}>
    <div className={`${className}-half`} data-testid="datepicker-day">
      {date}
    </div>
  </div>
);
Day.propTypes = {
  className: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Day;
