/* eslint-disable max-lines */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useMemo } from 'react';
import {
  SortableElement,
  SortableContainer,
  SortableHandle,
} from 'react-sortable-hoc';
import GripperVertical from '@elliemae/ds-icons/GripperVertical';
import { omit } from '@elliemae/ds-utilities/utils';
import { appendCellFormatter } from '@elliemae/ds-shared/useDataGrid/initColumnDefinition';
import createInstancePlugin from '@elliemae/ds-shared/createDataInstance/createInstancePlugin';

// todo: remove once it's fixed in react-sortable-hoc library
const keysToOmit = [
  'axis',
  'contentWindow',
  'disableAutoscroll',
  'distance',
  'getContainer',
  'getHelperDimensions',
  'helperClass',
  'helperContainer',
  'hideSortableGhost',
  'keyboardSortingTransitionDuration',
  'lockAxis',
  'lockOffset',
  'lockToContainerEdges',
  'onSortEnd',
  'onSortMove',
  'onSortOver',
  'onSortStart',
  'pressDelay',
  'pressThreshold',
  'shouldCancelStart',
  'transitionDuration',
  'updateBeforeSortStart',
  'useDragHandle',
  'useWindowAsScrollContainer',
];

const DragHandle = SortableHandle(() => (
  <GripperVertical className="drag-handle" />
));

const translateDnDData = ({ oldIndex, newIndex, index }) => ({
  sourceIndex: oldIndex,
  targetIndex: newIndex,
  fromIndex: index === undefined ? oldIndex : index,
});

const decorateColumns = (columns) => {
  const dragColumn = {
    property: 'drag',
    label: '',
    visible: true,
    width: 24,
    minWidth: 24,
    special: true,
  };
  return [
    appendCellFormatter(
      () => (
        <>
          <DragHandle />
        </>
      ),
      dragColumn,
    ),
    ...columns,
  ];
};

const getBodyProps = (props, grid) => {
  const { onMoveRowEnd, onReorder, onMoveRowStart } = grid.props;
  return {
    ...props,
    onSortEnd: (data) => {
      grid.enableEvents();
      grid.setIsRowDragging(false);
      onMoveRowEnd(translateDnDData(data));
    },
    onSortStart: (...args) => {
      grid.disableEvents();
      grid.setIsRowDragging(true);
      onMoveRowStart(...args);
    },
    onSortOver: (data) => {
      onReorder(translateDnDData(data));
    },
    lockAxis: 'y',
    useDragHandle: true,
    helperClass: 'row-drag-helper',
    // a promise is needed, since sortable it's mounted before it's container
    getContainer: () =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(grid.refs.innerBody.current || grid.refs.body.current);
        }, 100);
      }),
  };
};

const decorateRenderers = (renderers) => {
  const RowComponent = renderers.body.row;
  const BodyWrapperComponent = renderers.body.wrapper;

  const Row = useMemo(() => SortableElement(RowComponent), []);

  const BodyWrapper = useMemo(
    () =>
      SortableContainer((props) => (
        <BodyWrapperComponent {...omit(props, keysToOmit)} />
      )),
    [],
  );

  return {
    ...renderers,
    body: {
      ...renderers.body,
      wrapper: BodyWrapper,
      row: Row,
    },
  };
};

export const DndRowsPlugin = createInstancePlugin('dnd-rows', {
  decorateColumns,
  getBodyProps,
  decorateRenderers,
});
