export const getArrowStylesBySize = (position, size = 30) => {
  const basePosition = position && position.split('-')[0];
  return {
    borderWidth: size,
    ...{
      top: {
        top: '100% !important',
        transform: `rotateZ(${180}deg)`,
      },
      right: {
        right: '100%',
        transform: `rotateZ(${-90}deg)`,
      },
      bottom: {
        bottom: '100%',
        transform: `rotateZ(${0}deg)`,
      },
      left: {
        left: '100%',
        transform: `rotateZ(${90}deg)`,
      },
    }[basePosition],
  };
};

export default getArrowStylesBySize;
