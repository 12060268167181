import React, { useMemo, useRef } from 'react';
import { omit } from 'lodash';
import { aggregatedClasses } from '@elliemae/ds-classnames';
import {
  isFunction,
  removeUndefinedProperties,
} from '@elliemae/ds-utilities/utils';

import { useOnClickOutside } from '@elliemae/ds-utilities/hooks';
import DSPortal from '../Portal';
import { useHiddenTransition } from '../Hidden';

const blockName = 'popover';
const PopoverComponent = aggregatedClasses('div')(blockName);
function Popover({
  containerProps,
  destroyOnHidden = true,
  renderer = undefined,
  popperProps = {},
  visible = false,
  content = undefined,
  onClickOutside = () => null,
  style,
  ...otherProps
}) {
  const popoverRef = useRef();
  const hiddenTransitionProps = useHiddenTransition({ visible });
  const popoverProps = removeUndefinedProperties({
    containerprops: containerProps,
    content,
    ref: popoverRef,
    ...otherProps,
    ...hiddenTransitionProps,
  });

  useOnClickOutside(popoverRef, onClickOutside);

  const wrapStyle = omit(style, 'zIndex') || {};
  return (
    destroyOnHidden &&
    !hiddenTransitionProps.destroyed && (
      <DSPortal>
        <div {...popperProps} style={{ ...style, ...popperProps.style }}>
          {isFunction(renderer) ? (
            renderer(popoverProps)
          ) : (
            <PopoverComponent {...popoverProps} style={wrapStyle} />
          )}
        </div>
      </DSPortal>
    )
  );
}

export default Popover;
