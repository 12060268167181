import { useMemo } from 'react';
import { isFunction } from '@elliemae/ds-utilities/utils';
import useDerivedStateFromProps from './useDerivedStateFromProps';

const toggleStateInObject = (obj, key, value) => {
  const nextObj = { ...obj };
  if (nextObj[key]) {
    delete nextObj[key];
  } else {
    nextObj[key] = value;
  }
  return nextObj;
};

const noop = () => null;

export default function useExpandState({
  items = [],
  itemKey = 'id',
  expanded: expandedProp = {},
  onExpand,
  onExpandChange = noop,
  onExpandAll = noop,
  onToggleExpand,
  onCollapse,
}) {
  const [expanded, setExpanded] = useDerivedStateFromProps(expandedProp);

  const isExpandedAll = useMemo(
    () => Object.keys(expanded).length === items.length,
    [expanded, items],
  );

  const toggleExpandAll = (stateData = true) => {
    setExpanded(() => {
      const nextState = {};
      if (!isExpandedAll) {
        items.forEach(item => {
          nextState[item[itemKey]] = stateData;
        });
      }
      onExpandChange(nextState);
      onExpandAll(nextState);
      return nextState;
    });
  };

  const expand = (item, stateData = true) => {
    if (!item) return;
    if (isFunction(onExpand)) {
      onExpand(item);
    }
    setExpanded(prevState => {
      const key = item[itemKey];
      const nextState = { ...prevState, [key]: stateData };
      onExpandChange(nextState, item);
      return nextState;
    });
  };

  const toggleExpand = (item, stateData = true) => {
    if (isFunction(onToggleExpand)) {
      onToggleExpand(item);
    }
    setExpanded(prevState => {
      const key = item[itemKey];
      const nextState = toggleStateInObject(prevState, key, stateData);
      onExpandChange(nextState, item);
      return nextState;
    });
  };

  const collapse = item => {
    if (isFunction(onCollapse)) {
      onCollapse(item);
    }
    setExpanded(prevState => {
      const nextState = { ...prevState };
      delete nextState[item[itemKey]];
      onExpandChange(nextState, item);
      return nextState;
    });
  };

  return {
    state: { expanded, isExpandedAll },
    actions: {
      expand,
      toggleExpandAll,
      toggleExpand,
      collapse,
    },
    setExpanded,
  };
}
