/* eslint-disable max-lines */
import React from 'react';
import DSSearchBox from '@elliemae/ds-basic/form/SearchBox';
import { ModalHeader, ModalHeaderTopRight } from '../blocks';
import { Title } from './Title';

export const SelectionHeader = ({
  searchProps = {},
  modalTitle,
  modalType,
  showClose,
}) => (
  <ModalHeader classProps={{ type: 'selection' }}>
    <Title modalTitle={modalTitle} modalType={modalType} />
    {showClose && (
      <ModalHeaderTopRight
        classProps={{ type: 'selection' }}
        data-testid="modal-search-box"
      >
        <DSSearchBox {...searchProps} />
      </ModalHeaderTopRight>
    )}
  </ModalHeader>
);

export default SelectionHeader;
