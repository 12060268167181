import React, { createContext, useEffect, useMemo, useRef } from 'react';
import { isFunction, runAll, get } from '@elliemae/ds-utilities/utils';
import useHotkeys from '@elliemae/ds-utilities/hooks/useHotkeys';
import { mergeRefs } from '@elliemae/ds-utilities/system';
import getNextCellPosition from './utils/getNextCellPosition';

export const FocusGridContext = createContext();
const { Provider } = FocusGridContext;

const preventDefault = e => e.preventDefault();

function registerHotKeysHooks(hotKeys, { focusedRow, focusedCell }) {
  Object.keys(hotKeys).forEach(key => {
    const { handler, options } = hotKeys[key];
    useHotkeys({
      keys: key,
      handler: e => {
        e.preventDefault();
        handler({
          rowIndex: focusedRow.current,
          cellIndex: focusedCell.current,
        });
      },
      options,
    });
  });
}

// eslint-disable-next-line max-statements
export default function FocusGridProvider({
  shouldWrapRows = false,
  shouldWrapCells = false,
  shouldRefocus = true,
  children,
  keyBindings,
  hotKeys,
}) {
  const focusedRow = useRef();
  const focusedCell = useRef();
  const grid = useRef([]);
  const containerRef = useRef(document);
  const child = React.Children.only(children);
  const decoratedChild = React.cloneElement(child, {
    innerRef: mergeRefs(child.props.innerRef, containerRef),
  });

  const getNode = ({ rowIndex, cellIndex }) =>
    get(grid.current, [rowIndex, cellIndex]);

  const register = (node, rowIndex, columnIndex) => {
    if (!node) return;
    if (!Array.isArray(grid.current[rowIndex])) {
      grid.current[rowIndex] = [];
    }

    if (rowIndex === 0 && columnIndex === 0) {
      node.setAttribute('tabindex', 0);
    } else {
      node.setAttribute('tabindex', -1);
    }

    node.onfocus = () => {
      const prevNode = getNode({
        rowIndex: focusedRow.current,
        cellIndex: focusedCell.current,
      });

      if (prevNode) prevNode.setAttribute('tabindex', -1);

      node.setAttribute('tabindex', 0);
      focusedRow.current = rowIndex;
      focusedCell.current = columnIndex;
    };
    grid.current[rowIndex][columnIndex] = node;
  };

  useEffect(() => {
    if (shouldRefocus) {
      const rowIndex = focusedRow.current;
      const cellIndex = focusedCell.current;

      const node = get(grid.current, [rowIndex, cellIndex]);
      if (node) node.focus();
    }
  });

  const focusNextCell = (directionY, directionX, position) => {
    const { rowIndex, cellIndex } =
      position ||
      getNextCellPosition({
        grid: grid.current,
        currentCell: focusedCell.current,
        currentRow: focusedRow.current,
        directionX,
        directionY,
        shouldWrapCells,
        shouldWrapRows,
      });

    const node = getNode({ rowIndex, cellIndex });

    // can focus
    if (node) {
      node.focus();
      focusedRow.current = rowIndex;
      focusedCell.current = cellIndex;
    }
  };

  registerHotKeysHooks(hotKeys, { focusedCell, focusedRow });

  const defaultKeyBindings = {
    ArrowUp: runAll(preventDefault, () => focusNextCell(-1, 0)),
    ArrowRight: runAll(preventDefault, () => focusNextCell(0, 1)),
    ArrowDown: runAll(preventDefault, () => focusNextCell(1, 0)),
    ArrowLeft: runAll(preventDefault, () => focusNextCell(0, -1)),
    Home: runAll(preventDefault, e => {
      let rowIndex = focusedRow.current;
      if (e.ctrlKey) {
        rowIndex = 0;
      }
      focusNextCell(0, 0, { rowIndex, cellIndex: 0 });
    }),
    End: runAll(preventDefault, e => {
      let rowIndex = focusedRow.current;
      if (e.ctrlKey) {
        rowIndex = grid.current.length - 1;
      }
      focusNextCell(0, 0, { rowIndex, cellIndex: grid.current[0].length - 1 });
    }),
    ...keyBindings,
  };
  const nextKeyBindings = isFunction(keyBindings)
    ? keyBindings({
        defaultBindings: defaultKeyBindings,
        rowIndex: focusedRow.current,
        cellIndex: focusedCell.current,
      })
    : { ...defaultKeyBindings, ...keyBindings };

  const onKeyDown = e => {
    if (e.target.tagName.toLowerCase() === 'input') return;
    const action = nextKeyBindings[e.key];
    if (isFunction(action)) action(e);
  };

  const activate = container => {
    container.addEventListener('keydown', onKeyDown, true);
  };

  const deactivate = container => {
    container.removeEventListener('keydown', onKeyDown, true);
  };

  useEffect(() => {
    activate(containerRef.current);
    return () => {
      deactivate(containerRef.current);
    };
  }, []);

  const valueProvider = useMemo(
    () => ({
      register,
    }),
    [],
  );

  return <Provider value={valueProvider}>{decoratedChild}</Provider>;
}
