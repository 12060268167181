import React from 'react';
import { PropTypes, describe } from 'react-desc';
import ToggleImpl from './DSToggleImpl';
import { sizes } from '../../utils/prop-types';

const DSToggle = ({
  containerProps = {},
  hasError = false,
  readOnly = false,
  disabled = false,
  checked = undefined,
  labelOn,
  labelOff,
  name = '',
  value = '',
  size,
  ...otherProps
}) => (
  <ToggleImpl
    {...otherProps}
    checked={checked}
    containerProps={containerProps}
    disabled={disabled}
    hasError={hasError}
    labelOff={labelOff}
    labelOn={labelOn}
    name={name}
    readOnly={readOnly}
    size={size}
    value={value}
  />
);

DSToggle.defaultProps = {
  labelOn: 'ON',
  labelOff: 'OFF',
  size: 's',
};

const props = {
  /**
   * Set of Properties attached to the main container
   */
  containerProps: PropTypes.shape({}).description(
    'Set of Properties attached to the main container',
  ),
  /**
   * Whether the toggle has error or not
   */
  hasError: PropTypes.bool.description('Whether the toggle has error or not'),
  /**
   * Whether the toggle is read only or not
   */
  readOnly: PropTypes.bool.description(
    'Whether the toggle is read only or not',
  ),
  /**
   * Whether the toggle is disabled or not
   */
  disabled: PropTypes.bool.description('Whether the toggle is disabled or not'),
  /**
   * Whether the toggle is checked or not
   */
  checked: PropTypes.bool.description('Whether the toggle is checked or not'),
  /**
   * Allows a function that is triggered once the toggle changes
   */
  onChange: PropTypes.func.description(
    'Allows a function that is triggered once the toggle changes',
  ),
  /**
   * Label to show when the toggle is ON
   */
  labelOn: PropTypes.string.description('Label to show when the toggle is ON'),
  /**
   * Label to show when the toggle is OFF
   */
  labelOff: PropTypes.string.description(
    'Label to show when the toggle is OFF',
  ),
  /**
   * Default value once the component is initialized
   */
  value: PropTypes.string.description(
    'Default value once the component is initialized',
  ),
  /**
   * ['s', 'm', 'l']
   */
  size: PropTypes.oneOf(sizes).description("['s', 'm', 'l']"),
  /**
   * Input name
   */
  name: PropTypes.string.description('Input name'),
};

DSToggle.propTypes = props;

const DSToggleWithSchema = describe(DSToggle);

DSToggleWithSchema.propTypes = props;

export default DSToggle;

export { DSToggleWithSchema };
