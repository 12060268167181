/* eslint-disable no-param-reassign */
/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { isFunction, get } from '@elliemae/ds-utilities/utils';
import hotkeys from 'hotkeys-js';

const defaultOptions = {
  orientation: 'vertical',
};

const safeCallAction = (e, fun, ...args) => {
  if (isFunction(fun)) {
    e.preventDefault();
    fun(...args);
  }
};

const registerHotkeys = (
  hotKeys = {},
  params,
  getContainer = () => document,
) => {
  Object.keys(hotKeys).forEach((hotkey) => {
    const { options, handler, allowDocumentHandler = false } = hotKeys[hotkey];
    const parameterizedHandler = (e) => {
      const handlerParams = isFunction(params) ? params() : params;
      if (
        !allowDocumentHandler &&
        handlerParams.item !== document.activeElement
      )
        return;
      e.preventDefault();
      handler(handlerParams);
    };
    hotkeys(
      hotkey,
      { element: getContainer(), ...options },
      parameterizedHandler,
    );
  });
};

const unregisterHotKeys = (hotKeys = {}) => {
  Object.keys(hotKeys).forEach((hotkey) => {
    hotkeys.unbind(hotkey);
  });
};

const noop = () => null;

export default class FocusGroup {
  items = [];

  constructor(options = {}) {
    this.options = { ...defaultOptions, ...options };

    const { orientation } = this.options;

    this.keyBindings = {
      ArrowUp: orientation !== 'horizontal' && 'previous',
      ArrowRight: orientation !== 'vertical' && 'next',
      ArrowDown: orientation !== 'horizontal' && 'next',
      ArrowLeft: orientation !== 'vertical' && 'previous',
      Home: 'first',
      End: 'last',
      PageUp: 'first',
      PageDown: 'last',
      Escape: 'exit',
      ...options.keyBindings,
    };

    this.currentFocusedItem = null;
    this.currIndex = null;

    this.mapActions = {
      previous: this.focusPrevious.bind(this),
      next: this.focusNext.bind(this),
      first: this.focusFirst.bind(this),
      last: this.focusLast.bind(this),
      exit: this.exit.bind(this),
      enter: () => null,
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.register = this.register.bind(this);
    this.focusFirst = this.focusFirst.bind(this);
    this.focusLast = this.focusLast.bind(this);
    this.isGroupActive = this.isGroupActive.bind(this);
    this.focusNext = this.focusNext.bind(this);
    this.focusPrevious = this.focusPrevious.bind(this);
    this.focusItem = this.focusItem.bind(this);
    this.focusCurrent = this.focusCurrent.bind(this);
    this.getHotKeysParams = this.getHotKeysParams.bind(this);
  }

  getHotKeysParams() {
    const item = this.currentFocusedItem;
    const { index } = get(item, ['dataset'], {});
    return {
      item,
      index,
    };
  }

  activate() {
    const { getContainer } = this.options;
    document.addEventListener('keydown', this.handleKeyDown, true);
    registerHotkeys(this.options.hotKeys, this.getHotKeysParams, getContainer);
  }

  deactivate() {
    document.removeEventListener('keydown', this.handleKeyDown, true);
    unregisterHotKeys(this.options.hotKeys);
  }

  handleKeyDown(e) {
    if (!this.isGroupActive()) return;
    this.executeActionByEvent(e);
  }

  executeActionByEvent(e) {
    const actions = Array.isArray(this.keyBindings[e.key])
      ? this.keyBindings[e.key]
      : [this.keyBindings[e.key]];
    return actions.map((action) =>
      typeof action === 'string'
        ? safeCallAction(e, this.mapActions[action])
        : safeCallAction(e, action),
    );
  }

  register(node, props = {}) {
    const afterIndex = this.items.findIndex(
      (item) =>
        item.compareDocumentPosition(node) === Node.DOCUMENT_POSITION_PRECEDING,
    );
    node.specialOnFocus = props.onFocus || noop;
    node.specialOnBlur = props.onBlur || noop;
    node.onclick = () => this.focusItem(node);
    node.onfocus = () => {
      this.focusItem(node);
    };
    if (afterIndex === -1) {
      this.items.push(node);
    } else {
      this.items.splice(afterIndex, 0, node);
    }
  }

  unregister(node) {
    const index = this.getItemIndexByNode(node);
    if (index > -1) this.items.splice(index, 1);
  }

  exit() {
    const { onExitFocusGroup } = this.options;
    onExitFocusGroup();
  }

  focusItem(node) {
    if (this.currentFocusedItem && this.currentFocusedItem !== node) {
      this.currentFocusedItem.specialOnBlur();
      this.currentFocusedItem.setAttribute('tabindex', -1);
    }
    if (!node) return;
    this.currentFocusedItem = node;
    node.setAttribute('tabindex', 0);
    node.focus();
    node.specialOnFocus();
  }

  focusNext() {
    const item = this.getNextItem();
    this.focusItem(item);
  }

  focusByNode(node) {
    const index = this.getItemIndexByNode(node);
    this.focusByIndex(index);
  }

  focusByIndex(index) {
    const item = this.getItemByIndex(index);
    this.focusItem(item);
  }

  focusPrevious() {
    const prevItem = this.getPreviousItem();
    this.focusItem(prevItem);
  }

  focusFirst() {
    const item = this.getFirstItem();
    this.focusItem(item);
  }

  focusLast() {
    const item = this.getLastItem();
    this.focusItem(item);
  }

  focusCurrent() {
    this.focusItem(this.currentFocusedItem);
  }

  focusNextGroup() {
    const { onFocusNextGroup } = this.options;
    this.exit();
    onFocusNextGroup();
  }

  focusPreviousGroup() {
    const { onFocusPreviousGroup } = this.options;
    this.exit();
    onFocusPreviousGroup();
  }

  getNextItem() {
    const { loop } = this.options;
    const currentIndex = this.getFocusedIndex();
    const supposedNextIndex = currentIndex + 1;
    if (!this.checkCanFocusNext(supposedNextIndex)) {
      return loop ? this.getFirstItem() : this.focusNextGroup();
    }
    return this.getItemByIndex(supposedNextIndex);
  }

  getPreviousItem() {
    const { loop } = this.options;
    const currentIndex = this.getFocusedIndex();
    const supposedPrevIndex = currentIndex - 1;
    if (!this.checkCanFocusPrev(supposedPrevIndex)) {
      return loop ? this.getLastItem() : this.focusPreviousGroup();
    }
    return this.getItemByIndex(supposedPrevIndex);
  }

  checkCanFocusNext(index) {
    return this.items.length > index;
  }

  checkCanFocusPrev(index) {
    return index >= 0;
  }

  getItemByIndex(index) {
    return this.items[index];
  }

  getItemIndexByNode(node) {
    return this.items.findIndex((item) => item === node);
  }

  getFocusedIndex() {
    return this.getItemIndexByNode(document.activeElement);
  }

  getFocusedItem() {
    const index = this.getFocusedIndex();
    return index > -1 && this.items[index];
  }

  isGroupActive() {
    return this.getFocusedIndex() !== -1;
  }

  getFirstItem() {
    return !!this.items.length && this.items[0];
  }

  getLastItem() {
    return !!this.items.length && this.items[this.items.length - 1];
  }
}
