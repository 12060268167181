/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M17.5 3.375l-.875-.875L10 9.125 3.375 2.5l-.875.875L9.125 10 2.5 16.625l.875.875L10 10.875l6.625 6.625.875-.875L10.875 10z',
  ],
  height: 20,
  width: 20,
});
