import { createRef } from 'react';
import { mergeClassNameToColumnHeader } from '@elliemae/ds-shared/useDataGrid/initColumnDefinition';
import filterableFormatter from './filterableFormatter';

export const addFilterToColumn = (props, column) => {
  const filterMenuRef = createRef();
  column.header.formatters.push(
    filterableFormatter({ ...props, filterMenuRef }),
  );
  column.filterMenu = filterMenuRef;
  return mergeClassNameToColumnHeader('with-filtering', column);
};
