/* eslint-disable max-lines */
import React from 'react';
import CloseIcon from '@elliemae/ds-icons/Close';
import DSButton from '@elliemae/ds-basic/Button';
import { ModalHeader } from '../blocks';

export const DecisionHeader = ({ onClose, showClose }) => (
  <ModalHeader classProps={{ type: 'decision', showClose }}>
    {showClose && (
      <DSButton
        buttonType="link"
        containerProps={{ 'data-testid': 'modal-button-close' }}
        icon={<CloseIcon />}
        onClick={onClose}
      />
    )}
  </ModalHeader>
);

export default DecisionHeader;
