import {
  appendCellFormatter,
  appendHeaderFormatter,
} from '@elliemae/ds-shared/useDataGrid/initColumnDefinition';
import selectableFormatter from './selectableFormatter';

export function selectionColumnCreator({
  grid,
  selectAll,
  selectionComponent,
  onSelectRow,
  onSelectAll,
  multiple,

  // backward compatibility: later this needed to be passed through column definition
  multiSelectFilterLabel,
  multiSelectFilterOptions,
  multiSelectFilterLabels,
  multiSelectComponent, // favor selectionComponent
}) {
  const {
    props: { rowKey },
  } = grid;

  return ({
    visible = true,
    isDataColumn = false,
    width = 40,
    minWidth,
    cellRenderer,
    headerCellRenderer,
    customRenderer,
    header,
    ...column
  } = {}) => {
    let selectionColumn = {
      ...column,
      property: 'metaData.selected',
      filterLabel: multiSelectFilterLabel,
      filterOptions: multiSelectFilterOptions,
      filterLabels: multiSelectFilterLabels,
      visible,
      width,
      minWidth: minWidth || width,
      isDataColumn,
    };
    selectionColumn = appendCellFormatter(
      selectableFormatter(
        {
          isMultiSelect: multiple,
          renderer: selectionComponent || multiSelectComponent,
          onSelect: onSelectRow,
        },
        grid,
      ),
    )(selectionColumn);

    if (multiple) {
      selectionColumn = appendHeaderFormatter(
        selectableFormatter(
          {
            rowKey,
            selectAll,
            isMultiSelect: true,
            isHeader: true,
            className: 'multi-select-wrapper',
            renderer: selectionComponent || multiSelectComponent,
            onSelect: onSelectAll,
          },
          grid,
        ),
      )(selectionColumn);
    }
    return selectionColumn;
  };
}
