/* eslint-disable react/display-name */
import React, { useContext } from 'react';
import { runAll, get } from '@elliemae/ds-utilities/utils';
import DropdownMenuContext from './DropdownMenuContext';

const noop = () => null;

const defaultConfig = { type: 'default', handlerName: 'onClick' };

function MenuItemClickableHOC(WrappedComponent, config) {
  return ({ item = {}, ...restProps }) => {
    const { type, handlerName } = { ...defaultConfig, ...config };
    const { onItemClick, selection } = useContext(DropdownMenuContext);
    const propHandler = restProps[handlerName] || noop;
    const handler = {
      [handlerName]: () => runAll(onItemClick, propHandler)({ item, type }),
    };
    return (
      <WrappedComponent
        {...restProps}
        {...(type === 'selectable' && selection
          ? { checked: get(selection, [item.id]) }
          : {})}
        {...handler}
      />
    );
  };
}

export default MenuItemClickableHOC;
