import React from 'react';
import { Pagination } from '../../plugins/pagination/components/Pagination';

const addOptionalFooterComponents = ({ grid, rows }) => {
  const {
    props: { maxPerPage, minPerPage, paginated, paginationStep },
    state: { pagination },
    actions: { onPerPage, onPageSelect },
  } = grid;
  if (!paginated) return null;
  return (
    <>
      <Pagination
        maxPerPage={maxPerPage}
        minPerPage={minPerPage}
        onPageSelect={onPageSelect}
        onPerPage={onPerPage}
        paginationStep={paginationStep}
        paginated={rows}
        pagination={pagination}
      />
    </>
  );
};

export default addOptionalFooterComponents;
