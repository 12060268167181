import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// TODO change hardcoded opacity for theme variable
const BackdropTag = styled.div`
  ${({ type }) =>
    type === 'cover' ? 'position: fixed' : 'position: absolute'};

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.6;
  ${(props) => (props.zIndex ? `z-index: ${props.zIndex};` : '')}
`;

const Backdrop = ({ type = 'cover', zIndex, onClick = () => null }) => (
  <BackdropTag
    zIndex={zIndex}
    type={type}
    onClick={onClick}
    data-testid="ds-backdrop"
  />
);
Backdrop.propTypes = {
  /**
   * ['cover', 'fill']
   */
  type: PropTypes.oneOf(['fill', 'cover']),
  /**
   * z-index of dimmer background
   */
  zIndex: PropTypes.number,
  /**
   * Backdrop click event
   */
  onClick: PropTypes.func,
};

export default Backdrop;
