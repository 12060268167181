import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { aggregatedClasses } from '@elliemae/ds-classnames';
import DateInputs from './DateInputs';

const blockName = 'input-time';

const DateInputContainer = aggregatedClasses('div')(blockName);

const getVisibleTimeByFormat = (format) => ({
  showMonth: format.indexOf('MM') > -1 || format.indexOf('M') > -1,
  showDay: format.indexOf('DD') > -1 || format.indexOf('D') > -1,
  showYear: format.indexOf('YYYY') > -1,
});

const DateInputImpl = ({
  format,
  innerRef,
  onBlur,
  onChange,
  onKeyDown,
  onError,
  className,
  value,
  disabled,
  yearMaxRange,
  yearMinRange,
  onFocus,
  // eslint-disable-next-line react/prop-types
  INTERNAL_V2_NO_MUTATION,
}) => {
  const inputContainerRef = useRef();

  const handleFocus = (e) => {
    onFocus();
    if (!e.target.type) {
      inputContainerRef.current.querySelector('input').focus();
    }
  };

  const visibleInputs = useMemo(() => getVisibleTimeByFormat(format));

  return (
    <DateInputContainer
      className={className}
      disabled={disabled}
      innerRef={innerRef}
      onClick={handleFocus}
    >
      <DateInputs
        disabled={disabled}
        format={format}
        INTERNAL_V2_NO_MUTATION={INTERNAL_V2_NO_MUTATION}
        innerRef={inputContainerRef}
        onBlur={onBlur}
        onChange={onChange}
        onCustomKeyDown={onKeyDown}
        onError={onError}
        time={value}
        yearMaxRange={yearMaxRange}
        yearMinRange={yearMinRange}
        {...visibleInputs}
      />
    </DateInputContainer>
  );
};

DateInputImpl.propTypes = {
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  onBlur: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onError: PropTypes.func,
  format: PropTypes.string,
  showMonth: PropTypes.bool,
  className: PropTypes.string,
  showDay: PropTypes.bool,
  showYear: PropTypes.bool,
  step: PropTypes.number,
  onFocus: PropTypes.func,
  yearMaxRange: PropTypes.number,
  disabled: PropTypes.bool,
  yearMinRange: PropTypes.number,
  value: PropTypes.instanceOf(Moment),
};

export default DateInputImpl;
