/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M17.994 17.287l-4.561-4.561C14.408 11.589 15 10.115 15 8.5 15 4.91 12.09 2 8.5 2S2 4.91 2 8.5 4.91 15 8.5 15c1.615 0 3.089-.592 4.226-1.567l4.561 4.561.707-.707zM3 8.5C3 5.467 5.467 3 8.5 3S14 5.467 14 8.5 11.533 14 8.5 14 3 11.533 3 8.5z',
  ],
  height: 20,
  width: 20,
});
