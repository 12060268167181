/* eslint-disable react/destructuring-assignment,max-lines,react/display-name, react/require-default-props */
import React from 'react';
import CloseCircle from '@elliemae/ds-icons/CloseCircle';
import DSButton from '../../../../Button';

const ClearIndicator = props => {
  const {
    getStyles,
    innerProps: { ref },
    innerRef,
  } = props;
  return (
    <div
      ref={ref}
      aria-hidden="false"
      style={getStyles('clearIndicator', props)}
    >
      <DSButton
        aria-label="clear-indicator"
        buttonType="text"
        className="clear-indicator"
        icon={<CloseCircle size="m" />}
        innerRef={innerRef}
        onKeyDown={e => {
          if (e.keyCode === 32 || e.keyCode === 13) {
            e.preventDefault();
            props.clearValue();
          }
          props.onKeyDown(e);
        }}
      />
    </div>
  );
};

export default ClearIndicator;
