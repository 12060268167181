/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { components } from 'react-select';
import DSPopper, { PopperPositions } from '../../../../Popper';

import { aggregatedClasses } from '@elliemae/ds-classnames';
// todo: the dropdown menu should be separated to a basic component and then import it here
// use this for the moment but this needs to be changed
import Content from './Content';

const TriggerComponent = aggregatedClasses('div')('combobox-trigger', null);

const SelectMenu = (props) => {
  const {
    inlineMenu,
    selectMeasure: { bounds },
    expandMenuToContainer,
    expandMenuOutsideContainer,
    zIndex,
  } = props.selectProps;

  if (inlineMenu) {
    return <components.Menu {...props} />;
  }

  const styleMenu = expandMenuToContainer ? { width: bounds.width } : {};
  if (expandMenuOutsideContainer) {
    styleMenu.width = 'auto';
  }

  return (
    <DSPopper
      contentComponent={<Content {...props} styleMenu={styleMenu} />}
      isOpen
      placement={PopperPositions.BOTTOM_START}
      preventOverflow={<TriggerComponent />}
      showArrow={false}
      triggerComponent={<TriggerComponent />}
      zIndex={zIndex}
    />
  );
};

export default SelectMenu;
