import { isFunction } from '@elliemae/ds-utilities/utils';
import strategiesOperators from './strategiesOperators';

const getRuleStrategyOperator = rule =>
  rule.strategy || strategiesOperators(rule.operator);

const executeFilterStrategy = (rule, row) => {
  const { value, field, transform, transformRowValue } = rule;
  if (field === 'all')
    return Object.values(row).some(colValue =>
      strategiesOperators('contains')(colValue, value),
    );
  const rowValue = row[field];
  const transformedValue = isFunction(transform) ? transform(value) : value;
  const transformedRowValue = isFunction(transformRowValue)
    ? transformRowValue(rowValue)
    : rowValue;

  const filterStrategy = getRuleStrategyOperator(rule);
  return filterStrategy(transformedRowValue, transformedValue);
};

const filterByRules = (rules, condition = 'AND') => row =>
  condition === 'AND'
    ? rules.reduce(
        (isFiltered, rule) =>
          rule.rules && rule.rules.length
            ? isFiltered && filterByRules(rule.rules, rule.condition)(row)
            : isFiltered && executeFilterStrategy(rule, row),
        true,
      )
    : rules.some(rule =>
        rule.rules && rule.rules.length
          ? filterByRules(rule.rules, rule.condition)(row)
          : executeFilterStrategy(rule, row),
    );

export function filterRowsByQueryGroup(rows, query, composedRows) {
  if (query && query.rules && query.rules.length === 0) return rows;
  const newRows = [];
  const parentMap = {};
  composedRows.forEach(parentRow => {
    if (parentRow.isGroup) {
      newRows.push({
        ...parentRow,
        _filtered: true,
      });
    } else if (
      !parentRow.isGroup &&
      filterByRules(query.rules, query.condition)(parentRow.data)
    ) {
      newRows.push({
        ...parentRow,
        _filtered: true,
      });
      parentMap[parentRow.parentNode.id] = true;
    }
  });

  return newRows.filter(r => {
    if (r.isGroup && !parentMap[r.id]) return false;
    return true;
  });
}

export default function filterRowsByQuery(rows, query) {
  return rows.filter(filterByRules(query.rules, query.condition));
}
