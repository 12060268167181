/* eslint-disable max-lines */
import {
  cloneDeep,
  compose,
  isEqual,
  isObject,
} from '@elliemae/ds-utilities/utils';
import { useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as resolve from 'table-resolver';
import useDerivedStateFromProps from '@elliemae/ds-utilities/hooks/useDerivedStateFromProps';
import usePrevious from '@elliemae/ds-utilities/hooks/usePrevious';
import { setRef } from '@elliemae/ds-utilities/system';
import initColumnDefinition from './initColumnDefinition';
import {
  applyDecorators,
  decorateColumns,
  reducePropsGetter,
} from '../createDataInstance/utils';
import VolatileRowsListener from './VolatileRowsListener';
import { isInternetExplorer } from '../utils';

const IS_INTERNET_EXPLORER = isInternetExplorer();

// TODO: Reuse createDataInstance for the grid the same
//  way as the list does ex: packages/ds-shared/src/useDataList/useDataList.js
// eslint-disable-next-line max-statements
export default function useDataGrid({
  uuid: uuidProp,
  plugins: pluginsFromProps,
  rows,
  columns,
  renderers = {},
  ...props
}) {
  const plugins = pluginsFromProps;
  const instance = useRef();
  const tableRef = useRef();
  const headerRef = useRef();
  const bodyRef = useRef();
  const bodyInnerRef = useRef();
  const [uuid] = useState(uuidProp || (() => uuidv4()));
  const [hasScroll, setHasScroll] = useState(false);
  const [isRowDragging, setIsRowDragging] = useState(false);
  const isScrolling = useRef();
  const shouldRefocus = useRef(); // todo: we probably need a plugin for focus related stuff
  const [RowsObserver] = useState(() => new VolatileRowsListener());

  // todo move this with rows composition
  const [originalRows, updateRows] = useDerivedStateFromProps(rows, {
    onUpdate: resolve.resolve({
      columns,
      method: (extra) =>
        compose(
          resolve.byFunction('valueTransformation')(extra),
          resolve.nested(extra),
        ),
    }),
    updateOnStateChange: true,
  });

  instance.current = {
    decoratedRenderers: cloneDeep(renderers),
    decoratedColumns: columns,
    columns,
    rows,
    composedRows: originalRows,
    getRowProps: [],
    getHeaderRowProps: [],
    getBodyProps: [],
    getTableProps: [],
    effects: [],
    uuid,
    props,
    refs: {
      table: tableRef,
      body: bodyRef,
      header: headerRef,
      innerBody: bodyInnerRef,
    },
    state: {
      hasScroll,
    },
    actions: {
      updateRows,
    },
    updateRows,
    // eslint-disable-next-line no-return-assign
    enableEvents: () =>
      (document.body.style.pointerEvents = IS_INTERNET_EXPLORER ? '' : null),
    // eslint-disable-next-line no-return-assign
    disableEvents: () => (document.body.style.pointerEvents = 'none'),
    observeRows: (observer) => RowsObserver.observe(observer),
    hotKeys: {},
    shouldRefocus,
    isScrolling,
    setHasScroll,
    setIsRowDragging,
    isRowDragging,
    plugins,
  };
  instance.current.getState = () => instance.current.state;
  instance.current.getInstance = () => instance.current;

  const defaultDecorators = {
    decorateGrid: [],
    registerStateHook: [],
    registerHotKeys: [],
    decorateColumn: [],
    decorateColumns: [
      (cols) =>
        cols.map((col) =>
          initColumnDefinition({
            useTextTruncation: col.useTextTruncation,
            wrapText: props.wrapText,
            normalize: props.normalizeDataKeys,
          })(col),
        ),
    ],
    decorateRenderers: [],
    getRowProps: [],
    getHeaderRowProps: [],
    getBodyProps: [],
    getTableProps: [],
    getHeaderProps: [],
    composeRows: [],
  };

  const decorators = useMemo(() => {
    let nextDecorators = defaultDecorators;
    // eslint-disable-next-line no-return-assign
    plugins.forEach((plugin) => (nextDecorators = plugin(nextDecorators)));
    return nextDecorators;
  }, [plugins]);

  instance.current = decorators.decorateGrid.reduce(
    (nextInstance, decorator) => ({
      ...nextInstance,
      ...decorator(instance.current),
    }),
    instance.current,
  );

  instance.current = decorators.registerStateHook.reduce(
    (nextInstance, decorator) => {
      const { state, actions } = decorator(instance.current);
      return {
        ...nextInstance,
        state: {
          ...nextInstance.state,
          ...state,
        },
        actions: {
          ...nextInstance.actions,
          ...actions,
        },
      };
    },
    instance.current,
  );

  const reduceHotKey = (nextInstance, { key, handler, options }) => ({
    ...nextInstance,
    hotKeys: {
      ...nextInstance.hotKeys,
      [key]: { handler, options },
    },
  });
  instance.current = decorators.registerHotKeys.reduce(
    (nextInstance, decorator) => {
      const hotKeys = decorator(nextInstance);
      if (Array.isArray(hotKeys)) {
        return hotKeys.reduce(reduceHotKey, nextInstance);
      }
      return reduceHotKey(nextInstance, hotKeys);
    },
    instance.current,
  );

  instance.current.decoratedRenderers = applyDecorators(
    instance.current.decoratedRenderers,
    decorators.decorateRenderers,
    instance.current,
  );

  instance.current.decoratedColumns = useMemo(() => {
    const visibleColumns = cloneDeep(columns).filter(
      (column) => typeof column.visible === 'undefined' || column.visible,
    );
    return visibleColumns.length
      ? decorateColumns(visibleColumns, decorators, instance.current)
      : [];
  }, [columns]);

  instance.current.getBodyProps = reducePropsGetter(
    decorators.getBodyProps,
    instance.current,
  );
  instance.current.getHeaderProps = reducePropsGetter(
    decorators.getHeaderProps,
    instance.current,
  );
  instance.current.getRowProps = reducePropsGetter(
    decorators.getRowProps,
    instance.current,
  );
  instance.current.getHeaderRowProps = reducePropsGetter(
    decorators.getHeaderRowProps,
    instance.current,
  );
  instance.current.getTableProps = reducePropsGetter(
    decorators.getTableProps,
    instance.current,
  );

  instance.current.composedRows = decorators.composeRows.reduce(
    (nextRows, getter) => getter(nextRows, instance.current),
    instance.current.composedRows,
  );

  RowsObserver.notify(instance.current.composedRows);

  const currentShouldRefocus = instance.current.shouldRefocus.current;
  const prevState = usePrevious(instance.current.state);
  if (isObject(currentShouldRefocus) && currentShouldRefocus.forced) {
    instance.current.shouldRefocus.current = currentShouldRefocus.value;
  } else {
    instance.current.shouldRefocus.current = !isEqual(
      prevState,
      instance.current.state,
    );
  }

  if (props.instanceRef) setRef(props.instanceRef, instance.current);

  return {
    ...instance.current,
    decorators,
  };
}
