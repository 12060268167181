/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M5.965 10.5c0 .105.033.211.1.3l6 8c.166.221.479.266.7.1s.266-.479.101-.7l-5.775-7.7 5.775-7.7c.165-.22.12-.533-.101-.7a.5007.5007 0 0 0-.7.101l-6 8a.503.503 0 0 0-.1.3z',
  ],
  height: 20,
  width: 20,
});
