import { useContext, useEffect } from 'react';
import FocusGroupContext from './FocusGroupContext';

export default function useFocusGroupItem(ref = {}, props) {
  const { register, unregister, ...otherActions } =
    useContext(FocusGroupContext) || {};

  useEffect(() => {
    if (ref.current && register) register(ref.current, props);
    return () => {
      if (ref.current && unregister) unregister(ref.current);
    };
  });

  // if the focus item is not wrapped with the focus provider then return null
  if (!register) return {};
  return otherActions;
}

export const useFocusGroupWithState = (ref = {}, props) => {
  const { register, unregister, ...otherActions } =
    useContext(FocusGroupContext) || {};

  useEffect(() => {
    if (ref && register) register(ref, props);
    return () => {
      if (ref && unregister) unregister(ref);
    };
  }, [ref]);

  // if the focus item is not wrapped with the focus provider then return null
  if (!register) return {};
  return otherActions;
};
