/* eslint-disable max-lines */
/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { Popper } from 'react-popper';
import { Keyframes, animated } from 'react-spring/renderprops.cjs';
import getComponentFromProps from '@elliemae/ds-utilities/getComponentFromProps';
import DSOverlay from '../Overlay';
import { getArrowStylesBySize } from './getArrowStylesBySize';

const Content = Keyframes.Spring({});

export default class PopperContent extends PureComponent {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = { onContentDestroyed: () => null };

  constructor(props) {
    super(props);
    this.renderContent = this.renderContent.bind(this);
    this.renderArrow = this.renderArrow.bind(this);
    this.handleRestAnimation = this.handleRestAnimation.bind(this);
  }

  handleRestAnimation() {
    const { isOpen, onContentDestroyed } = this.props;
    if (!isOpen) {
      onContentDestroyed();
    }
  }

  renderArrow({ ref, style }, placement) {
    const { blockName, showArrow } = this.props;
    if (!showArrow) return null;
    return (
      <div
        key="popper-arrow"
        ref={ref}
        className={`${blockName || 'popper'}__arrow`}
        data-placement={placement}
        style={{
          ...style,
          ...getArrowStylesBySize(placement, 25),
        }}
      >
        <svg viewBox="0 0 30 30">
          <path
            className="stroke"
            d="M23.7,27.1L17,19.9C16.5,19.3,15.8,19,15,19s-1.6,0.3-2.1,0.9l-6.6,7.2C5.3,28.1,3.4,29,2,29h26
        C26.7,29,24.6,28.1,23.7,27.1z"
          />
          <path
            className="fill"
            d="M23,27.8c1.1,1.2,3.4,2.2,5,2.2h2H0h2c1.7,0,3.9-1,5-2.2l6.6-7.2c0.7-0.8,2-0.8,2.7,0L23,27.8L23,27.8z"
          />
        </svg>
      </div>
    );
  }

  renderContent({ ref, style, placement, arrowProps, scheduleUpdate }) {
    const {
      contentComponent,
      contentStyle,
      isOpen,
      closeAnimationConfig,
      openAnimationConfig,
      onMouseEnter,
      onMouseLeave,
      containerProps,
      zIndex,
    } = this.props;

    const computedStyle = {
      ...style,
      ...contentStyle,
      zIndex,
    };
    return (
      <div
        {...containerProps}
        data-placement={placement}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={computedStyle}
        data-testid="popper-container"
      >
        <Content
          native
          onRest={this.handleRestAnimation}
          state={isOpen ? 'open' : 'close'}
          states={{
            open: {
              ...openAnimationConfig,
              from: closeAnimationConfig.to,
            },
            close: closeAnimationConfig,
          }}
        >
          {(animationStyle) => (
            <animated.div ref={ref} style={animationStyle}>
              {getComponentFromProps(contentComponent, { scheduleUpdate }, [
                this.renderArrow(arrowProps, placement),
              ])}
            </animated.div>
          )}
        </Content>
      </div>
    );
  }

  render() {
    const {
      destroyed = false,
      onClickOutside = () => null,
      placement = 'bottom-start',
      modifiers = {},
      referenceNode,
      usePortal = true,
    } = this.props;
    return (
      <DSOverlay
        isOpen={!destroyed}
        onClickOutside={onClickOutside}
        usePortal={usePortal}
      >
        <Popper
          modifiers={modifiers}
          placement={placement}
          referenceElement={referenceNode}
        >
          {this.renderContent}
        </Popper>
      </DSOverlay>
    );
  }
}
