/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zM8 13.85l-3-3 .7-.7 2.3 2.3 6.3-6.3.7.7-7 7z',
  ],
  height: 20,
  width: 20,
});
