import * as sortabular from 'sortabular';
import { compose, isEmpty, orderBy } from '@elliemae/ds-utilities/utils';
import {
  appendHeaderFormatter,
  mergeClassNameToColumnHeader,
} from '@elliemae/ds-shared/useDataGrid/initColumnDefinition';
import createInstancePlugin from '@elliemae/ds-shared/createDataInstance/createInstancePlugin';
import sortHeaderFormatter from './sortHeaderFormatter';
import { useSortableState } from './useSortableState';
import { sorter, sorterGroups } from './sorter';

const decorateColumn = (column, grid) => {
  const {
    props: { isSortableColumnActive = (col) => col.sortable, groupedRows },
  } = grid;

  if (groupedRows) return column;
  if (!isSortableColumnActive(column)) return column;

  const formatter = sortHeaderFormatter(
    sortabular.sort({
      getSortingColumns: () => grid.getState().sortingColumns || [],
      onSort: grid.actions.sort,
      strategy: sortabular.strategies.byProperty,
    }),
    grid,
  );

  return compose(
    appendHeaderFormatter(formatter),
    mergeClassNameToColumnHeader('with-sortable'),
  )(column);
};

const composeRows = (rows, grid) => {
  const {
    state: { sortingColumns },
    props: { isDataSorted, groupedRows, groupedBy, sortEmptyLast },
    rows: rowsData,
    composedRows,
    decoratedColumns,
  } = grid.getInstance();

  const sortingConfig = {
    columns: decoratedColumns,
    sortingColumns: !isEmpty(sortingColumns) && sortingColumns,
    sort: orderBy,
    emptyLast: sortEmptyLast,
  };

  // TODO: 1572 FIX
  if (groupedRows && !groupedBy) {
    return !isDataSorted
      ? sorterGroups(sortingConfig)(composedRows, rowsData, groupedBy)
      : rows;
  }

  return !isDataSorted ? sorter(sortingConfig)(rows) : rows;
};

export const SortablePlugin = createInstancePlugin('sortable', {
  registerStateHook: useSortableState,
  decorateColumn,
  composeRows,
  registerHotKeys(grid) {
    return {
      key: 'ctrl+s',
      handler: ({ cellIndex }) => {
        const {
          actions: { sort },
          decoratedColumns,
        } = grid.getInstance();
        const column = decoratedColumns[cellIndex];
        if (!column) return;
        sort(column.property);
      },
    };
  },
});
