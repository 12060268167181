import { padStart } from './utils';

export const getVisibleTimeByFormat = format => ({
  showHours:
    format.indexOf('H') > -1 ||
    format.indexOf('h') > -1 ||
    format.indexOf('k') > -1,
  showMinutes: format.indexOf('m') > -1,
  showSeconds: format.indexOf('s') > -1,
  use12Hours:
    format.indexOf('A') > -1 ||
    format.indexOf('h') > -1 ||
    format.indexOf('a') > -1,
});

export const addLeadingZeros = count => number =>
  padStart(String(number), count, '0');
