import React from 'react';
import WarningCircle from '@elliemae/ds-icons/WarningCircle';
import CheckmarkCircle from '@elliemae/ds-icons/CheckmarkCircle';
import HelpCircle from '@elliemae/ds-icons/HelpCircle';
import InfoCircle from '@elliemae/ds-icons/InfoCircle';
import { convertPropToCssClassName } from '@elliemae/ds-classnames';
import MODAL_TYPE from '../DSModalType';

const feedbackIconSize = 'xxl';

const { WARNING, ERROR, SUCCESS, HELP, INFO } = MODAL_TYPE;

const getModalType = (
  modalType,
  { hasError, hasWarning, hasSuccess, hasInfo, hasHelp },
) => {
  if ([WARNING, ERROR, SUCCESS, HELP, INFO].indexOf(modalType) > -1)
    return modalType;
  if (hasError) return ERROR;
  if (hasWarning) return WARNING;
  if (hasSuccess) return SUCCESS;
  if (hasHelp) return HELP;
  if (hasInfo) return INFO;
  return INFO;
};

const getIconType = modalType => {
  switch (modalType) {
    case WARNING:
    case ERROR:
      return <WarningCircle size={feedbackIconSize} />;
    case SUCCESS:
      return <CheckmarkCircle size={feedbackIconSize} />;
    case HELP:
      return <HelpCircle size={feedbackIconSize} />;
    case INFO:
      return <InfoCircle size={feedbackIconSize} />;
    default:
      return <InfoCircle size={feedbackIconSize} />;
  }
};

const ModalFeedBack = ({
  className = '',
  modalType = null,
  hasError = false,
  hasWarning = false,
  hasSuccess = false,
  hasInfo = false,
  hasHelp = false,
}) => {
  const type = getModalType(modalType, {
    hasError,
    hasWarning,
    hasSuccess,
    hasInfo,
    hasHelp,
  });
  const { cssClassName } = convertPropToCssClassName(
    'modal-feedback',
    className,
    { modalType: type },
  );
  const feedbackIcon = getIconType(type);

  return <div className={cssClassName}>{feedbackIcon}</div>;
};

export default ModalFeedBack;
