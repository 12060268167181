/* eslint-disable max-lines */
import React from 'react';
import { PropTypes, describe } from 'react-desc';
import useHoverHandlersDelay from '@elliemae/ds-utilities/hooks/useHoverHandlersDelay';
import { useHiddenController } from '../Hidden';
import Popover from './Popover';
import usePopper from './usePopper';
import Arrow from './Arrow';
import usePopoverProviderState from './usePopoverProviderState';

export * from '../Popper';
export const Interaction = {
  CLICK: 'click',
  HOVER: 'hover',
};

/* Unstable Popover, not yet fully working */
const DSPopover = ({
  containerProps = {},
  content, // react component inside the popover to render its content
  renderer = undefined, // function to render the popover component, ex: (popoverProps) => (<div {...popoverProps}></div>)
  visible: visibleProp = undefined,
  offset = undefined,
  showArrow = false,
  // popper props options
  placement = 'top',
  boundaries, // scrollParent | window | viewport
  modifiers = {},
  referenceEl,
  renderTrigger = () => null,
  onClickOutside = () => null,
  destroyOnHidden = true,
  interactionType,
  isOpen = undefined,
  style,
}) => {
  const { visible, onShow, onHide, onToggle } = useHiddenController(
    visibleProp,
  );

  const { reference, popper, arrow, popperData } = usePopper({
    placement,
    showArrow,
    boundaries,
    modifiers,
    offset,
    referenceEl,
    eventsEnabled: visible,
    isOpen,
  });

  const hoverHandlers = useHoverHandlersDelay({
    onMouseOver: onShow,
    onMouseLeave: onHide,
  });
  const isPopoverVisible = visible || isOpen;
  return (
    <>
      {renderTrigger({
        ...reference,
        handlers: hoverHandlers,
        onShow,
        onToggle,
        onHide,
        'aria-haspopup': true,
        'aria-expanded': visible,
      })}
      {isPopoverVisible && reference.ref && (
        <Popover
          containerProps={containerProps}
          destroyOnHidden={destroyOnHidden}
          interactionType={interactionType}
          isOpen={isOpen}
          onClickOutside={onClickOutside}
          popperProps={popper}
          renderer={
            renderer &&
            ((props) =>
              renderer({
                ...props,
                onShow,
                onToggle,
                onHide,
              }))
          }
          style={style}
          visible={isPopoverVisible}
        >
          {showArrow && <Arrow {...arrow} placement={popperData.placement} />}
          {isPopoverVisible && content}
        </Popover>
      )}
    </>
  );
};

const popoverProps = {
  containerProps: PropTypes.shape({}).description(
    'Set of Properties attached to the main container',
  ),
  destroyOnHidden: PropTypes.bool
    .description('destroy popover on hidden or not')
    .defaultValue(true),
  renderer: PropTypes.func.description('custom renderer'),
  popperProps: PropTypes.object.description(
    'Popper props attached to container',
  ),
  visible: PropTypes.bool.description('is visible or not').defaultValue(false),
  content: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).description('popover content'),
  onClickOutside: PropTypes.func.description(
    'function called on click outside',
  ),
  interaction: PropTypes.string.description('interaction type: hover, click'),
  offset: PropTypes.object.description('offset'),
  showArrow: PropTypes.bool.description('show tooltip arrow or not'),
  placement: PropTypes.string.description(
    'popover placement: top, bottom, right, left',
  ),
  boundaries: PropTypes.string.description('popover boundaries'),
  modifiers: PropTypes.object.description('modifiers'),
  style: PropTypes.object.description('css style object'),
  referenceEl: PropTypes.element.description('reference element'),
  renderTrigger: PropTypes.func.description('render trigger element'),
  interactionType: PropTypes.string.description('interaction type'),
  isOpen: PropTypes.bool.description('open or not').defaultValue(false),
};

DSPopover.propTypes = popoverProps;

const PopoverWithSchema = describe(DSPopover);
PopoverWithSchema.propTypes = popoverProps;

export { usePopper, usePopoverProviderState, PopoverWithSchema };

export default DSPopover;
