/* eslint-disable max-lines */
import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { convertPropToCssClassName } from '@elliemae/ds-classnames';
import CloseIcon from '@elliemae/ds-icons/Close';
import { DSIconSizes } from '@elliemae/ds-basic/Icon';
import DSButton from '@elliemae/ds-basic/Button';
import MODAL_TYPE from './DSModalType';
import ModalFeedBack from './ModalFeedBack/ModalFeedBack';
import { modalTypes, sizes, iconSizes } from '../../../utils/prop-types';

const DSModal = ({
  containerProps = {},
  className = '',
  additionalFooterCssClass = '',
  style = {},
  iconCloseSize = DSIconSizes.S,
  modalType = MODAL_TYPE.DEFAULT,
  showHeader = true,
  showFooter = true,
  children,
  centered = true,
  confirmLabel = 'Accept',
  modalTitle = '',
  size = 'medium',
  isOpen = false,
  onClose = () => null,
  onAfterOpen = () => null,
  onConfirm = () => null,
  onReject = () => null,
  rejectLabel = 'Cancel',
  shouldCloseOnOverlayClick = false,
  hasError = false,
  hasWarning = false,
  hasSuccess = false,
  hasInfo = false,
  hasHelp = false,
  actionsRef = () => null,
  appElement = '#root',
  overridePropsConfirmButton = {},
  overridePropsRejectButton = {},
  zIndex = 10,
}) => {
  // http://reactcommunity.org/react-modal/accessibility/ https://github.com/reactjs/react-modal#examples
  typeof document !== 'undefined' &&
    document &&
    ReactModal.setAppElement &&
    ReactModal.setAppElement(appElement); // eslint-disable-line
  const {
    cssClassName,
    classNameElement,
    classNameBlock,
    classNameModifier,
    classNameBlockModifier,
  } = convertPropToCssClassName('modal', className, {
    hasError,
    hasWarning,
    hasSuccess,
    modalType,
    size,
  });

  const { WARNING, ERROR, SUCCESS, HELP, INFO } = MODAL_TYPE;

  const isAFeedbackType =
    [WARNING, ERROR, SUCCESS, HELP, INFO].indexOf(modalType) > -1;
  const showFeedBack =
    isAFeedbackType ||
    hasError ||
    hasWarning ||
    hasSuccess ||
    hasInfo ||
    hasHelp;

  return (
    <ReactModal
      className={cssClassName}
      contentLabel={modalTitle}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onClose}
      overlayClassName={`${classNameBlock('overlay')} ${classNameModifier(
        centered && 'center',
      )}`}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={{
        content: {
          ...style,
        },
        overlay: {
          zIndex,
        },
      }}
    >
      <div {...containerProps}>
        {showHeader && (
          <div className={classNameBlock('modal-header')}>
            <div className={classNameElement('title', 'modal-header')}>
              {modalTitle}
            </div>
            <div className={classNameElement('icon', 'modal-header')}>
              <DSButton
                buttonType="link"
                icon={<CloseIcon />}
                onClick={onClose}
                size={iconCloseSize}
              />
            </div>
          </div>
        )}
        <div className={classNameElement('body')}>
          {showFeedBack && (
            <ModalFeedBack
              hasError={hasError}
              hasHelp={hasHelp}
              hasInfo={hasInfo}
              hasSuccess={hasSuccess}
              hasWarning={hasWarning}
              modalType={modalType}
            />
          )}
          {children}
        </div>
        {showFooter && !isAFeedbackType && (
          <div
            className={`${classNameBlock('modal-footer')} ${
              additionalFooterCssClass
                ? classNameBlockModifier(
                    additionalFooterCssClass,
                    'modal-footer',
                  )
                : ''
            }`}
          >
            <div
              ref={actionsRef}
              className={classNameBlock('modal-footer-actions')}
            >
              {modalType === MODAL_TYPE.CONFIRM && (
                <DSButton
                  buttonType="secondary"
                  className={`${classNameElement(
                    'button-action',
                  )} ${classNameModifier('reject', 'button-action')} `}
                  labelText={rejectLabel}
                  onClick={onReject}
                  {...overridePropsRejectButton}
                />
              )}
              <DSButton
                buttonType="primary"
                className={`${classNameElement(
                  'button-action',
                )} ${classNameModifier('confirm', 'button-action')} `}
                labelText={confirmLabel}
                onClick={onConfirm}
                {...overridePropsConfirmButton}
              />
            </div>
          </div>
        )}
      </div>
    </ReactModal>
  );
};

DSModal.propTypes = {
  className: PropTypes.string,
  /**
   * Add an additional css class to the footer
   */
  additionalFooterCssClass: PropTypes.string,
  /**
   * Set style for the modal
   */
  style: PropTypes.shape({}),
  /**
   * Select size for the close icon
   */
  iconCloseSize: PropTypes.oneOf(iconSizes),
  /**
   * ['default', 'alert', 'confirm', 'notification', 'warning', 'error', 'success', 'help', 'info']
   */
  modalType: PropTypes.oneOf(modalTypes),
  /**
   * Show the header or not
   */
  showHeader: PropTypes.bool,
  /**
   * Show the footer or not
   */
  showFooter: PropTypes.bool,
  /**
   * Main content of the modal
   */
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.any,
  ]).isRequired,
  /**
   * Content is centered or not
   */
  centered: PropTypes.bool,
  /**
   * Text to be added in the confirm button
   */
  confirmLabel: PropTypes.string,
  /**
   * Text to be added in the modal title
   */
  modalTitle: PropTypes.string,
  /**
   * Text to be added in the modal title
   */
  size: PropTypes.oneOf(sizes),
  /**
   * Modal is open or not
   */
  isOpen: PropTypes.bool,
  /**
   * Allows a function to be triggered once the modal is closed
   */
  onClose: PropTypes.func,
  /**
   * Allows a function to be triggered once the modal is opened
   */
  onAfterOpen: PropTypes.func,
  /**
   * Allows a function to be triggered once the modal open is confirmed
   */
  onConfirm: PropTypes.func,
  /**
   * Allows a function to be triggered once the modal open is rejected
   */
  onReject: PropTypes.func,
  /**
   * Text to be added in the reject button
   */
  rejectLabel: PropTypes.string,
  /**
   * If modal should close on overlay click
   */
  shouldCloseOnOverlayClick: PropTypes.bool,
  /**
   * Modal has error or not
   */
  hasError: PropTypes.bool,
  /**
   * Modal has warning or not
   */
  hasWarning: PropTypes.bool,
  /**
   * Modal has success or not
   */
  hasSuccess: PropTypes.bool,
  /**
   * Modal has info or not
   */
  hasInfo: PropTypes.bool,
  /**
   * Modal has help or not
   */
  hasHelp: PropTypes.bool,
  actionsRef: PropTypes.func,
  appElement: PropTypes.string,
  /**
   * Customized props for the confirm button
   */
  overridePropsConfirmButton: PropTypes.shape({}),
  /**
   * Customized props for the reject button
   */
  overridePropsRejectButton: PropTypes.shape({}),
};

export { MODAL_TYPE };
export default DSModal;
