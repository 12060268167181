/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { conformToMask } from 'react-text-mask';
import DSTextBox from '../TextBox';
import { MASK_TYPES } from './MaskTypes';

const BACKSPACE_KEY = 8;

const getSafeValue = (e) => {
  let value = e;
  if (e && e.target) {
    const {
      target: { value: eValue },
    } = e;
    value = eValue;
  }
  return value;
};

function setCaretPosition(ctrl, pos) {
  // Modern browsers
  if (ctrl.setSelectionRange) {
    ctrl.focus();
    ctrl.setSelectionRange(pos, pos);

    // IE8 and below
  } else if (ctrl.createTextRange) {
    const range = ctrl.createTextRange();
    range.collapse(true);
    range.moveEnd('character', pos);
    range.moveStart('character', pos);
    range.select();
  }
}

const applyMaskFormat = (value, props = {}) => {
  const { mask, pipe } = props;
  const safeValue = getSafeValue(value);
  const maskedValue = conformToMask(safeValue, mask, {
    guide: false,
  });
  if (pipe !== null) return pipe()(maskedValue.conformedValue).value;
  return maskedValue.conformedValue;
};

class DSInputMaskImpl extends Component {
  lastkey = null;

  constructor(props) {
    super(props);
    this.state = {
      originalValue: props.value,
      value: props.value,
      maskedValue: applyMaskFormat(props.value, props),
    };
    this.inputref = React.createRef();
  }

  // eslint-disable-next-line max-statements
  handleOnChange = (e) => {
    const { skypchange, length } = this.state;
    if (skypchange) {
      this.setState({ skypchange: false });
      return;
    }

    const { onChange, mask } = this.props;
    const value = getSafeValue(e);
    const maskedValue = applyMaskFormat(value, this.props);
    this.setState(
      {
        maskedValue: maskedValue.substr(0, length),
        value,
        focus: true,
      },
      () => {
        const event = {
          target: {
            value: maskedValue,
            originalValue: value,
          },
        };
        onChange(event);
      },
    );

    let end = (maskedValue || '').length || 0;
    const { selectionEnd } = e.target;
    if (this.lastkey === BACKSPACE_KEY) {
      end = selectionEnd;
      setTimeout(() => setCaretPosition(this.ref, end));
    }
    // hack for percent mask
    if (
      [1, 4, 6, 10].indexOf(selectionEnd) > -1 &&
      mask === MASK_TYPES.PHONE &&
      // This validation below fixes PUI-3390
      !(maskedValue.slice(-2) === ') ' && this.lastkey === BACKSPACE_KEY)
    ) {
      setTimeout(() => setCaretPosition(this.ref, selectionEnd + 1));
      // hack for percent mask
    } else if (maskedValue && (maskedValue[end - 1] || '').indexOf('%') > -1) {
      setTimeout(() => setCaretPosition(this.ref, end - 1));
    } else if (selectionEnd + 1 < end && MASK_TYPES.PHONE !== mask) {
      // fix for PUI-2892 replacing setCaretPosition(this.ref, selectionEnd)
      // to setCaretPosition(this.ref, end)
      setTimeout(() => setCaretPosition(this.ref, end));
      // For PUI-3391. We should evaluate using this behavior on every mask
    } else if (MASK_TYPES.PHONE === mask) {
      setTimeout(() => setCaretPosition(this.ref, selectionEnd));
    }
  };

  static getDerivedStateFromProps(props, state) {
    const { value } = props;
    if (value !== state.originalValue) {
      const maskedValue = applyMaskFormat(value, props);
      return {
        value,
        maskedValue,
        originalValue: value,
      };
    }
    return null;
  }

  handleKeyDown = (e) => {
    e.stopPropagation();
    const { onKeyDown } = this.props;
    const { value } = this.state;
    const masked = applyMaskFormat(value, this.props);
    this.lastkey = e.keyCode;
    if (e.key === 'Backspace') {
      this.setState({
        length: masked.length - 2,
      });
      // if (e.target.selectionEnd === masked.length) {
      //   const end = e.target.selectionEnd
      //   this.inputref.current.selectionEnd = end - (useSubfix.length)
      // }
    } else {
      this.setState({
        length: masked.length,
      });
    }
    onKeyDown(e);
  };

  handleBlur = (e) => {
    const { onBlur } = this.props;
    this.setState({ focus: false });
    onBlur(e);
  };

  render() {
    const {
      autoFocus,
      style,
      disabled,
      className,
      name,
      maxLength,
      minLength,
      fluidWidth,
      placeholder,
      onKeyDown,
      onClick,
      onChange,
      onFocus,
      onBlur,
      onPaste,
      onKeyUp,
      hasError,
      readOnly,
      type,
      innerRef,
      value: originalValue,
      clearable = false,
      leftComponent,
      rightComponent,
      mask,
      ...rest
    } = this.props;
    const { maskedValue, focus } = this.state;
    return (
      <DSTextBox
        {...rest}
        aria-label={rest['aria-label']}
        className={className}
        clearable={clearable}
        disabled={disabled}
        fluidWidth={fluidWidth}
        hasError={hasError}
        innerRef={(ref) => {
          this.ref = ref;
          if (innerRef) innerRef(ref);
        }}
        isActive={focus}
        keepCharPositions
        leftComponent={leftComponent}
        mask={mask}
        maxLength={maxLength}
        minLength={minLength}
        name={name}
        onBlur={this.handleBlur}
        onChange={this.handleOnChange}
        onClick={onClick}
        onFocus={onFocus}
        onKeyDown={this.handleKeyDown}
        onKeyUp={onKeyUp}
        onPaste={onPaste}
        placeholder={placeholder}
        readOnly={readOnly}
        rightComponent={rightComponent}
        style={style}
        type={type}
        value={maskedValue}
      />
    );
  }
}

DSInputMaskImpl.propTypes = {
  autoFocus: PropTypes.bool,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  fluidWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  leftComponent: PropTypes.element,
  rightComponent: PropTypes.element,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onPaste: PropTypes.func,
  onKeyUp: PropTypes.func,
  hasError: PropTypes.bool,
  readOnly: PropTypes.bool,
  onClick: PropTypes.func,
  clearable: PropTypes.bool,
  type: PropTypes.string,
  /**
   * Ref for the component
   */
  innerRef: PropTypes.any,
  /**
   * Mask type
   */
  mask: PropTypes.any,
};

export default DSInputMaskImpl;
