import React from 'react';
import { cx } from '@elliemae/ds-utilities/utils';
import DSSeparator from '@elliemae/ds-basic/Separator';
import { aggregatedClasses } from '@elliemae/ds-classnames';
import { dataGridBlockName } from '../../blockNames';

const blockName = `${dataGridBlockName}-expanded-row`;

const DetailsWrapper = aggregatedClasses('div')(
  `${blockName}-details-wrapper`,
  null,
  () => ({ 'ie-flex-basis-auto': true }),
);

function ExpandedRowExtra({
  children,
  rowData,
  rowProps,
  rowComponent: RowComponent,
  renderRowDetails = () => null,
}) {
  return (
    <RowComponent
      {...rowProps}
      className={cx(rowProps.className, 'expanded-detail-row')}
    >
      <RowComponent className="master-row-expanded">{children}</RowComponent>
      <DSSeparator dashed margin="xs" />
      <DetailsWrapper>{renderRowDetails({ rowData })}</DetailsWrapper>
    </RowComponent>
  );
}

export default ExpandedRowExtra;
