import React from 'react';
import { get } from 'lodash';
import { components } from 'react-select';

function MultiValueRemove(props) {
  const value = get(props, 'selectProps.value');
  if (Array.isArray(value) && value.length > 1) {
    return (
      <components.MultiValueRemove {...props}>,</components.MultiValueRemove>
    );
  }
  return (
    <components.MultiValueRemove {...props}> </components.MultiValueRemove>
  );
}

export default MultiValueRemove;
