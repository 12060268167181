export const MODAL_TYPE_V2 = {
  INFORMATION: 'information',
  DECISION: 'decision',
  SELECTION: 'selection',
  FORM: 'form',
};

export const MODAL_SUB_TYPE_V2 = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFORMATION: 'information',
};
