import DateRangeFilterMenu from './DateRangeFilterMenu';
import SingleDateFilterMenu from './SingleDateFilterMenu'; // implement
import SingleRangeDateSwitcher from './SingleRangeSwitcherMenu'; // implement
import TextFilterMenu from './TextFilterMenu';

const ComponentTypes = {
  'date-range': DateRangeFilterMenu,
  'single-date': SingleDateFilterMenu,
  'date-switcher': SingleRangeDateSwitcher,
  string: TextFilterMenu,
};

export default function getFilterMenuByType(type) {
  return ComponentTypes[type] || ComponentTypes.string;
}
