/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M19.816 17.877l-8.53-15.135C11.007 2.247 10.503 2 10 2s-1.007.247-1.286.742L.184 17.877C-.354 18.832.354 20 1.47 20h17.06c1.117 0 1.824-1.168 1.286-2.123zm-.884.922c-.051.084-.17.224-.402.224H1.47c-.232 0-.351-.14-.402-.224s-.119-.253-.007-.451l8.53-15.135c.116-.205.306-.236.409-.236s.293.031.409.236l8.53 15.135c.112.198.043.368-.007.451z',
    'M9.5 14l-.455-6.461A.498.498 0 0 1 9.541 7h.917c.291 0 .521.249.496.539L10.499 14h-1zM11 16c0 .571-.429 1-1 1s-1-.429-1-1 .429-1 1-1 1 .429 1 1z',
  ],
  height: 20,
  width: 20,
});
