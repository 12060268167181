/* eslint-disable react/display-name */
import React, { useMemo } from 'react';
import { mergeRefs } from '@elliemae/ds-utilities/system';
import FocusGridManager from '@elliemae/ds-shared/FocusGroup/FocusGrid';
import createInstancePlugin from '@elliemae/ds-shared/createDataInstance/createInstancePlugin';
import paginate from './helper';
import { usePaginationState } from './usePaginationState';
import NoResults from '../../components/NoResults';

const composeRows = (rows, grid) => {
  const {
    state: { pagination },
    props: { groupedRows },
  } = grid.getInstance();
  if (groupedRows) return rows; // disable pagination with grouping
  if (groupedRows) {
    const filterIds = rows.map(r => r.id);
    return paginate(
      rows
        .filter(c => filterIds.includes(c.id))
        .map(r => ({
          ...r,
          _isGroup: r.isGroup,
        })),
      pagination,
    );
  }

  return paginate(rows, pagination);
};

const Body = React.memo(
  ({
    component: Component,
    rows,
    rowRenderer,
    noResultsPlaceholder,
    innerRef,
    innerBodyRef, // depends on body-header-scroll-sync
    isPlaceholderActive = false,
    ...otherBodyProps
  }) => (
    <Component {...otherBodyProps} innerRef={mergeRefs(innerRef)}>
      {!isPlaceholderActive ? (
        rows.map((row, index) =>
          rowRenderer(row, { index, rowIndex: index }, {}),
        )
      ) : (
        <NoResults innerRef={innerRef} rowRenderer={rowRenderer}>
          {noResultsPlaceholder}
        </NoResults>
      )}
    </Component>
  ),
);

const decorateRenderers = (renderers, grid) => {
  const BodyWrapper = renderers.body.wrapper;
  renderers.body.wrapper = useMemo(
    () => props => {
      const { hotKeys, shouldRefocus, state } = grid.getInstance();
      return (
        <FocusGridManager hotKeys={hotKeys} shouldRefocus={false}>
          <Body {...props} component={BodyWrapper} />
        </FocusGridManager>
      );
    },
    [],
  );
  return renderers;
};

export const PaginationPlugin = createInstancePlugin('pagination', {
  registerStateHook: usePaginationState,
  decorateRenderers,
  composeRows,
});
