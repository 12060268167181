/* eslint-disable max-lines */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import DSDateInput from '@elliemae/ds-basic/form/DateInput';
import DSInputGroup from '@elliemae/ds-basic/dsinputgroup';
import { aggregatedClasses } from '@elliemae/ds-classnames';

const blockname = 'date-range-picker';

const Header = aggregatedClasses('div')(
  blockname,
  'header',
  ({ invalidDate }) => ({ invalidDate }),
);

const formatDate = (date) => {
  if (date) {
    return date.format('MMDDYYYY');
  }
};

const DatePickerRangeHeader = ({
  onStartInputChange = () => null,
  onEndInputChange = () => null,
  onFocusChange = () => null,
  selectedStartDate,
  selectedEndDate,
  handleDatesChange,
  autoJumpOnEdit = true,
}) => {
  const [startValue, setStartValue] = useState(selectedStartDate);
  const [endValue, setEndValue] = useState(selectedEndDate);
  const startInputRef = useRef();
  const endInputRef = useRef();

  useEffect(() => {
    if (selectedStartDate) {
      setStartValue(selectedStartDate);
    }
    if (selectedEndDate) {
      setEndValue(selectedEndDate);
    }
  }, [selectedStartDate, selectedEndDate]);

  const isValidRangeDate = (start, end) => {
    let isValidRange = false;
    if (start && end) {
      isValidRange = formatDate(start) < formatDate(end);
    }
    return isValidRange;
  };

  const handleOnFocus = (inputType) => onFocusChange(inputType);

  const handleOnChangeStart = (newStartValue) => {
    onStartInputChange(newStartValue);
    setStartValue(newStartValue);
    if (autoJumpOnEdit) {
      endInputRef.current.firstChild.firstChild.focus();
    }
  };

  const handleOnChangeEnd = (newEndValue) => {
    if (isValidRangeDate(startValue, newEndValue)) {
      onEndInputChange(newEndValue);
      setEndValue(newEndValue);
    }
  };

  const handleOnKeyDownStart = (e, newDate) => {
    const isValid = isValidRangeDate(newDate, endValue);
    if (e.key === 'Enter' && newDate) {
      onStartInputChange(startValue);
      if (isValid) handleDatesChange({ startDate: newDate, endDate: endValue });
    }
  };

  const handleOnKeyDownEnd = (e, newDate) => {
    const isValid = isValidRangeDate(startValue, newDate);
    if (e.key === 'Enter' && isValid) {
      handleDatesChange({ startDate: startValue, endDate: newDate });
      onEndInputChange(newDate);
    }
  };

  return (
    <Header>
      <DSInputGroup>
        <DSDateInput
          INTERNAL_V2_NO_MUTATION
          containerProps={{ 'data-testid': 'start-date' }}
          innerRef={startInputRef}
          onChange={handleOnChangeStart}
          onFocus={() => handleOnFocus('startDate')}
          onKeyDown={handleOnKeyDownStart}
          value={startValue}
          clearable={false}
        />
        <DSDateInput
          INTERNAL_V2_NO_MUTATION
          containerProps={{ 'data-testid': 'end-date' }}
          innerRef={endInputRef}
          onChange={handleOnChangeEnd}
          onFocus={() => handleOnFocus('endDate')}
          onKeyDown={handleOnKeyDownEnd}
          value={endValue}
          clearable={false}
        />
      </DSInputGroup>
    </Header>
  );
};

DatePickerRangeHeader.propTypes = {
  onStartInputChange: PropTypes.func,
  onEndInputChange: PropTypes.func,
  onFocusChange: PropTypes.func,
  selectedStartDate: PropTypes.any,
  selectedEndDate: PropTypes.any,
  handleDatesChange: PropTypes.func,
  autoJumpOnEdit: PropTypes.bool,
};

export default DatePickerRangeHeader;
