import React from 'react';
import { components } from 'react-select';

function MultiValueRemove({ innerProps, ...rest }) {
  const innerPropsWithOutRemoval = {
    ...innerProps,
    onClick: () => {},
    onTouchEnd: () => {},
    onMouseDown: () => {},
  };
  return (
    <components.MultiValueRemove
      {...rest}
      innerProps={innerPropsWithOutRemoval}
    >
      ,
    </components.MultiValueRemove>
  );
}

export default MultiValueRemove;
