/* eslint-disable react/no-unknown-property */
import React, { useRef } from 'react';
import { PropTypes, describe } from 'react-desc';
import { convertPropToCssClassName } from '@elliemae/ds-classnames';
import Circle from './Circle';

const DSRadio = ({
  containerProps = {},
  className = '',
  style,
  labelText,
  htmlFor,
  hasError = false,
  readOnly = false,
  disabled = false,
  checked = false,
  value,
  name = '',
  onChange = () => null,
  children = null,
  tabIndex = 0,
  ...otherProps
}) => {
  const { cssClassName, classNameElement } = convertPropToCssClassName(
    'form-element-radio',
    className,
    {
      hasError,
      readOnly,
      disabled,
    },
  );
  const checkRef = useRef(null);
  return (
    <div {...containerProps} className={cssClassName}>
      <label className={classNameElement('label')} htmlFor={htmlFor}>
        <input
          {...otherProps}
          ref={checkRef}
          aria-disabled={disabled}
          checked={disabled ? false : checked}
          className={classNameElement('input')}
          disabled={disabled}
          name={name}
          onChange={(e) => {
            if (!readOnly && !disabled) {
              onChange(e);
            }
          }}
          readOnly={readOnly}
          tabIndex={-1}
          type="radio"
          value={value}
        />
        <span
          ref={otherProps.innerRef}
          aria-checked={disabled ? false : checked}
          aria-disabled={disabled}
          className={classNameElement('input-button')}
          onKeyDown={(e) => {
            e.target = checkRef.current;
            if (disabled) return;
            if (e.key === 'Enter' || e.keyCode === 32) {
              onChange(e);
            } else if (otherProps.onKeyDown) {
              otherProps.onKeyDown(e);
            }
          }}
          role="radio"
          style={style}
          tabIndex={tabIndex}
          value={value}
        >
          <Circle checked={disabled ? false : checked} />
        </span>
        <span className={classNameElement('label-text')}>{labelText}</span>
      </label>
      {children && (
        <div className={classNameElement('radio-children')}>{children}</div>
      )}
    </div>
  );
};

const radioProps = {
  /** Injected props to wrapper element of component */
  containerProps: PropTypes.object.description(
    'Injected props to wrapper element of component',
  ),
  /** css class prop */
  className: PropTypes.string.description('css class prop'),
  /** name property to manage form */
  name: PropTypes.string.description('name property to manage form'),
  /**
   * Displayable label for the radio
   */
  labelText: PropTypes.string.description('Displayable label for the radio'),
  /** HTMLFOR property to manage form */
  htmlFor: PropTypes.element.description('HTMLFOR property to manage form'),
  /**
   * Whether the radio has error or not
   */
  hasError: PropTypes.bool.description('Whether the radio has error or not'),
  /**
   * Whether the radio is read only or not
   */
  readOnly: PropTypes.bool.description('Whether the radio is read only or not'),
  /**
   * Whether the radio is disabled or not
   */
  disabled: PropTypes.bool.description('Whether the radio is disabled or not'),
  /**
   * Whether the radio is checked or not
   */
  checked: PropTypes.bool.description('Whether the radio is checked or not'),
  /**
   * Value that takes the checkbox if it is checked
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired.description('Value that takes the checkbox if it is checked'),
  /**
   * Allows a function that is triggered once the radio changes
   */
  onChange: PropTypes.func.description(
    'Allows a function that is triggered once the radio changes',
  ),
  /**
   * Optional, if you want a checkbox customized
   */
  children: PropTypes.node.description(
    'Optional, if you want a checkbox customized',
  ),
  /** HTML tabindex to manage focus */
  tabIndex: PropTypes.object.description('HTML tabindex to manage focus'),
  /** style override object */
  style: PropTypes.number.description('style override object'),
};

DSRadio.propTypes = radioProps;

const DSRadioWithSchema = describe(DSRadio).description(
  'Radio button component',
);
DSRadioWithSchema.propTypes = radioProps;

export { DSRadioWithSchema };

export default DSRadio;
