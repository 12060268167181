const basicAction = ({
  readOnly = false,
  disabled = false,
  checked = false,
}) => {
  const cssModifier = [];
  // input state modifiers
  if (checked) cssModifier.push('checked');
  if (readOnly) cssModifier.push('readonly');
  if (disabled) cssModifier.push('disabled');
  return cssModifier;
};

const instance = [basicAction];
const pipe = () => ({
  getInstance() {
    return instance;
  },
  add(customModifier) {
    instance.push(customModifier);
  },
  execute(props) {
    return instance.reduce((temp, action) => [...temp, ...action(props)], []);
  },
});

export default pipe;
