/* eslint-disable max-lines */
import React, { Component } from 'react';
import getComponentFromProps from '@elliemae/ds-utilities/getComponentFromProps';
import { Manager, Reference } from 'react-popper';
import memoizeOne from 'memoize-one';
import { isDOMTypeElement } from '@elliemae/ds-utilities/reactTypesUtility';
import { isFunction, get, runAll } from '@elliemae/ds-utilities/utils';
import { mergeRefs } from '@elliemae/ds-utilities/system';
import PopperContent from './PopperContent';
import { Interaction } from './interaction';

export default class PopperImpl extends Component {
  // we use safeIsMounted because isMounted is not allowed by React.
  safeIsMounted = false;

  handlePopperPlacementChange = memoizeOne(placement => {
    const { onPlacementChange } = this.props;
    onPlacementChange(placement);
  });

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      destroyed: true,
    };
    // TODO: Fix the autobinding to remove this!!
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleTriggerClick = this.handleTriggerClick.bind(this);
    this.renderTarget = this.renderTarget.bind(this);
    this.updatePopoverState = this.updatePopoverState.bind(this);
    this.handlePopperPlacementChange = this.handlePopperPlacementChange.bind(
      this,
    );
    this.handleDestroyContent = this.handleDestroyContent.bind(this);
    this.handleContentMouseEnter = this.handleContentMouseEnter.bind(this);
    this.handleContentMouseLeave = this.handleContentMouseLeave.bind(this);
  }

  // this is needed to prevent setState on unmounted components
  componentDidMount() {
    this.safeIsMounted = true;
  }

  componentDidUpdate() {
    const { isOpen, destroyed } = this.state;
    if (isOpen === true && destroyed === true) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ destroyed: false });
    }
  }

  componentWillUnmount() {
    this.safeIsMounted = false;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isOpen } = nextProps;
    const { prevPropIsOpen } = prevState;
    // todo: this causes multiple rerenders
    if (isOpen !== prevPropIsOpen) {
      return {
        isOpen,
        prevPropIsOpen: isOpen,
      };
    }
    return null;
  }

  getInteractionObject(interaction, referenceProps = {}) {
    const { isOpen } = this.props;
    if (isOpen !== undefined) return {};
    return (
      {
        [Interaction.CLICK]: {
          handlers: {
            onClick: this.handleTriggerClick,
          },
          canClose: () => true,
        },
        [Interaction.HOVER]: {
          handlers: {
            onMouseEnter: referenceProps.onMouseEnter || this.handleMouseEnter,
            onMouseLeave: referenceProps.onMouseLeave || this.handleMouseLeave,
            onClick: this.handleTriggerClick,
          },
          canClose: (isOnTrigger, isOnPopper) => !isOnTrigger && !isOnPopper,
        },
      }[interaction] || {
        handlers: {},
        canClose: () => true,
      }
    );
  }

  setOpen(opening) {
    const { onOpen } = this.props;
    const { prevPropIsOpen } = this.state;
    if (!this.safeIsMounted) return;

    if (prevPropIsOpen === undefined) {
      this.setState({
        isOpen: opening,
        destroyed: false,
      });
    }
    onOpen(opening);
  }

  open() {
    clearTimeout(this.closeTimer);
    const { delayOpen } = this.props;
    this.openTimer = setTimeout(() => this.setOpen(true), delayOpen);
  }

  close() {
    clearTimeout(this.openTimer);
    const { delayClose, onClose = () => null } = this.props;
    this.closeTimer = setTimeout(() => {
      this.setOpen(false);
      onClose();
    }, delayClose);
  }

  handleTriggerClick(e) {
    const { triggerComponent } = this.props;
    const { onClick } = get(triggerComponent, 'props') || {};

    this.open();

    if (isFunction(onClick)) onClick(e);
  }

  handleContentMouseEnter() {
    this.isMouseOverContent = true;
  }

  handleContentMouseLeave() {
    this.isMouseOverContent = false;
    const { onClose = () => null } = this.props;
    setTimeout(() => {
      if (!this.isMouseOverTarget) {
        this.setOpen(false);
        onClose();
      }
    }, 10);
  }

  handleMouseEnter() {
    this.isMouseOverTarget = true;
    this.open();
  }

  handleMouseLeave() {
    this.isMouseOverTarget = false;
    setTimeout(() => {
      if (!this.isMouseOverContent) {
        this.close();
      }
    }, 10);
  }

  updatePopoverState(popperData) {
    this.handlePopperPlacementChange(popperData.placement);
    return popperData;
  }

  handleDestroyContent() {
    this.setState({ destroyed: true });
  }

  renderTarget({ ref }) {
    const { isOpen } = this.state;
    const { triggerComponent, interactionType, renderReference } = this.props;

    const componentInnerRef = get(
      triggerComponent,
      ['props', 'innerRef'],
      () => null,
    );
    const compRef = isDOMTypeElement(triggerComponent)
      ? { ref }
      : { innerRef: mergeRefs(ref, componentInnerRef) };

    const triggerHandler = this.getInteractionObject(
      interactionType,
      triggerComponent.props,
    ).handlers;

    const targetProps = {
      ...triggerHandler,
      ...compRef,
      'aria-haspopup': true,
      'aria-expanded': isOpen,
    };

    if (renderReference) {
      return renderReference({
        ref,
        ...targetProps,
      });
    }

    return getComponentFromProps(triggerComponent, targetProps);
  }

  render() {
    const { isOpen, destroyed } = this.state;

    const {
      placement,
      interactionType,
      showArrow,
      arrowSize,
      arrowGap,
      preventOverflow,
      children: customPopper,
      usePortal,
      blockName,
      contentComponent,
      openAnimationConfig,
      closeAnimationConfig,
      modifiers,
      referenceNode,
      containerProps,
      zIndex,
    } = this.props;

    const allModifiers = {
      offset: {
        enabled: showArrow,
        offset: `0, ${arrowSize + arrowGap}`,
      },
      preventOverflow: {
        enabled: true,
        padding: 0,
        boundariesElement: preventOverflow,
      },
      updatePopoverState: {
        enabled: true,
        fn: this.updatePopoverState,
        order: 900,
      },
      ...modifiers,
    };
    const triggerHandler = this.getInteractionObject(interactionType).handlers;

    const popperContentProps = {
      containerProps,
      isOpen,
      destroyed: isOpen ? false : destroyed,
      arrowSize,
      blockName,
      showArrow,
      modifiers: allModifiers,
      contentComponent,
      onContentDestroyed: this.handleDestroyContent,
      onClickOutside: () => this.close(),
      placement,
      usePortal,
      openAnimationConfig,
      closeAnimationConfig,
      onMouseEnter:
        interactionType === Interaction.HOVER
          ? this.handleContentMouseEnter
          : undefined,
      onMouseLeave:
        interactionType === Interaction.HOVER
          ? this.handleContentMouseLeave
          : undefined,
      referenceNode,
      zIndex,
    };

    if (customPopper && typeof customPopper === 'function') {
      return (
        <Manager>
          {customPopper({
            Reference,
            triggerHandler,
            content: <PopperContent {...popperContentProps} />,
          })}
        </Manager>
      );
    }

    return (
      <Manager>
        <Reference>{this.renderTarget}</Reference>
        <PopperContent {...popperContentProps} />
      </Manager>
    );
  }
}

export { Reference, PopperContent };
