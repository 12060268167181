const letterFactor = 5.7;
const fixedSpace = 45;
export function calculateWidth(options) {
  // get longest label string
  const longest = options.reduce((a, b) => {
    if (a && a.label && b && b.label)
      return a.label.length > b.label.length ? a : b;
    return 0;
    // Calling reduce on an empty array without an initialValue will throw a TypeError.
  }, options[0] || null);
  if (longest && longest.label && longest.label.length) {
    // if (longest.label.length > fixedSpace) return fixedSpace * letterFactor
    return longest.label.length * letterFactor + fixedSpace;
  }
  return 0;
}
