import { useRef, useEffect, useState } from 'react';
import { mergeRefs } from '@elliemae/ds-utilities/system';
import createInstancePlugin from '@elliemae/ds-shared/createDataInstance/createInstancePlugin';

// TODO: EXPORT A SEPARATED INFINITE LOADER GRID

const registerStateHook = grid => {
  const {
    props: { getData, onInfiniteScrolling = () => null },
    actions: { updateRows },
  } = grid;

  const loading = useRef(false);

  const addMoreData = (data = []) => {
    loading.current = false;
    updateRows(prevRows => prevRows.concat(data || []));
  };

  const doLoadData = async currentRows => {
    loading.current = true;
    return Promise.resolve(getData(currentRows)).then(addMoreData);
  };

  const loadMoreData = currentRows => {
    onInfiniteScrolling(currentRows);
    if (loading.current || !getData) return;
    doLoadData(currentRows);
  };

  return {
    actions: {
      loadMoreData,
    },
  };
};

const getBodyProps = ({ children, ...props }, grid) => {
  const { loadMoreData } = grid.getInstance().actions;

  const [bodyNode, setBodyRef] = useState();
  const prevScroll = useRef();
  prevScroll.current = 0;

  const handleBodyScroll = (event, s) => {
    const { target } = event;
    const { scrollHeight, scrollTop, offsetHeight, scrollLeft } = target;
    const { composedRows } = grid.getInstance();
    // prevent horizontal scroll triggering infinite scrolling callbacks
    if (prevScroll.current !== scrollLeft) {
      prevScroll.current = scrollLeft;
      return;
    }
    // todo: clean this. Better approach using another component for the infinite scroll
    if (bodyNode !== target) return;
    if (scrollTop + offsetHeight >= scrollHeight - 1) {
      loadMoreData(composedRows);
    }
  };

  useEffect(() => {
    if (bodyNode) {
      bodyNode.addEventListener('scroll', handleBodyScroll);
    }
    return () => {
      if (bodyNode) {
        bodyNode.removeEventListener('scroll', handleBodyScroll);
      }
    };
  }, [bodyNode]);

  return {
    ...props,
    listProps: { outerRef: setBodyRef },
  };
};

export const InfiniteScrollPlugin = createInstancePlugin('infinite-scroll', {
  registerStateHook,
  getBodyProps,
});
