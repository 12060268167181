import React from 'react';
import PropTypes from 'prop-types';
import PopperImpl from './PopperImpl';
import { Position } from './positions';
import { Interaction } from './interaction';

const DSPopper = ({
  containerProps = {},
  arrowGap = 8,
  arrowSize = 6,
  blockName = '',
  contentOffset = null,
  contentStyle = null,
  contentComponent = () => null,
  delayClose = 300,
  delayOpen = 200,
  interactionType = Interaction.CLICK,
  isOpen = undefined, // if this is set DSPopper would be a controlled component if not, an uncontrolled one
  onOpen = () => null,
  onClose = () => null,
  onPlacementChange = () => null,
  placement = Position.BOTTOM,
  preventOverflow = 'window',
  showArrow = true,
  closeAnimationConfig = {
    to: { transform: 'scale(0.8)', opacity: '0' },
    config: { delay: 80, duration: 80 },
  },
  openAnimationConfig = {
    to: { transform: 'scale(1)', opacity: '1' },
    config: { delay: 80, duration: 80 },
  },
  usePortal = true,
  triggerComponent = () => null,
  wrapExtraComponent = undefined,
  getTargetFromChildren = () => null,
  getContentFromChildren = () => null,
  modifiers = {},
  triggerRef = () => null,
  children: customPopper,
  renderReference = undefined,
  referenceNode = undefined,
  zIndex,
}) => (
  <PopperImpl
    arrowGap={arrowGap}
    arrowSize={arrowSize}
    blockName={blockName}
    closeAnimationConfig={closeAnimationConfig}
    containerProps={containerProps}
    contentComponent={contentComponent}
    contentOffset={contentOffset}
    contentStyle={contentStyle}
    delayClose={delayClose}
    delayOpen={delayOpen}
    getContentFromChildren={getContentFromChildren}
    getTargetFromChildren={getTargetFromChildren}
    interactionType={interactionType}
    isOpen={isOpen}
    modifiers={modifiers}
    onOpen={onOpen}
    onClose={onClose}
    onPlacementChange={onPlacementChange}
    openAnimationConfig={openAnimationConfig}
    placement={placement}
    preventOverflow={preventOverflow}
    referenceNode={referenceNode}
    renderReference={renderReference}
    showArrow={showArrow}
    triggerComponent={triggerComponent}
    triggerRef={triggerRef}
    usePortal={usePortal}
    wrapExtraComponent={wrapExtraComponent}
    zIndex={zIndex}
  >
    {customPopper}
  </PopperImpl>
);

// @deprecated: There's the NEW (not finished) popover that uses its own popper implementation.
DSPopper.propTypes = {
  containerProps: PropTypes.shape({}),
  /** Space between the arrow and the popup */
  arrowGap: PropTypes.number,
  /** Arrow size */
  arrowSize: PropTypes.number,
  /** Bem blockname for the popper */
  blockName: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

export { Interaction as PopperInteractions, Position as PopperPositions };

export default DSPopper;
