import React from 'react';
import styled from 'styled-components';
import { components } from 'react-select';
import DSSeparator from '../../../../Separator';
import { getGroupHeaderPosition } from './utils';

const GroupHeading = props => {
  const headerPosition = getGroupHeaderPosition(props.id);
  const { hasSeparator } = props.selectProps.options[headerPosition];

  return (
    <Wrapper>
      {hasSeparator && <StyledSeparator type="non-form" />}
      <components.GroupHeading {...props} />
    </Wrapper>
  );
};

const StyledSeparator = styled(DSSeparator)`
  width: auto;
  margin: ${props => props.theme.space.xs};
  margin-top: unset;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default GroupHeading;
