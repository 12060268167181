/* eslint-disable prefer-template,max-statements,no-nested-ternary */
function getSvgPath({
  labelWidth = 50,
  width = 200,
  height = 35,
  radiusStyle,
  hasRightRadius = true,
  hasLeftRadius = true,
}) {
  const notchWidth = labelWidth;
  const isRtl = false;

  const radius = parseFloat(radiusStyle);
  const cornerWidth = radius + 1.3;
  const leadingStrokeLength = 4;
  const paddedNotchWidth = notchWidth + 8;

  let extraSizeForH = 0;
  let extraSizeForLeftV = 0;
  let extraSizeForRightV = 0;
  let extraSizeForTopH = radius;

  if (!hasRightRadius) {
    extraSizeForH += radius;
    extraSizeForRightV = radius * 2;
  }
  if (!hasLeftRadius) {
    extraSizeForH += radius;
    extraSizeForLeftV = radius * 2;
  }

  if ((!hasLeftRadius && hasRightRadius) || (hasRightRadius && hasLeftRadius)) {
    extraSizeForTopH = 0;
  }

  const pathMiddle =
    '' +
    (hasRightRadius
      ? 'a' + radius + ',' + radius + ' 0 0 1 ' + radius + ',' + radius
      : '') +
    'v' +
    (height - (2 * cornerWidth - (extraSizeForRightV + 1.5))) + // right vertical line
    (hasRightRadius
      ? 'a' + radius + ',' + radius + ' 0 0 1 ' + -radius + ',' + radius
      : '') +
    'h' +
    (-width + 2 * cornerWidth - extraSizeForH) +
    (hasLeftRadius
      ? 'a' + radius + ',' + radius + ' 0 0 1 ' + -radius + ',' + -radius
      : '') +
    'v' +
    (-height + (2 * cornerWidth - (extraSizeForLeftV + 1.5))) + // left vertical line
    (hasLeftRadius
      ? 'a' + radius + ',' + radius + ' 0 0 1 ' + radius + ',' + -radius
      : '');

  let path;
  if (!isRtl) {
    path =
      '' +
      'M' +
      (cornerWidth + leadingStrokeLength + paddedNotchWidth) +
      ',' +
      0.5 +
      'h' +
      (width -
        2 * cornerWidth -
        paddedNotchWidth -
        leadingStrokeLength +
        extraSizeForTopH) +
      pathMiddle +
      'h' +
      leadingStrokeLength;
  } else {
    path =
      '' +
      'M' +
      (width - cornerWidth - leadingStrokeLength) +
      ',' +
      0.5 +
      'h' +
      leadingStrokeLength +
      pathMiddle +
      'h' +
      (width - 2 * cornerWidth - paddedNotchWidth - leadingStrokeLength);
  }
  return path;
}

export default getSvgPath;
