/* eslint-disable max-lines */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { DayPickerRangeController } from '@elliemae/ds-date-range-picker';
import {
  DSDatePickerController,
  SingleRangeDateSwitcher,
} from '@elliemae/ds-date-picker';
import DSPopper, {
  PopperPositions as Position,
} from '@elliemae/ds-basic/Popper';
import DatePickerIcon from '@elliemae/ds-icons/DatePicker2';
import DSButton from '@elliemae/ds-basic/Button';
import moment from 'moment';
import { aggregatedClasses } from '@elliemae/ds-classnames';
import { DEFAULT_DATE_FORMAT } from './defaultDateFormat';

const START_DATE = 'startDate';
const END_DATE = 'endDate';

const blockname = 'date-range';
const Wrapper = aggregatedClasses('div')(blockname, 'wrapper');

const SingleRangeSwitcherContainer = ({
  onChange = () => null,
  enableOutsideDays,
  ...otherProps
}) => {
  const [focusedInputHeader, setFocusedInputHeader] = useState(START_DATE);
  const [focusedInputPicker, setFocusedInputPicker] = useState(START_DATE);
  const [singleDate, setSingleDate] = useState();
  const [dateRange, setDateRange] = useState({});
  const [isDateRange, setIsDateRange] = useState(false);
  const [pickerRange, setPickerRange] = useState({});

  const handleHeaderFocusChange = (inputType) => {
    setFocusedInputHeader(inputType);
  };

  const handlePickerFocusChange = () => {
    setFocusedInputPicker(
      focusedInputPicker === START_DATE ? END_DATE : START_DATE,
    );
  };

  const handleDatesChange = (dates) => {
    if (dates.endDate) {
      setTimeout(() => {
        onChange({
          startDate: dates.startDate,
          endDate: dates.endDate.endOf('day'),
        });
      }, 500);
    }
    setPickerRange({
      startDate: dates.startDate,
      endDate: dateRange.endDate,
    });
    setDateRange(dates);
  };

  const handlePickerChange = (dates) => {
    if (dates.endDate) {
      setTimeout(() => {
        onChange({
          startDate: dates.startDate,
          endDate: dates.endDate,
        });
      }, 500);
    }
    if (dates.startDate) handleHeaderFocusChange();
    setPickerRange(dates);
    setDateRange(dates);
  };

  const handleSingleDateChange = useCallback((date) => {
    const dates = {
      startDate: moment(date).startOf('day'),
      endDate: moment(date).endOf('day'),
    };
    onChange(dates);
    setSingleDate(dates);
  }, []);

  const handleStartInputChange = (newDate) => {
    setDateRange({
      startDate: newDate,
      endDate: dateRange.endDate,
    });
    setPickerRange({ ...pickerRange, startDate: newDate });
  };

  const handleEndInputChange = (newDate) => {
    setDateRange({
      startDate: dateRange.startDate,
      endDate: newDate,
    });
    setPickerRange({ ...pickerRange, endDate: newDate });
  };

  const dateRangeController = (
    <DayPickerRangeController
      {...otherProps}
      enableOutsideDays={enableOutsideDays}
      endDate={pickerRange.endDate}
      focusedInput={focusedInputPicker}
      hideKeyboardShortcutsPanel
      onDatesChange={handlePickerChange}
      onFocusChange={handlePickerFocusChange}
      startDate={pickerRange.startDate}
    />
  );

  const singleDateController = (
    <DSDatePickerController
      date={singleDate}
      enableOutsideDays={enableOutsideDays}
      onDateChange={handleSingleDateChange}
    />
  );

  return (
    <Wrapper data-testid="date-single-range__wrapper">
      <SingleRangeDateSwitcher
        isDateRange={isDateRange}
        focusedInput={focusedInputHeader}
        onFocusChange={handleHeaderFocusChange}
        onEndInputChange={handleEndInputChange}
        onSingleInputChange={handleSingleDateChange}
        onStartInputChange={handleStartInputChange}
        selectedEndDate={dateRange.endDate}
        selectedSingleDate={singleDate}
        selectedStartDate={dateRange.startDate}
        setIsDateRange={setIsDateRange}
        handleDatesChange={handleDatesChange}
      />
      {isDateRange ? dateRangeController : singleDateController}
    </Wrapper>
  );
};

SingleRangeSwitcherContainer.propTypes = {
  onChange: PropTypes.func,
  enableOutsideDays: PropTypes.bool,
};

const formatRangeDate = (
  { startDate, endDate },
  format = DEFAULT_DATE_FORMAT,
) => {
  const sameDay = startDate.isSame(endDate, 'day');
  if (sameDay) return `${endDate.format(format)}`;
  return `${startDate.format(format)} - ${endDate.format(format)}`;
};

function SingleRangeSwitcherMenu({
  column,
  className,
  isMenuOpened = false,
  onOpenMenu = () => null,
  placement = Position.BOTTOM_END,
  onAddFilter = () => null,
  dateFormatter = formatRangeDate,
  enableOutsideDays = true,
  menuProps,
}) {
  return (
    <DSPopper
      contentComponent={
        <div className={className}>
          <SingleRangeSwitcherContainer
            enableOutsideDays={enableOutsideDays}
            minimumNights={0}
            onChange={({ startDate, endDate }) => {
              startDate.startOf('day');
              endDate.endOf('day');
              onAddFilter(
                {
                  group: column.property,
                  label: column.label,
                  value: dateFormatter({ startDate, endDate }, column.format),
                  type: 'date', // todo: create constants for filter types
                  filterParams: { start: startDate, end: endDate },
                  transformRowValue: (value) =>
                    moment(value, column.dateFormat || DEFAULT_DATE_FORMAT),
                  operator: 'range',
                },
                column,
              );
              onOpenMenu(false);
            }}
          />
        </div>
      }
      isOpen={isMenuOpened}
      onOpen={onOpenMenu}
      placement={placement}
      showArrow={false}
      triggerComponent={
        <DSButton
          buttonType="text"
          icon={<DatePickerIcon />}
          onClick={() => (!isMenuOpened ? onOpenMenu(true) : onOpenMenu(false))}
          size="s"
        />
      }
      zIndex={menuProps.zIndex}
    />
  );
}

SingleRangeSwitcherMenu.propTypes = {
  column: PropTypes.any,
  className: PropTypes.string,
  isMenuOpened: PropTypes.bool,
  onOpenMenu: PropTypes.func,
  placement: PropTypes.string,
  onAddFilter: PropTypes.func,
  dateFormatter: PropTypes.any,
  enableOutsideDays: PropTypes.bool,
  menuProps: PropTypes.any,
};

export default SingleRangeSwitcherMenu;
