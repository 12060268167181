function getPath(parentNode, index, childrenKey) {
  if (!parentNode) return [index];
  return [...parentNode.path, childrenKey, index];
}

export function walkTreeStrategy({
  data,
  childrenKey = 'children',
  level = 0,
  getId = item => item.id,
  parentNode,
  shouldWalkChildren = () => true,
  cb,
}) {
  // eslint-disable-next-line no-loops/no-loops,no-plusplus
  for (let i = 0; i < data.length; i++) {
    const currLevel = level + 1;
    const children = data[i][childrenKey];
    const isGroup = children && !!children.length;
    const node = {
      index: i,
      id: getId(data[i]),
      data: data[i],
      path: getPath(parentNode, i, childrenKey),
      isGroup,
      nestingLevel: currLevel,
      parentNode: parentNode || {
        isGroup: true,
        data: { children: data },
      },
    };
    cb(node);
    if (shouldWalkChildren(node) && children && children.length) {
      walkTreeStrategy({
        data: children,
        level: currLevel,
        parentNode: node,
        shouldWalkChildren,
        cb,
      });
    }
  }
}
