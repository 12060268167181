/* eslint-disable max-lines */
const attributeWhiteList = {
  accept: true,
  acceptCharset: true,
  accessKey: true,
  action: true,
  allowFullScreen: true,
  allowTransparency: true,
  alt: true,
  async: true,
  autoComplete: true,
  autoFocus: true,
  autoPlay: true,
  capture: true,
  cellPadding: true,
  cellSpacing: true,
  charSet: true,
  challenge: true,
  checked: true,
  classID: true,
  className: true,
  cols: true,
  colSpan: true,
  content: true,
  contentEditable: true,
  contextMenu: true,
  controls: true,
  coords: true,
  crossOrigin: true,
  data: true,
  dateTime: true,
  defer: true,
  dir: true,
  disabled: true,
  download: true,
  draggable: true,
  encType: true,
  form: true,
  formAction: true,
  formEncType: true,
  formMethod: true,
  formNoValidate: true,
  formTarget: true,
  frameBorder: true,
  headers: true,
  height: true,
  hidden: true,
  high: true,
  href: true,
  hrefLang: true,
  htmlFor: true,
  httpEquiv: true,
  icon: true,
  id: true,
  inputMode: true,
  keyParams: true,
  keyType: true,
  label: true,
  lang: true,
  list: true,
  loop: true,
  low: true,
  manifest: true,
  marginHeight: true,
  marginWidth: true,
  max: true,
  maxLength: true,
  media: true,
  mediaGroup: true,
  method: true,
  min: true,
  minLength: true,
  multiple: true,
  muted: true,
  name: true,
  noValidate: true,
  open: true,
  optimum: true,
  pattern: true,
  placeholder: true,
  poster: true,
  preload: true,
  radioGroup: true,
  readOnly: true,
  rel: true,
  required: true,
  role: true,
  rows: true,
  rowSpan: true,
  sandbox: true,
  scope: true,
  scoped: true,
  scrolling: true,
  seamless: true,
  selected: true,
  shape: true,
  size: true,
  sizes: true,
  span: true,
  spellCheck: true,
  src: true,
  srcDoc: true,
  srcSet: true,
  start: true,
  step: true,
  style: true,
  summary: true,
  tabIndex: true,
  target: true,
  title: true,
  type: true,
  useMap: true,
  value: true,
  width: true,
  wmode: true,
  wrap: true,
};

const svgAttributesWhiteList = {
  clipPath: true,
  cx: true,
  cy: true,
  d: true,
  dx: true,
  dy: true,
  fill: true,
  fillOpacity: true,
  fontFamily: true,
  fontSize: true,
  fx: true,
  fy: true,
  gradientTransform: true,
  gradientUnits: true,
  markerEnd: true,
  markerMid: true,
  markerStart: true,
  offset: true,
  opacity: true,
  patternContentUnits: true,
  patternUnits: true,
  points: true,
  preserveAspectRatio: true,
  r: true,
  rx: true,
  ry: true,
  spreadMethod: true,
  stopColor: true,
  stopOpacity: true,
  stroke: true,
  strokeDasharray: true,
  strokeLinecap: true,
  strokeOpacity: true,
  strokeWidth: true,
  textAnchor: true,
  transform: true,
  version: true,
  viewBox: true,
  x1: true,
  x2: true,
  x: true,
  xlinkActuate: true,
  xlinkArcrole: true,
  xlinkHref: true,
  xlinkRole: true,
  xlinkShow: true,
  xlinkTitle: true,
  xlinkType: true,
  xmlBase: true,
  xmlLang: true,
  xmlSpace: true,
  y1: true,
  y2: true,
  y: true,
};

const otherWhiteList = {
  autoCapitalize: true,
  autoCorrect: true,
  property: true,
  itemProp: true,
  itemScope: true,
  itemType: true,
  itemRef: true,
  itemID: true,
  unselectable: true,
  results: true,
  autoSave: true,
};

const eventsWhiteList = {
  onCopy: true,
  onCut: true,
  onPaste: true,
  onCompositionEnd: true,
  onCompositionStart: true,
  onCompositionUpdate: true,
  onKeyDown: true,
  onKeyPress: true,
  onKeyUp: true,
  onFocus: true,
  onBlur: true,
  onChange: true,
  onInput: true,
  onInvalid: true,
  onReset: true,
  onSubmit: true,
  onError: true,
  onLoad: true,
  onClick: true,
  onContextMenu: true,
  onDoubleClick: true,
  onDrag: true,
  onDragEnd: true,
  onDragEnter: true,
  onDragExit: true,
  onDragLeave: true,
  onDragOver: true,
  onDragStart: true,
  onDrop: true,
  onMouseDown: true,
  onMouseEnter: true,
  onMouseLeave: true,
  onMouseMove: true,
  onMouseOut: true,
  onMouseOver: true,
  onMouseUp: true,
  onPointerDown: true,
  onPointerMove: true,
  onPointerUp: true,
  onPointerCancel: true,
  onGotPointerCapture: true,
  onLostPointerCapture: true,
  onPointerEnter: true,
  onPointerLeave: true,
  onPointerOver: true,
  onPointerOut: true,
  onSelect: true,
  onTouchCancel: true,
  onTouchEnd: true,
  onTouchMove: true,
  onTouchStart: true,
  onScroll: true,
  onWheel: true,
  onAbort: true,
  onCanPlay: true,
  onCanPlayThrough: true,
  onDurationChange: true,
  onEmptied: true,
  onEncrypted: true,
  onEnded: true,
  onLoadedData: true,
  onLoadedMetadata: true,
  onLoadStart: true,
  onPause: true,
  onPlay: true,
  onPlaying: true,
  onProgress: true,
  onRateChange: true,
  onSeeked: true,
  onSeeking: true,
  onStalled: true,
  onSuspend: true,
  onTimeUpdate: true,
  onVolumeChange: true,
  onWaiting: true,
  onAnimationStart: true,
  onAnimationEnd: true,
  onAnimationIteration: true,
  onTransitionEnd: true,
  onToggle: true,
};

export const whiteList = {
  ...attributeWhiteList,
  ...svgAttributesWhiteList,
  ...otherWhiteList,
  ...eventsWhiteList,
};

export const isDataAttribute = (attribute) =>
  attribute.substring(0, 5) === 'data-';
export const isAriaAttribute = (attribute) =>
  attribute.substring(0, 6) === 'aria-';

export const isValidNativeAttribute = (attribute) =>
  whiteList[attribute] === true ||
  isDataAttribute(attribute) ||
  isAriaAttribute(attribute) ||
  attribute.toLowerCase() === attribute;

const nativeHTMLElements = {
  a: true,
  abbr: true,
  address: true,
  area: true,
  article: true,
  aside: true,
  audio: true,
  b: true,
  base: true,
  bdi: true,
  bdo: true,
  big: true,
  blockquote: true,
  body: true,
  br: true,
  button: true,
  canvas: true,
  caption: true,
  cite: true,
  code: true,
  col: true,
  colgroup: true,
  data: true,
  datalist: true,
  dd: true,
  del: true,
  details: true,
  dfn: true,
  dialog: true,
  div: true,
  dl: true,
  dt: true,
  em: true,
  embed: true,
  fieldset: true,
  figcaption: true,
  figure: true,
  footer: true,
  form: true,
  h1: true,
  h2: true,
  h3: true,
  h4: true,
  h5: true,
  h6: true,
  head: true,
  header: true,
  hr: true,
  html: true,
  i: true,
  iframe: true,
  img: true,
  input: true,
  ins: true,
  kbd: true,
  keygen: true,
  label: true,
  legend: true,
  li: true,
  link: true,
  main: true,
  map: true,
  mark: true,
  menu: true,
  menuitem: true,
  meta: true,
  meter: true,
  nav: true,
  noscript: true,
  object: true,
  ol: true,
  optgroup: true,
  option: true,
  output: true,
  p: true,
  param: true,
  picture: true,
  pre: true,
  progress: true,
  q: true,
  rp: true,
  rt: true,
  ruby: true,
  s: true,
  samp: true,
  script: true,
  section: true,
  select: true,
  small: true,
  source: true,
  span: true,
  strong: true,
  style: true,
  sub: true,
  summary: true,
  sup: true,
  table: true,
  tbody: true,
  td: true,
  textarea: true,
  tfoot: true,
  th: true,
  thead: true,
  time: true,
  title: true,
  tr: true,
  track: true,
  u: true,
  ul: true,
  var: true,
  video: true,
  wbr: true,
};

export const isNativeHTMLElement = (type) => nativeHTMLElements[type] === true;
