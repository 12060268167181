import React from 'react';
import { PropTypes, describe } from 'react-desc';

const DSError = ({ hasError = false, errorText = 'Error' }) => {
  if (!hasError) return null;
  return <div className="em-ds-error">{errorText}</div>;
};

const props = {
  /** error state */
  hasError: PropTypes.bool.description('error state'),
  /** error label text */
  errorText: PropTypes.string.description('error label text'),
};

DSError.propTypes = props;

const DSErrorWithSchema = describe(DSError);

DSErrorWithSchema.propTypes = props;

export default DSError;
export { DSErrorWithSchema };
