/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M14.031 10.5c0 .105-.033.211-.1.3l-6 8c-.166.221-.479.266-.7.1s-.266-.479-.101-.7l5.775-7.7L7.13 2.8c-.165-.22-.12-.533.101-.7.221-.165.534-.12.7.101l6 8c.066.089.1.194.1.3z',
  ],
  height: 20,
  width: 20,
});
