import React from 'react';
import Pagify from 'react-pagify';
import segmentize from 'segmentize';
import { aggregatedClasses } from '@elliemae/ds-classnames';

const blockName = 'data-grid';

const PaginationContainer = aggregatedClasses('div')(blockName, 'pagination');
const PagifyContext = aggregatedClasses(Pagify.Context)(
  blockName,
  'pagify-pagination',
);

const Paginator = ({
  pagination,
  pages,
  onSelect,
  labelPrevious = 'Previous',
  labelNext = 'Next',
}) => {
  const prevPage = pagination.page - 1;
  const nextPage = pagination.page + 1;
  return (
    <PaginationContainer>
      <PagifyContext
        onSelect={page => page !== pagination.page && onSelect(page)}
        segments={segmentize({
          page: pagination.page,
          pages,
          beginPages: 1,
          endPages: 1,
          sidePages: 1,
        })}
      >
        <Pagify.Button
          className={pagination.page <= 1 ? 'button-disabled' : ''}
          page={prevPage}
        >
          {labelPrevious}
        </Pagify.Button>

        <Pagify.Segment field="beginPages" />

        <Pagify.Ellipsis
          className="ellipsis"
          nextField="previousPages"
          previousField="beginPages"
        />

        <Pagify.Segment field="previousPages" />
        <Pagify.Segment className="selected" field="centerPage" />
        <Pagify.Segment field="nextPages" />

        <Pagify.Ellipsis
          className="ellipsis"
          nextField="endPages"
          previousField="nextPages"
        />

        <Pagify.Segment field="endPages" />

        <Pagify.Button
          className={pagination.page >= pages ? 'button-disabled' : ''}
          page={nextPage}
        >
          {labelNext}
        </Pagify.Button>
      </PagifyContext>
    </PaginationContainer>
  );
};

export default Paginator;
