import { aggregatedClasses } from '@elliemae/ds-classnames';
import {
  bodyBlockName,
  dataGridBlockName,
  headerBlockName,
} from '../../blockNames';

export const NoResultsContainer = aggregatedClasses('div')(
  `${dataGridBlockName}-no-results`,
);
export const SpinnerContainer = aggregatedClasses('div')(
  `${dataGridBlockName}-spinner`,
);
export const DatagridWrapper = aggregatedClasses('div')(
  `${dataGridBlockName}-wrapper`,
  null,
  ({ fluidHeight }) => ({
    fluid: fluidHeight,
  }),
);

export const TableWrapper = aggregatedClasses('div')(dataGridBlockName);
export const HeaderWrapper = aggregatedClasses('div')(headerBlockName);
export const HeaderRow = aggregatedClasses('div')(headerBlockName, 'row');
export const HeaderCell = aggregatedClasses('div')(
  headerBlockName,
  'cell',
  ({ wrapText }) => ({
    wrapText,
  }),
);
export const BodyWrapper = aggregatedClasses('div')(
  bodyBlockName,
  null,
  ({ grouped }) => ({
    'ie-flex-basis-auto': true,
    'is-grouped': grouped,
  }),
);
export const BodyRow = aggregatedClasses('div')(bodyBlockName, 'row');
export const BodyCell = aggregatedClasses('div')(
  bodyBlockName,
  'cell',
  ({ wrapText }) => ({
    wrapText,
  }),
);
