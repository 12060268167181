import React from 'react';
import styled from 'styled-components';
import { __UNSAFE_SPACE_TO_DIMSUM } from '@elliemae/ds-system';

const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${props => __UNSAFE_SPACE_TO_DIMSUM(props.theme.space.s)};
  padding-bottom: 0;
  height: calc(100% - 36px);
  overflow: auto;
`;

function NoResults({ innerRef, rowRenderer, children, ...rest }) {
  return (
    <>
      <NoResultsContainer {...rest} innerRef={innerRef}>
        {children}
      </NoResultsContainer>
      {/* Hack to expand to the width of the row so we can scroll
      if the columns are bigger than the grid */}
      {rowRenderer(
        {},
        { style: { visibility: 'hidden', marginBottom: 0 } },
        {},
      )}
    </>
  );
}

export default NoResults;
