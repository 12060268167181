import { useState, useEffect } from 'react';
import { useSpring } from 'react-spring/web.cjs';
import useHiddenProps from './useHiddenProps';

const growTo = (from, to) => [
  { opacity: 1, transform: `scale(${to})` },
  { opacity: 0, transform: `scale(${from})` },
];

export default function useHiddenTransition({
  animationState = growTo(0, 1),
  visible = false,
  duration = 150,
  ...otherProps
}) {
  const { hidden, ...hiddenProps } = useHiddenProps({ visible });
  const [resting, setResting] = useState(false);
  const [destroyed, setDestroyed] = useState(true);
  const [animationStyles, set] = useSpring(() => ({
    ...animationState[0],
    config: { duration },
    onStart: () => {
      setResting(false);
      setDestroyed(false);
    },
    immediate: !visible,
    onRest: () => setResting(true),
  }));

  useEffect(() => {
    set(
      visible
        ? { ...animationState[0], immediate: false }
        : { ...animationState[1] },
    );
  }, [visible]);

  useEffect(() => {
    if (!visible && resting) setDestroyed(true);
  }, [resting]);

  // element props
  return {
    ...otherProps,
    ...hiddenProps,
    style: {
      ...otherProps.style,
      pointerEvents: !visible && 'none',
      ...animationStyles,
    },
    destroyed: destroyed || undefined,
  };
}
