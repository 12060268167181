import React from 'react';
import { PropTypes, describe } from 'react-desc';
import DSInput from '../Input';

const DSTextBox = ({
  containerProps = {},
  autoFocus = false,
  disabled = false,
  maxLength = 255,
  minLength = 0,
  fluidWidth = false,
  placeholder,
  value = '',
  hasError = false,
  readOnly = false,
  innerRef = null,
  type = 'text',
  min = undefined,
  max = undefined,
  clearable = false,
  leftComponent = null,
  rightComponent = null,
  required = false,
  variant,
  ...otherProps
}) => (
  <DSInput
    {...otherProps}
    aria-label={otherProps['aria-label'] || 'Text Box'}
    autoFocus={autoFocus}
    clearable={clearable}
    containerProps={containerProps}
    disabled={disabled}
    fluidWidth={fluidWidth}
    hasError={hasError}
    innerRef={innerRef}
    leftComponent={leftComponent}
    max={max}
    maxLength={maxLength}
    min={min}
    minLength={minLength}
    placeholder={placeholder}
    readOnly={readOnly}
    required={required}
    rightComponent={rightComponent}
    role="textbox"
    type={type}
    value={value}
    variant={variant}
  />
);

const props = {
  /** inject props at textbox wrapper */
  containerProps: PropTypes.shape({}).description(
    'inject props at textbox wrapper',
  ),
  /**
   * Whether the text box uses auto focus or not
   */
  autoFocus: PropTypes.bool.description(
    'Whether the text box uses auto focus or not',
  ),
  /**
   * Whether the text box is disabled or not
   */
  disabled: PropTypes.bool.description(
    'Whether the text box is disabled or not',
  ),
  /**
   * Max length for the text box
   */
  maxLength: PropTypes.number.description('Max length for the text box'),
  /**
   * Min length for the text box
   */
  minLength: PropTypes.number.description('Min length for the text box'),
  /**
   * Make the content of the text box fit the container or not
   */
  fluidWidth: PropTypes.bool.description(
    'Make the content of the text box fit the container or not',
  ),
  /**
   * Placeholder for the text box
   */
  placeholder: PropTypes.string.description('Placeholder for the text box'),
  /**
   * Default value once the component is initialized
   */
  value: PropTypes.string.description(
    'Default value once the component is initialized',
  ),
  /**
   * Whether the text box has error or not
   */
  hasError: PropTypes.bool.description('Whether the text box has error or not'),
  /**
   * Whether the text box is read only or not
   */
  readOnly: PropTypes.bool.description(
    'Whether the text box is read only or not',
  ),
  /** html min prop for input */
  min: PropTypes.number.description('html min prop for input'),
  /** html max prop for input */
  max: PropTypes.number.description('html max prop for input'),
  /**
   * Whether the text box is clearable or not
   */
  clearable: PropTypes.bool.description(
    'Whether the text box is clearable or not',
  ),
  /**
   * Component to be added at the left side of the text box
   */
  leftComponent: PropTypes.element.description(
    'Component to be added at the left side of the text box',
  ),
  /**
   * Component to be added at the right side of the text box
   */
  rightComponent: PropTypes.element.description(
    'Component to be added at the right side of the text box',
  ),
  /**
   * Whether the text box is required or not
   */
  required: PropTypes.bool.description(
    'Whether the text box is required or not',
  ),
  /**
   * [
   *  'variant-default',
   *  'variant-focus',
   *  'variant-active',
   *  'variant-disabled',
   * ]
   */
  variant: PropTypes.string.description(
    "variant-default','variant-focus','variant-active','variant-disabled'",
  ),
  /** html input type */
  type: PropTypes.string.description('html input type'),
  /** Internal ref */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).description(
    'internal ref',
  ),
};

DSTextBox.propTypes = props;

const DSTextBoxWithSchema = describe(DSTextBox);

DSTextBoxWithSchema.propTypes = props;

export default DSTextBox;
export { DSTextBoxWithSchema };
