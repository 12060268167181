export const BUTTON_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TEXT: 'text',
  LINK: 'link',
};

export const buttonTypes = ['primary', 'secondary', 'text', 'link'];

export const BUTTON_SIZE = {
  S: 's',
  M: 'm',
  L: 'l',
};

export const BUTTON_INTENT = {
  DANGER: 'danger',
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
};

export const BUTTON_VARIANT = {
  DEFAULT: 'variant-default',
  FOCUS: 'variant-focus',
  ACTIVE: 'variant-active',
  DISABLED: 'variant-disabled',
  HOVER: 'variant-hover',
};

export const CHECKBOX_VARIANT = {
  DEFAULT: 'variant-default',
  FOCUS: 'variant-focus',
  ACTIVE: 'variant-active',
  DISABLED: 'variant-disabled',
  ERROR: 'variant-error',
};
export const checkboxVariants = [
  CHECKBOX_VARIANT.DEFAULT,
  CHECKBOX_VARIANT.FOCUS,
  CHECKBOX_VARIANT.ACTIVE,
  CHECKBOX_VARIANT.DISABLED,
  CHECKBOX_VARIANT.ERROR,
];

export const COMBOBOX_VARIANT = {
  DEFAULT: 'variant-default',
  FOCUS: 'variant-focus-input',
  FOCUS_ICON: 'variant-focus-icon',
  ACTIVE: 'variant-active-input',
  ACTIVE_ICON: 'variant-active-icon',
  DISABLED: 'variant-disabled',
  ERROR: 'variant-error',
};

export const comboBoxVariants = [
  COMBOBOX_VARIANT.DEFAULT,
  COMBOBOX_VARIANT.FOCUS,
  COMBOBOX_VARIANT.FOCUS_ICON,
  COMBOBOX_VARIANT.ACTIVE,
  COMBOBOX_VARIANT.ACTIVE_ICON,
  COMBOBOX_VARIANT.DISABLED,
  COMBOBOX_VARIANT.ERROR,
];

export const buttonVariants = [
  'variant-default',
  'variant-focus',
  'variant-active',
  'variant-disabled',
  'variant-hover',
];

export const sizes = ['s', 'm', 'l'];

export const orientation = ['horizontal', 'vertical'];
