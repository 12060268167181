/* eslint-disable max-lines */
import React from 'react';
import { PropTypes, describe } from 'react-desc';
import { TooltipTextProvider } from '@elliemae/ds-basic/TruncatedTooltipText';
import DataGridImpl from './DataGridImpl';
import { RowSizesOptions, RowSizesOptionsArr } from './rowSizes';

const DSDataGrids = ({
  containerProps = {},
  instanceRef,
  className = '',

  // data
  columns = [],
  rows = [],
  rowKey = 'id',

  // features
  infiniteScrolling = false,
  editable = false,
  sortable = false,
  sortEmptyLast = false,
  paginated = false,
  dragAndDropRows = false,
  selectable = false,
  resizeableColumns = false,
  searchFilters = false,
  dragAndDropColumns = false,
  useTreeData = false,
  autoScrollToId,

  // virtualization props
  overscanCount = 10,

  // feature state
  selectAll = false, // todo: change to isAllSelected
  selectedRows = [],
  filters = undefined,
  onAddFilter = () => null,
  onRemoveFilter = () => null,
  onFiltersChange = () => null,
  showFilterBar = true,
  multiSelectFilterOptions = [
    { id: 'true', label: 'Selected' },
    { id: 'false', label: 'Not selected' },
  ],
  multiSelectFilterLabels = undefined,
  multiSelectFilterLabel = 'Selection',

  aggregations = {},
  groupingColumn = undefined,

  sortingColumns = {},

  // pagination
  minPerPage = 0,
  maxPerPage = 50,
  onPerPageChange = () => null,
  onPageChange = () => null,
  paginationStep = 2,
  pagination = {
    page: 1,
    perPage: 10,
  },
  // visual
  showHeader = true,
  fixedHeader = true,
  wrapText = false,
  height = undefined,
  minColumnWidth = 100,
  showRowsLoader = false,
  visibleColumns = undefined, // this enforces visibility of columns
  showSelectHighlight = false,
  showSelectColumn = true,
  selectColumnSortable = true,
  selectColumnFilterable = false,
  selectionColumnDefinition = {},
  filterBarOptions = undefined,
  // searchGrid = false,
  renderToolbar = undefined,
  toolbarDelayClose = 400,
  renderFilterToolbar = undefined,
  multiSelectComponent = undefined,
  // todo: deprecate this
  noResultsRender,
  noResultsPlaceholder,
  fluidHeight = false,

  customHandlers = {},

  // grouping feature
  groupedBy = undefined,

  // callbacks
  onDefaultFiltersLoad = () => null,
  onReorder = () => null,
  onMoveRowEnd = () => null,
  onMoveRowStart = () => null,
  onMoveColumnOver = () => null,
  onMoveColumnStart = () => null,
  onMoveColumnEnd = () => null,
  onColumnResize = () => null,
  onExpandChange = () => null,
  onToggleExpand = () => null,
  onExpandRow = () => null,
  onFilter = () => null,
  onSort = () => null,
  onColumnRowEdited = () => null,
  onColumnRowEdit = () => null,
  onSelectRow = () => null,
  onSelectAll = () => null,
  onInfiniteScrolling = () => null,
  onFilterMenuClose = () => null,
  onFilterMenuOpen = () => null,
  getData = undefined, // todo: change to onGetMoreData
  serverSideData = false,
  isDataSorted = false,
  isDataFiltered = false,
  virtualized = true,
  numRowsVisible = undefined,
  isColumnEditable = (column) => column.editable,

  // grouped props
  groupedRows = false,
  groupedRowsRenderHeader = undefined,
  // expandable props
  renderExpandedDetails = () => null,
  detailColumns,
  expandable = false,
  getChildrenRows = (row) => row.children,
  expandableSubrowsVisible = 7,
  renderRowDetails = () => null,
  getExpandedRowSize = undefined,
  getExpandedRowMinSize,
  rowSize = RowSizesOptions.normal,
  subRowSize = RowSizesOptions.normal,
  bindColumnsSizeTo = undefined,
  bindRowSizeTo = undefined,
  onToggleShowAllRows,
  expandedRows,
  autoHeight = false,
  noColumnsPlaceholder,
  plugins = [],
  normalizeDataKeys = false,
}) => (
  <TooltipTextProvider>
    <DataGridImpl
      aggregations={aggregations}
      autoHeight={autoHeight && !fluidHeight}
      autoScrollToId={autoScrollToId}
      bindColumnsSizeTo={bindColumnsSizeTo}
      bindRowSizeTo={bindRowSizeTo}
      className={className}
      columns={columns}
      containerProps={containerProps}
      customHandlers={customHandlers}
      detailColumns={detailColumns}
      dragAndDropColumns={dragAndDropColumns}
      dragAndDropRows={dragAndDropRows}
      editable={editable}
      expandable={expandable}
      expandableSubrowsVisible={expandableSubrowsVisible}
      expandedRows={expandedRows}
      filterBarOptions={filterBarOptions}
      filters={filters}
      fixedHeader={fixedHeader}
      fluidHeight={fluidHeight}
      getChildrenRows={getChildrenRows}
      getData={getData}
      getExpandedRowMinSize={getExpandedRowMinSize}
      getExpandedRowSize={getExpandedRowSize}
      groupedBy={groupedBy}
      groupedRows={groupedRows}
      groupedRowsRenderHeader={groupedRowsRenderHeader}
      groupingColumn={groupingColumn}
      height={height}
      infiniteScrolling={infiniteScrolling}
      instanceRef={instanceRef}
      isColumnEditable={isColumnEditable}
      isDataFiltered={isDataFiltered}
      isDataSorted={isDataSorted}
      maxPerPage={maxPerPage}
      minColumnWidth={minColumnWidth}
      minPerPage={minPerPage}
      multiSelectComponent={multiSelectComponent}
      multiSelectFilterLabel={multiSelectFilterLabel}
      multiSelectFilterLabels={multiSelectFilterLabels}
      multiSelectFilterOptions={multiSelectFilterOptions}
      noColumnsPlaceholder={noColumnsPlaceholder}
      noResultsPlaceholder={noResultsPlaceholder}
      noResultsRender={noResultsRender}
      normalizeDataKeys={normalizeDataKeys}
      numRowsVisible={numRowsVisible} // if defined, fluidHeight is disabled
      onAddFilter={onAddFilter}
      onColumnResize={onColumnResize}
      onColumnRowEdit={onColumnRowEdit}
      onColumnRowEdited={onColumnRowEdited}
      onExpandRow={onExpandRow}
      onExpandChange={onExpandChange}
      onFilter={onFilter}
      onFiltersChange={onFiltersChange}
      onFilterMenuClose={onFilterMenuClose}
      onFilterMenuOpen={onFilterMenuOpen}
      onDefaultFiltersLoad={onDefaultFiltersLoad}
      onInfiniteScrolling={onInfiniteScrolling}
      onMoveColumnEnd={onMoveColumnEnd}
      onMoveColumnOver={onMoveColumnOver}
      onMoveColumnStart={onMoveColumnStart}
      onMoveRowEnd={onMoveRowEnd}
      onMoveRowStart={onMoveRowStart}
      onPageChange={onPageChange}
      onPerPageChange={onPerPageChange}
      onRemoveFilter={onRemoveFilter}
      onReorder={onReorder}
      onSelectAll={onSelectAll}
      onSelectRow={onSelectRow}
      onSort={onSort}
      onToggleExpand={onToggleExpand}
      onToggleShowAllRows={onToggleShowAllRows}
      overscanCount={overscanCount}
      paginated={paginated || wrapText}
      pagination={pagination}
      paginationStep={paginationStep}
      plugins={plugins}
      renderExpandedDetails={renderExpandedDetails}
      renderFilterToolbar={renderFilterToolbar}
      renderRowDetails={renderRowDetails}
      renderToolbar={renderToolbar}
      resizeableColumns={resizeableColumns}
      rowKey={rowKey}
      rows={rows}
      rowSize={rowSize}
      searchFilters={searchFilters}
      selectable={selectable}
      selectAll={selectAll}
      selectColumnFilterable={selectColumnFilterable}
      selectColumnSortable={selectColumnSortable}
      selectedRows={selectedRows}
      selectionColumnDefinition={selectionColumnDefinition}
      serverSideData={serverSideData}
      showFilterBar={showFilterBar}
      showHeader={showHeader}
      showRowsLoader={showRowsLoader}
      showSelectColumn={showSelectColumn}
      showSelectHighlight={showSelectHighlight}
      sortable={sortable}
      sortEmptyLast={sortEmptyLast}
      sortingColumns={sortingColumns}
      subrowSize={subRowSize}
      toolbarDelayClose={toolbarDelayClose}
      useTreeData={useTreeData}
      virtualized={virtualized}
      visibleColumns={visibleColumns}
      wrapText={wrapText}
    />
  </TooltipTextProvider>
);

const props = {
  /** ref to the instance of the datagrid, handle it imperatively */
  instanceRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).description('ref to the instance of the datagrid, handle it imperatively'),
  /** class for data grid wrapper */
  className: PropTypes.string.description('class for data grid wrapper'),
  /** inject props to datagrid wrapper */
  containerProps: PropTypes.object.description(
    'inject props to datagrid wrapper',
  ),
  /** FilterBar props passed down to FilterBar */
  filterBarOptions: PropTypes.shape({
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number,
  }).description('FilterBar props passed down to FilterBar'),
  /** datagrid columns */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      /** Column property as an identifier */
      property: PropTypes.string.isRequired,
      /** Label in the header */
      label: PropTypes.string,
      /** Defines the width of the column, can be a string with percentages ("50%"), if not defined the width is flexible */
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      /** Min width for this column. NOTE: Has prevalence over the general minColumnWidth */
      minWidth: PropTypes.number,
      /** Function returning a custom editor for that cell ({ value, onValue, rowData }) => Element */
      customEditor: PropTypes.func,
      /** Function returning an element to render in the cell ({ value, customHandlers, metaData: { rowData } }) => Element */
      customRenderer: PropTypes.func,
      /** Activates 'searchable' feature for this column */
      searchable: PropTypes.bool,
      /** Activates 'sortable' feature for this column */
      sortable: PropTypes.bool,
      /** Activates 'resizable' feature for this column */
      resizable: PropTypes.bool,
      /** Activates 'editable' feature for this column */
      editable: PropTypes.bool,
      /** Whether this column is visible or not */
      visible: PropTypes.bool,
    }),
  ).description('datagrid columns'),
  /** datagrid rows */
  rows: PropTypes.arrayOf(PropTypes.object).description('datagrid rows'),
  /** key for each row */
  rowKey: PropTypes.string.description('key for each row'),
  /** toggle infinite scroll loading behavior */
  infiniteScrolling: PropTypes.bool.description(
    'toggle infinite scroll loading behavior',
  ),
  /** Activates 'editable' feature on the DataGrid NOTE: needs to be activated in the column definition as well */
  overscanCount: PropTypes.number.description(
    "Activates 'editable' feature on the DataGrid NOTE: needs to be activated in the column definition as well",
  ),
  /** turn datagrid to editable mode */
  editable: PropTypes.bool.description('turn datagrid to editable mode'),
  /** Activates 'sortable' feature on the GataGrid NOTE: needs to be activated in the column definition as well */
  sortable: PropTypes.bool.description(
    "Activates 'sortable' feature on the GataGrid NOTE: needs to be activated in the column definition as well",
  ),
  /** Put empty values always last on the column after sorting */
  sortEmptyLast: PropTypes.bool.description(
    'Put empty values always last on the column after sorting',
  ),
  /** Activates 'pagination' feature on the datagrid NOTE: needs to be activated in the column definition as well */
  paginated: PropTypes.bool.description(
    "Activates 'pagination' feature on the datagrid NOTE: needs to be activated in the column definition as well",
  ),
  /** Pagination step for clicking the next pages */
  paginationStep: PropTypes.number.description(
    'Pagination step for clicking the next pages',
  ),
  /** Activates 'drag and drop rows' feature on the datagrid NOTE: needs to be activated in the column definition as well */
  dragAndDropRows: PropTypes.bool.description(
    "Activates 'drag and drop rows' feature on the datagrid NOTE: needs to be activated in the column definition as well",
  ),
  /** Activates 'selection' feature on the datagrid NOTE: needs to be activated in the column definition as well */
  selectable: PropTypes.bool.description(
    "Activates 'selection' feature on the datagrid NOTE: needs to be activated in the column definition as well",
  ),
  /** Activates 'resize' feature on the datagrid NOTE: needs to be activated in the column definition as well */
  resizeableColumns: PropTypes.bool.description(
    "Activates 'resize' feature on the datagrid NOTE: needs to be activated in the column definition as well",
  ),
  /** Placeholder rendered when no rows are visible */
  noResultsPlaceholder: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.element,
  ]).description('Placeholder rendered when no rows are visible'),
  /** Activates 'filtering' feature on the datagrid NOTE: needs to be activated in the column definition as well */
  searchFilters: PropTypes.bool.description(
    "activates 'filtering' feature on the datagrid NOTE: needs to be activated in the column definition as well",
  ),
  /** Activates 'drag and drop columns' feature on the datagrid NOTE: needs to be activated in the column definition as well */
  dragAndDropColumns: PropTypes.bool.description(
    "Activates 'drag and drop columns' feature on the datagrid NOTE: needs to be activated in the column definition as well",
  ),
  /** Activates 'grouping and tree data' feature on the datagrid NOTE: needs to be activated in the column definition as well */
  useTreeData: PropTypes.bool.description(
    "activates 'grouping and tree data' feature on the datagrid NOTE: needs to be activated in the column definition as well",
  ),
  /** AutoScroll to row ID */
  autoScrollToId: PropTypes.any.description('AutoScroll to row ID'),
  /** Whether all rows are selected or not */
  selectAll: PropTypes.bool.description('Whether all rows are selected or not'),
  /** Defines the selected rows */
  selectedRows: PropTypes.arrayOf(PropTypes.object).description(
    ' Defines the selected rows',
  ),
  /** Filters to be applied to the grid */
  filters: PropTypes.arrayOf(PropTypes.object).description(
    'Filters to be applied to the grid',
  ),
  // todo: define params for handlers
  /** Handler when user expands a row */
  onExpandRow: PropTypes.func.description('Handler when user expands a row'),
  /** Handler when user expands a row with full expand state */
  onExpandChange: PropTypes.func.description(
    'Handler when user expands a row with full expand state',
  ),
  /** Handler when user expands a row (old API) */
  onToggleExpand: PropTypes.func.description(
    'Handler when user expands a row (old API)',
  ),
  /** Handler when user adds a filter */
  onAddFilter: PropTypes.func.description('Handler when user adds a filter'),
  /** Handler when user removes a filter */
  onRemoveFilter: PropTypes.func.description(
    'Handler when user removes a filter',
  ),
  /** Handler when the filters state has changed */
  onFiltersChange: PropTypes.func.description(
    'Handler when the filters state has changed ',
  ),
  /** Handler when the default filters load. Returns the rows rendered */
  onDefaultFiltersLoad: PropTypes.func.description(
    'Handler when the default filters load. Returns the rows rendered',
  ),
  /** Handler when column move is over another column */
  onMoveColumnOver: PropTypes.func.description(
    'Handler when column move is over another column',
  ),
  /** Handler when column move starts */
  onMoveColumnStart: PropTypes.func.description(
    ' Handler when column move starts',
  ),
  /** Handler when column move ends */
  onMoveColumnEnd: PropTypes.func.description('Handler when column move ends'),
  /** Whether to show the filter bar or not */
  showFilterBar: PropTypes.bool.description(
    'Whether to show the filter bar or not',
  ),
  /** Custom option for the header with multi select */
  multiSelectFilterOptions: PropTypes.arrayOf(PropTypes.object).description(
    'Custom option for the header with multi select',
  ),
  /** Label to the header column for multi select */
  multiSelectFilterLabel: PropTypes.string.description(
    'Label to the header column for multi select',
  ),
  /** Labels for multi selects */
  multiSelectFilterLabels: PropTypes.arrayOf(PropTypes.string).description(
    'Labels for multi selects',
  ),
  /** unstable */
  aggregations: PropTypes.object.description('').deprecated(''),
  /** unstable */
  /** Column which rows would be grouped by */
  groupingColumn: PropTypes.string
    .description('Column which rows would be grouped by')
    .deprecated(''),
  /** group by */
  groupedBy: PropTypes.string.description('group by'),
  /** Defines the sorting state */
  sortingColumns: PropTypes.objectOf(
    PropTypes.shape({
      // eslint-disable-next-line react/no-unused-prop-types
      direction: PropTypes.oneOf(['asc', 'desc']),
      // eslint-disable-next-line react/no-unused-prop-types
      position: PropTypes.number,
    }),
  ).description('Defines the sorting state'),
  /** Visible columns property */
  visibleColumns: PropTypes.arrayOf(PropTypes.string).description(
    'Visible columns property',
  ),
  /** Pagination: Minimum number of rows per page */
  minPerPage: PropTypes.string.description(
    'Pagination: Minimum number of rows per page',
  ),
  /** Pagination: Maximum number of rows per page */
  maxPerPage: PropTypes.string.description(
    'Pagination: Maximum number of rows per page',
  ),
  /** Pagination: Handler when user changes the amount of rows per page */
  onPerPageChange: PropTypes.string.description(
    'Pagination: Handler when user changes the amount of rows per page',
  ),
  /** Pagination: Handler when user changes the page */
  onPageChange: PropTypes.string.description(
    'Pagination: Handler when user changes the page',
  ),
  /** Pagination state */
  pagination: PropTypes.shape({
    // eslint-disable-next-line react/no-unused-prop-types
    page: PropTypes.number,
    // eslint-disable-next-line react/no-unused-prop-types
    perPage: PropTypes.number,
  }).description('pagination state'),
  /** Whether to show the header or not */
  showHeader: PropTypes.bool.description('Whether to show the header or not'),
  /** Whether to header is fixed or not */
  fixedHeader: PropTypes.bool.description('Whether to header is fixed or not'),
  /** Defines the height of the DataGrid */
  height: PropTypes.number.description('Defines the height of the DataGrid'),
  /** General min column width for all the columns */
  minColumnWidth: PropTypes.number.description(
    'General min column width for all the columns ',
  ),
  /** Whether to show a loader instead of the rows or not */
  showRowsLoader: PropTypes.bool.description(
    'Whether to show a loader instead of the rows or not',
  ),
  /** When a user selects a row, that row get highlighted */
  showSelectHighlight: PropTypes.bool.description(
    'When a user selects a row, that row get highlighted',
  ),
  /** Whether to show the select column when the selection feature is active */
  showSelectColumn: PropTypes.bool.description(
    'Whether to show the select column when the selection feature is active',
  ),
  /** Activates sortable feature to selection column */
  selectColumnSortable: PropTypes.bool.description(
    'activates sortable feature to selection column',
  ),
  /** Activates filterable feature to selection column */
  selectColumnFilterable: PropTypes.bool.description(
    'Activates filterable feature to selection column',
  ),
  /** Define the definition for the selection column */
  selectionColumnDefinition: PropTypes.object.description(
    'Define the definition for the selection column',
  ),
  /** Function that returns a toolbar to show when the user hovers the row */
  renderToolbar: PropTypes.func.description(
    'Function that returns a toolbar to show when the user hovers the row',
  ),
  /** Timeout to close the toolbar */
  toolbarDelayClose: PropTypes.number.description(
    ' Timeout to close the toolbar',
  ),
  /** Function that returns a toolbar in the FilterBar component */
  renderFilterToolbar: PropTypes.func.description(
    'Function that returns a toolbar in the FilterBar component',
  ),
  /** Custom component for the selection */
  multiSelectComponent: PropTypes.element.description(
    'Custom component for the selection',
  ),
  /** Normalize the keys of the data received and the columns */
  normalizeDataKeys: PropTypes.bool.description(
    'Normalize the keys of the data received and the columns',
  ),
  /** Function that returns an Element when there are no rows */
  noResultsRender: PropTypes.func.description(
    'Function that returns an Element when there are no rows',
  ),
  /** The height of the DataGrid will expand to its container */
  fluidHeight: PropTypes.bool.description(
    'The height of the DataGrid will expand to its container',
  ),
  /** @deprecated */
  customHandlers: PropTypes.object.description('').deprecated(''),
  /** Handler when user closes the filter menu */
  onFilterMenuClose: PropTypes.func.description(
    'Handler when user closes the filter menu',
  ),
  /** Handler when user opens the filter menu */
  onFilterMenuOpen: PropTypes.func.description(
    'Handler when user opens the filter menu',
  ),
  /** Handler when user drags a row */
  onReorder: PropTypes.func.description('handler when user drags a row '),
  /** Handler when user stops dragging a row */
  onMoveRowEnd: PropTypes.func.description(
    'Handler when user stops dragging a row ',
  ),
  /** Handler when user starts dragging a row */
  onMoveRowStart: PropTypes.func.description(
    'Handler when user starts dragging a row',
  ),
  /** Handler when user has resized a column */
  onColumnResize: PropTypes.func.description(
    'Handler when user has resized a column',
  ),
  /** Handler when user filters by a column */
  onFilter: PropTypes.func.description('Handler when user filters by a column'),
  /** Handler when user sorts by a column */
  onSort: PropTypes.func.description('Handler when user sorts by a column '),
  /** Handler when user has edited a cell row */
  onColumnRowEdited: PropTypes.func.description(
    'Handler when user has edited a cell row',
  ),
  /** Handler when user activates the cell row editing functionality */
  onColumnRowEdit: PropTypes.func.description(
    ' Handler when user activates the cell row editing functionality',
  ),
  /** Handler when user selects a row */
  onSelectRow: PropTypes.func.description('Handler when user selects a row'),
  /** Handler when user checks the selection header controller */
  onSelectAll: PropTypes.func.description(
    'Handler when user checks the selection header controller',
  ),
  /** Handler to get more data when infinite scroll is activated */
  getData: PropTypes.func.description(
    'Handler to get more data when infinite scroll is activated',
  ),
  /** Handler to get more data when infinite scroll is activated without side-effect */
  onInfiniteScrolling: PropTypes.func.description(
    'Handler to get more data when infinite scroll is activated without side-effect',
  ),
  /** Flag to override empty sort when data is from server */
  serverSideData: PropTypes.bool.description(
    'Flag to override empty sort when data is from server',
  ),
  /** Flag to force virtualization. Not used */
  virtualized: PropTypes.bool.description(
    'Flag to force virtualization. Not used',
  ),
  /** Number of rows visible. Not used */
  numRowsVisible: PropTypes.oneOf([PropTypes.number, 'all']).description(
    'Number of rows visible. Not used',
  ),
  /** Callback to check if column is editable */
  isColumnEditable: PropTypes.func.description(
    'Callback to check if column is editable',
  ),
  /** Flag for groupedRows */
  groupedRows: PropTypes.bool.description('Flag for groupedRows'),
  /** Header for grouped rows */
  groupedRowsRenderHeader: PropTypes.oneOf([
    PropTypes.func,
    PropTypes.element,
  ]).description('Header for grouped rows'),
  /** Once activated will disable the internal logic for sorting */
  isDataSorted: PropTypes.bool.description(
    'Once activated will disable the internal logic for sorting',
  ),
  /** Once activated will disable the internal logic for filtering */
  isDataFiltered: PropTypes.bool.description(
    'Once activated will disable the internal logic for filtering',
  ),
  /** Change row size */
  rowSize: PropTypes.oneOf(RowSizesOptionsArr).description('Change row size'),
  /** Whether to wrap the text of the data grid or not */
  wrapText: PropTypes.bool.description(
    'Whether to wrap the text of the data grid or not',
  ),
  /** Element to render expanded details */
  renderExpandedDetails: PropTypes.oneOf([
    PropTypes.func,
    PropTypes.element,
  ]).description('Element to render expanded details'),
  /** Columns to render on the details of an expanded row */
  detailColumns: PropTypes.arrayOf(PropTypes.object).description(
    'Columns to render on the details of an expanded row',
  ),
  /** Flag to enable the expandable feature */
  expandable: PropTypes.bool.description(
    'Flag to enable the expandable feature ',
  ),
  /** Callback to get the row children */
  getChildrenRows: PropTypes.func.description(
    'Callback to get the row children',
  ),
  /** Number of rows visible on the details of an expanded row */
  expandableSubrowsVisible: PropTypes.number.description(
    'Number of rows visible on the details of an expanded row',
  ),
  /** Element to render inside expanded row */
  renderRowDetails: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
  ]).description('Element to render inside expanded row'),
  /** Callback to get the expanded row size */
  getExpandedRowSize: PropTypes.func.description(
    'Callback to get the expanded row size',
  ),
  /** Callback to get the expanded row minimum size */
  getExpandedRowMinSize: PropTypes.func.description(
    'Callback to get the expanded row minimum size',
  ),
  /** Change expanded/details rows size */
  subRowSize: PropTypes.oneOf(RowSizesOptionsArr).description(
    'Change expanded/details rows size',
  ),
  /** Bind the column size to another grid instance */
  bindColumnsSizeTo: PropTypes.string.description(
    'Bind the column size to another grid instance',
  ),
  /** Bind the row size to another grid instance */
  bindRowSizeTo: PropTypes.string.description(
    'Bind the row size to another grid instance',
  ),
  /** Handler when toggle show all is clicked  */
  onToggleShowAllRows: PropTypes.func.description(
    'Handler when toggle show all is clicked',
  ),
  /** Expanded row state */
  expandedRows: PropTypes.object.description('Expanded row state'),
  /** Flag to enable autoheight */
  autoHeight: PropTypes.bool.description('Flag to enable autoheight'),
  /** Placeholder rendered when no columns are visible */
  noColumnsPlaceholder: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.element,
  ]).description('Placeholder rendered when no columns are visible'),
  /** Plugin additional array */
  plugins: PropTypes.arrayOf(PropTypes.any).description(
    'Plugin additional array',
  ),
};

DSDataGrids.propTypes = props;

const DSDataGridsWithSchema = describe(DSDataGrids);
DSDataGridsWithSchema.propTypes = props;

export { DSDataGridsWithSchema };
export default DSDataGrids;
