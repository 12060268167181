function VirtualizedBodyRow({ data, index, style, key, isScrolling }) {
  const {
    rows,
    renderer: { rowRenderer },
  } = data;
  const rowData = rows[index];

  return rowRenderer(
    rowData,
    {
      style,
      index,
      rowIndex: index,
      key,
    },
    {
      // eslint-disable-next-line no-underscore-dangle
      rowIndex: index,
      isScrolling,
    },
  );
}

export default VirtualizedBodyRow;
