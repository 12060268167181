/* eslint-disable max-lines */
import React from 'react';
import { describe, PropTypes } from 'react-desc';
import {
  aggregatedClasses,
  convertPropToCssClassName,
} from '@elliemae/ds-classnames';
import getComponentFromProps from '@elliemae/ds-utilities/getComponentFromProps';
import {
  DEFAULT_DELAY_OPEN,
  DEFAULT_DELAY_CLOSE,
} from '@elliemae/ds-shared/constants';
import DSPopper, { PopperPositions, PopperInteractions } from '../Popper';
import { TooltipType } from './TooltipType';
import { interactions } from '../Popper/interaction';
import { positions } from '../Popper/positions';

const blockName = 'tooltip';

const TooltipContainer = aggregatedClasses('div')(blockName);
const TooltipText = aggregatedClasses('span')(blockName, 'text');

const DSTooltip = ({
  containerProps = {},
  innerRef,
  placement = PopperPositions.TOP,
  title = '',
  delayClose = DEFAULT_DELAY_CLOSE,
  delayOpen = DEFAULT_DELAY_OPEN,
  interactionType = PopperInteractions.HOVER,
  triggerComponent = null,
  className = '',
  isOpen = undefined,
  onOpen = () => null,
  springAnimationComponent = undefined,
  tooltipType = TooltipType.TOOLTIP,
  showArrow = true,
  zIndex,
  ...otherTooltipProps
}) => {
  const { cssClassName: tooltipBlockName } = convertPropToCssClassName(
    blockName,
  );

  let Content = title;
  if (typeof title === 'string') {
    Content = <TooltipText>{title}</TooltipText>;
  }

  return (
    <DSPopper
      blockName={tooltipBlockName}
      contentComponent={
        <TooltipContainer
          {...containerProps}
          {...otherTooltipProps}
          className={`tooltip-container--${tooltipType} ${className}`}
          innerRef={innerRef}
        >
          {Content}
        </TooltipContainer>
      }
      delayClose={delayClose}
      delayOpen={delayOpen}
      interactionType={interactionType}
      isOpen={isOpen}
      onOpen={onOpen}
      placement={placement}
      showArrow={tooltipType === TooltipType.TOOLTIP ? showArrow : false}
      springAnimationComponent={springAnimationComponent}
      triggerComponent={getComponentFromProps(triggerComponent)}
      zIndex={zIndex}
    />
  );
};

const tooltipProps = {
  containerProps: PropTypes.object.description(
    'Set of Properties attached to the main container',
  ),
  placement: PropTypes.oneOf(positions)
    .description('Tooltip position')
    .defaultValue(PopperPositions.TOP),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).description(
    'Tooltip content',
  ).isRequired,
  delayClose: PropTypes.string
    .description('Delay to close the tooltip')
    .defaultValue(DEFAULT_DELAY_CLOSE),
  delayOpen: PropTypes.string
    .description('Delay to open the tooltip ')
    .defaultValue(DEFAULT_DELAY_OPEN),
  interactionType: PropTypes.oneOf(interactions)
    .description('A type indicating how to open/close the tooltip')
    .defaultValue(PopperInteractions.HOVER),
  triggerComponent: PropTypes.element.description(
    'The component that will open/close the tooltip',
  ).isRequired,
  isOpen: PropTypes.bool
    .description('Whether the tooltip is visible or not')
    .defaultValue(undefined),
  onOpen: PropTypes.func.description('Handler when the tooltip opens'),
  springAnimationComponent: PropTypes.element.description(
    'Spring animation component',
  ),
  showArrow: PropTypes.bool
    .description('Whether to show an arrow or not')
    .defaultValue(true),
  className: PropTypes.string.description('css class'),
  tooltipType: PropTypes.oneOf(['tooltip', 'toolbar'])
    .description('Type of tooltip')
    .defaultValue(TooltipType.TOOLTIP),
  zIndex: PropTypes.number
    .description('z-index value assigned to the tooltip')
    .defaultValue(undefined),
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).description(
    'Ref to the tooltip container element',
  ),
};

DSTooltip.propTypes = tooltipProps;

const TooltipWithSchema = describe(DSTooltip);
TooltipWithSchema.propTypes = tooltipProps;

export { TooltipType, TooltipContainer, TooltipText, TooltipWithSchema };
export default DSTooltip;
