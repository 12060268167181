import { isFunction } from './utils';

export const setRef = (ref, value) => {
  if (isFunction(ref)) {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
};

export function mergeRefs(...refs) {
  return node => {
    if (!node) return;
    refs.forEach(ref => {
      if (isFunction(ref)) {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
    });
  };
}

export function logger(log, message = '') {
  // eslint-disable-next-line no-console
  console.log(message, log);
}
