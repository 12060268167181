/* eslint-disable react/display-name */
import React, { Component } from 'react';
import { ScrollSyncContext } from './ScrollSync';

/**
 * MODIFIED FROM react-scroll-sync
 *
 * ScrollSyncPane Component
 *
 * Wrap your content in it to keep its scroll position in sync with other panes
 *
 * @param groups
 * @example ./example.md
 */
class ScrollSyncPane extends Component {
  static defaultProps = {
    group: 'default',
    enabled: true,
  };

  componentWillUnmount() {
    const { enabled, group, syncContext } = this.props;
    const { unregisterPane } = syncContext;

    if (enabled) unregisterPane(this.node, this.toArray(group));
  }

  toArray = groups => [].concat(groups);

  render() {
    const { children, group, syncContext, notPaginated } = this.props;
    const { registerPane } = syncContext;

    return children({
      registerPane: node => {
        if (notPaginated) {
          const virtualizedBody = node.querySelector(
            '.virtualized-body-wrapper',
          );
          if (virtualizedBody) {
            registerPane(virtualizedBody, this.toArray(group));
          }
        }
        return registerPane(node, this.toArray(group));
      },
    });
  }
}

export default props => (
  <ScrollSyncContext.Consumer>
    {context => <ScrollSyncPane {...props} syncContext={context} />}
  </ScrollSyncContext.Consumer>
);
