/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import ChevronDoubleLeft from '@elliemae/ds-icons/ChevronDoubleLeft';
import ChevronDoubleRight from '@elliemae/ds-icons/ChevronDoubleRight';
import { Grid } from '@elliemae/ds-basic/Grid';

export const renderMonthElement = ({ month, onYearSelect }) => (
  <Grid
    height="20px"
    cols={['auto', 1, 'auto']}
    alignItems="flex-end"
    mr="10px"
  >
    <div>
      <div
        role="button"
        tabIndex="0"
        onClick={() => onYearSelect(month, month.year() - 1)}
      >
        <ChevronDoubleLeft width={13.6} height={13.6} />
      </div>
    </div>
    <Grid>
      <strong>{month.format('MMMM YYYY')}</strong>
    </Grid>
    <div>
      <div
        role="button"
        tabIndex="0"
        onClick={() => onYearSelect(month, month.year() + 1)}
      >
        <ChevronDoubleRight width={13.6} height={13.6} />
      </div>
    </div>
  </Grid>
);
