import React, { cloneElement, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { addOrRemove, runAll } from '@elliemae/ds-utilities/utils';
import useDerivedStateFromProps from '@elliemae/ds-utilities/hooks/useDerivedStateFromProps';

export function useCheckableGroup({
  children,
  active: activeProp,
  multi,
  onCheck = () => null,
}) {
  const [active, setActive] = useDerivedStateFromProps(activeProp);

  const handleCheck = ({ target: { value } }, item) => {
    setActive(prevActive => {
      const nextActive = multi ? addOrRemove(prevActive, value) : value;
      onCheck(nextActive, item);
      return nextActive;
    });
  };

  return React.Children.map(children, child => {
    const { props } = child;
    const checked = Array.isArray(active)
      ? active.indexOf(props.name) !== -1
      : active === props.name;

    return cloneElement(child, {
      ...props,
      checked,
      onClick: runAll(handleCheck, props.onClick),
    });
  });
}

function CheckableGroup({
  active = [],
  multi = false, // checkbox or radio
  children,
}) {
  return useCheckableGroup({ active, multi, children });
}

CheckableGroup.propTypes = {
  /* The item (if multi false) or items (if multi true) selected */
  active: PropTypes.arrayOf(PropTypes.string),
  /* Whether is multi select or not */
  multi: PropTypes.bool,
  /* Handler when a user checks an item */
  onCheck: PropTypes.func,
};

export default CheckableGroup;
