/* eslint-disable max-lines */
import React, { useEffect, useMemo, useRef } from 'react';
import { cx, isFunction } from '@elliemae/ds-utilities/utils';
import { VariableSizeList } from 'react-window';
import ArrowheadDown from '@elliemae/ds-icons/ArrowheadDown';
import ArrowheadRight from '@elliemae/ds-icons/ArrowheadRight';
import DSButton from '@elliemae/ds-basic/Button';
import createInstancePlugin from '@elliemae/ds-shared/createDataInstance/createInstancePlugin';
import { appendCellFormatter } from '@elliemae/ds-shared/useDataGrid/initColumnDefinition';
import ExpandedRow from './ExpandedRow';
import ExpandedRowExtra from './ExpandedRowExtra';
import { RowSizes } from '../../rowSizes';
import { useExpandGridState } from './useExpandGridState';

const getExpandedRowSizeBySubrows = ({
  totalAmount,
  amountVisible,
  isShowingAll,
  size = 36,
}) =>
  isShowingAll ? size * (totalAmount + 1) - 6 : size * (amountVisible + 1) + 32;

const ExpandedRows = {
  SingleColumn: ExpandedRow,
  Master: ExpandedRowExtra,
};

const createExpandedRow = type => ExpandedRows[type] || ExpandedRows.Master;

const isExpandableSingleColumn = columns =>
  columns.some(column => column.expandableColumn);

export const ExpandablePlugin = createInstancePlugin('expandable', {
  registerStateHook: useExpandGridState,
  decorateGrid(grid) {
    const { decoratedColumns, columns } = grid.getInstance();
    const detailColumns = useMemo(
      () =>
        decoratedColumns
          .map(column => ({
            ...column,
            customRenderer: column.detailCustomRenderer,
          }))
          .filter(col => !col.expandableColumn),
      [columns],
    );
    return {
      detailColumns,
    };
  },

  decorateColumns(columns, grid) {
    if (isExpandableSingleColumn(columns)) return columns;
    // get first column from user
    const firstColumnIndex = columns.findIndex(column => column.isUserColumn);
    const firstColumn = columns[firstColumnIndex];
    firstColumn.formatExpandable = true;

    return [
      ...columns.slice(0, firstColumnIndex),
      appendCellFormatter((value, { isExpanded, rowData }) => {
        const {
          actions: { toggleExpand },
        } = grid.getInstance();
        /*
         * should return only 2 elements ../custom-cell-renderer/addCustomRendererToCell.js
         * getRenderer it's checking that length to apply the custom render to value only
         */
        return (
          <>
            <DSButton
              buttonType="text"
              className="expandable-arrow"
              icon={isExpanded ? <ArrowheadDown /> : <ArrowheadRight />}
              onClick={e => {
                e.stopPropagation();
                toggleExpand(rowData);
              }}
              size="s"
            />
            {value}
          </>
        );
      }, firstColumn),
      ...columns.slice(firstColumnIndex + 1),
    ];
  },

  getBodyProps(bodyProps, grid) {
    const {
      composedRows: rows,
      props: {
        rowKey,
        getChildrenRows,
        rowSize = 'normal',
        subrowSize = 'normal',
        getExpandedRowMinSize,
        getExpandedRowSize,
        expandableSubrowsVisible,
      },
      state: { expandedRows },
    } = grid.getInstance();
    const rowSizePx = RowSizes[rowSize];
    const subrowSizePx = RowSizes[subrowSize];
    const listRef = useRef();

    useEffect(() => {
      if (listRef.current) listRef.current.resetAfterIndex(0, true);
    });

    // implementation of expandable row single column with aggregation
    const getItemSize = index => {
      const rowData = rows[index];
      const key = rowData[rowKey];
      const children = getChildrenRows({ rowData });

      const expandedSize = getExpandedRowSize
        ? getExpandedRowSize(index, { rowData }) // https://jira.elliemae.io/browse/PUI-1659
        : getExpandedRowSizeBySubrows({
            totalAmount: children.length,
            amountVisible:
              children.length > expandableSubrowsVisible
                ? expandableSubrowsVisible
                : children.length,
            isShowingAll:
              children.length < expandableSubrowsVisible ||
              (expandedRows[key] && expandedRows[key].showAllRows),
            size: subrowSizePx,
          });

      const expandedMinSize =
        isFunction(getExpandedRowMinSize) && getExpandedRowMinSize(index);

      // eslint-disable-next-line no-nested-ternary
      return expandedRows[key]
        ? expandedMinSize > expandedSize
          ? expandedMinSize
          : expandedSize
        : rowSizePx;
    };

    return {
      ...bodyProps,
      className: cx(bodyProps.className, 'expandable'),
      listComponent: VariableSizeList,
      listProps: { ref: listRef, itemSize: getItemSize },
    };
  },

  decorateRenderers(renderers, grid) {
    const Row = renderers.body.row;
    const Cell = renderers.body.cell;

    // eslint-disable-next-line react/display-name
    renderers.body.row = useMemo(() => {
      // todo: the expandable grid should be created from another specific grid component
      const expandedRowType = isExpandableSingleColumn(grid.decoratedColumns)
        ? 'SingleColumn'
        : 'Master';
      const ExpandedRowComponent = createExpandedRow(expandedRowType);
      // eslint-disable-next-line react/display-name
      return ({ isExpanded, ...rowProps }) => {
        if (isExpanded) {
          const {
            props: { detailColumns, getChildrenRows, renderRowDetails },
            composedRows,
          } = grid.getInstance();
          // https://jira.elliemae.io/browse/PUI-1667
          const rowData = composedRows[rowProps.index]
            ? composedRows[rowProps.index]
            : rowProps.rowData;
          return (
            <ExpandedRowComponent
              cellComponent={Cell}
              detailColumns={detailColumns}
              parentGrid={grid}
              renderRowDetails={renderRowDetails}
              rowComponent={Row}
              rowData={rowData}
              rowProps={{
                rowData,
                isExpanded,
                ...rowProps,
              }}
              rows={getChildrenRows({ rowData })}
            >
              {rowProps.children}
            </ExpandedRowComponent>
          );
        }
        return <Row {...rowProps} />;
      };
    }, []);

    return renderers;
  },

  getRowProps(rowProps, grid, { rowData }) {
    const {
      props: { rowKey },
      state: { expandedRows },
    } = grid.getInstance();
    const key = rowData[rowKey];
    const isExpanded = !!expandedRows[key];
    return {
      ...rowProps,
      className: cx(
        rowProps.className,
        Object.keys(expandedRows).length > 0 && 'expandable-row',
        isExpanded && 'expanded',
      ),
      isExpanded,
      showAllRows: expandedRows[key] && expandedRows[key].showAllRows,
    };
  },
});
