const basicAction = ({
  size = null,
  extraTight = false,
  tight = false,
  base = false,
  bitLoose = false,
  loose = false,
  extraLoose = false,
  superLoose = false,
}) => {
  const cssModifier = [];
  if (size) cssModifier.push(`${size}`);
  // Size as Flag
  if (extraTight) cssModifier.push('size-extra-tight');
  else if (tight) cssModifier.push('size-tight');
  else if (base) cssModifier.push('size-base');
  else if (bitLoose) cssModifier.push('size-bit-loose');
  else if (loose) cssModifier.push('size-loose');
  else if (extraLoose) cssModifier.push('size-extra-loose');
  else if (superLoose) cssModifier.push('size-super-loose');
  return cssModifier;
};

const instance = [basicAction];
const pipe = () => ({
  getInstance() {
    return instance;
  },
  add(customModifier) {
    instance.push(customModifier);
  },
  execute(props) {
    return instance.reduce((temp, action) => [...temp, ...action(props)], []);
  },
});

export default pipe;
