import React from 'react';
import PropTypes from 'prop-types';
import DSTruncatedTooltipText from '../../../../TruncatedTooltipText';
import { PopperPositions as Positions } from '../../../../Popper';

function SingleValueLabel({ children }) {
  return (
    <DSTruncatedTooltipText tooltipPlacement={Positions.TOP} value={children} />
  );
}

SingleValueLabel.propTypes = {
  children: PropTypes.string.isRequired,
};
export default SingleValueLabel;
