import React from 'react';
import { appendHeaderFormatter } from '@elliemae/ds-shared/useDataGrid/initColumnDefinition';
import { useResizeHandle } from './useResizeHandle';

const addResizableToColumn = (column, formatter) =>
  appendHeaderFormatter(formatter, column);

const ResizableWrapper = React.memo(
  ({ value, onDrag, onDragEnd, onDragStart, minWidth, handleClassName }) => {
    const { containerRef, Handle } = useResizeHandle({
      onDrag,
      onDragEnd,
      onDragStart,
      minWidth,
      handleClassName,
    });
    return (
      <div ref={containerRef} className="resize-container">
        {value}
        <Handle />
      </div>
    );
  },
);

// eslint-disable-next-line react/display-name
const resizableFormatter = options => value => (
  <ResizableWrapper {...options} value={value} />
);

export { addResizableToColumn, resizableFormatter };
