import React from 'react';
import ErrorHexegon from '@elliemae/ds-icons/ErrorHexegon';
import WarningTriangle from '@elliemae/ds-icons/WarningTriangle';
import CheckmarkCircle from '@elliemae/ds-icons/CheckmarkCircle';
import HelpCircle from '@elliemae/ds-icons/HelpCircle';
import InfoCircle from '@elliemae/ds-icons/InfoCircle';
import { ModalFeedback } from '../blocks';
import { MODAL_SUB_TYPE_V2 } from './types';

const feedbackIconSize = 'xxl';

const getIconType = subtype => {
  switch (subtype) {
    case MODAL_SUB_TYPE_V2.WARNING:
      return (
        <WarningTriangle data-testid="icon-waring" size={feedbackIconSize} />
      );
    case MODAL_SUB_TYPE_V2.ERROR:
      return <ErrorHexegon data-testid="icon-error" size={feedbackIconSize} />;
    case MODAL_SUB_TYPE_V2.SUCCESS:
      return (
        <CheckmarkCircle data-testid="icon-success" size={feedbackIconSize} />
      );
    case MODAL_SUB_TYPE_V2.INFORMATION:
      return (
        <InfoCircle data-testid="icon-information" size={feedbackIconSize} />
      );
    default:
      return <HelpCircle data-testid="icon-help" size={feedbackIconSize} />;
  }
};

export const FeedbackIcon = ({ modalSubType }) => (
  <ModalFeedback classProps={{ type: modalSubType }}>
    {getIconType(modalSubType)}
  </ModalFeedback>
);

export default FeedbackIcon;
