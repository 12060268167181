import React, { useContext, useEffect } from 'react';
import { describe, PropTypes } from 'react-desc';
import styled from 'styled-components';
import { PopperPositions as positions } from '../Popper';
import { TruncatedTooltipContext } from './TooltipTextProvider';

const isEllipsisActive = ({ offsetWidth, scrollWidth }) =>
  offsetWidth < scrollWidth;

// reduce the possibility of error showing the tooltip (text-overflow: ellipsis) https://jira.elliemae.io/browse/PUI-1755
const Text = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  max-width: 100%;
`;

const DSTruncatedTooltipText = ({
  containerProps = {},
  value = '',
  zIndex = 110, // https://jira.elliemae.io/browse/PUI-1755
  ...otherTextProps
}) => {
  const tooltipContext = useContext(TruncatedTooltipContext);
  useEffect(() => {
    if (zIndex && tooltipContext) tooltipContext.setZIndex(zIndex);
  }, [zIndex]);

  if (!tooltipContext) return value;

  const { showTooltip, hideTooltip } = tooltipContext;

  const handleMouseEnter = (e) => {
    const { target } = e;
    if (target && isEllipsisActive(target, target.getBoundingClientRect())) {
      showTooltip(
        {
          value,
          reference: target,
        },
        e,
      );
    }
  };

  const handleMouseLeave = (e) => {
    hideTooltip({ reference: e.target });
  };

  const handlers = showTooltip
    ? { onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave }
    : {};
  return (
    <Text {...containerProps} {...otherTextProps} {...handlers}>
      {value}
    </Text>
  );
};

const truncatedTooltipTextProps = {
  containerProps: PropTypes.object.description(
    'Set of Properties attached to the main container',
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).description(
    'Text that when truncated will trigger the tooltip interaction',
  ),
  tooltipPlacement: PropTypes.oneOf([
    positions.AUTO_START,
    positions.AUTO_END,
    positions.AUTO,
    positions.TOP_START,
    positions.TOP,
    positions.TOP_END,
    positions.RIGHT_START,
    positions.RIGHT,
    positions.RIGHT_END,
    positions.BOTTOM_START,
    positions.BOTTOM,
    positions.BOTTOM_END,
    positions.LEFT_START,
    positions.LEFT,
    positions.LEFT_END,
  ]).description('Position of the tooltip'),
  tooltipDelay: PropTypes.number.description('Delay to show the tooltip'),
  zIndex: PropTypes.number
    .description('override default zIndex')
    .defaultValue(110),
};

DSTruncatedTooltipText.defaultProps = {
  containerProps: {},
  value: '',
  tooltipPlacement: undefined,
  tooltipDelay: undefined,
};

DSTruncatedTooltipText.propTypes = truncatedTooltipTextProps;

const TruncatedTooltipTextWithSchema = describe(DSTruncatedTooltipText);
TruncatedTooltipTextWithSchema.propTypes = truncatedTooltipTextProps;

export { TruncatedTooltipTextWithSchema };
export default DSTruncatedTooltipText;
