import React, { useState, useEffect, useCallback } from 'react';
import { find } from 'lodash';
import { isFunction, addOrRemove } from '@elliemae/ds-utilities/utils';
import { RemovablePill } from '../RemovablePill';
import { Pill } from '../Pill';
import { OverflowPill } from '../OverflowPill';
import { DropdownPill } from '../DropdownPill';

export default function LabeledDropdownMultiPills({
  label,
  options = [],
  value = [],
  onSelect = () => null,
  onRemove = () => null,
  onChange, // if this is defined, then it's calculating the next result onSelect or onRemove
  maxWidth,
  minWidth,
  ...menuProps
}) {
  const [totalDefaultOptions, setTotalDefaultOptions] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState(value);
  const [allOptions, setAllOptions] = useState(options);
  const [overflowOptions, setOverflowOptions] = useState([]);

  const overflowQuantity = 3;

  useEffect(() => setSelectedOptions(value), [value]);

  useEffect(() => {
    const newOptions = allOptions.filter(option => option.fixedItem);
    if (newOptions.length > 0) {
      setSelectedOptions(newOptions);
    }
    setTotalDefaultOptions(newOptions.length);
  }, []);

  useEffect(() => {
    if (totalDefaultOptions) {
      const newOptions = allOptions.map(opt => {
        if (
          selectedOptions.length <= totalDefaultOptions &&
          find(selectedOptions, { id: opt.id })
        ) {
          return { ...opt, fixedItem: true };
        }
        return { ...opt, fixedItem: false };
      });

      setAllOptions(newOptions);
    }
  }, [onSelect, onRemove, selectedOptions]);

  useEffect(() => {
    if (selectedOptions.length > overflowQuantity) {
      const overflow = selectedOptions.filter(
        (val, index) => index >= overflowQuantity,
      );
      setOverflowOptions(overflow);
    }
  }, [selectedOptions]);

  const selectOption = useCallback(
    option => {
      if (!option.fixedItem) {
        if (isFunction(onChange)) {
          const nextValue = addOrRemove(
            selectedOptions,
            option,
            item => item.id,
          );
          setSelectedOptions(nextValue);
          onChange(nextValue);
        }
        onSelect(option);
      }
    },
    [selectedOptions],
  );

  const removeOption = useCallback(
    option => {
      if (!option.fixedItem || selectedOptions.length > totalDefaultOptions) {
        if (isFunction(onChange)) {
          const nextValue = selectedOptions.filter(val => option.id !== val.id);
          setSelectedOptions(nextValue);
          onChange(nextValue);
        }
        onRemove(option);
      }
    },
    [selectedOptions],
  );

  const typeOfPill = () => {
    if (selectedOptions.length > totalDefaultOptions) {
      return RemovablePill;
    }

    return Pill;
  };

  return (
    <>
      <DropdownPill
        {...menuProps}
        focusOnOpen={false}
        label={label}
        maxWidth={maxWidth}
        minWidth={minWidth}
        onSelectMenuItem={selectOption}
        options={[
          {
            ...menuProps,
            type: 'SelectionGroup',
            id: 'multi-selection-views',
            multi: true,
            items: allOptions,
          },
        ]}
        selection={{
          'multi-selection-views': selectedOptions
            ? selectedOptions.map(o => o.id)
            : '',
        }}
        variant="title"
      />
      {selectedOptions &&
        selectedOptions.map((option, index) => {
          const TypeOfPill = typeOfPill();
          if (index < overflowQuantity) {
            return (
              <TypeOfPill
                key={option.id}
                label={option.label}
                onRemove={() => removeOption(option)}
              />
            );
          }
          return null;
        })}
      {selectedOptions && selectedOptions.length > overflowQuantity && (
        <OverflowPill
          label={`+ ${selectedOptions.length - overflowQuantity}`}
          options={overflowOptions}
        />
      )}
    </>
  );
}
