import createInstancePlugin from '@elliemae/ds-shared/createDataInstance/createInstancePlugin';
import { groupBy } from 'lodash';

const groupedByData = (groupedBy, composedRows) => {
  const grouped = groupBy(composedRows, groupedBy);
  return Object.keys(grouped).map((c, key) => ({
    id: `grouped-by-${groupedBy}-${key}`,
    key: groupedBy,
    value: c,
    children: grouped[c],
    _isGroup: true,
    isGroup: true,
  }));
};

export const GroupingByPlugin = createInstancePlugin('groupedByRows', {
  decorateGrid(grid) {
    const {
      composedRows,
      props: { groupedBy },
    } = grid.getInstance();
    grid.composedRows = groupedBy
      ? groupedByData(groupedBy, composedRows)
      : composedRows;
    return grid;
  },
});
