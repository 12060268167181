import React from 'react';
import styled from 'styled-components';
import { DSCircularProgressIndicator } from '@elliemae/ds-basic/CircularProgressIndicator';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 56px;
`;

const LoadingIndicator = () => (
  <Wrapper>
    <DSCircularProgressIndicator size="m" />
  </Wrapper>
);

export default LoadingIndicator;
