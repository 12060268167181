import React, { Component, useEffect } from 'react';
import { isFunction } from '@elliemae/ds-utilities/utils';
import FocusGroup from './FocusGroup';
import FocusGroupContext from './FocusGroupContext';

const noop = () => null;

export default class FocusGroupProvider extends Component {
  activated = false;

  static defaultProps = {
    exitWhenNoPrevious: false,
    exitWhenNoNext: false,
    keyBindings: {},
    onFocus: noop,
    onExitFocusGroup: noop,
    onFocusPreviousGroup: noop,
    onFocusNextGroup: noop,
    onFocusGroupSet: noop,
  };

  constructor(props) {
    super(props);

    this.focusGroup = new FocusGroup(props);

    props.onFocusGroupSet(this);

    this.actions = {
      activate: this.activate.bind(this),
      deactivate: this.deactivate.bind(this),
      register: this.focusGroup.register,
      unregister: this.unregister.bind(this),
      focusFirst: this.focusGroup.focusFirst,
      focusLast: this.focusGroup.focusLast,
      focusItemByIndex: this.focusItemByIndex.bind(this),
      focusItemByNode: this.focusItemByNode.bind(this),
      focus: this.focusGroup.focusCurrent,
      isGroupActive: this.focusGroup.isGroupActive,
      focusNext: this.focusGroup.focusNext,
      focusPrevious: this.focusGroup.focusPrevious,
    };
  }

  componentDidMount() {
    const { autoFocusOnMount } = this.props;

    this.activate();

    if (autoFocusOnMount) {
      setTimeout(() => {
        this.actions.focusFirst();
      }, 0);
    }
  }

  componentWillUnmount() {
    this.deactivate();
  }

  activate() {
    const { onActivate } = this.props;
    this.focusGroup.activate();
    this.activated = true;
    if (isFunction(onActivate)) onActivate();
  }

  deactivate() {
    const { onExitFocusGroup } = this.props;
    this.activated = false;
    this.focusGroup.deactivate();
    if (isFunction(onExitFocusGroup)) {
      onExitFocusGroup();
    }
  }

  register(item) {
    this.focusGroup.register(item);
  }

  unregister(item) {
    this.focusGroup.unregister(item);
  }

  focusItemByIndex(index) {
    if (!this.activated) this.activate();
    setTimeout(() => {
      this.focusGroup.focusByIndex(index);
    }, 200);
  }

  focusItemByNode(node) {
    if (!this.activated) this.activate();
    const index = this.focusGroup.getItemIndexByNode(node);
    this.focusItemByIndex(index);
  }

  render() {
    const { children } = this.props;
    return (
      <FocusGroupContext.Provider value={this.actions}>
        {children}
      </FocusGroupContext.Provider>
    );
  }
}
