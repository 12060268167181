import {
  BodyCell,
  BodyRow,
  BodyWrapper,
  HeaderCell,
  HeaderRow,
  HeaderWrapper,
  TableWrapper,
} from './defaultClassedRenderers';

export * from './renderRowsLoader';

export const defaultRenderers = {
  table: TableWrapper,
  header: {
    wrapper: HeaderWrapper,
    row: HeaderRow,
    cell: HeaderCell,
  },
  body: {
    wrapper: BodyWrapper,
    row: BodyRow,
    cell: BodyCell,
  },
};
