/* eslint-disable import/no-cycle */
import React from 'react';
import { isFunction } from '@elliemae/ds-utilities/utils';

import Separator from './Separator';
import MenuItem from './MenuItem';
import SubMenu from './SubMenu';
import SelectionGroup from './SelectionGroup';
import MenuItemCheckbox from './MenuItemCheckbox';
import MenuItemRadio from './MenuItemRadio';

const itemTypes = {
  separator: Separator,
  menuitem: MenuItem,
  radio: MenuItemRadio,
  checkbox: MenuItemCheckbox,
  submenu: SubMenu,
  'selection-group': SelectionGroup,
};

const fallback = {
  SelectionGroup: 'selection-group',
};

export function menuItemFactory(
  type = '',
  items,
  defaultItem = itemTypes.menuitem,
) {
  const itemsObject = items || itemTypes;
  const parsedType = fallback[type] || type.toLowerCase();
  return itemsObject[parsedType] || defaultItem;
}

export function renderMenuItems(options, factory = menuItemFactory) {
  return options.map((option, index) => {
    if (isFunction(option.renderer)) {
      return option.renderer({
        key: option.id,
        item: option,
      });
    }
    const ItemComponent = factory(option.type);
    const children =
      option.subItems && renderMenuItems(option.subItems, factory);
    return (
      <ItemComponent
        {...option}
        key={option.id || index}
        item={option}
        onClick={null}
        onMouseDown={option.onClick}
        // onClick callback called in onMouseDown due to events order issue
      >
        {children}
      </ItemComponent>
    );
  });
}
