import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { LabeledDropdownPills } from '@elliemae/ds-basic/Pills';
import useFocusGroupItem from '@elliemae/ds-shared/FocusGroup/useFocusGroupItem';

const noop = () => null;

function FilterDropdownPill({
  groupIndex,
  groupId,
  label,
  pills = [],
  onFocusGroupSet,
  onFocusNextGroup,
  onFocusPreviousGroup,
  maxWidth,
  onRemove = noop,
  minWidth,
}) {
  const ref = useRef(null);
  useFocusGroupItem(ref);

  const {
    onSelectOption = noop,
    onRemoveOption = noop,
    onChange,
    options,
    value,
    type,
    multi,
    fixed,
    ...otherDropdownProps
  } = pills[0];

  const selectOption = useCallback(
    (option, optionIndex) => {
      onSelectOption(option, {
        optionIndex,
        groupIndex,
        groupId,
      });
    },
    [groupId, value, options],
  );

  const removeOption = useCallback(
    (option, optionIndex) => {
      onRemove(option, {
        optionIndex,
        groupIndex,
        groupId,
      });
      onRemoveOption(option, {
        optionIndex,
        groupIndex,
        groupId,
      });
    },
    [groupId, value, options],
  );

  return (
    <LabeledDropdownPills
      {...otherDropdownProps}
      id={groupId}
      innerRef={ref}
      label={label}
      maxWidth={maxWidth}
      minWidth={minWidth}
      onChange={onChange}
      onFocusGroupSet={onFocusGroupSet}
      onFocusNextGroup={onFocusNextGroup}
      onFocusPreviousGroup={onFocusPreviousGroup}
      onRemove={removeOption}
      onSelect={selectOption}
      options={options}
      type={type || (multi ? 'multi' : 'single')}
      value={value}
      fixed={fixed}
    />
  );
}

FilterDropdownPill.propTypes = {
  groupIndex: PropTypes.number,
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  pills: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.any }),
  ),
  onFocusGroupSet: PropTypes.func,
  onFocusNextGroup: PropTypes.func,
  onFocusPreviousGroup: PropTypes.func,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onRemove: PropTypes.func,
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default FilterDropdownPill;
