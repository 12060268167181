/* eslint-disable max-lines */
import React from 'react';
import PropTypes from 'prop-types';
import DSModalV1, { MODAL_TYPE } from './v1/DSModal';
import DSModalV2, {
  MODAL_TYPE_V2,
  MODAL_SUB_TYPE_V2,
  DSModalContent,
} from './v2/DSModal';
import { modalTypes, sizes, iconSizes } from '../../utils/prop-types';

const DSModal = ({ version = 1, ...rest }) => {
  if (version === 2) return <DSModalV2 {...rest} />;
  return <DSModalV1 {...rest} />;
};

DSModal.propTypes = {
  version: PropTypes.oneOf([1, 2]),
};

DSModalV1.propTypes = {
  version: PropTypes.oneOf([1, 2]),
  className: PropTypes.string,
  /**
   * Add an additional css class to the footer
   */
  additionalFooterCssClass: PropTypes.string,
  /**
   * Set style for the modal
   */
  style: PropTypes.objectOf(PropTypes.string),
  /**
   * Select size for the close icon
   */
  iconCloseSize: PropTypes.oneOf(iconSizes),
  /**
   * ['default', 'alert', 'confirm', 'notification', 'warning', 'error', 'success', 'help', 'info']
   */
  modalType: PropTypes.oneOf(modalTypes),
  /**
   * Show the header or not
   */
  showHeader: PropTypes.bool,
  /**
   * Show the footer or not
   */
  showFooter: PropTypes.bool,
  /**
   * Main content of the modal
   */
  children: PropTypes.element.isRequired,
  /**
   * Content is centered or not
   */
  centered: PropTypes.bool,
  /**
   * Text to be added in the confirm button
   */
  confirmLabel: PropTypes.string,
  /**
   * Text to be added in the modal title
   */
  modalTitle: PropTypes.string,
  /**
   * Text to be added in the modal title
   */
  size: PropTypes.oneOf(sizes),
  /**
   * Modal is open or not
   */
  isOpen: PropTypes.bool,
  /**
   * Allows a function to be triggered once the modal is closed
   */
  onClose: PropTypes.func,
  /**
   * Allows a function to be triggered once the modal is opened
   */
  onAfterOpen: PropTypes.func,
  /**
   * Allows a function to be triggered once the modal open is confirmed
   */
  onConfirm: PropTypes.func,
  /**
   * Allows a function to be triggered once the modal open is rejected
   */
  onReject: PropTypes.func,
  /**
   * Text to be added in the reject button
   */
  rejectLabel: PropTypes.string,
  /**
   * If modal should close on overlay click
   */
  shouldCloseOnOverlayClick: PropTypes.bool,
  /**
   * Modal has error or not
   */
  hasError: PropTypes.bool,
  /**
   * Modal has warning or not
   */
  hasWarning: PropTypes.bool,
  /**
   * Modal has success or not
   */
  hasSuccess: PropTypes.bool,
  /**
   * Modal has info or not
   */
  hasInfo: PropTypes.bool,
  /**
   * Modal has help or not
   */
  hasHelp: PropTypes.bool,
  actionsRef: PropTypes.func,
  appElement: PropTypes.string,
  /**
   * Customized props for the confirm button
   */
  overridePropsConfirmButton: PropTypes.objectOf(PropTypes.shape({})),
  /**
   * Customized props for the reject button
   */
  overridePropsRejectButton: PropTypes.objectOf(PropTypes.string),
  /**
   * Show confirm button
   */
  showConfirmButton: PropTypes.bool,
  /**
   * Show confirm button
   */
  customCloseComponent: PropTypes.element,
};

export { MODAL_TYPE, MODAL_TYPE_V2, MODAL_SUB_TYPE_V2, DSModalContent };
export default DSModal;
