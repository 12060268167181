import {
  Menu,
  renderMenuItems,
  menuItemFactory,
} from '@elliemae/ds-basic/Menu';

import MenuItemClickableHOC from './MenuItemClickableHOC';
import MenuItemGroupSelectionHOC from './MenuItemGroupSelectionHOC';

const itemTypes = {
  separator: Menu.Separator,
  menuitem: MenuItemClickableHOC(Menu.Item),
  radio: MenuItemClickableHOC(Menu.ItemRadio, { type: 'selectable' }),
  checkbox: MenuItemClickableHOC(Menu.ItemCheckbox, { type: 'selectable' }),
  submenu: Menu.SubMenu,
  'selection-group': MenuItemGroupSelectionHOC(Menu.SelectionGroup),
};

export const factory = type =>
  menuItemFactory(type, itemTypes, itemTypes.menuitem);
export const renderOptions = options => renderMenuItems(options, factory);
