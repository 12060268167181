import React from 'react';
import { PropTypes, describe } from 'react-desc';
import FilterBarImpl from './FilterBarImpl';

const DSFilterBar = ({
  containerProps = {},
  innerRef,
  className = '',
  filters = [],
  fixedFilters = [],
  onRemoveFilter = () => null,
  onRemoveAllFilters = () => null,
  onChangeDropdownPill = () => null,
  renderToolbar = undefined,
  maxWidth,
  minWidth,
  style = {},
}) => (
  <FilterBarImpl
    ref={innerRef}
    className={className}
    containerProps={containerProps}
    filters={filters}
    fixedFilters={fixedFilters}
    maxWidth={maxWidth}
    minWidth={minWidth}
    onChangeDropdownPill={onChangeDropdownPill}
    onRemoveAllFilters={onRemoveAllFilters}
    onRemoveFilter={onRemoveFilter}
    renderToolbar={renderToolbar}
    style={style}
  />
);

const props = {
  /** props to inject to filter bar wrapper */
  containerProps: PropTypes.object.description(
    'props to inject to filter bar wrapper',
  ),
  /**
   * Refence to the component
   */
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).description('reference to the component'),
  /** class for filter bar wrapper */
  className: PropTypes.string.description(' class for filter bar wrapper'),
  /**
   * Customized filters to add to the filter bar
   */
  filters: PropTypes.arrayOf(PropTypes.object).description(
    'Customized filters to add to the filter bar',
  ),
  /**
   * Customized filters that cannot be deleted from the filter bar
   */
  fixedFilters: PropTypes.arrayOf(PropTypes.object).description(
    'Customized filters that cannot be deleted from the filter bar',
  ),
  /**
   * Allows a function to be triggered once a filter is removed
   */
  onRemoveFilter: PropTypes.func.description(
    'Allows a function to be triggered once a filter is removed',
  ),
  /**
   * Allows a function to be triggered once all filters are removed
   */
  onRemoveAllFilters: PropTypes.func.description(
    'Allows a function to be triggered once all filters are removed',
  ),
  /**
   * Allows a function to be triggered once a dropdown pill changes
   */
  onChangeDropdownPill: PropTypes.func.description(
    'Allows a function to be triggered once a dropdown pill changes',
  ),
  /**
   * Allows a function to render a toolbar
   */
  renderToolbar: PropTypes.func.description(
    'Allows a function to render a toolbar',
  ),
  /**
   * Set style for the filter bar
   */
  style: PropTypes.object.description('Set style for the filter bar'),
  /** max width for filterbar */
  maxWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).description('max width for filterbar'),
  /** min width for filterbar */
  minWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).description('min width for filterbar'),
};

DSFilterBar.propTypes = props;
const DSFilterBarWithSchema = describe(DSFilterBar);
DSFilterBarWithSchema.propTypes = props;

export default DSFilterBar;
export { DSFilterBarWithSchema };
