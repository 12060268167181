import React, { useEffect } from 'react';
import { components } from 'react-select';
import PropTypes from 'prop-types';

const Content = ({ scheduleUpdate, styleMenu, ...rest }) => {
  useEffect(() => {
    // trigger popper update on input change PUI-4069
    scheduleUpdate();
  }, [rest?.selectProps?.inputValue]);
  return (
    <div style={styleMenu} data-testid="combobox-menu">
      <components.Menu {...rest} />
    </div>
  );
};

Content.propTypes = {
  scheduleUpdate: PropTypes.func,
  styleMenu: PropTypes.any,
};

export default Content;
