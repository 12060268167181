/* eslint-disable max-lines */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compact } from 'lodash';
import DSDropdownMenu from '@elliemae/ds-dropdownmenu';
import DSButton from '@elliemae/ds-basic/Button';
import { PopperPositions as Position } from '@elliemae/ds-basic/Popper';
import { Menu, MenuCombobox } from '@elliemae/ds-basic/Menu';
import SearchSmall from '@elliemae/ds-icons/SearchSmall';

function TextFilterMenu({
  column,
  menuStyle = {},
  className,
  filters = [],
  isMenuOpened = false,
  onOpenMenu = () => null,
  onClose = () => null,
  placement = Position.BOTTOM_END,
  menuProps = {},
  onAddFilter = () => null,
  onRemoveFilter = () => null,
  maxOptions = 10,
  isMulti = false,
}) {
  const { options, ...otherMenuProps } = menuProps;

  const generateOption = (option, idx) => (
    <Menu.ItemCheckbox key={idx} label={option.label} name={option.id} />
  );

  const renderOptions = (arr) => arr.map(generateOption);

  const filtersForColumn = filters.filter((f) => f.group === column.property);

  const initialSelectedOptions = filtersForColumn.map((f) => f.value);

  const [selected, setSelected] = useState(initialSelectedOptions);

  React.useEffect(() => {
    const filtersForColumn2 = filters.filter(
      (f) => f.group === column.property,
    );
    setSelected(filtersForColumn2.map((f) => f.value));
  }, [filters]);

  const filterGroup = column.searchBy || column.property;

  const onCloseMulti = () => {
    let nextFilters = [...filters];
    const shouldRemove = [];
    // if selected doesn't includes item, then we have to remove the filter
    // but given that we are maintaining state here and updating the grid state
    // is not implemented on useFilterableState, we have to do this in 2 traverses
    // as doing it in one gives trouble when removing and adding at the same time.
    initialSelectedOptions.forEach((item) => {
      if (!selected.includes(item)) {
        nextFilters = nextFilters.filter(
          (filter) => !(filter.group === filterGroup && filter.value === item),
        );
        shouldRemove.push({ group: filterGroup, pill: { value: item } });
      }
    });
    shouldRemove.forEach((removedFilter) => {
      onRemoveFilter({
        ...removedFilter,
        nextFilters,
      });
    });
    selected.forEach((item) => {
      // if initialSelectedOptions includes item, then we do nothing
      if (!initialSelectedOptions.includes(item)) {
        onAddFilter(
          {
            group: filterGroup,
            label: column.label,
            value: item,
            operator: 'contains',
            transformRowValue: column.valueTransformation,
          },
          column,
        );
      }
    });
    onClose();
  };

  return (
    <DSDropdownMenu
      closeMenuOnItemSelection
      contentStyle={menuStyle}
      customMenu={
        isMulti ? (
          <Menu
            containerProps={{ id: 'text-filter-menu-multiple' }}
            className="dg-multiple-filter"
            visible
          >
            <Menu.SelectionGroup
              active={selected}
              onSelect={(newValues) => {
                setSelected(newValues);
              }}
              focusOnOpen
              multi
              searchable
            >
              {renderOptions(options)}
            </Menu.SelectionGroup>
          </Menu>
        ) : (
          <MenuCombobox
            {...otherMenuProps}
            className={className}
            dropdownFilterOptions={{
              ...column.dropdownFilterOptions,
              maxOptions,
            }}
            maxWidth={column.filterMenuMaxWidth}
            minWidth={column.filterMenuMinWidth}
            onSelectMenuItem={(selectedValue) => {
              if (selectedValue) {
                onAddFilter(
                  {
                    group: column.searchBy || column.property,
                    label: column.label,
                    value: selectedValue,
                    operator: 'contains',
                    transformRowValue: column.valueTransformation,
                  },
                  column,
                );
                onOpenMenu(false);
              }
            }}
          />
        )
      }
      isOpen={isMenuOpened}
      maxWidth={column.filterMenuMaxWidth}
      menuProps={menuProps}
      minWidth={column.filterMenuMinWidth}
      onOpenMenu={onOpenMenu}
      onClose={isMulti ? onCloseMulti : onClose}
      options={compact(options)}
      placement={placement}
      triggerComponent={
        <DSButton
          buttonType="text"
          icon={<SearchSmall />}
          onClick={() => (isMenuOpened ? onOpenMenu(false) : onOpenMenu(true))}
          size="s"
        />
      }
    />
  );
}

TextFilterMenu.propTypes = {
  column: PropTypes.any,
  menuStyle: PropTypes.shape({}),
  className: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.shape({})),
  isMenuOpened: PropTypes.bool,
  onOpenMenu: PropTypes.func,
  onClose: PropTypes.func,
  placement: PropTypes.string,
  menuProps: PropTypes.shape({}),
  onAddFilter: PropTypes.func,
  onRemoveFilter: PropTypes.func,
  maxOptions: PropTypes.number,
  isMulti: PropTypes.bool,
};

export default TextFilterMenu;
