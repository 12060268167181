import React from 'react';
import { toast as toastifyToast } from 'react-toastify';
import ToastRender from './ToastRender';

export { default as DSToast, DSToastWithSchema } from './DSToast';

export { ToastType } from './ToastType';
export { ToastPosition } from './ToastPosition';
export {
  DSToastAction,
  DSToastActionLink,
  DSToastActionWithSchema,
  DSToastActionLinkWithSchema,
} from './ToastAction';
export const toast = Object.assign(
  ({ type, messageTitle, messageText, ...rest }, options) =>
    toastifyToast(
      <ToastRender
        messageText={messageText}
        messageTitle={messageTitle}
        type={type}
      />,
      {
        ...rest,
        ...options,
        type,
      },
    ),
  { ...toastifyToast, isActive: (id) => toastifyToast.isActive(id) },
);
