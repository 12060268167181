import React, { useCallback, useMemo, useState } from 'react';
import { PropTypes, describe } from 'react-desc';
import { uniqBy } from '@elliemae/ds-utilities/utils';
import DSComboBox from '../../form/ComboBox';

const noop = () => {};

function SearchableList({
  onSelectMenuItem = noop,
  components = {},
  searchTerm: searchTermProp = '',
  items = [],
  dropdownFilterOptions = {},
  returnValue,
  extraListProps = {},
  appendTermInList = false,
  maxOptions = 10,
  loading = false,
}) {
  const [searchTerm, setInputValue] = useState(searchTermProp);

  const handleInputChange = useCallback(
    (value, args) => {
      const action = args ? args.action : null;
      if (action === 'input-blur' || action === 'menu-close') return;
      setInputValue(value);
    },
    [searchTerm],
  );

  const handleFilterByText = useCallback(() => {
    if (searchTerm) onSelectMenuItem(searchTerm);
  }, [searchTerm]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'ArrowRight') {
        handleFilterByText();
      }
    },
    [searchTerm],
  );

  const options = useMemo(
    () =>
      searchTerm && appendTermInList
        ? uniqBy(
          [
            {
              id: searchTerm,
              label: searchTerm,
            },
          ].concat(items),
          (item) => item.label,
        )
        : items,
    [searchTerm, appendTermInList],
  );

  return (
    <DSComboBox
      autoFocus
      components={{ DropdownIndicator: null, ...components }}
      customMenuItemOptions={{
        useTruncatedText: true,
      }}
      inlineMenu
      inputValue={searchTerm}
      menuIsOpen
      onChange={onSelectMenuItem}
      onFilterByInputText={handleFilterByText}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      options={options}
      returnValue={returnValue}
      valueProperty="id"
      maxOptions={maxOptions}
      loading={loading}
      {...extraListProps}
      {...dropdownFilterOptions}
    />
  );
}

const props = {
  /** callback after item gets selected */
  onSelectMenuItem: PropTypes.func.description(
    'callback after item gets selected',
  ),
  /** Object with custom components for react-select */
  components: PropTypes.shape({}).description(
    'Object with custom components for react-select',
  ),
  /** search term to filter for */
  searchTerm: PropTypes.string.description('search term to filter for'),
  /** item options */
  items: PropTypes.array.description('item options'),
  /** options */
  dropdownFilterOptions: PropTypes.shape({}).description('options'),
  /** Whether the combo box is has value to return or not */
  returnValue: PropTypes.bool.description(
    'Whether the combo box is has value to return or not',
  ),
  /** extra props to pass down to combo */
  extraListProps: PropTypes.shape({}).description(
    'extra props to pass down to combo',
  ),
  /** append search term as item on the list */
  appendTermInList: PropTypes.bool.description(
    'append search term as item on the list',
  ),
  /** maxoptions for combobox */
  maxOptions: PropTypes.number.description('maxoptions for combobox'),
  /**  loading flag */
  loading: PropTypes.bool.description('loading flag'),
};

SearchableList.propTypes = props;

const DSMenuSearchableListWithSchema = describe(SearchableList);

DSMenuSearchableListWithSchema.propTypes = props;

export default SearchableList;
export { DSMenuSearchableListWithSchema };
