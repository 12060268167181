import React from 'react';
import FloatingLabelInputImpl from './FloatingLabelInputImpl';
import DSLabel from '../FormItem/Label/DSLabel';
import DSTextBox from '../TextBox/DSTextBox';

const noop = () => {};
const DSFloatingLabelInput = ({
  containerProps = {},
  arialLabel = '',
  /**
   * Gets the reference for the input controller
   *
   * @param {HTMLElement} node
   */
  innerRef = noop,
  /**
   * Custom className for floatingLabelInput
   */
  className = '',
  /**
   * Props injected to the input component
   *
   * @type Object
   */
  extraInputProps = {},
  /**
   * Input controller
   *
   * @type {element}
   */
  inputComponent = DSTextBox,
  /**
   * Input value
   *
   * @type {string}
   */
  value = '',
  /**
   * Label component
   *
   * @type {element}
   */
  labelComponent = DSLabel,
  /**
   * Label text
   *
   * @type {string | element}
   */
  labelText = null,
  /**
   * Callback when input controller has changed
   *
   * @param event
   */
  onChange = noop,
  onBlur = noop,
  onFocus = noop,
  /**
   * Whether the label is floating or not
   *
   * @type {boolean}
   */
  isOpen = false,
  /**
   * Whether ...
   *
   * @type {boolean}
   */
  hasError = false,
  /**
   * Whether ...
   *
   * @type {boolean}
   */
  readOnly = false,
  /**
   * Whether ...
   *
   * @type {boolean}
   */
  disabled = false,
  /**
   * Whether ...
   *
   * @type {boolean}
   */
  required = false,
  optional = false,
  mask = null,
  useSubfix = '',
}) => (
  <FloatingLabelInputImpl
    arialLabel={arialLabel}
    className={className}
    disabled={disabled}
    extraInputProps={extraInputProps}
    hasError={hasError}
    innerRef={innerRef}
    inputComponent={inputComponent}
    isOpen={isOpen}
    labelComponent={labelComponent}
    labelText={labelText}
    mask={mask}
    onBlur={onBlur}
    onChange={onChange}
    onFocus={onFocus}
    optional={optional}
    readOnly={readOnly}
    required={required}
    useSubfix={useSubfix}
    value={value}
  />
);

export default DSFloatingLabelInput;
