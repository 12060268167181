const basicAction = ({
  hasError = false,
  hasWarning = false,
  hasSuccess = false,
  modalType,
}) => {
  const cssModifier = [];
  // valid state modifier
  if (hasError) cssModifier.push('error');
  else if (hasWarning) cssModifier.push('warning');
  else if (hasSuccess) cssModifier.push('success');
  if (modalType) cssModifier.push(`modal-type-${modalType}`);
  return cssModifier;
};

const instance = [basicAction];
const pipe = () => ({
  getInstance() {
    return instance;
  },
  add(customModifier) {
    instance.push(customModifier);
  },
  execute(props) {
    return instance.reduce((temp, action) => [...temp, ...action(props)], []);
  },
});

export default pipe;
