import React from 'react';
import { FixedSizeList as List } from 'react-window';
import useHeightByAmountOfItems from './utils/useHeightByAmountOfItems';

const MenuItem = ({ data, index, style }) => (
  <div key={index} style={style}>
    {data[index]}
  </div>
);

// eslint-disable-next-line no-unused-vars
function resolveComputation(items, cb, timeout = 1000) {
  return () =>
    new Promise(resolve => {
      const doResolve = result => {
        cb(result);
        resolve(result);
      };
      let result = 0;
      const timer = setTimeout(() => doResolve(result), timeout);
      items.forEach(option => {
        result =
          option.props.label.length > result
            ? option.props.label.length
            : result;
      });

      clearTimeout(timer);
      doResolve(result);
    });
}

export default function VirtualMenuList({
  items,
  itemHeight = 32,
  amountItemsInWindow = 5,
  width,
  height,
}) {
  const computedListHeight = useHeightByAmountOfItems({
    amountItems: amountItemsInWindow,
    itemHeight,
    items,
  });

  return (
    <List
      height={height || computedListHeight}
      itemCount={items.length}
      itemData={items}
      itemSize={itemHeight}
      width={width}
    >
      {MenuItem}
    </List>
  );
}
