/* eslint-disable no-plusplus */
/* eslint-disable no-loops/no-loops */
export const checkIfSortable = (column, rows) => {
  for (let index = 0; index < rows.length; index++) {
    const element = rows[index][column];
    if (!(element === null || element === undefined || element === '')) {
      return true;
    }
  }
  return false;
};

export default checkIfSortable;
