/* eslint-disable max-lines */
/* eslint-disable react/display-name */
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  get,
  curry,
  set,
  cloneDeep,
  isFunction,
} from '@elliemae/ds-utilities/utils';

// TODO: use lenses to access nested fields

export const appendHeaderFormatter = curry((formatter, column) => ({
  ...column,
  header: {
    ...column.header,
    formatters: [...get(column, 'header.formatters', []), formatter],
  },
}));

export const appendCellFormatter = curry(
  (formatter, column, path = 'formatters') => {
    const nextColumn = cloneDeep(column);
    const formatters = get(nextColumn, ['cell', path], []);
    const scrollingFormatters = get(nextColumn, 'cell.scrollingFormatters', []);
    if (Array.isArray(formatter)) {
      if (formatter[1]) scrollingFormatters.push(formatter[1]);
      formatters.push(formatter[0]);
    } else {
      formatters.push(formatter);
      scrollingFormatters.push(formatter);
    }
    set(nextColumn, ['cell', path], formatters);
    set(nextColumn, 'cell.scrollingFormatters', scrollingFormatters);
    return nextColumn;
  },
);

const defaultColumnDefinitionOptions = {
  normalize: false,
  useTextTruncation: true,
  isUserColumn: true,
  visible: true,
};

export const mergeClassNameToColumnHeader = curry((className, column) => {
  const headerProps = get(column, ['header', 'props'], {});
  const headerClassName = headerProps.className || '';
  return {
    ...column,
    header: {
      ...column.header,
      props: {
        ...headerProps,
        className: `${headerClassName} ${className}`,
      },
    },
  };
});

export default function initColumnDefinition(options = {}) {
  // eslint-disable-next-line complexity
  return (column) => {
    // todo: clean this
    if (column.type === 'number') {
      // eslint-disable-next-line no-param-reassign
      column.cellStyle = {
        textAlign: 'right',
      };
    }
    const { useTextTruncation = true, isUserColumn, visible, normalize } = {
      ...defaultColumnDefinitionOptions,
      ...options,
    };

    if (normalize) {
      // eslint-disable-next-line no-param-reassign
      column.originalProperty = column.property;
      // eslint-disable-next-line no-param-reassign
      column.property = column.property.replace(/\s+/g, '');
    }

    // eslint-disable-next-line no-param-reassign
    column.props = {
      ...column.props,
      style: {},
    };
    // eslint-disable-next-line no-param-reassign
    column.header = {
      ...column.header,
      label: column.label,
      formatters: [...get(column, ['header', 'formatters'], [])],
      props: {
        style: column.headerStyle || {},
      },
    };
    if (
      useTextTruncation &&
      !(
        isFunction(column.customHeaderRenderer) ||
        column.header.formatters.length
      )
    ) {
      column.header.formatters.push((value) => (
        <span className="cell-value">{value}</span>
      ));
    }

    // eslint-disable-next-line no-param-reassign
    column.cell = {
      ...column.cell,
      transforms: [],
      formatters: [...get(column, ['cell', 'formatters'], [])],
      scrollingFormatters: [...get(column, ['cell', 'formatters'], [])],
      props: {
        style: column.cellStyle || {},
      },
    };

    if (
      useTextTruncation &&
      !(isFunction(column.customRenderer) || column.cell.formatters.length)
    ) {
      column.cell.formatters.push((value) => (
        <span
          className="cell-value"
          style={{
            justifyContent:
              column.type === 'number' ? 'flex-end' : 'flex-start',
          }}
        >
          {value}
        </span>
      ));
    }
    // eslint-disable-next-line no-param-reassign
    column.uuid = column.uuid || uuidv4();
    // eslint-disable-next-line no-param-reassign
    column.visible = column.visible === undefined ? visible : column.visible;
    // eslint-disable-next-line no-param-reassign, max-lines
    column.isUserColumn = column.isUserColumn || isUserColumn;
    // eslint-disable-next-line no-param-reassign
    column.headerHookEffects = [];
    return column;
  };
}
