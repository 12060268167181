export const parsePerPageNumber = (number, max, min = 1) => {
  if (number > max) return max;
  if (number < min) return min;
  return number;
};

export default function paginate(
  rows,
  { page, perPage = 1, amountOfPages: amount, totalRows },
) {
  // return if pagination state is controlled
  if (amount && totalRows) {
    return {
      amount,
      rows,
      page,
    };
  }
  // adapt to zero indexed logic
  const p = page - 1 || 0;

  const amountOfPages = perPage
    ? Math.ceil(rows.length / (perPage > 0 ? perPage : 1))
    : 1;
  const startPage = p < amountOfPages ? p : 0;

  return {
    amount: amountOfPages,
    rows: perPage
      ? rows.slice(startPage * perPage, startPage * perPage + perPage)
      : rows,
    allRows: rows,
    page: startPage,
  };
}
