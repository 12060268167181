/* eslint-disable react/prop-types */
/* eslint-disable indent */
import React, { useCallback, useMemo, useState } from 'react';
import { runAll, toggleInObject } from '@elliemae/ds-utilities/utils';
import DropdownMenuContext from './DropdownMenuContext';

const noop = () => null;

function DropdownMenuProvider({
  onSelectMenuItem = noop,
  onOpenMenu = noop,
  onClose,
  children,
  isOpen,
  selection,
  singleGroupSelection,
  onSelectChange = noop,
}) {
  const [isMenuOpen, setMenuOpen] = useState(isOpen);

  const closeMenu = useCallback(() => {
    setMenuOpen(false);
    onOpenMenu(false);
    onClose();
  });
  const openMenu = useCallback(() => {
    setMenuOpen(true);
    onOpenMenu(true);
  });

  const handleItemClick = ({ item, group }) => {
    let activeState;
    // reset selection if single selection group is activated
    let nextSelection = !singleGroupSelection ? selection : {};

    if (group) {
      activeState = group.active;
      nextSelection = {
        ...nextSelection,
        [group.id]: activeState,
      };
    } else {
      const selectionItem = group || item;
      activeState = selectionItem.active || selectionItem.checked;

      // can be toggled (radio with unselection)
      nextSelection = Array.isArray(activeState)
        ? {
            ...nextSelection,
            [selectionItem.id]: activeState,
          }
        : toggleInObject(
            nextSelection,
            selectionItem.id,
            item.label || activeState || true,
          );
    }
    onSelectChange({
      item,
      group,
      selection: nextSelection,
    });
    // todo: just pass item on item and group when selection callback,
    //  use onSelectchange for nextSelection
    onSelectMenuItem(item, activeState, nextSelection);
  };

  const handleCloseOnClick = useCallback(({ item, group = {} }) => {
    if (item.closeOnClick !== undefined) {
      if (item.closeOnClick) closeMenu();
      return;
    }
    if (group.closeOnClick !== undefined) {
      if (group.closeOnClick) closeMenu();
    }
  }, []);

  const valueProvider = useMemo(
    () => ({
      onItemClick: runAll(handleItemClick, handleCloseOnClick),
      closeMenu,
      openMenu,
      isMenuOpen,
      selection,
    }),
    [isMenuOpen, selection],
  );
  return (
    <DropdownMenuContext.Provider value={valueProvider}>
      {children}
    </DropdownMenuContext.Provider>
  );
}

export default DropdownMenuProvider;
