import React from 'react';
import { describe, PropTypes } from 'react-desc';
import { aggregatedClasses } from '@elliemae/ds-classnames';
import styled from 'styled-components';
import DSSeparator from '../../Separator';

const blockName = 'menu-separator';
const Li = styled.li`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const Title = aggregatedClasses('h5')(blockName, 'title');
const MenuSeparator = ({ title, showSeparator = true }) => (
  <Li>
    {showSeparator && <DSSeparator />}
    {title && <Title>{title}</Title>}
  </Li>
);

const props = {
  /** menu separator h5 title */
  title: PropTypes.string.description('menu separator h5 title'),
  /** toggle separator on and off */
  showSeparator: PropTypes.bool.description('toggle separator on and off'),
};

MenuSeparator.propTypes = props;

const DSMenuSeparatorWithSchema = describe(MenuSeparator);
DSMenuSeparatorWithSchema.propTypes = props;

export default MenuSeparator;

export { DSMenuSeparatorWithSchema };
