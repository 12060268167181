/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { aggregatedClasses } from '@elliemae/ds-classnames';
import { ResizeObserver } from 'resize-observer';
import getComponentFromProps from '@elliemae/ds-utilities/getComponentFromProps';
import getSvgPath from './getSvgPath';
import DSTextWrapper from '../../TextWrapper/DSTextWrapper';
import RequiredMark from '../RequiredMark/RequiredMark';

const blockName = 'labeled-outline';

const Wrapper = aggregatedClasses('div')(blockName, null, ({ isOpen }) => ({
  'has-value': isOpen,
}));
const FloatingLabel = aggregatedClasses('label')(blockName, 'label');
const OutlineIdle = aggregatedClasses('div')(blockName, 'idle');
const Outline = aggregatedClasses('div')(blockName, 'outline');
const OutlinePath = aggregatedClasses('path')(blockName, 'outline__path');

const shouldUpdateSvgPath = ({ width, height, labelWidth }, prevSizes) => {
  if (width !== prevSizes.width) return true;
  if (height !== prevSizes.height) return true;
  return labelWidth !== prevSizes.labelWidth;
};

class FloatingLabelInputImpl extends Component {
  resizeObserver = new ResizeObserver(() => this.updatePath());

  prevSizes = {};

  static defaultProps = { onChange: () => null };

  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.outlinePathRef = React.createRef();
    this.outlineIdleRef = React.createRef();
    this.labelRef = React.createRef();

    this.updatePath = this.updatePath.bind(this);
  }

  componentDidMount() {
    this.resizeObserver.observe(this.wrapperRef.current);
  }

  componentDidUpdate() {
    if (!shouldUpdateSvgPath(this.sizes, this.prevSizes)) return;
    this.updatePath();
  }

  componentWillUnmount() {
    this.resizeObserver.unobserve(this.wrapperRef.current);
  }

  get sizes() {
    const { current: labelEl } = this.labelRef;
    const { current: wrapperEl } = this.wrapperRef;
    const { current: outlineIdleEl } = this.outlineIdleRef;
    const elStyles = window.getComputedStyle(outlineIdleEl);
    const leftRadius = elStyles.getPropertyValue('border-top-left-radius');
    const rightRadius = elStyles.getPropertyValue('border-top-right-radius');
    const hasLeftRadius = parseFloat(leftRadius) !== 0;
    const hasRightRadius = parseFloat(rightRadius) !== 0;
    return {
      labelWidth: labelEl.offsetWidth * 0.9,
      width: wrapperEl.offsetWidth,
      height: wrapperEl.offsetHeight,
      radiusStyle: leftRadius,
      hasLeftRadius,
      hasRightRadius,
    };
  }

  get isOpened() {
    const { focused, isOpen, value } = this.props;
    return (
      focused || (Array.isArray(value) ? !!value.length : !!value) || isOpen
    );
  }

  getValue(value) {
    const { valueAccessor } = this.props;
    if (valueAccessor) return valueAccessor(value);
    // is an event
    if (value && value.target) return value.target.value;

    return value;
  }

  updatePath() {
    const path = getSvgPath(this.sizes);
    this.prevSizes = this.sizes;
    this.outlinePathRef.current.setAttribute('d', path);
  }

  render() {
    const {
      ariaLabel,
      hasError,
      readOnly,
      disabled,
      inputComponent,
      labelText,
      onBlur,
      onFocus,
      innerRef,
      extraInputProps,
      required,
      value,
      onChange,
      mask,
      useSubfix,
      optional,
    } = this.props;

    const inputProps = {
      ariaLabel,
      className: 'floating-label-input-controller',
      disabled,
      hasError,
      onBlur,
      onChange,
      onFocus,
      readOnly,
      value,
      innerRef,
      mask,
      useSubfix,
      ...extraInputProps,
    };
    const InputComponent = getComponentFromProps(inputComponent, inputProps);
    const isOpen = this.isOpened;

    return (
      <Wrapper
        classProps={{
          hasError,
          isOpen,
          readOnly,
          disabled,
        }}
        innerRef={this.wrapperRef}
        onBlur={() => null}
        onFocus={() => null}
        onMouseOut={this.handleMouseOut}
        onMouseOver={this.handleMouseOver}
      >
        {InputComponent}
        <FloatingLabel classProps={{ isOpen }} innerRef={this.labelRef}>
          <DSTextWrapper maxWidth="100%" text={labelText} />
          <RequiredMark optional={optional} required={required} />
        </FloatingLabel>
        <Outline>
          <svg>
            <OutlinePath innerRef={this.outlinePathRef} />
          </svg>
        </Outline>
        <OutlineIdle innerRef={this.outlineIdleRef} />
      </Wrapper>
    );
  }
}

export default FloatingLabelInputImpl;
