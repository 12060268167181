export const reducePropsGetter = (decorators = [], grid) => (
  props = {},
  extraParams,
) => {
  let nextProps = props;
  decorators.forEach((decorator) => {
    nextProps = decorator(nextProps, grid, extraParams);
  });
  return nextProps;
};

/* eslint-disable-next-line max-params */
export function evaluateTransforms(
  transforms,
  value,
  extraParams = {},
  props = {},
  grid,
) {
  let nextProps = props;
  transforms.forEach((transform) => {
    nextProps = transform(value, extraParams, nextProps, grid);
  });
  return nextProps;
}

/* eslint-disable-next-line max-params */
export function evaluateFormatters(formatters, value, extraParams = {}, grid) {
  return formatters.reduce(
    (result, formatter) => formatter(result, extraParams, grid),
    value,
  );
}

export function composeFormatters(
  formatters,
  instance,
  getItemValue = (record) => record.data.name,
) {
  // todo: make the formatters subscribe to the item props instead of getting the whole instance
  // todo: addd should formatter update to memoize specific ones
  return (props) =>
    formatters.reduce(
      (result, formatter) => formatter(result, props, instance),
      getItemValue(props.record),
    );
}

export const applyDecorators = (subject, decorators = [], ...args) =>
  decorators.reduce(
    (nextSubject, decorator) => decorator(nextSubject, ...args),
    subject,
  );

export const decorateColumns = (columns, decorators, grid) => {
  const nextColumns = applyDecorators(
    columns,
    decorators.decorateColumns,
    grid,
  );
  return nextColumns.map((column, index) =>
    applyDecorators(column, decorators.decorateColumn, grid, index),
  );
};

export const mergeInstance = (instance, instanceHook) => ({
  ...instance,
  ...instanceHook(instance),
});

export const registerStateHookToInstance = (instance, stateHook) => {
  const { state, actions } = stateHook(instance);
  return {
    ...instance,
    state: {
      ...instance.state,
      ...state,
    },
    actions: {
      ...instance.actions,
      ...actions,
    },
  };
};

const reduceHotKey = (
  instance,
  { key, handler, options, allowDocumentHandler },
) => ({
  ...instance,
  hotKeys: {
    ...instance.hotKeys,
    [key]: { handler, options, allowDocumentHandler },
  },
});

export const registerHotKeysHookToInstance = (instance, hotKeysHook) => {
  const hotKeys = hotKeysHook(instance);
  if (Array.isArray(hotKeys)) {
    return hotKeys.reduce(reduceHotKey, instance);
  }
  return reduceHotKey(instance, hotKeys);
};
