import { aggregatedClasses } from '@elliemae/ds-classnames';
import React from 'react';
import { describe, PropTypes } from 'react-desc';
import FocusGroupProvider from '@elliemae/ds-shared/FocusGroup/FocusGroupManager';

const pillGroupBlockName = 'pill-group';

export const Container = aggregatedClasses('div')(pillGroupBlockName);

const PillGroup = ({
  containerProps = {},
  onFocusNextGroup,
  onFocusPreviousGroup,
  onFocusGroupSet,
  children,
}) => (
  <FocusGroupProvider
    keyBindings={{
      Tab: 'next',
    }}
    onFocusGroupSet={onFocusGroupSet}
    onFocusNextGroup={onFocusNextGroup}
    onFocusPreviousGroup={onFocusPreviousGroup}
    orientation="horizontal"
  >
    <Container {...containerProps}>{children}</Container>
  </FocusGroupProvider>
);

const props = {
  /** props injected to component wrapper */
  containerProps: PropTypes.object.description(
    'props injected to component wrapper',
  ),
  /** focus next group callback */
  onFocusNextGroup: PropTypes.func.description('focus next group callback'),
  /** focus prev group callback */
  onFocusPreviousGroup: PropTypes.func.description('focus prev group callback'),
  /** focus group set callback */
  onFocusGroupSet: PropTypes.func.description('focus group set callback'),
  /** pill components */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired.description('pill components'),
};

PillGroup.propTypes = props;

const DSPillGroupWithSchema = describe(PillGroup);
DSPillGroupWithSchema.propTypes = props;

export { PillGroup, DSPillGroupWithSchema };
