/* eslint-disable max-lines */
import React from 'react';
import DSButton from '@elliemae/ds-basic/Button';
import { ModalFooter, ModalFooterActions } from '../blocks';

export const Footer = ({
  additionalFooterCssClass,
  actionsRef,
  rejectLabel,
  onReject,
  overridePropsRejectButton,
  confirmLabel,
  onConfirm,
  overridePropsConfirmButton,
  showRejectButton,
}) => (
  <ModalFooter
    classProps={{ additionalFooterCssClass }}
    data-testid="modal-footer-wrapper"
  >
    <ModalFooterActions ref={actionsRef}>
      {showRejectButton === true && (
        <DSButton
          buttonType="secondary"
          className="action-reject"
          containerProps={{ 'data-testid': 'modal-footer-reject-btn' }}
          labelText={rejectLabel}
          onClick={onReject}
          {...overridePropsRejectButton}
        />
      )}
      <DSButton
        buttonType="primary"
        className="action-confirm"
        containerProps={{ 'data-testid': 'modal-footer-confirm-btn' }}
        labelText={confirmLabel}
        onClick={onConfirm}
        {...overridePropsConfirmButton}
      />
    </ModalFooterActions>
  </ModalFooter>
);

export default Footer;
