import useDerivedStateFromProps from '@elliemae/ds-utilities/hooks/useDerivedStateFromProps';
import { toggleInObject } from '@elliemae/ds-utilities/utils';
import createInstancePlugin from '@elliemae/ds-shared/createDataInstance/createInstancePlugin';
import { addEditableToCell, makeEditableConfig } from './decorateEditable';

const registerStateHook = grid => {
  const {
    rowKey = 'id',
    editingRows: editingRowsProp,
    onColumnRowEdited,
    onColumnRowEdit,
  } = grid.props;
  const [editingRows, setEditingRows] = useDerivedStateFromProps(
    editingRowsProp || {},
  );

  const activateEdit = editParams => {
    const { shouldRefocus } = grid.getInstance();
    const { rowData, columnIndex } = editParams;
    shouldRefocus.current = { forced: true, value: false };
    setEditingRows(prevEditingRows => {
      onColumnRowEdit(editParams);
      return toggleInObject(prevEditingRows, rowData[rowKey], columnIndex);
    });
  };

  const editValue = ({ value, rowData, property, rowIndex }) => {
    setEditingRows({});
    onColumnRowEdited({
      value,
      rowData,
      property,
      rowIndex,
    });
  };

  return {
    state: { editingRows },
    actions: {
      activateEdit,
      editValue,
    },
  };
};

const decorateColumn = (column, grid) => {
  const {
    props: { isColumnEditable = col => col.editable, customHandlers },
  } = grid;

  const editableConfig = makeEditableConfig(grid);

  return isColumnEditable(column)
    ? addEditableToCell(
        {
          column,
          config: editableConfig,
          handlers: customHandlers,
        },
        grid,
      )
    : column;
};

export const EditablePlugin = createInstancePlugin('editable', {
  registerStateHook,
  decorateColumn,
  registerHotKeys(grid) {
    return {
      key: 'enter',
      handler: ({ rowIndex, cellIndex }) => {
        const {
          composedRows,
          decoratedColumns,
          actions: { activateEdit },
          props: { isColumnEditable },
        } = grid.getInstance();

        if (!isColumnEditable(decoratedColumns[cellIndex])) return;

        const rows = composedRows.rows || composedRows;
        const row = rows[rowIndex];
        if (!row) return;

        activateEdit({
          rowData: row,
          columnIndex: cellIndex,
        });
      },
      options: {
        keydown: false,
        keyup: true,
      },
    };
  },
});
