import React, { useRef, useState } from 'react';
import { PropTypes, describe } from 'react-desc';
import DSDropdownMenu from '@elliemae/ds-dropdownmenu';
import ChevronDown from '@elliemae/ds-icons/ChevronDown';
import useFocusGroupItem from '@elliemae/ds-shared/FocusGroup/useFocusGroupItem';
import { Pill } from './Pill';

function DropdownPill({
  containerProps = {},
  label = '',
  variant,
  tabIndex = -1,
  closeMenuOnItemSelection = false,
  maxWidth,
  minWidth,
  ...dropdownProps
}) {
  const ref = useRef(null);
  const [chevronEl, chevronRef] = useState();
  const { focusFirst } = useFocusGroupItem(ref);
  return (
    <DSDropdownMenu
      {...dropdownProps}
      closeMenuOnItemSelection={closeMenuOnItemSelection}
      maxWidth={maxWidth}
      menuProps={{ className: 'dropdown-pill-menu', zIndex: 100 }}
      minWidth={minWidth}
      onClose={focusFirst}
      referenceNode={chevronEl}
      triggerComponent={
        <Pill
          className="dropdown-pill"
          containerProps={{ role: 'listbox', ...containerProps }}
          innerRef={ref}
          label={label}
          rightAddon={
            <div ref={chevronRef}>
              <ChevronDown
                className="dropdown-pill-chevron-down"
                data-testid="dropdown-pill-button"
              />
            </div>
          }
          tabIndex={tabIndex}
          variant={variant}
        />
      }
    />
  );
}

const props = {
  /** props injected to component wrapper */
  containerProps: PropTypes.object.description(
    'props injected to component wrapper',
  ),
  /** labeled pill text label */
  label: PropTypes.string.isRequired.description('pill text label'),
  /** pill variant */
  variant: PropTypes.oneOf(['value', 'title']).description('pill variant'),
  /** html focus border */
  tabIndex: PropTypes.number.description('html focus order'),
  /** close menu on item selection */
  closeMenuOnItemSelection: PropTypes.bool.description('close menu on item selection'),
  /** pill max width */
  maxWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).description('pill max width'),
  /** pill min width */
  minWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).description('pill min width'),
};

DropdownPill.propTypes = props;
const DSDropdownPillWithSchema = describe(DropdownPill);
DSDropdownPillWithSchema.propTypes = props;

export { DropdownPill, DSDropdownPillWithSchema };
