import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import GripperVertical from '@elliemae/ds-icons/GripperVertical';
import {
  appendHeaderFormatter,
  mergeClassNameToColumnHeader,
} from '@elliemae/ds-shared/useDataGrid/initColumnDefinition';

const DragHandle = SortableHandle(() => (
  <GripperVertical className="drag-handle" />
));

const DndHeaderFormatter = value => (
  <>
    <DragHandle />
    {value}
  </>
);

const addDragAndDropToColumn = (grid, column) => {
  column = appendHeaderFormatter(DndHeaderFormatter, column);
  return mergeClassNameToColumnHeader('with-dnd-column', column);
};

export { addDragAndDropToColumn };
