import { useState, useEffect } from 'react';
import { getDefaultTheme } from '@elliemae/pui-theme';
import { rem } from 'polished';
import { op } from './arithmetic';

const DSTheme = getDefaultTheme();

const mobileFactor = 13 / 16;
const desktopFactor = 13;
const desktopBaseFont = 13;
const mobileBaseFont = 16;

/**
 * 
 * dimsum
 *   none: 0,                           
     xxs:   4px
     xs:    8px
     s:     16px
     m:     24px
     l:     32px
     xl:    48px
     xxl:   64px
 *
 * pui
 *    none: 0,                           
     xxs:   8px 
     xs:    16px
     s:     32px
     m:     48px
     l:     56px
     xl:    64px
     xxl:   72px
 *
 * translate
 *    
     xxs:   8px   => 4px
     xs:    16px  => 8px
     s:     32px  => 16px
     m:     48px  => 24px
     l:     56px  => 32px
     xl:    64px  => 48px
     xxl:   72px  => 64px
 * 
 */

const translateUnits = {
  '8px': '4px',
  '16px': '8px',
  '32px': '16px',
  '48px': '24px',
  '56px': '32px',
  '64px': '48px',
  '72px': '64px',
};

const translateAliasUnits = {
  xxs: '4px',
  xs: '8px',
  s: '16px',
  m: '24px',
  l: '32px',
  xl: '48px',
  xxl: '64px',
};

/**
 * 
 * dimsum
 *
    8: 900: '2.7692rem', // 36px
    7: 800: '1.8461rem', // 24px
    6: 700: '1.3846rem', // 18px
    5: 600: '1.2307rem', // 16px
    4: 500: '1.0769rem', // 14px
    3: 400: '1rem', // 13px
    2: 300: '0.9230rem', // 12px
    1: 200: '0.8461rem', // 11px
    0: 100: '0.75rem',
 *
 * pui
 *                        
    0: '0.8461rem',
    1: '0.9230rem',
    2: '1rem',
    3: '1.0769rem',
    4: '1.2307rem',
    5: '1.3846rem',
    6: '1.8461rem',
    7: '2.7692rem',
 *
 * translate
 *    
     900: 7
     800: 6
     700: 5
     600: 4,
     500: 3,
     400: 2,
     300: 1,
     200: 0
 * 
 */
const translateFonts = {
  '8': 7,
  '7': 6,
  '6': 5,
  '5': 4,
  '4': 3,
  '3': 2,
  '2': 1,
  '1': 0,
};

const translateAliasFonts = {
  '900': 7,
  '800': 6,
  '700': 5,
  '600': 4,
  '500': 3,
  '400': 2,
  '300': 1,
  '200': 0,
};

export function __UNSAFE_ALIAS_TO_DIMSUM(unit) {
  if (translateAliasUnits[unit]) return translateAliasUnits[unit];
  return unit;
}

export function __UNSAFE_SPACE_TO_DIMSUM(unit) {
  if (translateUnits[unit]) return translateUnits[unit];
  return `${(parseFloat(unit) * (mobileBaseFont / desktopBaseFont)) / 2}px`;
}

export function __UNSAFE_FONT_TO_DIMSUM(unit) {
  if (translateAliasFonts[unit]) return translateAliasFonts[unit];
  if (translateFonts[unit]) return translateFonts[unit];
  return 0;
}

export function toMobile(unit) {
  if (!isMobile()) return unit;
  return `${parseFloat(unit) * (desktopBaseFont / mobileBaseFont)}rem`;
}

export const useIsMobile = () => {
  const [mobile, setMobile] = useState(isMobile());
  useEffect(() => {
    if (!window) return false;
    function handleResize() {
      setMobile(isMobile());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  if (!window) return false;
  return mobile;
};

export const isMobile = () => {
  if (!window) return false;
  return (
    parseFloat(op('-', DSTheme.breakpoints[1], rem(window.innerWidth, 16))) >= 0
  );
};

// https://jsramblings.com/2018/02/04/styled-components-media-queries.html
DSTheme.mediaQueries = {
  small: `(min-width: ${DSTheme.breakpoints[0]})`,
  medium: `(min-width: ${DSTheme.breakpoints[1]})`,
  large: `(min-width: ${DSTheme.breakpoints[2]})`,
};

export default DSTheme;
