/* eslint-disable react/display-name */
import React, { useContext } from 'react';
import { runAll, get } from '@elliemae/ds-utilities/utils';
import DropdownMenuContext from './DropdownMenuContext';

const noop = () => null;

function MenuItemGroupSelectionHOC(WrappedComponent, handlerName = 'onSelect') {
  return ({ item = {}, ...restProps }) => {
    const { onItemClick, selection } = useContext(DropdownMenuContext);
    const propHandler = restProps[handlerName] || noop;
    const handler = {
      [handlerName]: (active, itemOption) =>
        runAll(
          item.onSelect,
          onItemClick,
          propHandler,
        )({ item: itemOption, group: { ...item, active } }),
    };
    return (
      <WrappedComponent
        {...restProps}
        {...(selection
          ? { active: get(selection, [item.id]) || item.active }
          : {})}
        {...handler}
      />
    );
  };
}

export default MenuItemGroupSelectionHOC;
