import { isEmpty } from '@elliemae/ds-utilities/utils';
import DimsumConfig from '../../../dimsum.config';
import formPipe from './converPropsToFormModifiers';
import layoutModifiers from './convertPropsToLayoutModifiers';
import feedBackModifiers from './convertPropToFeedBackModifiers';
import sizeModifiers from './convertPropsToSizeModifiers';
import textModifiers from './convertPropsToTextModifiers';
import colorModifiers from './convertPropsToColorModifiers';

export const cssPrefix = `${DimsumConfig.classNamePrefix}-`;

const executePipelines = props => {
  const form = formPipe().execute(props);
  const layout = layoutModifiers().execute(props);
  const feedback = feedBackModifiers().execute(props);
  const size = sizeModifiers().execute(props);
  const text = textModifiers().execute(props);
  const color = colorModifiers().execute(props);
  return [...form, ...layout, ...feedback, ...size, ...text, ...color];
};

/**
 * Docs: http://getbem.com/naming/
 * blockName
 * cssClassNameModifier: Main modifier
 * props { hasError, hasWarning, hasSuccess, readOnly, disabled}
 *
 * @param blockName
 * @param cssClassNameModifier
 * @param props
 * @param options
 */
const cssPipeline = (
  blockName = 'block',
  cssClassNameModifier = '',
  props = {},
  options = {},
) => {
  const { prefix, element } = options;

  const prefixBlockName = `${prefix || cssPrefix}${blockName}${
    element ? `__${element}` : ''
  }`;

  // PipeLine
  const pipes = !isEmpty(props) ? executePipelines(props) : [];

  const basePipeline = [prefixBlockName, ...pipes];

  // specific classname
  const className = cssClassNameModifier
    ? [...basePipeline]
        .join(` ${prefixBlockName}--`)
        .concat(` ${cssClassNameModifier}`)
    : [...basePipeline].join(` ${prefixBlockName}--`);

  return {
    blockName,
    cssPrefix,
    prefixBlockName,
    cssClassName: className,
    mainModifier(modifier) {
      return `${className}--${modifier}`;
    },
    classNameBlock(elementName, usePrefix = true) {
      return `${!usePrefix ? blockName : prefixBlockName}-${elementName}`;
    },
    classNameBlockModifier(modifierName, elementBlockName = '') {
      return `${prefixBlockName}-${elementBlockName}--${modifierName}`;
    },
    classNameElement(elementName, elementBlockName = '') {
      return `${prefixBlockName}${
        elementBlockName ? `-${elementBlockName}` : ''
      }__${elementName}`;
    },
    classNameModifier(modifierName, elementName = '') {
      return `${prefixBlockName}${
        elementName ? `__${elementName}` : ''
      }--${modifierName}`;
    },
  };
};

export default cssPipeline;
