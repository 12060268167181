/* eslint-disable max-lines */
/* eslint-disable no-underscore-dangle */
import React, { useMemo } from 'react';
import createInstancePlugin from '@elliemae/ds-shared/createDataInstance/createInstancePlugin';
import { aggregatedClasses } from '@elliemae/ds-classnames';
import { VariableSizeList } from 'react-window';
import { walkTreeStrategy } from './walkStrategy';
import { RowSizes } from '../../rowSizes';
import { dataGridBlockName } from '../../blockNames';

const blockName = `${dataGridBlockName}-group-row-header`;
const GroupRowHeaderWrapper = aggregatedClasses('div')(blockName);
const GroupRowHeaderTitle = aggregatedClasses('div')(blockName, 'title');

export const GroupingPlugin = createInstancePlugin('groupedRows', {
  decorateGrid(grid) {
    const { columns, composedRows } = grid.getInstance();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const detailColumns = useMemo(
      () =>
        columns
          .map((column) => ({
            ...column,
            customRenderer: column.detailCustomRenderer,
          }))
          .filter((col) => !col.expandableColumn),
      [columns],
    );
    const flatRows = [];
    walkTreeStrategy({
      data: composedRows,
      cb: (n) => flatRows.push(n),
    });
    return {
      detailColumns,
      composedRows: flatRows,
    };
  },
  getBodyProps(bodyProps, grid) {
    const { composedRows: rows } = grid.getInstance();
    // implementation of expandable row single column with aggregation
    const getItemSize = (index) => {
      const rowData = rows[index];
      if (!rowData) return RowSizes.normal;
      return rowData._isGroup || rowData.isGroup
        ? RowSizes.compact
        : RowSizes.normal;
    };
    // eslint-disable-next-line no-unused-vars
    const getListHeight = () => {
      const size = rows.reduce((h, r) => {
        const rowHeight =
          r._isGroup || r.isGroup ? RowSizes.compact : RowSizes.normal;
        return h + rowHeight;
      }, 0);
      return size;
    };
    return {
      ...bodyProps,
      getListHeight,
      listComponent: VariableSizeList,
      listProps: { itemSize: getItemSize },
    };
  },
  composeRows: (rows, grid) => {
    const {
      composedRows,
      props: { rowKey },
    } = grid.getInstance();

    if (Array.isArray(rows)) {
      const filterIds = rows.map((r) => r.data[rowKey]);
      return composedRows
        .filter((c) => filterIds.includes(c.data[rowKey]))
        .map((r) => ({
          ...r.data,
          children: Array.isArray(r.data.children)
            ? r.data.children.filter((c) => filterIds.includes(c[rowKey]))
            : r.data.children,
          _isGroup: r.isGroup,
        }))
        .filter((g) => g.children === undefined || g.children.length > 0);
    }
    return {
      ...rows,
      allRows: rows.allRows.map((r) => ({
        ...r.data,
        _isGroup: r.isGroup,
      })),
      rows: rows.rows.map((r) => ({
        ...r.data,
        _isGroup: r.isGroup,
      })),
    };
  },
  decorateRenderers(renderers, grid) {
    const Row = renderers.body.row;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    renderers.body.row = useMemo(
      () =>
        // eslint-disable-next-line react/display-name
        // eslint-disable-next-line react/prop-types
        ({ isExpanded, ...rowProps }) => {
          const { composedRows } = grid.getInstance();
          // // https://jira.elliemae.io/browse/PUI-1667
          const node = composedRows[rowProps.index]
            ? composedRows[rowProps.index]
            : rowProps.rowData;
          const { groupedRowsRenderHeader } = grid.props;
          // eslint-disable-next-line no-underscore-dangle
          if (node && node._isGroup) {
            return (
              <>
                <GroupRowHeaderWrapper {...rowProps}>
                  <GroupRowHeaderTitle>
                    {groupedRowsRenderHeader
                      ? groupedRowsRenderHeader(node)
                      : 'Group'}
                  </GroupRowHeaderTitle>
                </GroupRowHeaderWrapper>
              </>
            );
          }
          return <Row {...rowProps} />;
        },
      [],
    );

    return renderers;
  },
});
