/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M9.5 1C4.253 1 0 5.253 0 10.5S4.253 20 9.5 20s9.5-4.253 9.5-9.5S14.747 1 9.5 1zM9 5c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm3 11H7v-1h2V9H7V8h3v7h2v1z',
  ],
  height: 20,
  width: 20,
});
