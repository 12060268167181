/* eslint-disable react/display-name */
import React from 'react';
import FocusGroupManager from './FocusGroupManager';

export default function FocusGroupHoc(WrappedComponent, options = {}) {
  return ({
    onExitFocusGroup,
    onFocusPrevGroup,
    focusKeyBindings,
    ...props
  }) => (
    <FocusGroupManager
      keyBindings={focusKeyBindings}
      onExitFocusGroup={onExitFocusGroup}
      onFocusPrevGroup={onFocusPrevGroup}
      {...options}
    >
      <WrappedComponent {...props} {...options} />
    </FocusGroupManager>
  );
}
