/* eslint-disable react/display-name */
import React from 'react';
import { ComboBox, TextBox } from './EditableComponents';

const components = {
  DSComboBox: ComboBox,
  DSTextBox: TextBox,
};

const getEditorComponent = (editorComponent = {}, handlers) => ({
  value,
  onValue,
  extraParameters,
}) => {
  if (typeof editorComponent === 'function') {
    return editorComponent({
      value,
      onValue,
      customHandlers: handlers,
      ...extraParameters,
    });
  }

  const { type = 'DSTextBox', ...restEditorProps } = editorComponent;
  const Component = components[type];

  return (
    <Component autoFocus onValue={onValue} value={value} {...restEditorProps} />
  );
};

export default getEditorComponent;
