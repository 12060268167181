/* eslint-disable indent */
/* eslint-disable react/destructuring-assignment,max-lines,react/display-name, react/require-default-props */
import React from 'react';
import { get, isArray } from 'lodash';
import DSTruncatedTooltipText from '../../../../TruncatedTooltipText';
import DSSeparator from '../../../../Separator';

const CustomOption = ({ index, data, style, isScrolling }) => {
  const {
    items,
    options: { useTruncatedText },
  } = data;
  const option = items[index];

  // react-select performance optimization
  delete option?.props?.innerProps?.onMouseMove;
  delete option?.props?.innerProps?.onMouseOver;

  const optionProps = {
    ...option.props.innerProps,
    style,
    'data-testid': 'combobox-option',
    onClick: (e) => {
      e.stopPropagation();
      if (option.props.innerProps.onClick) option.props.innerProps.onClick(e);
    },
  };

  if (
    useTruncatedText &&
    !isScrolling &&
    get(option, 'props.selectProps.menuIsOpen')
  ) {
    return React.cloneElement(
      option,
      {
        ...option.props,
        innerProps: optionProps,
        tabIndex: 0,
      },
      <>
        {option.props.data.hasSeparator &&
          !isArray(option.props.data.options) && (
            <DSSeparator margin="none" type="non-form" />
          )}
        <DSTruncatedTooltipText value={option.props.children} />
      </>,
    );
  }

  return React.cloneElement(
    option,
    {
      ...option.props,
      tabIndex: 0,
      innerProps: optionProps,
    },
    <>
      {option.props.data.hasSeparator &&
        !isArray(option.props.data.options) && (
          <DSSeparator margin="none" type="non-form" />
        )}
      {option.props.children}
    </>,
  );
};

export default CustomOption;
