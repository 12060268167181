/* eslint-disable max-lines */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { PropTypes, describe } from 'react-desc';
import { omit } from 'lodash';
import { aggregatedClasses } from '@elliemae/ds-classnames';
import { animated } from 'react-spring/web.cjs';
import { useOnClickOutside } from '@elliemae/ds-utilities/hooks';
import { mergeRefs } from '@elliemae/ds-utilities/system';
import {
  focusGroupManagerHoc,
  FocusGroupContext,
} from '@elliemae/ds-shared/FocusGroup';
import { useHiddenTransition } from '../Hidden';
import MenuItemRadio from './MenuItems/MenuItemRadio';
import MenuItemCheckbox from './MenuItems/MenuItemCheckbox';
import CheckboxGroup from './MenuItems/CheckboxGroup';
import RadioGroup from './MenuItems/RadioGroup';
import Separator from './MenuItems/Separator';
import MenuItem from './MenuItems/MenuItem';
import SubMenu from './MenuItems/SubMenu';
import SearchableGroup from './MenuItems/SearchableGroup';
import SelectionGroup from './MenuItems/SelectionGroup';
import { renderMenuItems, menuItemFactory } from './MenuItems/menuItemFactory';
import MenuContext from './MenuContext';

const RESPONSIVE_HEIHGT_MARGIN = 120;

const blockName = 'menu';

const noop = () => {};

const MenuComponent = aggregatedClasses('div')(
  blockName,
  '',
  ({ menuComboBox, type }) => ({
    'menu-combo-box': menuComboBox,
    [`type-${type}`]: type,
  }),
);

/**
 * todo: -- IDEA -- each menu could have an overlay based on an overlay stack context so we can
 * capture the context of the clicks on very nested components
 */

const isChildOfType = (child, type) => child.type.name === type;

function DSMenu({
  containerProps = {},
  innerRef,
  as: MenuTag = animated.ul,
  children = undefined,
  onClickOutside = noop,
  // handlers from hidden
  visible = undefined,
  focusOnOpen = false,
  maxOption = 0,
  style = {},
  minWidth = undefined,
  maxWidth = undefined,
  closeMenu,
  responsiveHeight = false,
  ...otherProps
}) {
  const menuRef = useRef(null);
  const { focusFirst } = useContext(FocusGroupContext);
  const { destroyed, ...hiddenProps } = useHiddenTransition({ visible, style });

  // force no addon to the children if the menu doesn't have any item with leftAddon like SelectionGroup
  const nextChildren = useMemo(() => {
    const forceLeftAddon = React.Children.toArray(children).some(
      (child) =>
        isChildOfType(child, 'SelectionGroup') ||
        isChildOfType(child, 'MenuItemCheckbox') ||
        isChildOfType(child, 'MenuItemRadio') ||
        child.props.leftAddon,
    );

    return React.Children.map(
      children,
      (child) =>
        child &&
        React.cloneElement(child, {
          ...child.props,
          noAddon: !forceLeftAddon ? true : undefined,
          maxOption,
          closeMenu,
        }),
    );
  }, [children]);

  useOnClickOutside(menuRef, onClickOutside);

  useEffect(() => {
    if (focusOnOpen && visible) {
      setTimeout(() => {
        focusFirst();
      }, 0);
    }
  }, [visible, focusOnOpen]);
  // todo: find out a better approach to this
  return !hiddenProps.destroyed ? (
    <MenuContext.Provider value={{ visible, closeMenu }}>
      <MenuComponent
        data-testid="em-ds-menu"
        {...containerProps}
        as={MenuTag}
        classProps={{ ...(otherProps.classProps || {}), type: otherProps.type }}
        innerRef={mergeRefs(innerRef, menuRef)}
        role="menu"
        {...omit(otherProps, ['scheduleUpdate'])}
        {...hiddenProps}
      >
        {/* eslint-disable indent */}
        <div
          className="menu-component-internal-wrapper"
          style={{
            ...style,
            minWidth,
            maxWidth,
            ...(responsiveHeight
              ? {
                  overflowY: 'scroll',
                  maxHeight: responsiveHeight
                    ? `${window.innerHeight - RESPONSIVE_HEIHGT_MARGIN}px`
                    : undefined,
                }
              : {}),
          }}
        >
          {/* eslint-enable indent */}
          {nextChildren}
        </div>
      </MenuComponent>
    </MenuContext.Provider>
  ) : null;
}

const WrappedMenu = focusGroupManagerHoc(DSMenu, { loop: true });

const props = {
  /** Injected props to wrapper element of component */
  containerProps: PropTypes.object.description(
    'Injected props to wrapper element of component',
  ),
  /** Renders the menu with a specific html element */
  as: PropTypes.element.description(
    'Renders the menu with a specific html element',
  ),
  /** Handler when a user clicks outside the menu */
  onClickOutside: PropTypes.func.description(
    'Handler when a user clicks outside the menu',
  ),
  /** Whether the menu is visible or not */
  visible: PropTypes.bool.description('Whether the menu is visible or not'),
  /** When set to true, it's going to focus the first item */
  focusOnOpen: PropTypes.bool.description(
    "When set to true, it's going to focus the first item",
  ),
  /** Customize menu minWidth. Can be undefined or 'number' */
  minWidth: PropTypes.number.description(
    "Customize menu minWidth. Can be undefined or 'number'",
  ),
  /** Customize menu maxWidth. Can be undefined or 'number' */
  maxWidth: PropTypes.number.description(
    "Customize menu maxWidth. Can be undefined or 'number'",
  ),
};

DSMenu.propTypes = props;

const DSMenuWithSchema = describe(DSMenu);

DSMenuWithSchema.propTypes = props;

// todo: add a handler to get this kind of exports for documentation
WrappedMenu.Item = MenuItem;
WrappedMenu.ItemCheckbox = MenuItemCheckbox;
WrappedMenu.ItemRadio = MenuItemRadio;
WrappedMenu.SubMenu = SubMenu;
WrappedMenu.Separator = Separator;
WrappedMenu.CheckboxGroup = CheckboxGroup;
WrappedMenu.RadioGroup = RadioGroup;
WrappedMenu.SearchableGroup = SearchableGroup;
WrappedMenu.SelectionGroup = SelectionGroup;

export { menuItemFactory, renderMenuItems, DSMenuWithSchema };
export default WrappedMenu;
