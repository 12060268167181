/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M11 15c0 .571-.429 1-1 1s-1-.429-1-1 .429-1 1-1 1 .429 1 1zM9.045 4.539L9.5 12h1l.455-7.461A.498.498 0 0 0 10.459 4h-.917c-.291 0-.521.249-.496.539zM10 1c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9zm0-1c5.5 0 10 4.5 10 10s-4.5 10-10 10S0 15.5 0 10 4.5 0 10 0z',
  ],
  height: 20,
  width: 20,
});
