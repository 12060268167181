import { get, set } from '@elliemae/ds-utilities/utils';
import { columnMeasurerTransformer } from '../columnMeasurerTransformer';

export function decorateColumn(column, grid, index) {
  const transformsPath = ['header', 'transforms'];
  const transforms = get(column, transformsPath, []);
  transforms.push(columnMeasurerTransformer(grid.columnSizeService, grid));
  column.index = index;
  set(column, transformsPath, transforms);

  return column;
}
