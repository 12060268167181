/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useRef } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMove, omit } from '@elliemae/ds-utilities/utils';
import { mergeRefs } from '@elliemae/ds-utilities/system';
import createInstancePlugin from '@elliemae/ds-shared/createDataInstance/createInstancePlugin';
import { addDragAndDropToColumn } from './decorateGridWithDndColumns';

// todo: remove once it's fixed in react-sortable-hoc library
const keysToOmit = [
  'axis',
  'contentWindow',
  'disableAutoscroll',
  'distance',
  'getContainer',
  'getHelperDimensions',
  'helperClass',
  'helperContainer',
  'hideSortableGhost',
  'keyboardSortingTransitionDuration',
  'lockAxis',
  'lockOffset',
  'lockToContainerEdges',
  'onSortEnd',
  'onSortMove',
  'onSortOver',
  'onSortStart',
  'pressDelay',
  'pressThreshold',
  'shouldCancelStart',
  'transitionDuration',
  'updateBeforeSortStart',
  'useDragHandle',
  'useWindowAsScrollContainer',
];

const decorateColumn = (column, grid) =>
  column.isUserColumn ? addDragAndDropToColumn(grid, column) : column;

const getHeaderRowProps = (props, grid) => {
  const { onMoveColumnStart, onMoveColumnEnd, onMoveColumnOver } = grid.props;

  const headerRowRef = useRef();
  const columnDraggingOffset = useRef(0);
  const sortedColumns = useRef(grid.getInstance().decoratedColumns);
  const nodeRef = useRef();

  useEffect(() => {
    sortedColumns.current = grid.getInstance().decoratedColumns;
  });

  const onSortOver = params => {
    const { index, newIndex, oldIndex } = params;
    const oldColumn = sortedColumns.current[oldIndex];
    const newColumn = sortedColumns.current[newIndex];

    if (!newColumn.isUserColumn || !oldColumn.isUserColumn) return;

    const columnWidth = nodeRef.current.offsetWidth;
    const newColumnWidth = headerRowRef.current.childNodes.item(newColumn.index)
      .offsetWidth;

    const forward = newIndex > oldIndex;

    // eslint-disable-next-line no-nested-ternary
    const columnTranslate = forward
      ? newIndex > index
        ? columnWidth * -1
        : 0
      : newIndex < index
      ? columnWidth
      : 0;

    columnDraggingOffset.current += forward
      ? newColumnWidth
      : newColumnWidth * -1;

    newColumn.updateStyle({
      transitionDuration: '300ms',
      transform: `translate3d(${columnTranslate}px, 0, 0)`,
    });
    oldColumn.updateStyle({
      transitionDuration: '300ms',
      transform: `translate3d(${columnDraggingOffset.current}px, 0, 0)`,
    });

    sortedColumns.current = arrayMove(
      sortedColumns.current,
      oldIndex,
      newIndex,
    );
    onMoveColumnOver(params);
  };

  const onSortStart = params => {
    const { node } = params;
    grid.disableEvents();
    nodeRef.current = node;
    onMoveColumnStart(params);
  };

  const onSortEnd = params => {
    const { oldIndex, newIndex } = params;
    grid.enableEvents();
    columnDraggingOffset.current = 0;
    sortedColumns.current = grid.decoratedColumns;
    grid.decoratedColumns.forEach(column => {
      if (!column.updateStyle) return;
      column.updateStyle({
        transitionDuration: null,
        transform: null,
      });
    });

    const originalOldColumnIndex = grid.columns.findIndex(
      column => column.property === grid.decoratedColumns[oldIndex].property,
    );
    const originalNewColumnIndex = grid.columns.findIndex(
      column => column.property === grid.decoratedColumns[newIndex].property,
    );

    onMoveColumnEnd({
      newIndex: originalNewColumnIndex,
      oldIndex: originalOldColumnIndex,
    });
  };

  return {
    axis: 'x',
    helperClass: 'header-dnd-column',
    innerRef: mergeRefs(props.innerRef, headerRowRef),
    lockAxis: 'x',
    onSortEnd,
    onSortOver,
    onSortStart,
    useDragHandle: true,
  };
};

const decorateRenderers = renderers => {
  const RowComponent = renderers.header.row;
  const CellComponent = renderers.header.cell;

  const Row = useMemo(
    () =>
      SortableContainer(props => <RowComponent {...omit(props, keysToOmit)} />),
    [],
  );

  const Cell = useMemo(() => SortableElement(CellComponent), []);

  return {
    ...renderers,
    header: {
      ...renderers.header,
      row: Row,
      cell: Cell,
    },
  };
};

export const DndColumnsPlugin = createInstancePlugin('dnd-columns', {
  getHeaderRowProps,
  decorateColumn,
  decorateRenderers,
});
