import createNumberMask from './addons/NumberMask';
import createPercentMask from './addons/PercentMask';

export const MASK_TYPES = {
  DATE: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  DATE_TIME: [
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /[0-2]/,
    /[0-9]/,
    ':',
    /[0-5]/,
    /[0-9]/,
  ],
  PHONE: [
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  PHONE_INTENATIONAL: [
    '+',
    '1',
    ' ',
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  SSN: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  US_ZIP_CODE: [/\d/, /\d/, /\d/, /\d/, /\d/],
  NUMBER: createNumberMask,
  PERCENT: createPercentMask,
};

export const maskTypes = [
  MASK_TYPES.DATE,
  MASK_TYPES.DATE_TIME,
  MASK_TYPES.PHONE,
  MASK_TYPES.PHONE_INTENATIONAL,
  MASK_TYPES.SSN,
  MASK_TYPES.US_ZIP_CODE,
  MASK_TYPES.NUMBER,
  MASK_TYPES.PERCENT,
];
