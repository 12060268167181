import { useRef, useState } from 'react';
import { isEqual, isFunction } from '../utils';

const defaultPropUpdateOnChange = prop => prop;

function defaultShouldUpdate({ prop, prevProp, stateValue }) {
  return !isEqual(prop, prevProp) && !isEqual(prop, stateValue);
}

function setStateWithUpdate(onUpdate, setState) {
  return state => {
    setState(prevState =>
      onUpdate(isFunction(state) ? state(prevState) : state, prevState),
    );
  };
}

export default function useDerivedStateFromProp(prop, options = {}) {
  const {
    onUpdate = defaultPropUpdateOnChange,
    shouldUpdate = defaultShouldUpdate,
    updateOnStateChange = false,
  } = options;

  const [stateValue, setState] = useState(onUpdate(prop, {}));
  const handleSetState = updateOnStateChange
    ? setStateWithUpdate(onUpdate, setState)
    : setState;
  const prevProp = useRef(prop);

  if (
    shouldUpdate({
      prop,
      prevProp: prevProp.current,
      stateValue,
    })
  ) {
    const nextProp = onUpdate(prop, prevProp.current);
    setState(nextProp);
  }
  prevProp.current = prop;
  return [stateValue, handleSetState];
}
