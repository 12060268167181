/* eslint-disable max-lines */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment,max-lines,react/display-name, react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { debounce, throttle } from 'lodash';
import Select from 'react-select';
import { Manager } from 'react-popper';
import { withContentRect } from 'react-measure';
import { cx } from '@elliemae/ds-utilities/utils';
import withSelectStringValueConverter from './withSelectStringValueConverter';
import { COMBOBOX_VARIANT, comboBoxVariants } from '../../../utils/prop-types';
import Control from './components/Control';
import DropdownIndicator from './components/DropdownIndicator';
import SelectMenu from './components/SelectMenu';
import ClearIndicator from './components/ClearIndicator';
import MenuList from './components/MenuList';
import MultiValueLabel from './components/MultiValueLabel';
import MultiValueRemove from './components/MultiValueRemove';
import SingleValueLabel from './components/SingleValueLabel';
import { calculateWidth } from './components/calculateWidth';
import { ValueContainer } from './components/ValueContainer';

// todo: use convertClassPropsTo....
const ThemeConstants = {
  prefix: 'em-ds',
};

const blockName = 'selectbox';
const allOption = { value: 'SELECT_ALL_COMBOBOX', label: 'All' };

const getValues = (option, isMulti, valueProperty) => {
  if (!option) return null;
  return isMulti
    ? option.map(mOption => mOption[valueProperty])
    : option[valueProperty];
};

class DSComboBox extends React.Component {
  forceFocus = false;

  isHover = undefined;

  handleHover = debounce(value => {
    if (this.state.isHover === this.isHover) return;
    if (!this.unmount) this.setState({ isHover: value });
  }, 0);

  static defaultProps = {
    showAllOption: false,
  };

  constructor(props) {
    super(props);
    this.clear = React.createRef();
    this.arrow = React.createRef();
    this.select = React.createRef();
  }

  state = {
    focus: null,
    isHover: undefined,
  };

  componentDidMount() {
    const { handleHoverRef } = this.props;
    if (handleHoverRef) handleHoverRef(this.handleHoverRef);
    window.addEventListener(
      'scroll',
      throttle(this.handleOutsideScroll, 300),
      true,
    );
  }

  componentWillUnmount() {
    this.unmount = true;
    window.removeEventListener(
      'scroll',
      throttle(this.handleOutsideScroll, 300),
      true,
    );
  }

  handleOutsideScroll = () => {
    if (this.isHover !== true && !this.unmount) {
      this.isHover = false;
      this.handleHover(false);
    }
  };

  handleHoverRef = value => {
    this.isHover = value;
    this.handleHover(value);
  };

  // onKeyDownIndicator = (e) => {
  //   if (e.key === 'ArrowLeft') {
  //     if (this.clear && this.clear.focus) this.clear.focus()
  //     else this.select.focus()
  //   }
  // }
  onKeyDownClear = e => {
    // if (e.key === 'ArrowRight') {
    //   this.arrow.focus()
    // } else if (e.key === 'ArrowLeft') {
    //   this.select.focus()
    // }
    if (e.keyCode === 32 || e.keyCode === 13) {
      this.select.focus();
    }
  };

  handleKeyDown = e => {
    // if (e.key === 'ArrowRight') e.preventDefault()
    // if (e.target.type === 'text' && e.key === 'ArrowRight') {
    //   if (this.clear && this.clear.focus) {
    //     this.clear.focus()
    //   } else {
    //     this.arrow.focus()
    //   }
    // }
    if (e.target.type === 'text' && e.key === 'Backspace') {
      this.select.focus();
    }
    return () => {
      const { value } = e.target;
      if (!value && e.key === ' ') e.preventDefault();
      this.props.onKeyDown(e);
    };
  };

  render() {
    const {
      hideSelectedOptions = false,
      autoFocus = false,
      className = '',
      hasError = false,
      onFocus = () => null,
      onBlur = () => null,
      onChange = () => null,
      // onKeyDown = () => null,
      filterOption = undefined,
      onClickDropdownIndicator = () => null,
      onInputKeyDown = () => null,
      onInputChange = undefined,
      isRtl = false,
      isFocused = undefined,
      isMulti = false,
      showAllOption = false,
      value = null,
      options = [],
      clearable = false,
      searchable = true,
      disabled = false,
      placeholder = '',
      valueProperty = 'value',
      labelProperty = 'label',
      menuIsOpen = undefined,
      inlineMenu = false,
      noOptionsMessage = () => <span>No options</span>,
      components: customComponents = {},
      measureRef,
      contentRect,
      readOnly = false,
      expandMenuToContainer = true,
      expandMenuOutsideContainer = false,
      customMenuItemOptions = {
        useTruncatedText: false,
        itemSize: 35,
      },
      returnValue = true,
      variant = COMBOBOX_VARIANT.DEFAULT,
      containerProps = {},
      zIndex = 11,
      ...restPropsToCustomizeSelect
    } = this.props;
    const { focus } = this.state;
    let internalOptions = options;
    let internalValue = value;
    if (showAllOption && isMulti) {
      internalOptions = [allOption, ...options];
    }
    if (this.allSelected && isMulti) {
      internalValue = [allOption, ...value];
    }
    return (
      <Manager>
        <div
          ref={measureRef}
          className={cx(
            `${ThemeConstants.prefix}-${blockName}-wrapper`,
            className,
            hasError &&
              `${ThemeConstants.prefix}-${blockName}--${COMBOBOX_VARIANT.ERROR}`,
            `${ThemeConstants.prefix}-${blockName}--${variant}`,
            `focus-${focus}`,
            isMulti && 'combo-multi',
            expandMenuOutsideContainer && 'expanded-outside-container',
          )}
          {...containerProps}
          onMouseEnter={() => {
            this.handleHoverRef(undefined);
          }}
        >
          <Select
            ref={ref => {
              this.select = ref;
            }}
            autoFocus={autoFocus}
            classNamePrefix={`${ThemeConstants.prefix}-${blockName}`}
            components={{
              Control,
              Menu:
                !options || options.length === 0
                  ? SelectMenu
                  : props => (
                      <div
                        className={`select-menu-combo ${isMulti &&
                          'select-menu-combo-multi'}`}
                        onMouseLeave={() => {
                          this.isHover = undefined;
                          this.handleHover(undefined);
                        }}
                        onMouseOverCapture={() => {
                          this.isHover = true;
                          this.handleHover(true);
                        }}
                      >
                        <SelectMenu {...props} />
                      </div>
                    ),
              // Menu: SelectMenu,
              DropdownIndicator: () => (
                <DropdownIndicator
                  innerRef={ref => {
                    this.arrow = ref;
                  }}
                  onClick={onClickDropdownIndicator}
                  onKeyDown={this.onKeyDownIndicator}
                />
              ),
              ClearIndicator: props => (
                <ClearIndicator
                  {...props}
                  innerRef={ref => {
                    this.clear = ref;
                  }}
                  onKeyDown={this.onKeyDownClear}
                />
              ),
              MultiValueLabel,
              MultiValueRemove,
              SingleValue: SingleValueLabel,
              MenuList,
              ValueContainer,
              ...customComponents,
            }}
            customMenuItemOptions={customMenuItemOptions}
            expandMenuOutsideContainer={expandMenuOutsideContainer}
            expandMenuToContainer={expandMenuToContainer}
            filterOption={filterOption}
            getOptionLabel={option => option[labelProperty]}
            getOptionValue={option => option[valueProperty]}
            hideSelectedOptions={hideSelectedOptions}
            inlineMenu={inlineMenu}
            isClearable={clearable}
            isDisabled={disabled || readOnly}
            isFocused={isFocused}
            isMulti={isMulti}
            isRtl={isRtl}
            isSearchable={readOnly ? false : searchable}
            menuIsOpen={menuIsOpen === undefined ? this.isHover : menuIsOpen}
            noOptionsMessage={(...args) => {
              noOptionsMessage(...args);
            }}
            onBlur={onBlur}
            onChange={
              returnValue
                ? (option, action) => {
                    this.allSelected = false;
                    this.isHover = undefined;
                    this.setState({ isHover: undefined });
                    if (
                      showAllOption &&
                      isMulti &&
                      option.find(op => op.value === 'SELECT_ALL_COMBOBOX')
                    ) {
                      this.allSelected = true;
                      return onChange(
                        getValues(options, isMulti, valueProperty),
                        action,
                      );
                    }
                    return onChange(
                      getValues(option, isMulti, valueProperty),
                      action,
                    );
                  }
                : (option, action) => {
                    this.allSelected = false;
                    this.isHover = undefined;
                    this.setState({ isHover: undefined });
                    if (
                      showAllOption &&
                      isMulti &&
                      option.find(op => op.value === 'SELECT_ALL_COMBOBOX')
                    ) {
                      this.allSelected = true;
                      return onChange(options, action);
                    }
                    return onChange(option, action);
                  }
            }
            onFocus={onFocus}
            onInputChange={onInputChange}
            onInputKeyDown={onInputKeyDown}
            onKeyDown={this.handleKeyDown}
            options={internalOptions}
            placeholder={placeholder}
            selectMeasure={contentRect}
            styles={{
              control: () => null,
              menu: () => {
                if (expandMenuOutsideContainer) {
                  const width = calculateWidth(options);
                  if (!width) return null;
                  return {
                    width: `${width}px`,
                  };
                }
                return null;
              },
              option: () => null,
              dropdownIndicator: () => null,
              clearIndicator: () => null,
              placeholder: () => null,
              indicatorSeparator: () => null,
              multiValue: base => ({
                ...base,
                background: 'none',
                minWidth: '3rem',
              }),
            }}
            value={internalValue}
            zIndex={zIndex}
            {...restPropsToCustomizeSelect}
          />
        </div>
      </Manager>
    );
  }
}

DSComboBox.propTypes = {
  containerProps: PropTypes.shape({}),
  handleHoverRef: PropTypes.func,
  /**
   * Whether the combo box uses auto focus or not
   */
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Whether the combo box has error or not
   */
  hasError: PropTypes.bool,
  /**
   * Allows a function that is triggered once the combo box is focused
   */
  onFocus: PropTypes.func,
  /**
   * Allows a function that is triggered once the combo box loses focus
   */
  onBlur: PropTypes.func,
  /**
   * Allows a function that is triggered once the combo box changes
   */
  onChange: PropTypes.func,
  /**
   * Allows a function that is triggered once a key is being pressed
   */
  onKeyDown: PropTypes.func,
  /**
   * Allows a function that is triggered once the combo box input key down
   */
  onInputKeyDown: PropTypes.func,
  /**
   * Allows a function that is triggered once the combo box input changes
   */
  onInputChange: PropTypes.func,
  /**
   * Whether the combo box is rtl or not  //ASK
   */
  isRtl: PropTypes.bool,
  /**
   * Whether the combo box is focused or not
   */
  isFocused: PropTypes.bool,
  /**
   * Whether the combo box is multi or not
   */
  isMulti: PropTypes.bool,
  inputValue: PropTypes.string,
  showAllOption: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  /**
   * Value that the combo box has as default
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  /**
   * Value that the combo box has as default
   */
  options: PropTypes.array,
  /**
   * Whether the combo box is clearable or not
   */
  clearable: PropTypes.bool,
  /**
   * Whether the combo box is searchable or not. Set to false makes the input non-editable
   */
  searchable: PropTypes.bool,
  /**
   * Whether the combo box is disabled or not
   */
  disabled: PropTypes.bool,
  /**
   * Placeholder for the combo box
   */
  placeholder: PropTypes.string,
  /**
   * Displays a Loading Indicator in the menu
   */
  loading: PropTypes.bool,
  valueProperty: PropTypes.string,
  labelProperty: PropTypes.string,
  /**
   * Whether the combo box menu is open or not
   */
  menuIsOpen: PropTypes.bool,
  /**
   * Whether to show the combo box menu inline or not
   */
  inlineMenu: PropTypes.bool,
  /**
   * Message to show once there aren't any options
   */
  noOptionsMessage: PropTypes.func,
  /**
   * Components you can add to the combo box
   */
  measureRef: PropTypes.func,
  /**
   * Whether the combo box is read only or not
   */
  readOnly: PropTypes.bool,
  /**
   * Whether the combo box can be expanded the menu to container or not
   */
  expandMenuToContainer: PropTypes.bool,
  /**
   * Allow have options larger than his container
   */
  expandMenuOutsideContainer: PropTypes.bool,
  /**
   * Custom combo box menu item options
   */
  customMenuItemOptions: PropTypes.object,
  /**
   * Whether the combo box is has value to return or not
   */
  returnValue: PropTypes.bool,
  /**
    [
      'variant-default',
      'variant-focus-input',
      'variant-focus-icon',
      'variant-active-input',
      'variant-active-icon',
      'variant-disabled',
      'variant-error',
    ]
   */
  variant: PropTypes.oneOf(comboBoxVariants),
  hideSelectedOptions: PropTypes.bool,
  maxOptions: PropTypes.number,
  /**
   * Custom method to filter whether an option should be displayed in the menu
   */
  filterOption: PropTypes.func,
  onClickDropdownIndicator: PropTypes.func,
  /**
   * Object with custom components for react-select
   */
  components: PropTypes.object,
  contentRect: PropTypes.object,
  zIndex: PropTypes.number,
};

export { components } from 'react-select';
export { COMBOBOX_VARIANT };
export default withSelectStringValueConverter(
  withContentRect('bounds')(DSComboBox),
);
