/* eslint-disable react/prop-types */
import React from 'react';
import { convertPropToCssClassName } from '@elliemae/ds-classnames';

function SvgIcon({
  containerProps = {},
  size,
  color,
  className = '',
  width = 16,
  height = 16,
  style = {},
  fill = '#000',
  innerRef,
  ...rest
}) {
  const { cssClassName } = convertPropToCssClassName('icon', className, {
    size,
    color,
  });
  return (
    <span
      ref={innerRef}
      className={cssClassName}
      {...rest}
      {...containerProps}
      style={{
        width,
        height,
        ...style,
      }}
    >
      <svg
        width={String(width)}
        height={String(height)}
        style={{
          width,
          height,
        }}
        fill={fill}
        viewBox="0 0 16 16"
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g fill={fill} fillRule="nonzero">
            <path
              d="M0,8c0,0.1,0,0.2,0.1,0.3l5.6,7.5c0.2,0.2,0.5,0.3,0.7,0.1c0.2-0.2,0.3-0.5,0.1-0.7L1.1,8l5.4-7.2
	c0.2-0.2,0.1-0.5-0.1-0.7C6.2-0.1,5.9,0,5.7,0.2L0.1,7.7C0,7.8,0,7.9,0,8L0,8z M9,8c0,0.1,0,0.2,0.1,0.3l5.6,7.5
	c0.2,0.2,0.5,0.3,0.7,0.1c0.2-0.2,0.3-0.5,0.1-0.7L10.1,8l5.4-7.2c0.2-0.2,0.1-0.5-0.1-0.7c-0.2-0.2-0.5-0.1-0.7,0.1L9.1,7.7
	C9,7.8,9,7.9,9,8L9,8z"
            ></path>
          </g>
        </g>
      </svg>
    </span>
  );
}

export default SvgIcon;
