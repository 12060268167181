/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable max-statements */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { omit } from '@elliemae/ds-utilities/utils';
import { ToolbarTrigger } from './ToolbarTrigger';
import { RowSizes } from '../../rowSizes';

export const RowRenderer = (Row, grid) => {
  const Wrapped = (props) => {
    const ref = useRef();
    const { rowData = {}, index, rowIndex, style } = props;
    const {
      props: { wrapText },
    } = grid.getInstance();
    const [popStyle, setPopStyle] = useState({});
    const [rowStyle, setRowStyle] = useState(style);
    const gridInstance = grid.getInstance();
    const { refs, isRowDragging } = gridInstance;
    const [hasVerticalScrollBar, setHasVerticalScrollBar] = useState(
      refs.body.current &&
        refs.body.current.offsetWidth > refs.body.current.clientWidth,
    );

    useEffect(() => {
      const shouldOmit = isRowDragging ? ['top'] : [];
      setPopStyle({
        ...popStyle,
        ...{ top: style && !isRowDragging ? style.top : '0' },
      });
      if (style) {
        // style defined means it has virtualization
        setHasVerticalScrollBar(true);
      } else {
        setHasVerticalScrollBar(false);
      }
      setRowStyle(
        omit(
          style || {
            height: !wrapText ? RowSizes[gridInstance.props.rowSize] : 'auto',
            position: 'relative',
          },
          [shouldOmit],
        ),
      );
    }, [style, isRowDragging]);
    return (
      <div
        className="row-renderer-fixed-height"
        style={{
          height: !wrapText ? RowSizes[gridInstance.props.rowSize] : 'auto',
          position: style ? 'auto' : 'relative',
          // top: isDragging ? rowStyle.top - RowSizes[gridInstance.props.rowSize] : 'auto',
        }}
      >
        <Row ref={ref} {...props} style={rowStyle} />
        {isEmpty(rowData) && !rowData.index && !rowData.rowIndex ? null : (
          <div
            className={`toolbar-trigger-container ${style ? 'move' : 'stay'}`}
            style={{ ...popStyle }}
          >
            <ToolbarTrigger
              grid={grid}
              rowData={{ rowData, index, rowIndex }}
              hasScrollBar={hasVerticalScrollBar}
              rowRef={ref}
            />
          </div>
        )}
      </div>
    );
  };

  Wrapped.propTypes = {
    rowData: PropTypes.any,
    index: PropTypes.number,
    rowIndex: PropTypes.number,
    style: PropTypes.any,
  };

  return Wrapped;
};
