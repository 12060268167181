import React from 'react';
import { describe, PropTypes } from 'react-desc';
import CheckableGroup from '@elliemae/ds-shared/CheckableGroup';

function RadioGroup(props) {
  return <CheckableGroup {...props} multi={false} />;
}

const props = {
  /* The item selected */
  active: PropTypes.arrayOf(PropTypes.string).description('the item selected'),
  /* Handler when a user checks an item */
  onCheck: PropTypes.func.description('Handler when a user checks an item'),
};

RadioGroup.propTypes = props;

const DSMenuRadioGroupWithSchema = describe(RadioGroup);

DSMenuRadioGroupWithSchema.propTypes = props;

export default RadioGroup;

export { DSMenuRadioGroupWithSchema };
