/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';
import { get } from 'lodash';
import ChevronSmallDown from '@elliemae/ds-icons/ChevronSmallDown';
import DSButton, { BUTTON_VARIANT } from '../../../../Button';
import { DropDownContext } from '../context';

const DropdownIndicator = ({
  innerRef,
  onKeyDown,
  options,
  theme,
  clearValue,
  getStyles,
  getValue,
  setValue,
  hasStyle,
  hasValue,
  isMulti,
  isRtl,
  isDisabled,
  isFocused,
  selectOption,
  selectProps,
  innerProps,
  cx,
  ...rest
}) => {
  const {
    changeHandleOpening,
    changeMenuOpen,
    inputInFocus,
    handleOpening,
    menuOpen,
    onClickDropdownIndicator,
  } = useContext(DropDownContext);

  const handleOnClick = (e) => {
    onClickDropdownIndicator(e);
    changeMenuOpen(!menuOpen);
    changeHandleOpening(!handleOpening);
  };

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      changeMenuOpen(!menuOpen);
      e.preventDefault();
    }
  };

  return (
    <DSButton
      aria-label="dropdown-indicator"
      buttonType="text"
      className={`dropdown-indicator ${
        get(selectProps, 'menuIsOpen', false) && !inputInFocus ? 'active' : ''
      }`}
      icon={<ChevronSmallDown />}
      innerRef={innerRef}
      onClick={handleOnClick}
      onKeyDown={handleOnKeyDown}
      tabIndex={-1}
      variant={
        get(selectProps, 'menuIsOpen', false) && !inputInFocus
          ? BUTTON_VARIANT.ACTIVE
          : BUTTON_VARIANT.DEFAULT
      }
      {...rest}
    />
  );
};

export default DropdownIndicator;
