/* eslint-disable no-restricted-syntax */
/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable max-params */
const GROUP_PADDING = 16;
const GROUP_TITLE = 13.44;
const SEPARATOR = 17;

function isGroup(opts) {
  return opts.length && opts[0].type.name === 'Group';
}

function countOptions(opts) {
  let count;
  if (isGroup(opts)) {
    count = opts.reduce(
      (o1, o2) => o1.props.options.length + o2.props.options.length,
    );
  } else {
    count = opts.length;
  }
  return count;
}

export function useMenuListHeight(
  elements,
  maxElements,
  itemSize,
  remainingHeight,
) {
  const amountOfOptions = countOptions(elements);
  const safeOptionLength =
    typeof amountOfOptions === 'number' ? amountOfOptions : 1;
  const groupLength = isGroup(elements) ? elements.length : 0;
  const elementSlotsToShow =
    safeOptionLength < maxElements || !maxElements
      ? safeOptionLength
      : maxElements;

  let calculatedHeight = itemSize * elementSlotsToShow;
  if (elementSlotsToShow === 1) {
    if (elements[0]?.props?.data?.hasSeparator) calculatedHeight += 40;
    else calculatedHeight += 25;
  }

  // add pixels for group type (title and padding)
  if (isGroup(elements) && groupLength > 1) {
    let groupElementsShowed = 0;
    for (const group of elements) {
      if (groupElementsShowed >= elementSlotsToShow) {
        break;
      }
      const currentOptions = group;
      groupElementsShowed += currentOptions?.props?.options?.length || 0;
      calculatedHeight += GROUP_PADDING + GROUP_TITLE;
      if (currentOptions?.props?.data?.hasSeparator) {
        calculatedHeight += SEPARATOR;
      }
    }
  }

  let height;

  if (maxElements) {
    height = calculatedHeight;
  } else if (remainingHeight > calculatedHeight) {
    height = calculatedHeight;
  } else {
    height = remainingHeight;
  }
  return height;
}
