import React from 'react';
import styled from 'styled-components';
import { DSCircularProgressIndicator } from '../../../../CircularProgressIndicator';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
`;

const LoadingIndicator = () => (
  <Wrapper data-testid="ds-combobox_loading-indicator">
    <DSCircularProgressIndicator size="m" />
  </Wrapper>
);

export default LoadingIndicator;
