/* eslint-disable complexity */
/* eslint-disable spaced-comment */
/* eslint-disable no-else-return */
/* eslint-disable max-statements */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-cycle, max-lines */
import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { PropTypes, describe } from 'react-desc';
import Select, { Creatable, createFilter } from 'react-select';
import { Manager } from 'react-popper';
import { withContentRect } from 'react-measure';
import { cx, isEqual } from '@elliemae/ds-utilities/utils';
import theme from '@elliemae/ds-system/theme';
import usePrevious from '@elliemae/ds-utilities/hooks/usePrevious';
import useShouldRecalculate from '@elliemae/ds-utilities/hooks/useShouldRecalculate';
import { noop } from 'lodash';

import { TooltipTextProvider } from '../../../TruncatedTooltipText';
import withSelectStringValueConverter from '../v1/withSelectStringValueConverter';
import DropdownIndicator from './components/DropdownIndicator';
import GroupHeading from './components/GroupHeading';
import SelectMenu from './components/SelectMenu';
import Control from './components/Control';
import ClearIndicator from './components/ClearIndicator';
import MenuList from './components/MenuList';
import MultiValueLabel from './components/MultiValueLabel';
import MultiValueRemove from './components/MultiValueRemove';
import SingleValueLabel from './components/SingleValueLabel';
import LoadingIndicator from './components/LoadingIndicator';
import { calculateWidth } from './components/calculateWidth';
import { ValueContainer } from './components/ValueContainer';
import { DropDownContext } from './context';

const blockName = 'em-ds-combobox';
const container = `${blockName}-container`;
const getValues = (options, isMulti, valueProperty) => {
  if (!options) return null;
  return isMulti
    ? options.map((mOption) => mOption[valueProperty])
    : options[valueProperty];
};
const createOption = (label, value) => ({ label, value });

const DSComboBox2 = ({
  hideSelectedOptions = false,
  autoFocus = false,
  className = '',
  hasError = false,
  onFocus = noop,
  onBlur = noop,
  onChange = noop,
  onChangeV2 = noop,
  // onKeyDown = () => null,
  filterOption = createFilter({ ignoreAccents: false }), // this fix performance issue
  onClickDropdownIndicator = noop,
  onInputKeyDown = noop,
  onInputChange = noop,
  isRtl = false,
  isFocused = undefined,
  isMulti = false,
  isFreeSolo,
  selectAllDisabled = false,
  formatCreateLabel,
  createOptionPosition,
  value: selectedValue = null,
  options = [],
  clearable = false,
  searchable = true,
  disabled = false,
  placeholder = '',
  valueProperty = 'value',
  labelProperty = 'label',
  noOptionsMessage = 'No matches found',
  loading = false,
  menuIsOpen = undefined,
  inlineMenu = false,
  components: customComponents = {},
  measureRef,
  contentRect,
  readOnly = false,
  returnValue = true,
  expandMenuToContainer = true,
  expandMenuOutsideContainer = false,
  customMenuItemOptions = {
    useTruncatedText: false,
    itemSize: 35,
  },
  containerProps = {},
  maxOptions,
  zIndex = 11,
  componentsStyle,
  keepTypedValue = true, //TEMPORARY PROP UNTIL WE HAVE THIS FUNCTIONALITY IN SINGLE FREE SOLO
  ...restPropsToCustomizeSelect
}) => {
  const select = useRef();
  const [selectAll, setSelectAll] = useState(false);
  const [inputInFocus, setInputInFocus] = useState(false);
  const [value, setValue] = useState(selectedValue);
  const [inputValue, setInputValue] = useState('');
  const [handleOpening, setHandleOpening] = useState(false);
  const [menuOpen, setMenuOpen] = useState(menuIsOpen);
  const [isMultipleKeyPressed, setIsMultipleKeyPressed] = useState(false);
  const prevOptions = usePrevious(options);
  const optionsChanged = useShouldRecalculate(isEqual(prevOptions, options));
  const SelectComponent = isFreeSolo ? Creatable : Select;

  useEffect(() => {
    if (selectedValue && !isFreeSolo && !Array.isArray(selectedValue)) {
      const newValue = options.find(
        (option) => option.value === selectedValue.value,
      );
      setValue(newValue);
    } else setValue(selectedValue);
  }, [selectedValue, options, optionsChanged]);

  const handleInputChange = useCallback((val, action) => {
    setInputValue(val);
    if (action.action === 'input-change' && !isMulti) {
      setMenuOpen(true);
      if (!inlineMenu && keepTypedValue) {
        onChange({ value: val, label: val }, action);
      }
    }
    if (val || typeof val === 'string') onInputChange(val, action);
  }, []);

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 32) {
      setMenuOpen(true);
    }
    if (e.key === 'Escape') setMenuOpen(false);
    if (e.key === 'Backspace' && !e.target.value && value && clearable) {
      onChange('', { action: 'input-clear' }); // this clears the input
    }
    if (e.key === 'Control') setIsMultipleKeyPressed(true);
    // If Ctrl + Enter are pressed, focus is set to the input and the menu closes
    if (isMultipleKeyPressed && e.key === 'Enter') {
      setInputInFocus(true);
      setMenuOpen(false);
      setIsMultipleKeyPressed(false);
      e.preventDefault();
    }
  };

  const handleOnChange = (newValue, action) => {
    onChangeV2(newValue, action);
    setMenuOpen(isMulti);
    if (returnValue) {
      if (isFreeSolo) return onChange(newValue, action);
      return onChange(getValues(newValue, isMulti, valueProperty), action);
    }
    if (isMulti && selectAll) return onChange(options, action);
    return onChange(newValue, action);
  };

  const onSelectAll = useCallback(
    (isSelected) => {
      setSelectAll(isSelected);
      if (isSelected) {
        const createdValues = Array.isArray(value) ? value : [];
        const allOptions = [...createdValues, ...options];
        const optionsValues = [...new Set(allOptions.map((opt) => opt.value))];
        const optionsSet = optionsValues.map((val) =>
          allOptions.find((option) => option.value === val),
        );
        onChange(optionsSet.map((o) => o.value));
        onChangeV2(optionsSet);
      } else {
        onChange(getValues());
        onChangeV2([]);
      }
    },
    [value, getValues],
  );

  const handleCreateNewOption = (inputVal) => {
    const newOption = createOption(inputVal, inputVal);
    const prevValue = Array.isArray(value) ? value : [];
    const newValue = isMulti ? [...prevValue, newOption] : newOption;
    setMenuOpen(isMulti);
    onChange(newValue);
    onChangeV2(newValue);
  };

  const handleFocus = useCallback((e) => {
    setInputInFocus(true);
    setMenuOpen(undefined); // allows prop openOnMenuClick to work correctly
    onFocus(e);
    setHandleOpening(true);
  }, []);

  const handleBlur = (e) => {
    setInputInFocus(false);
    if (!inlineMenu) setMenuOpen(false);
    onBlur(e, value);
    setHandleOpening(false);
  };

  const ctx = useMemo(
    // contexts values must be memoized to avoid heavy performance hits
    () => ({
      inputInFocus,
      value,
      isMulti,
      menuOpen,
      handleOpening,
      selectAll,
      selectAllDisabled,
      changeHandleOpening: (isOpen) => setHandleOpening(isOpen),
      changeMenuOpen: (isOpen) => setMenuOpen(isOpen),
      onClickDropdownIndicator,
      onSelectAll: (val) => onSelectAll(val),
      clearable,
      removeDropdownIndicator: customComponents.DropdownIndicator === null,
      select,
    }),
    [
      inputInFocus,
      value,
      isMulti,
      menuOpen,
      handleOpening,
      selectAll,
      selectAllDisabled,
      clearable,
      customComponents.DropdownIndicator,
      select,
    ],
  );

  return (
    <TooltipTextProvider>
      <DropDownContext.Provider value={ctx}>
        <Manager>
          <div
            ref={measureRef}
            className={cx(
              `${container}`,
              inputInFocus && 'in-focus',
              hasError && 'with-error',
              disabled && 'is-disabled',
              className,
            )}
            onBlur={handleBlur}
            data-testid="combobox"
            {...containerProps}
          >
            <SelectComponent
              ref={select}
              autoFocus={autoFocus}
              classNamePrefix={blockName}
              // If it is multiselect let the menu open on select.
              closeMenuOnSelect={!isMulti}
              components={{
                Control,
                // IndicatorSeparator,
                Menu: SelectMenu,
                DropdownIndicator,
                ClearIndicator: isFreeSolo ? null : ClearIndicator,
                GroupHeading,
                MultiValueLabel,
                MultiValueRemove,
                SingleValue: SingleValueLabel,
                MenuList,
                ValueContainer,
                ...customComponents,
              }}
              customMenuItemOptions={{ maxOptions, ...customMenuItemOptions }}
              expandMenuOutsideContainer={expandMenuOutsideContainer}
              expandMenuToContainer={expandMenuToContainer}
              filterOption={filterOption}
              getOptionLabel={(option) => option[labelProperty]}
              getOptionValue={(option) => option[valueProperty]}
              formatCreateLabel={formatCreateLabel}
              createOptionPosition={createOptionPosition}
              hideSelectedOptions={hideSelectedOptions}
              inlineMenu={inlineMenu}
              isClearable={clearable}
              isDisabled={disabled || readOnly}
              isFocused={isFocused}
              isMulti={isMulti}
              isRtl={isRtl}
              isSearchable={readOnly ? false : searchable}
              menuIsOpen={menuIsOpen === true ? menuIsOpen : menuOpen}
              openMenuOnClick
              noOptionsMessage={() =>
                loading ? <LoadingIndicator /> : noOptionsMessage
              }
              onChange={handleOnChange}
              onCreateOption={handleCreateNewOption}
              onFocus={handleFocus}
              onInputChange={handleInputChange}
              onInputKeyDown={onInputKeyDown}
              onKeyDown={handleOnKeyDown}
              options={loading ? [] : options}
              placeholder={placeholder}
              selectMeasure={contentRect}
              styles={{
                control: () => null,
                option: () => null,
                dropdownIndicator: () => null,
                groupHeading: (provided) => ({ ...provided }),
                clearIndicator: () => null,
                placeholder: () => null,
                indicatorSeparator: () => null,
                singleValue: (provided) => ({ ...provided, maxWidth: '90%' }),
                multiValueLabel: (provided) => ({
                  ...provided,
                  backgroundColor: 'transparent',
                }),
                multiValue: (provided) => ({
                  ...provided,
                  backgroundColor: 'transparent',
                }),
                multiValueRemove: (provided) => ({
                  ...provided,
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: theme.colors.neutral[800],
                  },
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  flexWrap: 'nowrap',
                }),
                menu: () => {
                  if (expandMenuOutsideContainer) {
                    const width = calculateWidth(options);
                    if (!width) return null;
                    return { width: `${width}px` };
                  }
                  if (loading) return { height: '72px' };
                  return null;
                },
                ...componentsStyle,
              }}
              tabSelectsValue={false}
              value={value}
              inputValue={inputValue}
              zIndex={zIndex}
              {...restPropsToCustomizeSelect}
            />
          </div>
        </Manager>
      </DropDownContext.Provider>
    </TooltipTextProvider>
  );
};

const comboBoxProps = {
  containerProps: PropTypes.object.description(
    'Set of Properties attached to the main container',
  ),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).description('Selected value').isRequired,
  options: PropTypes.array.description('List of options'),
  onChange: PropTypes.func.description(
    'function triggered when an option is selected. use onChangeV2',
  ),
  onChangeV2: PropTypes.func.description(
    'function triggered when an option is selected it will send the options selected',
  ),
  autoFocus: PropTypes.bool
    .description('Whether the combo box uses auto focus or not')
    .defaultValue(false),
  className: PropTypes.string.description('html class attr'),
  hasError: PropTypes.bool
    .description('Whether the combo box has error or not')
    .defaultValue(false),
  onFocus: PropTypes.func.description(
    'function triggered once the combo box is focused',
  ),
  onBlur: PropTypes.func.description(
    'function triggered once the combo box loses focus',
  ),
  onKeyDown: PropTypes.func.description(
    'function triggered once a key is being pressed',
  ),
  onInputKeyDown: PropTypes.func.description(
    'function triggered once the combobox input key down',
  ),
  onInputChange: PropTypes.func.description(
    'function triggered once the combo box input changes',
  ),
  isRtl: PropTypes.bool
    .description('Whether the combo box is rtl or no')
    .defaultValue(false),
  isFocused: PropTypes.bool
    .description('Whether the combo box is focused or not')
    .defaultValue(false),
  selectAllDisabled: PropTypes.bool
    .description('Whether the select all/clear buttons are displayed or not')
    .defaultValue(false),
  isMulti: PropTypes.bool
    .description('Whether the combo box is multiselect or not')
    .defaultValue(false),
  clearable: PropTypes.bool
    .description('Whether the combo box is clearable or not')
    .defaultValue(false),
  searchable: PropTypes.bool
    .description(
      'Whether the combo box is searchable or not. Set to false makes the input non-editable',
    )
    .defaultValue(true),
  disabled: PropTypes.bool
    .description('Whether the combo box is disabled or not')
    .defaultValue(false),
  placeholder: PropTypes.string.description('input s placeholder value'),
  loading: PropTypes.bool
    .description('Displays a Loading Indicator in the menu')
    .defaultValue(false),
  valueProperty: PropTypes.string
    .description('property used as value')
    .defaultValue('value'),
  labelProperty: PropTypes.string
    .description('property used as label')
    .defaultValue('label'),
  menuIsOpen: PropTypes.bool.description(
    'Whether the combo box menu is open or not',
  ),
  inlineMenu: PropTypes.bool
    .description('Whether to show the combo box menu inline or not')
    .defaultValue(false),
  noOptionsMessage: PropTypes.string
    .description('Message to show once there are no options')
    .defaultValue('No matches found'),
  measureRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).description('ref to the components container'),
  readOnly: PropTypes.bool
    .description('Whether the combo box is read only or not')
    .defaultValue(false),
  expandMenuToContainer: PropTypes.bool
    .description(
      'Whether the combo box can be expanded the menu to container or not',
    )
    .defaultValue(true),
  expandMenuOutsideContainer: PropTypes.bool
    .description('Allow have options larger than his container')
    .defaultValue(false),
  customMenuItemOptions: PropTypes.object.description(
    'Custom combo box menu item options',
  ),
  returnValue: PropTypes.bool
    .description('Whether the combo box is has value to return or not')
    .defaultValue(true),
  hideSelectedOptions: PropTypes.bool
    .description('Whether to hide the selected options or not')
    .defaultValue(true),
  maxOptions: PropTypes.number.description(
    'Maximun amount of options to display in the menu before adding scroll, this controls the menu s height',
  ),
  filterOption: PropTypes.func.description(
    'Custom method to filter whether an option should be displayed in the menu',
  ),
  isFreeSolo: PropTypes.bool.description('ONLY FOR DIMSUM INTERNAL USE'),
  formatCreateLabel: PropTypes.func.description('ONLY FOR DIMSUM INTERNAL USE'),
  createOptionPosition: PropTypes.string.description(
    'ONLY FOR DIMSUM INTERNAL USE',
  ),
  onClickDropdownIndicator: PropTypes.func.description(
    'function executed when clicking dropdown indicator',
  ),
  components: PropTypes.object.description(
    'Object with custom components for react-select',
  ),
  componentsStyle: PropTypes.object.description(
    'Object with custom styles for react-select',
  ),
  contentRect: PropTypes.object.description(''),
  zIndex: PropTypes.number.description('z-index value').defaultValue(11),
  keepTypedValue: PropTypes.bool
    .description('keep typed value in the input')
    .deprecated('use ComboBoxFreeSolo instead'),
};

DSComboBox2.propTypes = comboBoxProps;

const ComboBox = withSelectStringValueConverter(
  withContentRect('bounds')(DSComboBox2),
);

const ComboBoxWithSchema = describe(DSComboBox2);
ComboBoxWithSchema.propTypes = comboBoxProps;

export { ComboBoxWithSchema };
export default ComboBox;
