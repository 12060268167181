/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable max-statements */
import React, { useRef, useState, useEffect } from 'react';
import { components } from 'react-select';
import { FixedSizeList as List } from 'react-window';
import memoize from 'memoize-one';
import { debounce } from 'lodash';
import CustomOption from './CustomOption';
import CustomOptionMulti from './CustomOptionMulti';
import MultiSelectHeader from './MultiSelectHeader';
// import AllOption from './AllOption'

const createItemData = memoize((items, options) => ({
  items,
  options,
}));

const scrollTo = (listRef, type) => {
  if (listRef && listRef.current && listRef.current.props) {
    const { items } = listRef.current.props.itemData;
    const index = items.findIndex(item => item.props.isFocused);
    listRef.current.scrollToItem(index, type);
  }
};
// const scrollToMouse = debounce(scrollTo, 500)
const scrollToKeyword = debounce(scrollTo, 50);

const MenuList = props => {
  const listRef = useRef(null);
  const [inside, setInside] = useState(false);
  const handleKeyDown = () => {
    setInside(false);
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });
  const { children: options, selectProps, isMulti } = props;
  const { customMenuItemOptions } = selectProps;
  const { itemSize = 35 } = customMenuItemOptions;
  const itemData = createItemData(options, customMenuItemOptions);
  const maxOptionsScreen = 10;
  const safeOptionLength = options.length || 1;
  const height =
    itemSize *
    (safeOptionLength < maxOptionsScreen ? safeOptionLength : maxOptionsScreen);
  const RenderOption = !isMulti ? CustomOption : CustomOptionMulti;
  if (!options.length) return <components.MenuList {...props} />;

  if (listRef && listRef.current && !inside) {
    scrollToKeyword(listRef, 'center'); // use autoScroll
  }
  return (
    <div
      onMouseLeave={() => {
        setInside(false);
      }}
    >
      {isMulti && <MultiSelectHeader {...props} />}
      {/* {isMulti && <AllOption {...props} />} */}
      <List
        ref={listRef}
        className={`combobox-menu-list ${isMulti &&
          'combobox-menu-list-is-multi'}`}
        height={height}
        itemCount={options.length}
        itemData={itemData}
        itemSize={itemSize}
        onScroll={() => {
          setInside(true);
        }}
        useIsScrolling
      >
        {RenderOption}
      </List>
    </div>
  );
};
export default MenuList;
