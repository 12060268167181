import React, { useMemo } from 'react';
import { isFunction } from '@elliemae/ds-shared/utils';
import useDataGrid from '@elliemae/ds-shared/useDataGrid';
import Table from './components/Table';
import TableBody from './components/TableBody';
import TableHeader from './components/TableHeader';
import { defaultRenderers, renderRowsLoader } from './components/renderers';
import { DatagridWrapper } from './components/renderers/defaultClassedRenderers';
import addOptionalFooterComponents from './components/footer/addOptionalFooterComponents';
import addOptionalHeaderComponents from './components/header/addOptionalHeaderComponents';
import { EmptyState } from './components/EmptyState';
import getPluginsFromProps from './utilities/getPluginsFromProps';
import { defaultPlugins } from './defaultPlugins';
import { normalizeRows } from './utilities/normalizeData';

const isFluid = ({ autoHeight, paginated, numRowsVisible }) => {
  if (autoHeight) return false;
  if (paginated) return false;
  return !numRowsVisible;
};

function DataGridImpl({
  className,
  renderers,
  columns,
  rows,
  containerProps,
  plugins: pluginsProp,
  normalizeDataKeys,
  ...otherProps
}) {
  const {
    noResultsPlaceholder = <EmptyState />,
    noColumnsPlaceholder = 'Please select one of more columns in order for data to be displayed.',
    noResultsRender,
    paginated,
    height,
    showHeader,
    showRowsLoader,
    rowsLoaderRenderer = renderRowsLoader,
    groupedRows,
    sortable,
    rowSize,
    wrapText,
    onDefaultFiltersLoad,
  } = otherProps;
  const plugins = getPluginsFromProps({
    ...otherProps,
    virtualized: (!paginated || groupedRows) && !wrapText,
    paginated: (paginated && !groupedRows) || wrapText,
    sortable: sortable && !groupedRows,
  });

  if (normalizeDataKeys) {
    normalizeRows(rows);
  }

  const grid = useDataGrid({
    columns,
    rows,
    renderers: defaultRenderers,
    plugins: [...plugins, ...pluginsProp, ...defaultPlugins],
    ...otherProps,
  });

  const noColumnsVisible = useMemo(() => {
    const visibleUserColumns = grid.columns.filter(
      column => column.visible || typeof column.visible === 'undefined',
    );
    return visibleUserColumns.length === 0;
  }, [grid.columns]);

  const bodyPlaceholder = useMemo(() => {
    if (noColumnsVisible) {
      return noColumnsPlaceholder;
    }
    return isFunction(noResultsRender)
      ? noResultsRender()
      : noResultsPlaceholder;
  }, [noResultsPlaceholder, noColumnsPlaceholder, noColumnsVisible]);

  const { pagination } = otherProps;
  const conditionalPagination =
    pagination &&
    pagination.conditionalPagination === true &&
    rows.length <= otherProps.pagination.perPage;
  const usePagination = paginated && !conditionalPagination;
  return (
    <DatagridWrapper
      className={`${className} ds-row-size-${rowSize}`}
      classProps={{
        fluidHeight: isFluid({
          autoHeight: otherProps.autoHeight,
          numRowsVisible: otherProps.numRowsVisible,
          paginated: usePagination,
        }),
      }}
      style={{ height }}
      {...containerProps}
    >
      {addOptionalHeaderComponents({ grid })}
      <Table {...grid} onDefaultFiltersLoad={onDefaultFiltersLoad}>
        {showHeader && <TableHeader />}
        {showRowsLoader ? (
          rowsLoaderRenderer()
        ) : (
          <TableBody
            isPlaceholderActive={
              noColumnsVisible ||
              (grid.composedRows.rows && grid.composedRows.rows.length === 0) ||
              grid.composedRows.length === 0
            }
            noResultsPlaceholder={bodyPlaceholder}
          />
        )}
      </Table>

      {addOptionalFooterComponents({
        grid,
        rows: grid.composedRows,
      })}
    </DatagridWrapper>
  );
}

export default DataGridImpl;
