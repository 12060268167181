/* eslint-disable import/no-cycle */
import React from 'react';
import { PropTypes, describe } from 'react-desc';
import Checkmark from '@elliemae/ds-icons/Checkmark';
import { runAll } from '@elliemae/ds-utilities/utils';
import MenuItem from './MenuItem';

// TODO: where to move this?
function useCheckable({ checked }) {
  return {
    checked,
    'aria-checked': checked,
  };
}

/**
 * Reuses the same props as the MenuItem
 *
 * @param root0
 * @param root0.role
 * @param root0.checked
 * @param root0.name
 * @param root0.checkIcon
 * @param root0.children
 * @param root0.leftAddon
 * @param root0.item
 */
function MenuItemCheckable({
  role = 'menuitemcheckbox',
  checked = false,
  name = '',
  checkIcon = <Checkmark color={['brand-primary', 600]} />,
  // eslint-disable-next-line react/prop-types
  children,
  leftAddon,
  item = undefined,
  ...otherProps
}) {
  const checkableProps = useCheckable({ checked, name });
  const handleClick = () =>
    runAll(otherProps.onClick, checkableProps.onClick)(
      { target: { value: name, checked } },
      item,
    );

  const checkAddon =
    leftAddon ||
    (checkableProps.checked ? (
      <div className="checkable-mark">{checkIcon}</div>
    ) : (
      <div />
    ));
  return (
    <MenuItem
      {...item}
      {...otherProps}
      {...checkableProps}
      customRenderer={
        item && item.customRenderer ? item.customRenderer : () => {}
      }
      items={otherProps.subItems}
      leftAddon={checkAddon}
      onClick={handleClick}
      role={role}
    />
  );
}

const props = {
  /** Whether the item is checked or not */
  checked: PropTypes.bool.description('Whether the item is checked or not'),
  /** A custom item when the item is checked */
  checkIcon: PropTypes.element.description(
    'A custom item when the item is checked',
  ),
  /** form field name */
  name: PropTypes.string.description('form field name'),
  /** menu item object props */
  item: PropTypes.object.description('menu item object props'),
  /** a11y role */
  role: PropTypes.string.description('a11y role'),
  /** left addon component */
  leftAddon: PropTypes.node.description('left addon component'),
};

MenuItemCheckable.propTypes = props;

const DSMenuItemCheckeableWithSchema = describe(MenuItemCheckable);
DSMenuItemCheckeableWithSchema.propTypes = props;

export default MenuItemCheckable;
export { DSMenuItemCheckeableWithSchema };
