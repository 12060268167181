/* eslint-disable import/no-unresolved */
import React from 'react';
import { describe, PropTypes } from 'react-desc';
import { aggregatedClasses } from '@elliemae/ds-classnames';

const blockName = 'spinner';

const SpinnerContainer = aggregatedClasses('div')(blockName);
const SpinnerMessage = aggregatedClasses('span')(blockName, 'message');
const Spinner = aggregatedClasses('div')(blockName, 'spinner');

// TODO: do we want dynamic position of the text (top, right, bottom, left)?

const DSSpinner = ({
  containerProps = {},
  innerRef,
  message = '',
  size = 'm',
  ...otherProps
}) => (
  <SpinnerContainer
    {...containerProps}
    ref={innerRef}
    aria-busy
    aria-label={message}
    aria-live="polite"
    role="alert"
    {...otherProps}
  >
    <Spinner classProps={{ size }}>
      <svg
        className={`${blockName}-tl`}
        viewBox="0 0 16.6 16.6"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.1.5A15.6,15.6,0,0,0,.5,16.1,15.6,15.6,0,0,0,16.1.5Z"
          fill="#408a90"
          stroke="#fbfbfb"
        />
      </svg>
      <svg
        className={`${blockName}-tr`}
        viewBox="0 0 16.6 16.6"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.1,16.1A15.6,15.6,0,0,0,.5.5,15.6,15.6,0,0,0,16.1,16.1Z"
          fill="#f7901e"
          stroke="#fbfbfb"
        />
      </svg>
      <svg
        className={`${blockName}-br`}
        viewBox="0 0 16.6 16.6"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M.5.5A15.6,15.6,0,0,0,16.1,16.1,15.6,15.6,0,0,0,.5.5Z"
          fill="#0067ab"
          stroke="#fbfbfb"
        />
      </svg>
      <svg
        className={`${blockName}-bl`}
        viewBox="0 0 16.6 16.6"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.1.5A15.6,15.6,0,0,0,.5,16.1,15.6,15.6,0,0,0,16.1.5Z"
          fill="#00acdc"
          stroke="#fbfbfb"
        />
      </svg>
    </Spinner>
    {message && <SpinnerMessage>{message}</SpinnerMessage>}
  </SpinnerContainer>
);

const spinnerProps = {
  innerRef: PropTypes.object.description('ref to the component s container'),
  containerProps: PropTypes.object.description(
    'Set of Properties attached to the main container',
  ),
  message: PropTypes.string.description('Text below the spinner'),
  size: PropTypes.oneOf(['s', 'm', 'l'])
    .description('Spinner size')
    .defaultValue('m'),
};

DSSpinner.propTypes = spinnerProps;

const SpinnerWithSchema = describe(DSSpinner);
SpinnerWithSchema.propTypes = spinnerProps;

export { SpinnerWithSchema };
export default DSSpinner;
