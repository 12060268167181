import { repeat } from 'lodash';
// TODO update when Grid starts supporting
// m x n items (rows and cols simultaneously)
export function positionIE11Grid({ rows, cols, childNumber }) {
  let css = '';
  if ((Array.isArray(rows) && rows.length === 0) || !rows) {
    for (let x = 0; x <= childNumber; x++) {
      // :nth-child() doesn't work in ie11
      css = css.concat(
        `& > div ${repeat('+ div ', x)}{ -ms-grid-column: ${x + 1}; } `,
      );
    }
  }
  if ((Array.isArray(cols) && cols.length === 0) || !cols) {
    for (let x = 0; x <= childNumber; x++) {
      css = css.concat(
        `& > div ${repeat('+ div ', x)}{ -ms-grid-row: ${x + 1}; } `,
      );
    }
  }
  return css;
}
