import React from 'react';
import DSButton from '@elliemae/ds-basic/Button';
import DSCheckbox from '@elliemae/ds-basic/form/Checkbox';
import DSComboBox from '@elliemae/ds-basic/form/ComboBox';
import DSRadio from '@elliemae/ds-basic/form/Radio';
import DSTextBox from '@elliemae/ds-basic/form/TextBox';
import DSToggle from '@elliemae/ds-basic/form/Toggle';
import DSDropdownMenu from '@elliemae/ds-dropdownmenu';
import * as renderers from '../../components/renderers';

const components = {
  DSButton,
  DSCheckbox,
  DSComboBox,
  DSRadio,
  DSTextBox,
  DSToggle,
  DSDropdownMenu,
};

const getRendererComponent = (
  { props },
  { type = 'DSTextBox', handlers, ...rest },
  { rowData },
) => {
  const Component = components[type] || renderers[type];
  const { customHandlers, rowKey } = props;
  const mappedHandlers = {};

  Object.keys(handlers).forEach(key => {
    mappedHandlers[handlers[key]] = () =>
      typeof customHandlers[key] === 'function'
        ? customHandlers[key](rowData, rowData[rowKey])
        : null;
  });
  return <Component {...rowData} {...mappedHandlers} {...rest} />;
};

export default getRendererComponent;
