/* eslint-disable max-statements */
import { isFunction, get } from '@elliemae/ds-utilities/utils';
import { appendCellFormatter } from '@elliemae/ds-shared/useDataGrid/initColumnDefinition';
import getRendererComponent from './getRendererComponent';

export const getRenderer = (renderer, grid, column) => (value, metaData) => {
  const {
    props: { customHandlers, parentRowData },
  } = grid.getInstance();
  if (
    column &&
    column.formatExpandable &&
    value &&
    value.props &&
    value.props.children &&
    value.props.children.length === 2
  ) {
    return [
      value.props.children[0],
      renderer({
        value: value.props.children[1], // apply custom render only to the original value not the arrow
        metaData: { ...metaData, parentRowData },
        customHandlers,
      }),
    ];
  }
  return renderer({
    value,
    metaData: { ...metaData, parentRowData },
    customHandlers,
  });
};

const addCustomRendererToCell = grid => column => {
  const { customRenderer } = column;
  const customHeaderRenderer = get(column, ['header', 'customRenderer']);
  if (!customRenderer && !customHeaderRenderer) return column;

  if (isFunction(customHeaderRenderer)) {
    column.header.formatters.push(getRenderer(customHeaderRenderer, grid));
  }

  if (isFunction(customRenderer)) {
    column = appendCellFormatter(
      getRenderer(customRenderer, grid, column),
      column,
    );
  } else {
    if (!customRenderer) return column;
    column.cell.formatters.push((value, columnData) =>
      getRendererComponent(grid, customRenderer, columnData),
    );
  }

  return column;
};

export default addCustomRendererToCell;
