/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M16.01 15H13.5c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h2.51c1.65 0 2.99-1.34 2.99-3s-1.34-2.99-2.99-2.99c-.35 0-.7.06-1.02.18-.22.08-.46 0-.59-.2-.13-.19-.1-.45.06-.62C14.81 7 15 6.51 15 6c0-1.1-.9-2-2-2-.64 0-1.23.3-1.61.81-.23.31-.36.68-.39 1.06-.01.21-.16.39-.36.45s-.42-.02-.55-.2v-.01c-.11-.15-.23-.3-.35-.44A5.024 5.024 0 0 0 6 4C3.24 4 1 6.24 1 9s2.24 5 5 5h1.5c.28 0 .5.22.5.5s-.22.5-.5.5H6c-3.31 0-6-2.69-6-6s2.69-6 6-6c1.6 0 3.14.64 4.26 1.78.09-.2.2-.38.32-.55C11.15 3.46 12.05 3 13 3c1.65 0 3 1.35 3 3 0 .35-.06.69-.18 1.02H16c2.2 0 3.99 1.79 3.99 3.99S18.21 15 16.01 15z',
    'M12.85 11.14l-2-2c-.19-.19-.51-.19-.71 0l-2 2c-.2.19-.2.51 0 .71.19.19.51.19.71 0L10 10.71v4.79c0 .28.22.5.5.5s.5-.22.5-.5v-4.79l1.15 1.15c.09.09.22.14.35.14s.26-.05.35-.15c.2-.19.2-.51 0-.71z',
  ],
  height: 20,
  width: 20,
});
