import React, { useState } from 'react';
import styled from 'styled-components';
import DSButton from '@elliemae/ds-basic/Button';
import MoreOptionsVert from '@elliemae/ds-icons/MoreOptionsVert';

const ToolbarBtns = styled.div`
  & > .em-ds-toolbar {
    box-shadow: none;
  }
`;

const ToolbarWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${(props) => (props.rowSize === 'normal' ? '36px' : '28px')};
  margin-left: -4px;
  margin-right: ${(props) => (props.hasScrollBar ? '0' : '-10px')};
  padding-right: 4px;
  pointer-events: all;
  background: white;
  ${(props) =>
    props.showShadow
      ? `box-shadow: -1px 0px 1px 0px rgba(0, 0, 0, 0.2),
  1px 0px 2px 0px rgba(0, 0, 0, 0.2);`
      : ''}
  &:hover {
    box-shadow: -1px 0px 1px 0px rgba(0, 0, 0, 0.2),
      1px 0px 2px 0px rgba(0, 0, 0, 0.2);
    & > .toolbar-trigger {
      border: none;
    }
  }
`;

const ToolbarPosition = styled.div`
  position: absolute;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 0;
  pointer-events: none;
  top: 0;
  height: ${(props) =>
    props.rowRef.current ? `${props.rowRef.current.offsetHeight}px` : '36px'};
  margin-top: ${(props) => (props.rowSize === 'normal' ? '0' : '0')};
`;

// eslint-disable-next-line react/prop-types
export const ToolbarTrigger = ({ grid, rowData, hasScrollBar, rowRef }) => {
  const {
    props: { renderToolbar, rowSize },
    // eslint-disable-next-line react/prop-types
  } = grid.getInstance();
  const [show, setShow] = useState(false);
  /* eslint-disable indent */
  const toolbar = show
    ? renderToolbar({
        ...rowData,
        size: rowSize,
        isToolbarShown: show,
        setToolbarShow: setShow,
      })
    : null;
  /* eslint-enable indent */
  return (
    <ToolbarPosition rowSize={rowSize} rowRef={rowRef}>
      <ToolbarWrapper
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        showShadow={show}
        rowSize={rowSize}
        hasScrollBar={hasScrollBar}
      >
        {show && <ToolbarBtns>{toolbar}</ToolbarBtns>}
        <DSButton
          buttonType="text"
          className="toolbar-trigger"
          data-testid="toolbar-trigger"
          icon={<MoreOptionsVert />}
          // eslint-disable-next-line react/prop-types
          index={rowData.index}
          onClick={() => setShow(true)}
          tabIndex={-1}
        />
      </ToolbarWrapper>
    </ToolbarPosition>
  );
};

export default ToolbarTrigger;
