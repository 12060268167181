export const normalizeRows = rows =>
  rows.map(row => {
    Object.keys(row).forEach(key => (row[normalizeText(key)] = row[key]));
    return row;
  });

export const normalizeColumns = columns =>
  columns.map(column => {
    const { property } = column;
    return {
      ...column,
      originalProperty: column.property,
      property: normalizeText(property),
    };
  });

export const normalizeText = text => text.replace(/\s+/g, '');
