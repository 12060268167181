import React from 'react';
import DSTruncatedTooltipText from '../../../../TruncatedTooltipText';
import { PopperPositions as Positions } from '../../../../Popper';

function MultiValueLabel({ children }) {
  return (
    <DSTruncatedTooltipText tooltipPlacement={Positions.TOP} value={children} />
  );
}

export default MultiValueLabel;
