/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import Search from '@elliemae/ds-icons/Search';
import DSButton from '../../Button';
import DSTextBox from '../TextBox';
import withSearchable from './withSearchable';

const SearchBoxImpl = ({
  containerProps,
  placeholder,
  className,
  disabled,
  disableButton,
  innerRef,
  showIcon,
  style,
  onChange,
  onKeyUp,
  onSearch,
  onBlur,
  readOnly,
  value,
  clearable,
}) => (
  <DSTextBox
    className={className}
    clearable={clearable}
    containerProps={containerProps}
    disabled={disabled}
    innerRef={innerRef}
    onChange={onChange}
    onKeyUp={onKeyUp}
    onBlur={onBlur}
    placeholder={placeholder}
    readOnly={readOnly}
    rightComponent={
      showIcon && (
        <DSButton
          disabled={disabled || disableButton}
          buttonType="text"
          icon={<Search />}
          iconSize="s"
          onClick={onSearch}
          type="secondary"
          style={{ borderColor: '#A9B1BE', borderLeft: 'none' }}
        />
      )
    }
    style={style}
    value={value}
  />
);

SearchBoxImpl.propTypes = {
  containerProps: PropTypes.shape({}),
  className: PropTypes.string,
  innerRef: PropTypes.any,
  style: PropTypes.objectOf(PropTypes.string),
  placeholder: PropTypes.string,
  onKeyUp: PropTypes.func,
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  showIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  disableButton: PropTypes.bool,
  readOnly: PropTypes.bool,
  clearable: PropTypes.bool,
};

export default withSearchable(SearchBoxImpl);
