import React, { Component } from 'react';
import { aggregatedClasses } from '@elliemae/ds-classnames';

const blockName = 'input-addon-group';

const Container = aggregatedClasses('div')(blockName);
const InputAddon = aggregatedClasses('span')(blockName, 'addon');

export default class AddonWrapper extends Component {
  static defaultProps = {};

  state = {};

  render() {
    const {
      leftAddon,
      rightAddon,
      children: inputComponent,
      className,
      containerProps,
    } = this.props;
    const childType =
      inputComponent && inputComponent.type && inputComponent.type.name
        ? inputComponent.type.name.toLowerCase()
        : 'default';
    return (
      <Container
        className={`${className} ${blockName}--${childType}`}
        {...containerProps}
      >
        {leftAddon && <InputAddon>{leftAddon}</InputAddon>}
        {inputComponent}
        {rightAddon && <InputAddon>{rightAddon}</InputAddon>}
      </Container>
    );
  }
}
