/* eslint-disable react/prop-types */
import React from 'react';
import { convertPropToCssClassName } from '@elliemae/ds-classnames';

function SvgIcon({
  containerProps = {},
  size,
  color,
  className = '',
  width = 16,
  height = 16,
  style = {},
  fill = '#000',
  innerRef,
  ...rest
}) {
  const { cssClassName } = convertPropToCssClassName('icon', className, {
    size,
    color,
  });
  return (
    <span
      ref={innerRef}
      className={cssClassName}
      {...rest}
      {...containerProps}
      style={{
        width,
        height,
        ...style,
      }}
    >
      <svg
        width={String(width)}
        height={String(height)}
        style={{
          width,
          height,
        }}
        fill={fill}
        viewBox="0 0 16 16"
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g fill={fill} fillRule="nonzero">
            <path
              d="M6.6,8c0,0.1,0,0.2-0.1,0.3l-5.6,7.5c-0.2,0.2-0.5,0.3-0.7,0.1c-0.2-0.2-0.3-0.5-0.1-0.7L5.5,8L0.1,0.8
	C-0.1,0.5,0,0.3,0.2,0.1C0.4-0.1,0.7,0,0.8,0.2l5.6,7.5C6.6,7.8,6.6,7.9,6.6,8L6.6,8z M15.6,8c0,0.1,0,0.2-0.1,0.3l-5.6,7.5
	c-0.2,0.2-0.5,0.3-0.7,0.1c-0.2-0.2-0.3-0.5-0.1-0.7L14.5,8L9.1,0.8C8.9,0.5,9,0.3,9.2,0.1C9.4-0.1,9.7,0,9.8,0.2l5.6,7.5
	C15.6,7.8,15.6,7.9,15.6,8L15.6,8z"
            ></path>
          </g>
        </g>
      </svg>
    </span>
  );
}

export default SvgIcon;
