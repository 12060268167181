/* eslint-disable mdx/no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable max-lines */
import React, {
  useCallback,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import usePrevious from '@elliemae/ds-utilities/hooks/usePrevious';
import useShouldRecalculate from '@elliemae/ds-utilities/hooks/useShouldRecalculate';
import { isFunction, isString } from '@elliemae/ds-utilities/utils';
import { DropdownPill } from '../DropdownPill';
import { InputPill } from '../InputPill';
import { Pill } from '../Pill';
import { RemovablePill } from '../RemovablePill';
import { hasOptionChanged } from './utils';

export default function LabeledDropdownSinglePill({
  label,
  labelIsValue = false,
  options,
  value,
  onSelect = () => null,
  onChange,
  maxWidth,
  minWidth,
  fixed = false,
  ...menuProps
}) {
  const [optionValue, setOptionValue] = useState(value);
  const prevOptions = usePrevious(options);
  const areOptionsEqual = useShouldRecalculate(
    hasOptionChanged(prevOptions, options),
  );

  useEffect(() => setOptionValue(value), [value]);

  useEffect(() => {
    const selectedValue = options.find((option) => option.selected);

    if (selectedValue) {
      setOptionValue(selectedValue);
    } else if (!selectedValue) {
      setOptionValue(options[0]);
    }
  }, [areOptionsEqual]);

  useLayoutEffect(() => {
    if (optionValue) {
      const selectedValue = options.find(
        (option) => option.id === optionValue.id,
      );
      setOptionValue(selectedValue);
    }
  }, [options]);

  const selectOption = useCallback(
    (option) => {
      if (isFunction(onChange)) {
        onChange(option);
      }
      setOptionValue(option);
      onSelect(option);
    },
    [onChange, onSelect],
  );

  const onChangeInputValue = useCallback(
    ({ target: { value: inputValue } }) => {
      const { onChangeInput } = optionValue;

      onChangeInput && onChangeInput(inputValue);
    },
    [optionValue],
  );

  const removeOption = (props) => {
    const { onRemove } = menuProps;
    const { onChangeInput } = optionValue;
    setOptionValue(null);
    onChangeInput && onChangeInput('');
    onRemove && onRemove(props);
  };

  const renderPill = (option, fixed) => {
    const { input, onChangeInput, ...otherProps } = option;
    let SelectedPill;
    if (isString(input)) {
      SelectedPill = InputPill;
    } else if (fixed) {
      SelectedPill = Pill;
    } else {
      SelectedPill = RemovablePill;
    }

    return (
      <SelectedPill
        key={option.id}
        label={option.label}
        onChange={onChangeInputValue}
        onRemove={(e) => removeOption(option, e)}
        value={input}
        {...otherProps}
      />
    );
  };

  return (
    <>
      <DropdownPill
        {...menuProps}
        closeMenuOnItemSelection
        label={!labelIsValue ? label : optionValue.label}
        maxWidth={maxWidth}
        minWidth={minWidth}
        onSelectMenuItem={selectOption}
        options={[
          {
            ...menuProps,
            type: 'SelectionGroup',
            id: 'multi-selection-views',
            multi: false,
            items: options,
          },
        ]}
        selection={{
          'multi-selection-views': optionValue ? [optionValue.id] : '',
        }}
        variant="title"
      />
      {optionValue ? renderPill(optionValue, fixed) : <Pill label="-" />}
    </>
  );
}

LabeledDropdownSinglePill.propTypes = {
  label: PropTypes.string,
  labelIsValue: PropTypes.bool,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fixed: PropTypes.bool,
};
