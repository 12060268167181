import { useEffect, useState } from 'react';
import useResizeObserver from '@elliemae/ds-utilities/hooks/useResizeObserver';
import { mergeRefs } from '@elliemae/ds-utilities/system';
import { getScrollbarSizeFromHeaderAndBody } from '../utils';

export function getTableProps(
  tableProps,
  {
    refs,
    tableRef,
    bodyWidth,
    scrollbarWidth,
    isDragging,
    columnSizeService,
    updateRowWidth,
    columns,
  },
) {
  const [tableNode, tableRefCallback] = useState();

  useResizeObserver(({ width }) => {
    if (!refs.header.current) return;
    if (typeof scrollbarWidth.current !== 'number')
      scrollbarWidth.current = getScrollbarSizeFromHeaderAndBody(refs, width);
    if (typeof bodyWidth.current !== 'number')
      bodyWidth.current = refs.body.offsetWidth;
    // DataGrid: header with width 0 after redirect from details page (https://jira.elliemae.io/browse/PUI-2162)
    // if (scrollbarWidth.current !== 0 && width - scrollbarWidth.current > 0) {
    //   refs.header.current.style.width = `${width - scrollbarWidth.current}px`
    // }

    if (isDragging.current) return;
    columnSizeService.updateMissingWidths(width);
    updateRowWidth(width);
  }, tableNode);

  useEffect(() => {
    if (tableNode) {
      columnSizeService.updateMissingWidths(tableNode.clientWidth);
      updateRowWidth(tableNode.clientWidth);
    }
    if (typeof bodyWidth.current !== 'number')
      bodyWidth.current = refs.body.clientWidth;
  }, [columns, tableNode]);

  return {
    ...tableProps,
    innerRef: mergeRefs(tableRefCallback, tableProps.innerRef, tableRef),
  };
}
