import React from 'react';
import { withTheme, op } from '@elliemae/ds-system';
import { rem, stripUnit } from 'polished';

const Circle = ({ checked, theme }) => {
  const r =
    stripUnit(op('-', theme.breakpoints.medium, rem(window.innerWidth, 13))) >=
    0
      ? 6
      : 4;
  return (
    <svg width="100%" height="100%">
      <circle
        className={checked ? 'checked' : 'unchecked'}
        r={r}
        cx="50%"
        cy="50%"
      />
    </svg>
  );
};

export default withTheme(Circle);
