/* eslint-disable no-param-reassign */
/* eslint-disable max-statements */
import { runAll } from '@elliemae/ds-utilities/utils';
import createInstancePlugin from '@elliemae/ds-shared/createDataInstance/createInstancePlugin';
import { addResizableToColumn, resizableFormatter } from './decorateResizable';

const noop = () => null;

const DELTA_LAST_COLUMN = 25;

// eslint-disable-next-line no-unused-vars
const decorateColumn = (column, grid, index) => {
  const {
    onResizeEnd = noop,
    onResizeStart = noop,
    minColumnWidth = 90,
    isColumnResizeActive = (col) => col.resizable && !col.expandableColumn,
    onColumnResize,
  } = grid.props;

  const { decoratedColumns } = grid.getInstance();
  // need to do this because the decorated columns which the index in the function call
  // could be different from the final decorated columns because a plugin could add a
  // column afterwards
  const colIndex = decoratedColumns.findIndex(
    (col) => col.property === column.property,
  );
  const isLast = colIndex === decoratedColumns.length - 1;

  if (!isColumnResizeActive(column)) return column;
  return addResizableToColumn(
    column,
    resizableFormatter({
      onDragStart: () => {
        grid.isDragging.current = true;
        grid.disableEvents();
        onResizeStart();
        onColumnResize('onResizeStart', { column });
      },
      onDrag: (width, increasing) => {
        const { resizedColumns, refs } = grid.getInstance();
        if (isLast && increasing) {
          const thisCol = resizedColumns.current[column.uuid] || 0;
          width = Math.max(thisCol + DELTA_LAST_COLUMN, width);
        }
        column.updateBodyHeaderWidth(width, width);
        grid.updateRowWidth();
        if (isLast && increasing) {
          // TODO: Move this to packages/ds-shared/src/ScrollSync/ScrollSync.js
          // It isn't watching the scrollLeft so you have to move both at the same time
          refs.header.current.scrollLeft += DELTA_LAST_COLUMN;
          refs.body.current.scrollLeft += DELTA_LAST_COLUMN;
        }
      },
      onDragEnd: (width, increasing, extraData) => {
        const { resizedColumns } = grid.getInstance();
        if (isLast && increasing) {
          const thisCol = resizedColumns.current[column.uuid] || 0;
          width = Math.max(thisCol + DELTA_LAST_COLUMN, width);
        }
        resizedColumns.current[column.uuid] = width;

        grid.isDragging.current = false;
        grid.enableEvents();
        try {
          const total = grid.getInstance().refs.body.current.scrollWidth;
          const sum = Object.keys(grid.resizedColumns.current).reduce(
            (p, n) => grid.resizedColumns.current[n] + p,
            0,
          );
          const lastKey = grid.decoratedColumns[grid.columns.length - 1].uuid;
          if (sum < total) {
            const lastCol = grid.getInstance().columnSizeService.columns[
              lastKey
            ];
            lastCol.updateWidth(
              grid.resizedColumns.current[lastKey] + total - sum,
            );
            lastCol.updateBodyHeaderWidth(
              grid.resizedColumns.current[lastKey] + total - sum,
            );
            grid.getInstance().columnSizeService.updateMissingWidths(total);
          }
        } catch (error) {
          console.error(
            'Unexpected error in the addResizableToColumn trying to use the remaining grid space to the last column',
            error,
          );
        }

        onColumnResize('onResizeEnd', {
          column,
          width,
          extraData,
        });
        runAll(onResizeEnd)(width, extraData);
        grid.updateRowWidth();
      },
      minWidth:
        (column.minWidth || minColumnWidth) - (isLast ? DELTA_LAST_COLUMN : 0),
      isLast,
    }),
  );
};

export const ResizablePlugin = createInstancePlugin('resizable', {
  decorateColumn,
});
