/* eslint-disable max-lines */
/* eslint-disable react/display-name,react/no-unused-state */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { aggregatedClasses } from '@elliemae/ds-classnames';
import { isFunction, isEqual } from '@elliemae/ds-utilities/utils';
import DSButton from '@elliemae/ds-basic/Button';
import FilterLabeledPills from './FilterLabeledPills';
import FilterDropdownPill from './FilterDropdownPill';
import { prepareFilters } from './transformFilters';

const blockName = 'filter-bar';

const FilterBarContainer = aggregatedClasses('div')(blockName);
const PillsContainer = aggregatedClasses('div')(blockName, 'pills');
const ButtonsContainer = aggregatedClasses('div')(blockName, 'buttons');

/* eslint-disable prettier/prettier */
const updateFilters = (nextProps, { prevProps }) => (state) =>
  !isEqual(nextProps.filters, prevProps.filters)
    ? {
      ...state,
      filters: nextProps.filters,
      prevProps: { ...state.prevProps, filters: nextProps.filters },
    }
    : state;
/* eslint-enable prettier/prettier */
export default class FilterBarImpl extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filters: props.filters,
      fixedFilters: props.fixedFilters,
      prevProps: {},
    };
    this.focusGroups = [];
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState = updateFilters(nextProps, prevState)(prevState);

    return !isEqual(nextState, prevState) ? nextState : null;
  }

  setFocusGroup(index, focusGroup) {
    this.focusGroups[index] = focusGroup;
  }

  focusNextGroup(currIndex) {
    const nextGroup = this.focusGroups[currIndex + 1];
    if (nextGroup) {
      // hack so it doesn't get called 2 times on the next focus group
      setTimeout(() => nextGroup.actions.focusFirst(), 10);
    }
  }

  focusPreviousGroup(currIndex) {
    const prevGroup = this.focusGroups[currIndex - 1];
    if (prevGroup) {
      prevGroup.actions.focusLast();
    }
  }

  handleRemoveFilter({ pill, group }) {
    const { filters } = this.state;
    const { onRemoveFilter } = this.props;
    const pillId = pill?.id;
    const nextFilters = filters.filter(
      (filter) => !(filter.group === group && filter.id === pillId),
    );

    this.setState({ filters: nextFilters });

    onRemoveFilter({
      // get remove params from LabeledDropdownSinglePill
      // without breaking backwards compat for group pills
      // eslint-disable-next-line prefer-rest-params
      pill: pill || get(arguments, '0', {}),
      // eslint-disable-next-line prefer-rest-params
      group: group || get(arguments, '1.groupId'),
      nextFilters,
    });
  }

  render() {
    const {
      onRemoveAllFilters,
      renderToolbar,
      containerProps,
      maxWidth,
      minWidth,
    } = this.props;

    const { filters } = this.state;

    const groupedFilters = prepareFilters(filters);

    const renderFilterToolbar = isFunction(renderToolbar) ? (
      renderToolbar({ onRemoveAllFilters })
    ) : (
      <DSButton
        buttonType="text"
        labelText="CLEAR"
        onClick={onRemoveAllFilters}
      />
    );

    return (
      <FilterBarContainer data-testid="em-ds-filter-bar" {...containerProps}>
        <PillsContainer>
          {groupedFilters.map((pillGroup, index) => {
            const FilterPillComponent =
              pillGroup.type === 'dropdown'
                ? FilterDropdownPill
                : FilterLabeledPills;
            return (
              <FilterPillComponent
                key={pillGroup.label}
                groupId={pillGroup.group}
                groupIndex={index}
                label={pillGroup.label}
                maxWidth={maxWidth}
                minWidth={minWidth}
                onFocusGroupSet={(focusGroup) =>
                  this.setFocusGroup(index, focusGroup)
                }
                onFocusNextGroup={() => this.focusNextGroup(index)}
                onFocusPreviousGroup={() => this.focusPreviousGroup(index)}
                onRemove={this.handleRemoveFilter}
                pills={pillGroup.values}
              />
            );
          })}
        </PillsContainer>
        {!!filters.length && (
          <ButtonsContainer>{renderFilterToolbar}</ButtonsContainer>
        )}
      </FilterBarContainer>
    );
  }
}

FilterBarImpl.propTypes = {
  filters: PropTypes.array,
  fixedFilters: PropTypes.array,
  onRemoveFilter: PropTypes.func,
  onRemoveAllFilters: PropTypes.func,
  renderToolbar: PropTypes.func,
  containerProps: PropTypes.object,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
