import React, { useContext } from 'react';
import { TableContext } from './tableContext';

function TableBody({
  rows,
  noResultsPlaceholder = 'No data',
  isPlaceholderActive = false,
  ...props
}) {
  const {
    refs,
    decoratedColumns,
    decoratedRenderers: renderers,
    composedRows,
    getBodyProps,
    bodyRowRenderer: rowRenderer,
    props: { rowKey, numRowsVisible, groupedRows },
  } = useContext(TableContext);

  const BodyWrapperComponent = renderers.body.wrapper;
  return (
    <BodyWrapperComponent
      classProps={{ grouped: groupedRows }}
      {...getBodyProps({
        ...props,
        innerRef: refs.body,
        innerBodyRef: refs.innerBody,
        rows: rows || composedRows.rows || composedRows,
        noResultsPlaceholder,
        rowRenderer,
        columns: decoratedColumns,
        rowKey,
        numRowsVisible,
        isPlaceholderActive,
      })}
    >
      kajsd;klfajsd;fj
    </BodyWrapperComponent>
  );
}

export default React.memo(TableBody);
