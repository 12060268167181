export const Position = {
  AUTO: 'auto',
  AUTO_START: 'auto-start',
  AUTO_END: 'auto-end',
  BOTTOM: 'bottom',
  BOTTOM_START: 'bottom-start',
  BOTTOM_END: 'bottom-end',
  LEFT: 'left',
  LEFT_END: 'left-end',
  LEFT_START: 'left-start',
  RIGHT: 'right',
  RIGHT_END: 'right-end',
  RIGHT_START: 'right-start',
  TOP: 'top',
  TOP_START: 'top-start',
  TOP_END: 'top-end',
};

export const positions = [
  Position.AUTO_START,
  Position.AUTO_END,
  Position.AUTO,
  Position.TOP_START,
  Position.TOP,
  Position.TOP_END,
  Position.RIGHT_START,
  Position.RIGHT,
  Position.RIGHT_END,
  Position.BOTTOM_START,
  Position.BOTTOM,
  Position.BOTTOM_END,
  Position.LEFT_START,
  Position.LEFT,
  Position.LEFT_END,
];
