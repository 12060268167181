/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { get } from '@elliemae/ds-utilities/utils';
import moment from 'moment';

export const sorter = ({ columns, sortingColumns, sort, emptyLast } = {}) => (
  data,
) => {
  if (!columns) {
    throw new Error('sort.sorter - Missing "columns" argument!');
  }

  if (!sort) {
    throw new Error('sort.sorter - Missing "sort" argument!');
  }

  if (!sortingColumns) {
    return data;
  }
  const columnIndexList = new Array(sortingColumns.length);
  const orderList = new Array(sortingColumns.length);

  let actualSortFunction = sort;
  if (emptyLast) {
    actualSortFunction = (innerData, innerColumnIndexList, innerOrderList) => {
      const sortedData = sort(innerData, innerColumnIndexList, innerOrderList);

      const keys = Object.keys(sortingColumns);

      const withData = sortedData.filter((r) => keys.some((key) => !!r[key]));
      const withoutData = sortedData.filter((r) => keys.some((key) => !r[key]));

      return [...withData, ...withoutData];
    };
  }

  Object.keys(sortingColumns).forEach((sortingColumnKey) => {
    const realColumn = columns.find(
      (column) => column.property === sortingColumnKey,
    );
    if (!realColumn) return;
    const columnSorter =
      realColumn.sort ||
      function resolveValue(row) {
        const { property, sortBy } = realColumn;
        const value = row[sortBy || property];
        // Pick resolved value by convention
        const resolvedValue = get(row, `_${property}`, value);

        if (realColumn.type === 'DateTime') {
          return moment(resolvedValue);
        }

        return typeof resolvedValue === 'string'
          ? resolvedValue.toLowerCase()
          : resolvedValue;
      };

    const sortingColumn = sortingColumns[sortingColumnKey];

    columnIndexList[sortingColumn.position] = columnSorter;

    orderList[sortingColumn.position] = sortingColumn.direction;
  });
  return actualSortFunction(data, columnIndexList, orderList);
};

export const sorterGroups = ({
  columns,
  sortingColumns,
  sort,
  emptyLast,
} = {}) => (data, rowsData) => {
  if (!columns) {
    throw new Error('sort.sorter - Missing "columns" argument!');
  }

  if (!sort) {
    throw new Error('sort.sorter - Missing "sort" argument!');
  }

  if (!sortingColumns) {
    return data;
  }

  const columnIndexList = new Array(sortingColumns.length);
  const orderList = new Array(sortingColumns.length);

  let actualSortFunction = sort;
  if (emptyLast) {
    actualSortFunction = (innerData, innerColumnIndexList, innerOrderList) => {
      const sortedData = sort(innerData, innerColumnIndexList, innerOrderList);

      const keys = Object.keys(sortingColumns);

      const withData = sortedData.filter((r) => keys.some((key) => !!r[key]));
      const withoutData = sortedData.filter((r) => keys.some((key) => !r[key]));

      return [...withData, ...withoutData];
    };
  }

  Object.keys(sortingColumns).forEach((sortingColumnKey) => {
    const realColumn = columns.find(
      (column) => column.property === sortingColumnKey,
    );
    if (!realColumn) return;
    const columnSorter =
      realColumn.sort ||
      function resolveValue(row) {
        const { property, sortBy } = realColumn;
        const value = row[sortBy || property];
        // Pick resolved value by convention
        const resolvedValue = get(row, `_${property}`, value);

        if (realColumn.type === 'DateTime') {
          return moment(resolvedValue);
        }

        return typeof resolvedValue === 'string'
          ? resolvedValue.toLowerCase()
          : resolvedValue;
      };

    const sortingColumn = sortingColumns[sortingColumnKey];

    columnIndexList[sortingColumn.position] = columnSorter;

    orderList[sortingColumn.position] = sortingColumn.direction;
  });

  const groups = {};
  const preserveData = {};
  data.forEach((c) => {
    preserveData[c.id] = { ...c };
  });

  rowsData.forEach((group) => {
    groups[group.id] = {
      children: actualSortFunction(
        group.children.map((c) => ({
          parentNode: preserveData[c.id].parentNode,
          data: c,
          ...c,
        })),
        columnIndexList,
        orderList,
      ), // sort internally
    };
  });

  const sorted = actualSortFunction(
    data.map((r) => ({
      ...r.data,
      ...r,
    })),
    columnIndexList,
    orderList,
  ); // sort global
  let ssortGlobal = [];
  sorted
    .filter((s) => !s.isGroup)
    .forEach((sr) => {
      if (groups[sr.parentNode.id]) {
        ssortGlobal = [
          ...ssortGlobal,
          preserveData[sr.parentNode.id],
          ...groups[sr.parentNode.id].children,
        ];
        delete groups[sr.parentNode.id];
      }
    });
  return ssortGlobal;
};
