import React, { useEffect, useMemo, useState, useRef, useContext } from 'react';
import { aggregatedClasses } from '@elliemae/ds-classnames';
import { focusGroupManagerHoc } from '@elliemae/ds-shared/FocusGroup';
import { PropTypes, describe } from 'react-desc';
import DSSearchBox from '../../form/SearchBox';
import VirtualMenuList from '../VirtualMenuList';
import MenuContext from '../MenuContext';

const SearchBoxWrapper = aggregatedClasses('div')(
  'menu-search-group',
  'search-wrapper',
);
const NoOptionsWrapper = aggregatedClasses('div')(
  'menu-search-group',
  'no-options',
);

function SearchableGroup({
  children,
  amountItemsInWindow = 5,
  searchTerm: searchTermProp = '',
  noOptionsText = 'No options',
  height,
  width,
  focusOnOpen = false,
}) {
  const searchRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState(searchTermProp);

  useEffect(() => {
    if (searchRef.current) searchRef.current.focus();
  }, []);

  const { visible } = useContext(MenuContext) || {};

  useEffect(() => {
    if (focusOnOpen && visible) {
      setTimeout(() => {
        if (searchRef.current) searchRef.current.focus();
      }, 0);
    }
  }, [focusOnOpen, visible]);

  const filteredChildren = useMemo(
    () =>
      children.filter(
        (child) =>
          !searchTerm ||
          child.props.label.toLowerCase().startsWith(searchTerm.toLowerCase()),
      ),
    [children, searchTerm],
  );

  return (
    <>
      <SearchBoxWrapper>
        <DSSearchBox
          innerRef={searchRef}
          onSearch={({ value }) => setSearchTerm(value)}
          searchOnEnter={false}
          showIcon={false}
          value={searchTerm}
        />
      </SearchBoxWrapper>
      {!filteredChildren.length && (
        <NoOptionsWrapper>{noOptionsText}</NoOptionsWrapper>
      )}
      <VirtualMenuList
        amountItemsInWindow={amountItemsInWindow}
        height={height}
        items={filteredChildren}
        width={width}
      />
    </>
  );
}

const props = {
  /** The amount of items that are going to be rendered in the group */
  amountItemsInWindow: PropTypes.number.description(
    'The amount of items that are going to be rendered in the group',
  ),
  /** A search term */
  searchTerm: PropTypes.string.description('A search term'),
  /** Text to show when there's no options to show */
  noOptionsText: PropTypes.string.description(
    "Text to show when there's no options to show",
  ),
  /** array of children for search list */
  children: PropTypes.array.description('array of children for search list'),
  /** height for virtual list wrapper */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).description(
    'height for virtual list wrapper',
  ),
  /** width for virtual list wrapper */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).description(
    'width for virtual list wrapper',
  ),
  /** weather to focus on searchable group on open */
  focusOnOpen: PropTypes.bool.description(
    'weather to focus on searchable group on open',
  ),
};

SearchableGroup.propTypes = props;

const DSMenuSearchableGroupWithSchema = describe(SearchableGroup);
DSMenuSearchableGroupWithSchema.propTypes = props;

export default focusGroupManagerHoc(SearchableGroup);

export { DSMenuSearchableGroupWithSchema };
