/* eslint-disable import/no-unresolved */
/* eslint-disable indent */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-lines */
/* eslint-disable max-statements */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { aggregatedClasses } from '@elliemae/ds-classnames';
import usePrevious from '@elliemae/ds-utilities/hooks/usePrevious';
import {
  formatMonth,
  formatDay,
  formatYear,
  getValidTimeNumber,
  setNativeValue,
  parseTimeNumberFromText,
  focusNextInput,
  focusPreviousInput,
  getDateValuesFromTime,
  getNextTimeValue,
  focusNextInputIfNeeded,
  commonInputProps,
  handleCompletedDate,
  triggerOnBlur,
  isTimeCompletelySet,
} from './utils';

const blockName = 'input-date-group';

const InputTimesGroup = aggregatedClasses('div')(blockName);
const MonthInput = aggregatedClasses('input')(blockName, 'input input-month');
const DayInput = aggregatedClasses('input')(blockName, 'input input-day');
const YearInput = aggregatedClasses('input')(blockName, 'input input-year');
const Divider = aggregatedClasses('span')(
  blockName,
  'divider',
  ({ value }) => ({
    value,
  }),
);

// eslint-disable-next-line react/prop-types
const DateDivider = ({ content = ':', value = '' }) => (
  <Divider value={!!value}>{content}</Divider>
);

const DateInputs = ({
  innerRef,
  onBlur,
  onChange,
  onCustomKeyDown,
  onError,
  format = '',
  showMonth = true,
  showDay = true,
  showYear = true,
  step = 1,
  yearMaxRange,
  disabled,
  yearMinRange,
  time,
  // TODO remove and keep `true` behavior ( PUI-4141 )
  INTERNAL_V2_NO_MUTATION, // eslint-disable-line react/prop-types
}) => {
  const [currentKey, setCurrentKey] = useState('ArrowUp');
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [year, setYear] = useState('');
  const prevTime = usePrevious(time);
  const prevFormat = usePrevious(format);

  let digits = 2;
  let placeholder = '';

  useEffect(() => {
    if (time) {
      const dateValues = getDateValuesFromTime(time, format);
      setMonth(dateValues.month);
      setDay(dateValues.day);
      setYear(dateValues.year);
    }
  }, [time]);

  useEffect(() => {
    if (!time && prevTime) {
      setMonth('');
      setDay('');
      setYear('');
      onChange('');
    }
    if (
      time &&
      prevTime &&
      time.format &&
      time.format(format) !== prevTime.format(prevFormat)
    ) {
      const dateValues = getDateValuesFromTime(time, format);

      setMonth(dateValues.month);
      setDay(dateValues.day);
      setYear(dateValues.year);
    }
  }, [time, prevTime, format]);

  const isCompletedDate = () =>
    isTimeCompletelySet(
      { year, month, day },
      { showYear, showMonth, showDay },
      { yearMinRange, yearMaxRange },
    );

  useEffect(() => {
    if (INTERNAL_V2_NO_MUTATION) {
      const dateValues = getDateValuesFromTime(time, format);

      if (dateValues.day !== day && isCompletedDate()) {
        onChange(
          handleCompletedDate(
            time,
            { year, month, day },
            { showYear, showMonth, showDay },
            INTERNAL_V2_NO_MUTATION,
          ),
        );
      } else if (dateValues.month !== month && isCompletedDate()) {
        onChange(
          handleCompletedDate(
            time,
            { year, month, day },
            { showYear, showMonth, showDay },
            INTERNAL_V2_NO_MUTATION,
          ),
        );
      } else if (dateValues.year !== year && isCompletedDate()) {
        onChange(
          handleCompletedDate(
            time,
            { year, month, day },
            { showYear, showMonth, showDay },
            INTERNAL_V2_NO_MUTATION,
          ),
        );
      }
    }
  }, [day, month, year]);

  const triggerChange = () =>
    handleCompletedDate(
      time,
      { year, month, day },
      { showYear, showMonth, showDay },
      INTERNAL_V2_NO_MUTATION,
    );

  const handleYearChange = (e) => {
    const { value } = e.target;
    const yearValue = parseTimeNumberFromText(value);
    const yearRange = { min: 1, max: 9999 };
    const nextYear = getValidTimeNumber(yearRange, yearValue, value, onError);

    setYear(formatYear(format, nextYear));
    if (isCompletedDate() && !INTERNAL_V2_NO_MUTATION) {
      onChange(triggerChange());
    }
    focusNextInputIfNeeded(
      e.target,
      yearRange.max,
      nextYear,
      value,
      currentKey,
    );
  };

  const handleMonthChange = (e) => {
    const { value } = e.target;
    const monthValue = parseTimeNumberFromText(value);
    const monthRange = { min: 1, max: 12 };
    const nextMonth = getValidTimeNumber(
      monthRange,
      monthValue,
      value,
      onError,
    );
    setMonth(formatMonth(format, nextMonth));
    if (isCompletedDate() && !INTERNAL_V2_NO_MUTATION) {
      onChange(triggerChange());
    }
    focusNextInputIfNeeded(
      e.target,
      monthRange.max,
      nextMonth,
      value,
      currentKey,
    );
  };

  const handleDayChange = (e) => {
    const { value } = e.target;
    const dayValue = parseTimeNumberFromText(value);
    const dayRange = { min: 1, max: 31 };
    const nextDay = getValidTimeNumber(dayRange, dayValue, value, onError);
    setDay(formatDay(format, nextDay));
    if (isCompletedDate() && !INTERNAL_V2_NO_MUTATION) {
      onChange(triggerChange());
    }
    if (value !== day)
      focusNextInputIfNeeded(
        e.target,
        dayRange.max,
        nextDay,
        value,
        currentKey,
      );
  };

  // eslint-disable-next-line complexity
  const onKeyDown = (event, shouldIncrementDecrement = true) => {
    const { value, name } = event.target;
    const change = {
      day: (v) => handleDayChange({ target: { value: v } }),
      month: (v) => handleMonthChange({ target: { value: v } }),
      year: (v) => handleYearChange({ target: { value: v } }),
    };
    switch (event.key) {
      case 'ArrowLeft': {
        event.preventDefault();
        focusPreviousInput(event.target);
        break;
      }
      case 'ArrowUp': {
        if (shouldIncrementDecrement) {
          event.preventDefault();
          setCurrentKey(event.key);
          const incrementedValue = getNextTimeValue(
            value,
            name,
            yearMinRange,
            yearMaxRange,
            step,
            true,
          );
          if (INTERNAL_V2_NO_MUTATION) {
            change[name](incrementedValue);
          } else {
            setNativeValue(event.target, incrementedValue);
          }
        }
        break;
      }
      case 'ArrowDown': {
        if (shouldIncrementDecrement) {
          event.preventDefault();
          setCurrentKey(event.key);
          const decrementedValue = getNextTimeValue(
            value,
            name,
            yearMinRange,
            yearMaxRange,
            step,
            false,
          );
          if (INTERNAL_V2_NO_MUTATION) {
            change[name](decrementedValue);
          } else {
            setNativeValue(event.target, decrementedValue);
          }
        }
        break;
      }
      case 'ArrowRight': {
        event.preventDefault();
        focusNextInput(event.target);
        break;
      }
      case 'Backspace': {
        event.preventDefault();
        if (!value) {
          focusPreviousInput(event.target);
        } else {
          // eslint-disable-next-line no-lonely-if
          if (INTERNAL_V2_NO_MUTATION) {
            change[name]('');
          } else {
            setNativeValue(event.target, '');
          }
        }
        break;
      }
      case 'Enter': {
        if (onCustomKeyDown && isCompletedDate())
          onCustomKeyDown(event, triggerChange());
        setCurrentKey(null);
        break;
      }
      default:
        if (onCustomKeyDown) onCustomKeyDown(event);
        setCurrentKey(null);
    }
  };

  const renderMonthInput = () => {
    if (!showMonth) return null;
    placeholder = 'MM';
    const onMonthInputBlur = (e) => {
      handleMonthChange(e);
      triggerOnBlur(e, onBlur);
    };
    return (
      <MonthInput
        {...commonInputProps(digits, onKeyDown, placeholder)}
        autocomplete="off"
        key="month-input"
        data-testid="month"
        disabled={disabled}
        name="month"
        onBlur={onMonthInputBlur}
        onChange={handleMonthChange}
        value={month}
      />
    );
  };
  const renderDayInput = () => {
    if (!showDay) return null;
    placeholder = 'DD';
    const onDayInputBlur = (e) => {
      handleDayChange(e);
      triggerOnBlur(e, onBlur);
    };
    return (
      <DayInput
        {...commonInputProps(digits, onKeyDown, placeholder)}
        autocomplete="off"
        key="day-input"
        data-testid="day"
        disabled={disabled}
        name="day"
        onBlur={onDayInputBlur}
        onChange={handleDayChange}
        value={day}
      />
    );
  };
  const renderYearInput = () => {
    if (!showYear) return null;
    placeholder = 'YY';
    digits = 4;
    const onYearInputBlur = (e) => {
      handleYearChange(e);
      triggerOnBlur(e, onBlur);
    };
    return (
      <YearInput
        {...commonInputProps(digits, onKeyDown, placeholder)}
        autocomplete="off"
        key="seconds-input"
        data-testid="year"
        disabled={disabled}
        name="year"
        onBlur={onYearInputBlur}
        onChange={handleYearChange}
        value={year}
      />
    );
  };

  const renderDateInputs = () => {
    const inputs = [renderMonthInput(), renderDayInput(), renderYearInput()];
    const inputsWithDividers = inputs.reduce(
      (acc, input, index) =>
        input
          ? acc.concat([
              index ? (
                <DateDivider
                  key={index}
                  content=" / "
                  value={input.props.value}
                />
              ) : null,
              input,
            ])
          : acc,
      [],
    );

    return [
      inputsWithDividers,
      React.createElement('span', { key: 'span' }), // hack for next/prev focus
    ];
  };

  return (
    <InputTimesGroup innerRef={innerRef}>{renderDateInputs()}</InputTimesGroup>
  );
};

DateInputs.propTypes = {
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  onBlur: PropTypes.bool,
  onChange: PropTypes.func,
  onCustomKeyDown: PropTypes.func,
  onError: PropTypes.func,
  format: PropTypes.string,
  showMonth: PropTypes.bool,
  showDay: PropTypes.bool,
  showYear: PropTypes.bool,
  step: PropTypes.number,
  yearMaxRange: PropTypes.number,
  disabled: PropTypes.bool,
  yearMinRange: PropTypes.number,
  time: PropTypes.instanceOf(Moment),
};

export default DateInputs;
