import React from 'react';
import CloseXsmall from '@elliemae/ds-icons/CloseXsmall';
import { aggregatedClasses } from '@elliemae/ds-classnames';
import DSInputMask from '../form/InputMask';
import DSInput from '../form/Input';
import { Pill } from './Pill';

const StyledInputPill = aggregatedClasses(Pill)(
  'input-pill',
  null,
  ({ value }) => ({
    value,
  }),
);

export function InputPill({
  containerProps = {},
  placeholder = 'Enter Value',
  onChange = () => {},
  onRemove,
  value,
  type,
  ...otherProps
}) {
  let TypeOfInput = DSInput;
  // TODO: implement the DSTimeInput, see how because it returns a moment()
  // TODO: implement the DSDateInput
  switch (type) {
    case 'inputText':
      TypeOfInput = DSInput;
      break;
    case 'inputMask':
      TypeOfInput = DSInputMask;
      break;
    default:
      break;
  }

  return (
    <StyledInputPill
      className="input-pill"
      classProps={{ value }}
      containerProps={containerProps}
      label={
        <TypeOfInput
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          {...otherProps}
        />
      }
      rightAddon={
        value && (
          <CloseXsmall className="remove-pill-button" onClick={onRemove} />
        )
      }
      tabIndex={-1}
    />
  );
}
