/* eslint-disable max-lines */
import React from 'react';
import { PropTypes, describe } from 'react-desc';
import { PillGroup } from './PillGroup';
import LabeledDropdownSinglePill from './LabeledDropdownPillsTypes/LabeledDropdownSinglePill';
import LabeledDropdownMultiPills from './LabeledDropdownPillsTypes/LabeledDropdownMultiPill';
import LabeledDropdownCascadeMenuPill from './LabeledDropdownPillsTypes/LabeledDropdownCascadeMenuPill';

const DropdownPills = {
  single: LabeledDropdownSinglePill,
  multi: LabeledDropdownMultiPills,
  cascaded: LabeledDropdownCascadeMenuPill,
};

const createDropdownPill = (type) =>
  DropdownPills[type] || DropdownPills.single;

function LabeledDropdownPills({
  label = '',
  value,
  type,
  onFocusNextGroup,
  onFocusPreviousGroup,
  onFocusGroupSet,
  maxWidth,
  minWidth,
  fixed,
  ...menuProps
}) {
  const Component = createDropdownPill(type);

  return (
    <PillGroup
      onFocusGroupSet={onFocusGroupSet}
      onFocusNextGroup={onFocusNextGroup}
      onFocusPreviousGroup={onFocusPreviousGroup}
    >
      <Component
        {...menuProps}
        label={label}
        maxWidth={maxWidth}
        minWidth={minWidth}
        value={value}
        fixed={fixed}
      />
    </PillGroup>
  );
}

const props = {
  /** pill type */
  type: PropTypes.oneOf(['single', 'multi', 'cascaded']).description(
    'pill type',
  ),
  /** focus next group callback */
  onFocusNextGroup: PropTypes.func.description('focus next group callback'),
  /** focus prev group callback */
  onFocusPreviousGroup: PropTypes.func.description('focus prev group callback'),
  /** focus group set callback */
  onFocusGroupSet: PropTypes.func.description('focus group set callback'),
  /** pill max width */
  maxWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).description('pill max width'),
  /** pill min width */
  minWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).description('pill min width'),
  /** fixed pill */
  fixed: PropTypes.bool.description('fixed pill'),
  /** pill label text */
  label: PropTypes.string.isRequired.description('pill label text'),
  /** pill value */
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
  ]).description('pill value'),
};

LabeledDropdownPills.propTypes = props;
const DSLabeledDropdownPillsWithSchema = describe(LabeledDropdownPills);
DSLabeledDropdownPillsWithSchema.propTypes = props;

export { LabeledDropdownPills, DSLabeledDropdownPillsWithSchema };
