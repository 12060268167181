/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GroupContext } from './GroupContext';

const getValueItem = (e) => {
  const { target, currentTarget } = e;
  if (target) {
    const { value } = target;
    return value;
  }
  if (currentTarget) {
    const { value } = currentTarget;
    return value;
  }
  return e;
};

const toggleItemValue = (array, e) => {
  const newvalue = getValueItem(e);
  const res = array;
  const index = array.indexOf(newvalue);

  if (index === -1) {
    res.push(newvalue);
  } else {
    res.splice(index, 1);
  }

  return res;
};

const { Provider } = GroupContext;
/** deprecated use CheckableGroup instead */
class Group extends Component {
  constructor(props) {
    super(props);
    const { activeValue, disabled } = props;
    this.state = {
      activeValue,
      disabled,
      prevValue: null,
      onChange: this.handleChange.bind(this),
    };
  }

  static getDerivedStateFromProps(nextProps, { prevValue }) {
    if (nextProps.activeValue === prevValue) return null;
    return {
      activeValue: nextProps.activeValue,
      prevValue: nextProps.activeValue,
    };
  }

  handleChange(e, ...rest) {
    const { multiple, onChange } = this.props;
    const { activeValue } = this.state;
    const value = getValueItem(e);
    let newValue;
    if (multiple) {
      newValue = toggleItemValue(activeValue || [], value);
    } else if (activeValue !== value) {
      // default behaviour it will be toggle the active value
      newValue = value;
    }

    this.setState({
      activeValue: newValue,
    });

    if (onChange) onChange(newValue, ...rest);
  }

  render() {
    const { children } = this.props;
    return <Provider value={this.state}>{children}</Provider>;
  }
}

Group.defaultProps = {
  multiple: false,
};

Group.propTypes = {
  children: PropTypes.array,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  activeValue: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  disabled: PropTypes.bool,
};

export default Group;
