/* eslint-disable react/display-name */
import React from 'react';
import FilterableHeader from './components/FilterableHeader';
import { getFilterLabels, getFilterLabelsGroup } from './helper';

function FilterableFormatter({
  grid,
  onAddFilter,
  onRemoveFilter,
  query,
  filterMenuRef,
}) {
  return (value, { column }) => {
    const {
      rows,
      composedRows,
      props: { groupedRows, groupedBy, onFilterMenuClose, onFilterMenuOpen },
      state: { filters },
    } = grid.getInstance();

    let rowsToUse = composedRows;
    if (column.filter && column.filter.isMulti) {
      rowsToUse = rows;
    }

    const filteredLabels =
      groupedRows && !groupedBy
        ? getFilterLabelsGroup(column, rows, query)
        : getFilterLabels(column, rowsToUse.rows || rowsToUse, query);

    const columnData = column.filterOptions || filteredLabels;
    return (
      <FilterableHeader
        column={column}
        columnData={columnData}
        filters={filters}
        filterMenuRef={filterMenuRef}
        onAddFilter={onAddFilter}
        onFilterMenuClose={onFilterMenuClose}
        onFilterMenuOpen={onFilterMenuOpen}
        onRemoveFilter={onRemoveFilter}
        isMulti={column.filter && column.filter.isMulti}
        value={value}
      />
    );
  };
}

export default FilterableFormatter;
