const basicAction = ({
  id,
  buttonType = 'default',
  fluidWidth = false,
  fluidHeight = false,
}) => {
  const cssModifier = [];
  // buttons types
  if (id) cssModifier.push(`id-${id}`);
  if (buttonType) cssModifier.push(buttonType);
  if (fluidWidth) cssModifier.push('fluid-width');
  if (fluidHeight) cssModifier.push('fluid-height');

  return cssModifier;
};

const instance = [basicAction];
const pipe = () => ({
  getInstance() {
    return instance;
  },
  add(customModifier) {
    instance.push(customModifier);
  },
  execute(props) {
    return instance.reduce((temp, action) => [...temp, ...action(props)], []);
  },
});

export default pipe;
