/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import DSPopover, { usePopoverProviderState } from '../Popover';

export const TruncatedTooltipContext = React.createContext();

const { Provider } = TruncatedTooltipContext;

function TooltipTextProvider({
  children,
  tooltipDelay = 200,
  placement = 'top',
  ...tooltipOptions
}) {
  const {
    show: showTooltip,
    hide: hideTooltip,
    state: tooltipState,
  } = usePopoverProviderState({ delay: tooltipDelay });
  const [zIndex, setZIndex] = useState(110);

  const value = useMemo(
    () => ({
      showTooltip,
      hideTooltip,
      setZIndex,
    }),
    [],
  );

  return (
    <Provider value={value}>
      {children}
      {tooltipState.visible ? (
        <DSPopover
          boundaries="window"
          placement={placement}
          showArrow
          {...tooltipOptions}
          {...(tooltipState.options || {})}
          content={tooltipState.value}
          referenceEl={tooltipState.reference}
          style={{ pointerEvents: 'none', zIndex }}
          visible={tooltipState.visible}
        />
      ) : null}
    </Provider>
  );
}

TooltipTextProvider.propTypes = {};
TooltipTextProvider.defaultProps = {};

export default TooltipTextProvider;
