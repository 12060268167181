/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M5.545 13.01c-2.07-.92-3.72-2.23-4.54-2.95.99-.88 3.19-2.62 5.88-3.49-.54.68-.88 1.54-.88 2.48 0 .92.32 1.75.84 2.43l.66-.79c-.32-.47-.5-1.03-.5-1.64 0-1.65 1.35-3 3-3 .43 0 .84.1 1.21.26l.13-.16.53-.62.22-.26c-.68-.14-1.37-.22-2.09-.22-4.35 0-8.1 2.87-9.67 4.26-.45.4-.45 1.07 0 1.47.89.79 2.5 2.07 4.53 3.02l.68-.79zM19.675 9.31c-1.03-.92-3.01-2.47-5.49-3.43l1.7-2.01c.18-.21.15-.53-.06-.7-.2-.17-.49-.15-.67.03l-1.98 2.34-.48.57-.23.27-.41.49-3.86 4.55-.64.77-1.03 1.22-.69.81-.92 1.09c-.01.01-.02.01-.03.02l-.76.89c-.18.21-.15.53.06.71.09.08.21.12.32.12.14 0 .28-.06.38-.18l1.95-2.31c1 .3 2.07.49 3.17.49 4.35 0 8.1-2.87 9.67-4.26.44-.41.44-1.08 0-1.48zm-7.01-1.63c.21.41.34.87.34 1.37 0 1.65-1.35 3-3 3-.32 0-.62-.07-.91-.16l3.57-4.21zm-2.66 6.37c-.84 0-1.66-.13-2.45-.33l.85-1.01c.49.21 1.03.34 1.6.34 2.21 0 4-1.79 4-4 0-.81-.24-1.55-.65-2.18l.15-.17c2.52.9 4.56 2.52 5.51 3.34-1.36 1.2-4.95 4.01-9.01 4.01z',
  ],
  height: 20,
  width: 20,
});
