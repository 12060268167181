import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  LabeledPills,
  Pill,
  RemovablePill,
  OverflowPill,
} from '@elliemae/ds-basic/Pills';

function FilterLabeledPills({
  groupId,
  label,
  pills,
  onRemove = () => null,
  onFocusGroupSet,
  onFocusNextGroup,
  onFocusPreviousGroup,
  overflowQuantity = 3,
}) {
  const [overflowOptions, setOverflowOptions] = useState([]);

  useEffect(() => {
    if (pills.length > overflowQuantity) {
      const overflow = pills.filter((pill, index) => index >= overflowQuantity);
      const options = overflow.map(pill => ({ label: pill.value }));
      setOverflowOptions(options);
    }
  }, [pills]);

  const pillGroup = pills
    .map(pill => {
      const PillComponent = !pill.fixed ? RemovablePill : Pill;
      return (
        <PillComponent
          key={pill.value}
          label={pill.value}
          onRemove={() =>
            onRemove({
              pill,
              group: groupId,
            })
          }
        />
      );
    })
    .filter((pill, index) => index < overflowQuantity);

  const overflowPills = pills.length > overflowQuantity && (
    <OverflowPill
      label={`+${pills.length - overflowQuantity}`}
      options={overflowOptions}
    />
  );

  return (
    <LabeledPills
      label={label}
      onFocusGroupSet={onFocusGroupSet}
      onFocusNextGroup={onFocusNextGroup}
      onFocusPreviousGroup={onFocusPreviousGroup}
    >
      {pillGroup}
      {overflowPills}
    </LabeledPills>
  );
}

FilterLabeledPills.propTypes = {};

export default FilterLabeledPills;
