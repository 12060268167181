/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { convertPropToCssClassName } from '@elliemae/ds-classnames';
import { isFunction } from '@elliemae/ds-utilities/utils';
import { DOCUMENT } from '@elliemae/ds-utilities/platform';
import './ie-remove-polyfill';

const blockName = 'portal';

const canRenderPortal = isFunction(ReactDOM.createPortal);

// TODO: If this is rendered on server, is going to break

export default class DSPortal extends Component {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = { onRender: () => null };

  // eslint-disable-next-line react/state-in-constructor
  state = { isMounted: false };

  componentDidMount() {
    const { onRender } = this.props;
    this.portalEl = this.createPortalContainer();
    if (DOCUMENT && this.portalEl) {
      DOCUMENT.body.appendChild(this.portalEl);
      onRender();
      this.setState({ isMounted: true });
    }
  }

  componentDidUpdate() {
    if (!canRenderPortal) {
      this.renderNoPortal();
    }
  }

  componentWillUnmount() {
    if (this.portalEl) {
      this.portalEl.parentNode.removeChild(this.portalEl);
    }
  }

  createPortalContainer() {
    if (!DOCUMENT) return null;

    const { className } = this.props;
    const { cssClassName } = convertPropToCssClassName(blockName, className);
    const container = DOCUMENT.createElement('div');
    container.classList.add(cssClassName);
    container.setAttribute('data-testid', 'portal');

    return container;
  }

  renderNoPortal() {
    const { children } = this.props;
    ReactDOM.unstable_renderSubtreeIntoContainer(
      this,
      <div>{children}</div>,
      this.portalEl,
    );
  }

  render() {
    const { children } = this.props;
    const { isMounted } = this.state;
    return !canRenderPortal || !isMounted
      ? null
      : ReactDOM.createPortal(children, this.portalEl);
  }
}
