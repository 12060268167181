const MODAL_TYPE = {
  DEFAULT: 'default',
  ALERT: 'alert',
  CONFIRM: 'confirm',
  NOTIFICATION: 'notification',
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success',
  HELP: 'help',
  INFO: 'info',
};

export const modalTypes = [
  MODAL_TYPE.DEFAULT,
  MODAL_TYPE.ALERT,
  MODAL_TYPE.CONFIRM,
  MODAL_TYPE.NOTIFICATION,
  MODAL_TYPE.WARNING,
  MODAL_TYPE.ERROR,
  MODAL_TYPE.SUCCESS,
  MODAL_TYPE.HELP,
  MODAL_TYPE.INFO,
];

export default MODAL_TYPE;
