import createInstancePlugin from '@elliemae/ds-shared/createDataInstance/createInstancePlugin';
import addCustomRendererToCell from './addCustomRendererToCell';

// todo: this probably needs to go to the core

const decorateColumn = (column, grid) => addCustomRendererToCell(grid)(column);

export const CustomRendererPlugin = createInstancePlugin('custom-renderer', {
  decorateColumn,
});
