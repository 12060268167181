/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useContext, useRef } from 'react';
import { components } from 'react-select';
import { cx } from '@elliemae/ds-utilities/utils';
import { DropDownContext } from '../context';

export function ValueContainer({ children, ...rest }) {
  const valueContainerRef = useRef(null);
  const c = useContext(DropDownContext);
  return (
    <div
      ref={valueContainerRef}
      className={cx(
        'combobox-v2-value-container',
        c.inputInFocus && 'in-focus',
        c.value && c.clearable && 'has-value',
        c.removeDropdownIndicator && 'dropdown-indicator-removed',
      )}
    >
      <components.ValueContainer {...rest}>
        {children}
      </components.ValueContainer>
    </div>
  );
}

export default ValueContainer;
