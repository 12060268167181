/* eslint-disable no-param-reassign */
/* eslint-disable max-lines */
import * as stylesheet from 'stylesheet-helpers';
import { useState, useEffect } from 'react';
import { set } from '@elliemae/ds-utilities/utils';
import getColumnNameFromProperty from './getColumnNameFromProperty';
import { getScrollbarWidth } from '../../utilities/getScrollbarSize';

const SCROLLBAR_WIDTH = getScrollbarWidth();

export const RowStylesheetHelper = ({ grid, className }) => {
  const { styleSheet, styleSheetElement } = stylesheet.create();
  const update = (width) => {
    const widths = typeof width === 'string' ? width : `${width}px`;
    stylesheet.updateProperties(
      window,
      styleSheet,
      className,
      width
        ? { width: widths, minWidth: widths, maxWidth: widths }
        : { width: null, minWidth: null, maxWidth: null },
    );
    const { hasScroll } = grid.getState();
    const headerWidth = `${width + (hasScroll ? SCROLLBAR_WIDTH : 0)}px`;
    stylesheet.updateProperties(
      window,
      styleSheet,
      `${className}-header`,
      width
        ? { width: headerWidth, minWidth: headerWidth, maxWidth: headerWidth }
        : { width: null, minWidth: null, maxWidth: null },
    );
  };
  const remove = () => styleSheetElement.remove();
  const getRowClassName = () => className;
  return {
    update,
    remove,
    getRowClassName,
  };
};

const getColumnClassName = ({ globalId, column, isHeader = false, getId }) =>
  `column-${globalId}-${getId(column)}${isHeader ? '-header' : ''}`;

function setWidth({ styleSheet, className, width, minWidth }) {
  let widths;
  if (typeof width === 'string') widths = width;
  if (typeof width === 'number') widths = `${width}px`;

  let minWidths;
  if (typeof minWidth === 'string') minWidths = minWidth;
  if (typeof minWidth === 'number') minWidths = `${minWidth}px`;

  stylesheet.updateProperties(window, styleSheet, className, {
    width: `${widths || minWidths}`,
    minWidth: `${widths || minWidths}`,
    // minWidth: `${minWidths || widths}`,
    maxWidth: `${widths || minWidths}`,
  });
}

function updateStyle({ styleSheet, className, style }) {
  stylesheet.updateProperties(window, styleSheet, className, style);
}

function columnSizesHelperImpl({
  globalId,
  getId = ({ property }) => getColumnNameFromProperty(property),
}) {
  const { styleSheetElement, styleSheet } = stylesheet.create();

  const updateBodyHeaderWidth = ({
    className,
    headerClassName,
    width,
    minWidth,
  }) => {
    setWidth({
      styleSheet,
      className,
      width,
      minWidth,
    });
    setWidth({
      styleSheet,
      className: headerClassName,
      width,
      minWidth,
      isHeader: true,
    });
  };

  return {
    decorateColumns: (columns, grid) => {
      const {
        props: { minColumnWidth },
      } = grid.getInstance();
      return columns.map((column) => {
        const cellClassName = getColumnClassName({ globalId, getId, column });
        const headerClassName = `${cellClassName}-header`;

        set(column, 'header.props.className', headerClassName);
        set(column, 'cell.props.className', cellClassName);

        column.updateBodyHeaderWidth = (width, minWidth) => {
          updateBodyHeaderWidth({
            className: cellClassName,
            headerClassName,
            width,
            minWidth,
          });
        };
        column.updateWidth = (width, minWidth) => {
          setWidth({
            styleSheet,
            className: cellClassName,
            width,
            minWidth,
          });
        };
        column.updateStyle = (style) =>
          updateStyle({
            styleSheet,
            className: cellClassName,
            style,
          });

        column.headerHookEffects = [
          () => {
            useEffect(() => {
              const { resizedColumns } = grid.getInstance();
              const resizedWidth = resizedColumns.current[column.uuid];
              updateBodyHeaderWidth({
                className: cellClassName,
                headerClassName,
                width: resizedWidth || column.width || minColumnWidth,
                minWidth:
                  resizedWidth ||
                  column.minWidth ||
                  minColumnWidth ||
                  column.width,
              });
            }, [column.uuid]);
          },
        ];

        return column;
      });
    },
    getColumnCellClassName(column) {
      return getColumnClassName({ globalId, getId, column });
    },
    getColumnHeaderClassName(column) {
      return `${getColumnClassName({ globalId, getId, column })}-header`;
    },
    cleanup() {
      styleSheetElement.remove();
    },
  };
}

export function useStylesheetHelpers(grid) {
  const {
    props: { bindColumnsSizeTo, bindRowSizeTo },
  } = grid;

  const [columnsStylesheet] = useState(() =>
    columnSizesHelperImpl({
      globalId: bindColumnsSizeTo || grid.uuid,
      getId: ({ uuid }) => getColumnNameFromProperty(uuid),
    }),
  );
  const rowClassName = `row-${bindRowSizeTo || grid.uuid}`;
  const [rowsStylesheet] = useState(() =>
    RowStylesheetHelper({ grid, className: rowClassName }),
  );

  return { columnsStylesheet, rowsStylesheet, rowClassName };
}
