export function getNumberAndUnit(numberStrWithUnit) {
  const [number, unit] = String(numberStrWithUnit).match(
    /[a-z]+|[(/^\-?\d*.\d+|\d+),?]+/gi,
  );
  return { number, unit };
}

export function op(operator, n1, n2) {
  const { number, unit } = getNumberAndUnit(n1);
  const { number: number2, unit: unit2 } = getNumberAndUnit(n2);
  switch (operator) {
    case '*':
      return Number(number) * Number(number2) + (unit || unit2);
    case '+':
      return Number(number) + Number(number2) + (unit || unit2);
    case '-':
      return Number(number) - Number(number2) + (unit || unit2);
    case '/':
      return Number(number) / Number(number2) + (unit || unit2);
    default:
      return Number(number) + Number(number2) + (unit || unit2);
  }
}
