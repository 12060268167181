/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M13 17c0 .6-.4 1-1 1s-1-.4-1-1 .4-1 1-1 1 .4 1 1zM11 6.5l.5 7.5h1l.5-7.5c0-.3-.2-.5-.5-.5h-.9c-.4 0-.6.2-.6.5zM15.7 3H8.3L3 8.3v7.5L8.3 21h7.5l5.3-5.3V8.3L15.7 3m.4-1L22 7.9v8.3L16.1 22H7.9L2 16.1V7.9L7.9 2h8.2z',
  ],
  height: 24,
  width: 24,
});
