const basicAction = ({ textEllipsis, textAlignment, wordBreak }) => {
  const cssModifier = [];
  if (textEllipsis) cssModifier.push('text-overflow-ellipsis');
  if (wordBreak) cssModifier.push('text-overflow-word-break');
  if (['left', 'right', 'center', 'justify'].indexOf(textAlignment) > -1)
    cssModifier.push(`text-align-${textAlignment}`);
  return cssModifier;
};

const instance = [basicAction];
const pipe = () => ({
  getInstance() {
    return instance;
  },
  add(customModifier) {
    instance.push(customModifier);
  },
  execute(props) {
    return instance.reduce((temp, action) => [...temp, ...action(props)], []);
  },
});

export default pipe;
