import useDerivedStateFromProps from '@elliemae/ds-utilities/hooks/useDerivedStateFromProps';
import { parsePerPageNumber } from './helper';

export function usePaginationState(grid) {
  const {
    minPerPage = 0,
    maxPerPage = 50,
    onPerPageChange = () => null,
    onPageChange = () => null,
    pagination: paginationProp = {
      page: 1,
      perPage: 10,
    },
    groupedRows,
  } = grid.props;
  const { composedRows, rows } = grid.getInstance();
  const [pagination, setPagination] = useDerivedStateFromProps(paginationProp);

  const onPerPage = perPageNumber => {
    const parsedPerPageNumber = parsePerPageNumber(
      perPageNumber,
      maxPerPage,
      minPerPage,
    );
    const actualNumber = parsedPerPageNumber
      ? parseInt(parsedPerPageNumber, 10)
      : 0;

    setPagination(prevPagination => {
      onPerPageChange(actualNumber);
      return {
        page: prevPagination.page,
        perPage: actualNumber,
      };
    });
  };

  const onPageSelect = page => {
    const length = groupedRows ? composedRows.length : rows.length;
    setPagination(prevPagination => {
      const pages = Math.ceil(length / prevPagination.perPage);
      onPageChange(page);
      return {
        ...prevPagination,
        page: Math.min(Math.max(page, 1), pages),
      };
    });
  };

  return {
    state: {
      pagination,
    },
    actions: {
      onPerPage,
      onPageSelect,
    },
  };
}
