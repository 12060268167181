/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useMemo, useRef } from 'react';
import { get } from 'lodash';
import FocusGridManager from '@elliemae/ds-shared/FocusGroup/FocusGrid';
import { runAll } from '@elliemae/ds-utilities/utils';
import { mergeRefs } from '@elliemae/ds-utilities/system';
import createInstancePlugin from '@elliemae/ds-shared/createDataInstance/createInstancePlugin';
import VirtualizedBody from './VirtualizedBody';

export const VirtualizationPlugin = createInstancePlugin('virtualization', {
  decorateRenderers(renderers, grid) {
    const BodyWrapper = renderers.body.wrapper;
    // eslint-disable-next-line react/display-name
    renderers.body.wrapper = useMemo(
      () => (props) => {
        const { listProps = {}, rows } = props;
        const {
          hotKeys,
          props: {
            autoHeight,
            autoScrollToId,
            expandable,
            overscanCount,
            rowSize,
          },
          refs: { innerBody },
          setHasScroll,
        } = grid.getInstance();

        const listRef = useRef();
        const nextListProps = {
          ...listProps,
          ref: mergeRefs(listRef, listProps.ref),
        };
        return (
          <FocusGridManager
            hotKeys={hotKeys}
            keyBindings={({ defaultBindings }) => ({
              ...defaultBindings,
              // todo: this is kind of hacky, since it's virtualized and there's
              //  the possibility that the firsts or lasts rows aren't rendered
              Home: runAll((e) => {
                if (e.ctrlKey) {
                  listRef.current.scrollToItem(0);
                  setTimeout(() => defaultBindings.Home(e), 10);
                } else {
                  defaultBindings.Home(e);
                }
              }),
              End: runAll((e) => {
                if (e.ctrlKey) {
                  listRef.current.scrollToItem(rows.length - 1);
                  setTimeout(() => defaultBindings.End(e), 10);
                } else {
                  defaultBindings.End(e);
                }
              }),
            })}
            shouldRefocus={false}
          >
            <VirtualizedBody
              {...props}
              autoScrollToId={autoScrollToId}
              autoHeight={autoHeight}
              component={BodyWrapper}
              expandable={expandable}
              innerBody={innerBody}
              listProps={nextListProps}
              overscanCount={overscanCount}
              rowSize={rowSize}
              setHasScroll={setHasScroll}
            />
          </FocusGridManager>
        );
      },
      [get(grid.getInstance(), 'rows.length')],
    );
    return renderers;
  },
});
