import React, { useRef, useState, useEffect } from 'react';
import { PropTypes, describe } from 'react-desc';
import useFocusGroupItem from '@elliemae/ds-shared/FocusGroup/useFocusGroupItem';
import DSTooltip from '../Tooltip';
import { Pill } from './Pill';

function OverflowPill({ containerProps = {}, label = '', options = [] }) {
  const ref = useRef(null);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [tooltipText, setTooltipText] = useState('');

  useEffect(() => {
    let text = '';
    options.forEach((option) => {
      if (!tooltipText || option === options[0]) {
        text = `${option.label}`;
      } else {
        text = text.concat(`, ${option.label}`);
      }
    });
    setTooltipText(text);
  }, [options]);

  useFocusGroupItem(ref);

  return (
    <DSTooltip
      isOpen={tooltipIsOpen}
      title={tooltipText}
      zIndex={11}
      triggerComponent={
        <Pill
          className="overflow-pill"
          containerProps={containerProps}
          innerRef={ref}
          label={label}
          tabIndex={-1}
          onMouseEnter={() => setTooltipIsOpen(true)}
          onMouseLeave={() => setTooltipIsOpen(false)}
        />
      }
    />
  );
}

const props = {
  /** props to inject to pill wrapper */
  containerProps: PropTypes.object.description(
    'props to inject to pill wrapper',
  ),
  /** pill text label */
  label: PropTypes.string.isRequired.description('pill text label'),
  /** overflow pills options */
  options: PropTypes.array.description('overflow pills options'),
};

OverflowPill.propTypes = props;
const DSOverflowPillWithSchema = describe(OverflowPill);
DSOverflowPillWithSchema.propTypes = props;

export { OverflowPill, DSOverflowPillWithSchema };
