import React, { useCallback, useRef } from 'react';

export const useResizeHandle = ({
  onDragStart,
  onDrag,
  onDragEnd,
  minWidth = 10,
  isLast = false,
}) => {
  const startX = useRef();
  const startWidth = useRef();
  const containerRef = useRef();

  const triggerMove = (handler, event) => {
    const increasing = startX.current - event.clientX < 0;
    handler(
      Math.max(startWidth.current - startX.current + event.clientX, minWidth),
      increasing,
      {},
    );
  };

  const onMouseMove = event => {
    event.stopPropagation();
    event.preventDefault();

    triggerMove(onDrag, event);
  };

  const onMouseUp = event => {
    event.stopPropagation();
    event.preventDefault();

    triggerMove(onDragEnd, event);

    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  };

  const onMouseDown = event => {
    event.stopPropagation();
    event.preventDefault();

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    startX.current = event.clientX;
    startWidth.current = containerRef.current.offsetWidth;

    triggerMove(onDragStart, event);
  };

  const HandleComponent = ({ className = 'resize-handle' }) => (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={className} onMouseDown={onMouseDown} />
  );

  return {
    containerRef,
    Handle: HandleComponent,
  };
};
