import React from 'react';
import { PropTypes, describe } from 'react-desc';
import CheckableGroup from '@elliemae/ds-shared/CheckableGroup';

function CheckboxGroup(props) {
  return <CheckableGroup {...props} multi />;
}

const props = {
  /* The item (if multi false) or items (if multi true) selected */
  active: PropTypes.arrayOf(PropTypes.string).description(
    'The item (if multi false) or items (if multi true) selected',
  ),
  /* Whether is multi select or not */
  multi: PropTypes.bool.description('Whether is multi select or not'),
  /* Handler when a user checks an item */
  onCheck: PropTypes.func.description('Handler when a user checks an item'),
};

CheckboxGroup.propTypes = props;

const DSMenuCheckboxGroupWithSchema = describe(CheckboxGroup);
DSMenuCheckboxGroupWithSchema.propTypes = props;

export default CheckboxGroup;

export { DSMenuCheckboxGroupWithSchema };
