/* eslint-disable no-unused-vars */
// import PropTypes from 'prop-types';
import { PropTypes } from 'react-desc';
import {
  BUTTON_TYPE,
  sizes,
  buttonTypes,
  buttonVariants,
  BUTTON_SIZE,
  BUTTON_VARIANT,
} from '../utils/prop-types';

export const btnPropTypes = {
  /**
   * Tab index
   */
  tabIndex: PropTypes.number.description('Tab index'),
  /**
   * Additional className
   */
  className: PropTypes.string.description('Additional className'),
  /**
   * aria disabled
   */
  'aria-disabled': PropTypes.string.description('aria disabled'),
  /**
   * Html tag. default 'button'
   */
  as: PropTypes.string.description('Html tag').defaultValue('button'),
  /**
   * Whether the button is disabled or not
   */
  disabled: PropTypes.bool.description('Whether the button is disabled or not'),
  /**
   * Make the content of the button fit the button container or not
   */
  fluidWidth: PropTypes.bool.description(
    'Make the content of the button fit the button container or not',
  ),
  /**
   * Displayable label inside the button
   */
  labelText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).description('Displayable label inside the button'),
  /**
   * Put an icon inside the button
   */
  icon: PropTypes.element.description('Put an icon inside the button'),
  /**
   * Put an icon on the left side of the button
   */
  leftIcon: PropTypes.element.description(
    'Put an icon on the left side of the button',
  ),
  /**
   * ['primary', 'secondary', 'text', 'link']
   */
  buttonType: PropTypes.oneOf(buttonTypes).description('type of the button'),
  /**
   * ['s', 'm', 'l']
   */
  size: PropTypes.oneOf(sizes).description('size of the button'),
  /**
   * submit or button
   */
  type: PropTypes.oneOf(['button', 'submit']).description('type of the button'),
  /**
   * [
   *  'variant-default',
   *  'variant-focus',
   *  'variant-active',
   *  'variant-disabled',
   * ]
   */
  variant: PropTypes.oneOf(buttonVariants).description('variant of the button'),
  /**
   * Refence
   */
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).description('reference to the component'),
  /**
   * Onblur callback
   */
  onBlur: PropTypes.func.description(
    'Allows a function that is triggered once the button is blur',
  ),
  /**
   * Apply intent color: ['success', 'warning', 'danger', 'info']
   * Only valid with buttonType="text"
   */
  intent: PropTypes.oneOf(['success', 'warning', 'danger', 'info']).description(
    'Apply intent color. Only valid with buttonType="text"',
  ),
  /**
   * additional props for the container
   */
  containerProps: PropTypes.object.description(
    'additional props for the container',
  ),
  /**
   * Allows a function that is triggered once the button is clicked
   */
  onClick: PropTypes.func.description(
    'Allows a function that is triggered once the button is clicked',
  ),
  /**
   * Allows a function that is triggered once the button is key pressed
   */
  onKeyPress: PropTypes.func.description(
    'Allows a function that is triggered once the button is key pressed',
  ),
};
