/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M10 8H7v1h2v6H7v1h5v-1h-2zM9 5c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z',
    'M9.5 1C4.253 1 0 5.253 0 10.5S4.253 20 9.5 20s9.5-4.253 9.5-9.5S14.747 1 9.5 1zm0 18C4.813 19 1 15.187 1 10.5S4.813 2 9.5 2c4.687 0 8.5 3.813 8.5 8.5S14.187 19 9.5 19z',
  ],
  height: 20,
  width: 20,
});
