/* eslint-disable react/prop-types */
/* eslint-disable complexity */
import React from 'react';
import onClickHandlerForNonInteractiveElements from '@elliemae/ds-utilities/onClickHandlerForNonInteractiveElements';
import { convertPropToCssClassName } from '@elliemae/ds-classnames';
import DSTooltip from '../../Tooltip';

const DSToggleRender = ({
  containerProps,
  id,
  className = '',
  style = {},
  hasError = false,
  hide,
  readOnly = false,
  disabled = false,
  checked = false,
  onBlur = () => null,
  onFocus = () => null,
  onChange = () => null,
  onMouseDown = () => null,
  onMouseEnter = () => null,
  onMouseLeave = () => null,
  onTouchCancel = () => null,
  onTouchEnd = () => null,
  onTouchMove = () => null,
  onTouchStart = () => null,
  labelOn = 'ON',
  labelOff = 'OFF',
  dragStyle = {},
  containerRef = null,
  handRef = null,
  name = '',
  value = '',
  size = 's',
  defaultState,
}) => {
  const {
    cssClassName,
    classNameBlock,
    classNameElement,
  } = convertPropToCssClassName('form-element-toggle', className, {
    hasError,
    readOnly,
    disabled,
    checked,
    id,
    size,
  });
  const allowChange = disabled || readOnly;

  let label = checked ? (
    <div className={classNameElement('icon-on')}>{labelOn}</div>
  ) : (
    <div className={classNameElement('icon-off')}>{labelOff}</div>
  );

  if (readOnly) {
    label = (
      <DSTooltip
        interactionType="hover"
        title="Read Only"
        triggerComponent={label}
      />
    );
  }

  return (
    <div
      data-testid="em-ds-toggle"
      {...containerProps}
      aria-disabled={disabled}
      defaultChecked={checked}
      defaultValue={value}
      {...onClickHandlerForNonInteractiveElements(
        allowChange ? null : onChange,
      )}
      ref={containerRef}
      className={`${cssClassName} ${
        defaultState ? 'default-state-checked' : ''
      }${hide ? ' ds-form-element-toggle-hide' : ''}`}
      onBlur={onBlur}
      onFocus={onFocus}
      // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
      aria-checked={checked}
      role="checkbox"
      tabIndex={0}
    >
      <div className={classNameBlock('box')}>{label}</div>
      <div
        ref={handRef}
        aria-checked={checked}
        aria-disabled={disabled}
        className={`${classNameElement('input')} ${
          defaultState ? 'default-state-checked' : ''
        }`}
        onMouseDown={allowChange ? null : onMouseDown}
        onMouseEnter={allowChange ? null : onMouseEnter}
        onMouseLeave={allowChange ? null : onMouseLeave}
        onTouchCancel={allowChange ? null : onTouchCancel}
        onTouchEnd={allowChange ? null : onTouchEnd}
        onTouchMove={allowChange ? null : onTouchMove}
        onTouchStart={allowChange ? null : onTouchStart}
        style={{
          ...style,
          ...dragStyle,
        }}
      />
      <input
        className={classNameElement('hidden-checkbox')}
        defaultChecked={checked}
        defaultValue={value}
        name={name}
        type="checkbox"
      />
    </div>
  );
};

export default DSToggleRender;
