/* eslint-disable react/display-name */
import React, { Component } from 'react';
import { aggregatedClasses } from '@elliemae/ds-classnames';

const blockName = 'input-addon-wrapper';

const InputWrapper = aggregatedClasses('div')(blockName);
const InputAddon = aggregatedClasses('span')(
  blockName,
  'addon',
  ({ left, right, variant, hasError }) => ({
    left,
    right,
    error: hasError,
    [variant]: variant,
  }),
);

export default class InputAddonWrapper extends Component {
  constructor(props) {
    super(props);
    this.renderAddonComponent = this.renderAddonComponent.bind(this);
  }
  static defaultProps = {
    rightComponents: [],
    leftComponents: [],
    containerProps: {},
  };

  getLeftAddon(components) {
    return this.renderAddonComponent(true, components);
  }

  getRightAddon(components) {
    return this.renderAddonComponent(false, components);
  }

  renderAddonComponent(left = true, components) {
    if (!components) return null;
    if (Array.isArray(components)) {
      if (!components.filter(comp => comp).length) return null;
    }
    const { containerProps, hasError } = this.props;
    const classProps = left ? { left: true } : { right: true };

    return (
      <InputAddon classProps={classProps} hasError={hasError} {...containerProps}>
        {components.map(
          (component, index) =>
            component &&
            React.cloneElement(component, { ...component.props, key: index }),
        )}
      </InputAddon>
    );
  }

  render() {
    const {
      className,
      innerRef,
      rightComponents,
      leftComponents,
      children,
      variant,
      containerProps,
    } = this.props;

    const InputComponent = React.Children.only(children);

    const { hasError, readOnly, disabled } = InputComponent.props;

    const rightAddon = this.getRightAddon([...rightComponents]);
    const leftAddon = this.getLeftAddon([...leftComponents]);
    return (
      <InputWrapper
        className={className}
        classProps={{
          hasError,
          readOnly,
          disabled,
          variant,
        }}
        innerRef={innerRef}
        {...containerProps}
      >
        {leftAddon}
        {InputComponent}
        {rightAddon}
      </InputWrapper>
    );
  }
}
