import { useMemo, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  buildFilterArray,
  getFilterValue,
  parseFiltersToQuery,
} from './helper';

const noop = () => null;

export function useFilterableState(grid) {
  const {
    filters: filtersProp,
    onAddFilter = noop,
    onRemoveFilter = noop,
    onRemoveAllFilters = noop,
    onFiltersChange = noop,
  } = grid.props;

  const [filters, setFilters] = useState(filtersProp || []);

  useEffect(() => {
    if (filtersProp && filtersProp !== filters) {
      setFilters(filtersProp);
    }
  }, [filtersProp]);

  const queryFromFilters = useMemo(() => parseFiltersToQuery(filters || []), [
    filters,
  ]);

  const handleFiltersChange = (nextFilters) => {
    const {
      refs: { body },
    } = grid.getInstance();
    body.current.scrollTop = 0;
    setFilters(nextFilters);
    grid.observeRows((rows) =>
      onFiltersChange(nextFilters, queryFromFilters, rows),
    );
  };

  const addFilter = (filter, column) => {
    let hasBeenCalled = false;
    const trimmedFilter = {
      ...filter,
      value: String(filter.value).trim(),
    };
    /* eslint-disable indent */
    const parsedFilter = !filter.group
      ? {
          ...trimmedFilter,
          value: String(filter.value).trim(),
          group: 'all',
          label: 'All',
          id: uuidv4(),
        }
      : {
          ...trimmedFilter,
          value: String(filter.value).trim(),
          id: uuidv4(),
          label: (column && column.filterLabel) || trimmedFilter.label,
          originalProperty: column.originalProperty || column.property,
          customValue: getFilterValue(trimmedFilter, column),
        };
    /* eslint-enable indent */
    setFilters((prevFilters) => {
      // This was setted for an issue that was recalling this function on the handleFiltersChange
      if (hasBeenCalled) {
        return;
      }
      hasBeenCalled = true;

      const nextFilters = buildFilterArray(parsedFilter, prevFilters);
      handleFiltersChange(nextFilters, true);
      onAddFilter(parsedFilter, nextFilters, column);
    });
  };

  const removeFilter = ({ group, pill, nextFilters }) => {
    onRemoveFilter(group, pill.value, nextFilters);
    handleFiltersChange(nextFilters);
  };

  const removeAllFilters = () => {
    onRemoveAllFilters();
    handleFiltersChange([]);
  };
  return {
    actions: {
      addFilter,
      removeFilter,
      removeAllFilters,
    },
    state: {
      query: queryFromFilters,
      filters,
    },
  };
}
