import React from 'react';
import { aggregatedClasses } from '@elliemae/ds-classnames';

const blockName = 'form-item';

const FeedbackSpan = aggregatedClasses('span')(blockName, 'feedback');

const Feedback = ({ text, hasError }) => {
  if (!text) return null;
  return <FeedbackSpan classProps={hasError}>{text}</FeedbackSpan>;
};

export default Feedback;
