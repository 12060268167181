import compare from 'comparejs';

export default op =>
  ({
    range: (rowValue, { start, end }) => start <= rowValue && end >= rowValue,
    equal: (value1, value2) => compare.id(value1, value2),
    greaterThan: compare.gt,
    lessThan: compare.lt,
    contains: (rowValue, value) =>
      String(rowValue)
        .toLowerCase()
        .indexOf(String(value).toLowerCase()) !== -1,
  }[op] || compare.id);
