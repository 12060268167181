import React from 'react';
import { describe, PropTypes } from 'react-desc';
import MenuItemCheckable from './MenuItemCheckable';

function MenuItemRadio(props) {
  return <MenuItemCheckable {...props} role="menuitemradio" />;
}

const props = {
  /** Whether the item is checked or not */
  checked: PropTypes.bool.description('Whether the item is checked or not'),
  /** A custom item when the item is checked */
  checkIcon: PropTypes.element.description(
    'A custom item when the item is checked',
  ),
};

MenuItemRadio.propTypes = props;

const DSMenuItemRadioWithSchema = describe(MenuItemCheckable);
DSMenuItemRadioWithSchema.propTypes = props;

export default MenuItemRadio;

export { DSMenuItemRadioWithSchema };
