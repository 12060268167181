/* eslint-disable max-lines */
/* eslint-disable jest/valid-describe */
/* eslint-disable jest/valid-title */
import React, { useState, useMemo } from 'react';
import {
  aggregatedClasses,
  convertPropToCssClassName,
} from '@elliemae/ds-classnames';
import { cx } from '@elliemae/ds-utilities/utils';
import { describe } from 'react-desc';
import {
  BUTTON_TYPE,
  buttonTypes,
  buttonVariants,
  BUTTON_SIZE,
  BUTTON_VARIANT,
} from '../utils/prop-types';
import { btnPropTypes } from './propTypes';

const blockName = 'button';

const getContainer = (Element) =>
  aggregatedClasses(Element)(
    blockName,
    null,
    ({ iconOnly, variant }) => ({
      'icon-only': iconOnly,
      [variant]: variant,
    }),
    {
      propsToRemoveFromFinalElement: ['cx'],
    },
  );

// eslint-disable-next-line complexity
const DSButton = ({
  innerRef,
  disabled = false,
  buttonType = BUTTON_TYPE.PRIMARY,
  fluidWidth = false,
  size = BUTTON_SIZE.M,
  labelText = '',
  icon = null,
  onBlur = () => null,
  onClick = () => null,
  onKeyPress = () => null,
  leftIcon = null,
  tabIndex = 0,
  variant = BUTTON_VARIANT.DEFAULT,
  intent,
  containerProps = {},
  className,
  as: Component = 'button',
  type = 'button',
  ...rest
}) => {
  const [pressed, setPressed] = useState(false);
  const Button = useMemo(() => getContainer(Component), [Component]);
  const intentBlockName = `${blockName}${intent ? `-${intent}` : ''}`;
  const { cssClassName: intentClassName } = convertPropToCssClassName(
    intentBlockName,
  );
  return (
    <Button
      data-testid={containerProps['data-testid'] || 'button-wrapper'}
      {...rest}
      {...containerProps}
      aria-disabled={
        rest['aria-disabled'] !== undefined ? rest['aria-disabled'] : disabled
      }
      aria-label={rest['aria-label'] || labelText || 'button'}
      aria-pressed={pressed}
      className={cx(intentClassName, className)}
      classProps={{
        disabled,
        buttonType,
        size,
        fluidWidth,
        iconOnly: !labelText && (icon || leftIcon),
        variant: disabled ? BUTTON_VARIANT.DISABLED : variant,
      }}
      disabled={disabled} // https://jira.elliemae.io/browse/PUI-1215
      innerRef={innerRef}
      onBlur={(e) => {
        if (disabled) return;
        setPressed(false);
        onBlur(e);
      }}
      onClick={(e) => {
        if (disabled) return;
        setPressed(true);
        onClick(e);
      }}
      onKeyPress={(e) => {
        if (disabled) return;
        e.preventDefault();
        if (e.key === 'Enter') {
          setPressed(true);
          onClick(e);
        }
        onKeyPress(e);
      }}
      tabIndex={tabIndex}
      type={type}
    >
      {leftIcon}
      {labelText && (
        <span
          className={`label-wrapper ${
            icon || leftIcon ? 'with-icon' : 'no-icon'
          }`}
          data-testid="button-label"
          role="presentation"
        >
          <span>{labelText}</span>
        </span>
      )}
      {icon}
    </Button>
  );
};

DSButton.propTypes = btnPropTypes;

const DSButtonWithSchema = describe(DSButton).description('A button');
DSButtonWithSchema.propTypes = btnPropTypes;

export {
  BUTTON_TYPE,
  BUTTON_SIZE,
  BUTTON_VARIANT,
  buttonVariants,
  buttonTypes,
  DSButtonWithSchema,
};

export default DSButton;
