/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { FixedSizeList as List } from 'react-window';
import memoize from 'memoize-one';
import CustomOption from './CustomOption';
import CustomOptionMulti from './CustomOptionMulti';
import MultiSelectHeader from './MultiSelectHeader';
import { useMenuListHeight } from './useMenuListHeight';

const createItemData = memoize((items, options) => {
  if (items?.findIndex) {
    const index = items.findIndex((item) => item.props.isFocused);
    if (index > -1) return { items, options };
    return {
      items: [
        { ...items[0], props: { ...items[0].props, isFocused: true } },
        ...items.slice(1),
      ],
      options,
    };
  }
  return { items, options };
});

const scrollTo = (listRef, type) => {
  if (listRef && listRef.current && listRef.current.props) {
    const { items } = listRef.current.props.itemData;
    const index = items.findIndex((item) => item.props.isFocused);
    listRef.current.scrollToItem(index !== -1 ? index : 0, type);
  }
};

const MenuList = (props) => {
  const { children: options, selectProps, isMulti } = props;
  const {
    customMenuItemOptions,
    selectMeasure: {
      bounds: { bottom },
    },
  } = selectProps;
  const listRef = useRef(null);
  const [inside, setInside] = useState(false);
  const remainingHeight = window.innerHeight - bottom - 10;

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  const handleKeyDown = () => setInside(false);

  const { itemSize = 35, maxOptions } = customMenuItemOptions;
  const itemData = createItemData(options, customMenuItemOptions);
  const RenderOption = !isMulti ? CustomOption : CustomOptionMulti;

  const height = useMenuListHeight(
    options,
    maxOptions,
    itemSize,
    remainingHeight,
  );

  if (listRef && listRef.current && !inside) {
    scrollTo(listRef); // use autoScroll
  }

  useEffect(() => {
    // fix this that has a bug where the groups is being focused
    scrollTo(listRef);
  }, [options]);

  if (!options.length) return <components.MenuList {...props} />;

  return (
    <div data-testid="combobox-menu-list" onMouseLeave={() => setInside(false)}>
      {isMulti && <MultiSelectHeader {...props} />}
      <List
        ref={(r) => {
          listRef.current = r;
          if (!inside) {
            scrollTo(listRef);
          }
        }}
        className={`combobox-menu-list ${
          isMulti && 'combobox-menu-list-is-multi'
        }`}
        height={height}
        itemCount={options.length}
        itemData={itemData}
        itemSize={itemSize}
        onScroll={() => setInside(true)}
        useIsScrolling
      >
        {RenderOption}
      </List>
    </div>
  );
};

MenuList.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  selectProps: PropTypes.object,
  isMulti: PropTypes.bool,
};

export default MenuList;
