/* eslint-disable max-lines */
import { useRef, useState, useLayoutEffect } from 'react';
import { getColumnWidthSize, getPercentageValue } from './utils';

export default function useColumnSizeService(instance) {
  const columns = useRef({});
  const [expandableGridDetailWidth, setExpandableGridDetailWidth] = useState(0);
  const [expandableColumnWidth, setExpandableColumnWidth] = useState(0);
  const expandedToContentColumns = useRef({});
  const previousTotalWidth = useRef(0);
  const initialUsedSpace = useRef({
    space: 0,
    amount: 0,
    percentage: 0,
  });

  const increaseUsedSpace = (space, columnUuid) => {
    if (typeof space === 'number') {
      initialUsedSpace.current.space += space;
    } else if (space && space.endsWith('%')) {
      initialUsedSpace.current.percentage += Number(space.slice(0, -1));
    }
    initialUsedSpace.current.amount += 1;
    initialUsedSpace.current[columnUuid] = true;
  };

  // eslint-disable-next-line no-return-assign
  const registerColumn = (column) => (node) =>
    (columns.current[column.uuid] = { ...column, node });

  const unregisterColumn = (column) => {
    delete columns.current[column.property];
  };

  const setActualColumnsSizesFixed = () => {
    const columnsArray = Object.values(columns.current);

    columnsArray.forEach((column) => {
      const columnOffsetWidth = column.node.offsetWidth;
      column.updateBodyHeaderWidth(
        `${columnOffsetWidth}px`,
        `${columnOffsetWidth}px`,
      );
    });
  };

  const updateMissingWidths = (totalWidth) => {
    if (totalWidth) {
      previousTotalWidth.current = totalWidth;
    }
    const gridWidth = previousTotalWidth.current - 4;
    const {
      props: { minColumnWidth, expandable },
      resizedColumns,
      decoratedColumns: columnDefinitions,
    } = instance.getInstance();
    if (!initialUsedSpace.current) return;
    const columnUuids = columnDefinitions.map((c) => c.uuid);
    const columnsArray = Object.values(columns.current).filter((c) =>
      columnUuids.includes(c.uuid),
    );
    // reset the initial space to the last columns
    initialUsedSpace.current = { space: 0, amount: 0, percentage: 0 };
    columnsArray.forEach((column) => {
      if (
        column.width &&
        column.width !== 'string' &&
        !initialUsedSpace[column.uuid]
      ) {
        increaseUsedSpace(
          getColumnWidthSize(column.width, minColumnWidth, column),
          column.uuid,
        );
      }
    });

    const nextColumnDict = {};
    // eslint-disable-next-line max-statements
    columnsArray.forEach((column) => {
      if (typeof column.width === 'number') {
        nextColumnDict[column.uuid] = column;
        return;
      }
      if (resizedColumns.current[column.uuid]) {
        nextColumnDict[column.uuid] = column;
        return;
      }
      const {
        space: usedSpace,
        amount: usedAmount,
        percentage: usedPercentage,
      } = initialUsedSpace.current;

      const percentValue = getPercentageValue(column.width);
      // check if is percentage value
      if (percentValue) {
        const pxs = (gridWidth * percentValue) / 100;
        const pxWidths = `${getColumnWidthSize(pxs, minColumnWidth, column)}px`;
        column.updateBodyHeaderWidth(pxWidths, pxWidths);
        columns.current[column.uuid].computedWidth = pxs;
        if (expandable && column.expandableColumn) {
          setExpandableColumnWidth(pxs);
        }
      } else {
        // otherwise the values are auto calculated from the total size
        const columnFixWidth =
          (gridWidth * (1 - usedPercentage / 100) - usedSpace) /
          (columnsArray.length - usedAmount);
        const percentWidth = (columnFixWidth / gridWidth) * 100;
        const pxs = (gridWidth * percentWidth) / 100;
        const actualWidthAfterChecks = getColumnWidthSize(
          pxs,
          minColumnWidth,
          column,
        );
        column.updateBodyHeaderWidth(
          `${actualWidthAfterChecks}px`,
          column.minWidth || minColumnWidth
            ? `${column.minWidth || minColumnWidth}px`
            : `${pxs}px`,
        );
        resizedColumns.current[column.uuid] = actualWidthAfterChecks;
        columns.current[column.uuid].computedWidth = actualWidthAfterChecks;
      }
      nextColumnDict[column.uuid] = column;
    });
    columns.current = nextColumnDict;
  };

  useLayoutEffect(() => {
    const totalWidth = computeColumnsTotalSize();
    setExpandableGridDetailWidth(totalWidth - expandableColumnWidth);
  }, [expandableColumnWidth]);

  // todo: make this incremental
  const computeColumnsTotalSize = () => {
    const columnsArray = Object.values(columns.current);
    return columnsArray.reduce(
      (totalSize, column) => totalSize + column.node.offsetWidth,
      0,
    );
  };

  return {
    columns: columns.current,
    expandedToContentColumns: expandedToContentColumns.current,
    initialUsedSpace: initialUsedSpace.current,
    increaseUsedSpace,
    registerColumn,
    unregisterColumn,
    updateMissingWidths,
    computeColumnsTotalSize,
    setActualColumnsSizesFixed,
    expandableGridDetailWidth,
    expandableColumnWidth,
  };
}
