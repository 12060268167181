/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M18.869 19.162l-5.943-6.484c1.339-1.401 2.075-3.233 2.075-5.178 0-2.003-.78-3.887-2.197-5.303S9.504 0 7.501 0 3.614.78 2.198 2.197.001 5.497.001 7.5s.78 3.887 2.197 5.303S5.498 15 7.501 15c1.726 0 3.362-.579 4.688-1.645l5.943 6.483a.497.497 0 0 0 .369.162.4997.4997 0 0 0 .369-.837l-.001-.001zM1 7.5C1 3.916 3.916 1 7.5 1S14 3.916 14 7.5 11.084 14 7.5 14 1 11.084 1 7.5z',
  ],
  height: 20,
  width: 20,
});
