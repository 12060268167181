/* eslint-disable max-lines */
import React from 'react';
import CloseIcon from '@elliemae/ds-icons/Close';
import DSButton from '@elliemae/ds-basic/Button';
import { ModalHeader, ModalHeaderTopRight } from '../blocks';
import { Title } from './Title';

export const FormHeader = ({ showClose, modalTitle, modalType, onClose }) => (
  <ModalHeader classProps={{ type: 'selection' }}>
    <Title modalTitle={modalTitle} modalType={modalType} />
    <ModalHeaderTopRight classProps={{ type: 'form' }}>
      {showClose && (
        <DSButton
          buttonType="link"
          containerProps={{ 'data-testid': 'modal-close-btn' }}
          icon={<CloseIcon />}
          onClick={onClose}
        />
      )}
    </ModalHeaderTopRight>
  </ModalHeader>
);

export default FormHeader;
