import { DndRowsPlugin } from '../plugins/row-dnd/DndRowsPlugin';
import { DndColumnsPlugin } from '../plugins/column-dnd/DndColumnsPlugin';
import { InfiniteScrollPlugin } from '../plugins/infinite-scrolling/InfiniteScrollPlugin';
import { EditablePlugin } from '../plugins/editable/EditablePlugin';
import { SortablePlugin } from '../plugins/sortable/SortablePlugin';
import { SelectablePlugin } from '../plugins/selectable/SelectablePlugin';
import { FilterablePlugin } from '../plugins/filterable/FilterablePlugin';
import { ToolbarPlugin } from '../plugins/toolbar/ToolbarPlugin';
import { PaginationPlugin } from '../plugins/pagination/PaginationPlugin';
import { VirtualizationPlugin } from '../plugins/virtualization/VirtualizationPlugin';
import { ExpandablePlugin } from '../plugins/expandable-grid/ExpandablePlugin';
import { GroupingPlugin } from '../plugins/grouping-grid/GroupingPlugin';
import { GroupingByPlugin } from '../plugins/grouping-by/GroupingByPlugin';

const mapPropToPlugin = {
  dragAndDropColumns: DndColumnsPlugin,
  editable: EditablePlugin,
  selectable: SelectablePlugin,
  sortable: SortablePlugin,
  searchFilters: FilterablePlugin,
  renderToolbar: ToolbarPlugin,
  infiniteScrolling: InfiniteScrollPlugin,
  paginated: PaginationPlugin,
  virtualized: VirtualizationPlugin,
  expandable: ExpandablePlugin,
  groupedBy: GroupingByPlugin,
  groupedRows: GroupingPlugin,
  dragAndDropRows: DndRowsPlugin,
};

export default function getPluginsFromProps(props, omit = {}) {
  const plugins = [];

  Object.keys(mapPropToPlugin).forEach((key) => {
    if (props[key] && !omit[key]) plugins.push(mapPropToPlugin[key]);
  });
  return plugins;
}
