/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M9.496 12c-.828 0-1.5-.672-1.5-1.5S8.668 9 9.496 9s1.5.672 1.5 1.5-.672 1.5-1.5 1.5zM9.496 7c-.828 0-1.5-.672-1.5-1.5S8.668 4 9.496 4s1.5.672 1.5 1.5-.672 1.5-1.5 1.5zM9.496 17c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5 1.5.672 1.5 1.5-.672 1.5-1.5 1.5z',
  ],
  height: 20,
  width: 20,
});
