/**
 * onClickHandler: callback
 *
 * @param onClickHandler
 */
export default onClickHandler => ({
  role: 'button',
  tabIndex: '0',
  onClick: onClickHandler,
  onKeyPress: event => {
    event.stopPropagation();
    const { keycode, key } = event;
    if (key === 'Enter') onClickHandler(event);
    else if (keycode === 13) onClickHandler(event);
  },
});
