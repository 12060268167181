// eslint-disable-next-line max-statements
export default function getNextCellPosition({
  grid,
  currentRow = 0,
  currentCell = 0,
  directionY,
  directionX,
  shouldWrapCells,
  shouldWrapRows,
}) {
  let nextRow = currentRow + directionY;
  let nextCell = currentCell + directionX;
  const rowCount = grid.length;
  const isLeftRight = directionX !== 0;

  if (!rowCount) {
    return false;
  }

  const cellsLength = grid[0].length;

  // moving horizontally
  if (shouldWrapCells && isLeftRight) {
    // to left
    if (nextCell < 0) {
      nextCell = cellsLength - 1;
      nextRow -= 1;
    }

    if (nextCell >= cellsLength) {
      nextCell = 0;
      nextRow += 1;
    }
  }

  // moving vertically
  if (shouldWrapRows && !isLeftRight) {
    // to top
    if (nextRow < 0) {
      nextCell -= 1;
      nextRow = rowCount - 1;
      if (grid[nextRow] && nextCell >= 0 && !grid[nextRow][nextCell]) {
        nextRow -= 1;
      }
    } else if (nextRow >= rowCount || !grid[nextRow][nextCell]) {
      nextRow = 0;
      nextCell += 1;
    }
  }

  return {
    cellIndex: nextCell,
    rowIndex: nextRow,
  };
}
