import React from 'react';
import * as edit from 'react-edit';
import EditPencil from '@elliemae/ds-icons/EditPencil';
import { cx } from '@elliemae/ds-utilities/utils';
import { appendCellFormatter } from '@elliemae/ds-shared/useDataGrid/initColumnDefinition';
import getEditorComponent from './getEditorComponent';

const makeEditableConfig = grid =>
  edit.edit({
    isEditing: ({ columnIndex, rowData, column = {}, ...rest }) => {
      const {
        state: { editingRows },
        props: { rowKey },
      } = grid.getInstance();
      let isCustomEditing;

      if (typeof column.isEditing === 'function') {
        isCustomEditing = column.isEditing({
          columnIndex,
          rowData,
          column,
          ...rest,
        });
      }
      return (
        editingRows[rowData[rowKey]] === columnIndex ||
        column.alwaysEditing ||
        isCustomEditing
      );
    },
    onActivate: () => null,
    onValue: (...args) => grid.getInstance().actions.editValue(...args),
  });
const addEditableToCell = ({ column, config, handlers }, grid) => {
  column.cell.transforms.push(
    config(getEditorComponent(column.customEditor, handlers)),
  );
  column.cell.props.className = cx(
    column.cell.props.className,
    `editable${column.customEditor ? ' customEditor' : ''}`,
  );
  return appendCellFormatter(
    [
      (value, extraParams) => (
        <>
          <div
            onClick={e => {
              e.stopPropagation();
              grid.getInstance().actions.activateEdit(extraParams);
            }}
            onKeyUp={e => {
              e.stopPropagation();
              if (e.keyCode === 33) {
                grid.getInstance().actions.activateEdit(extraParams);
              }
            }}
            role="gridcell"
            tabIndex={0}
          >
            {value}
            <EditPencil className="edit-pencil" />
            <span className="edit-separator"> </span>
          </div>
        </>
      ),
    ],
    column,
  );
};

export { makeEditableConfig, addEditableToCell };
