/* eslint-disable complexity */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { describe, PropTypes } from 'react-desc';
import { convertPropToCssClassName } from '@elliemae/ds-classnames';
import DSTruncatedTooltipText from '../TruncatedTooltipText';

const {
  cssClassName,
  classNameBlock,
  classNameElement,
} = convertPropToCssClassName('indeterminate-progress-indicator');

function DSIndeterminateProgressIndicator({
  containerProps = {},
  title = '',
  complete = false,
  failed = false,
  processing = false,
  processingComplete = false,
  processingCompleteTitle = 'Complete',
  processingFail = false,
  processingFailTitle = 'Fail',
  animated = true,
  percent = 50,
}) {
  const [label, setLabel] = useState(title);
  useEffect(() => {
    if (
      (processingComplete || processingFail || processing) &&
      (complete || failed)
    ) {
      setLabel(complete ? processingCompleteTitle : processingFailTitle);
    }
  }, [failed, complete, processingComplete, processingFail, processing]);
  return (
    <div
      aria-busy={processing}
      aria-label={label}
      aria-live="polite"
      className={`${cssClassName} ${
        !complete && !failed && !processing ? classNameBlock('default') : ''
      } ${complete ? classNameBlock('complete') : ''}  ${
        // eslint-disable-next-line max-len
        failed ? classNameBlock('failed') : ''
      } ${processing ? classNameBlock('processing') : ''} ${
        animated && processing ? 'animated' : ''
      } 
         ${processingComplete ? classNameBlock('processing-complete') : ''} 
         ${processingFail ? classNameBlock('processing-fail') : ''} 
      `}
      role="alert"
      {...containerProps}
    >
      <DSTruncatedTooltipText
        className={classNameBlock('tooltip-text')}
        containerComponent={<div />}
        value={label}
      />
      <div className={`${classNameElement('bar')}`}>
        <div
          className={`${classNameElement('bar-indicator')}`}
          style={{
            width: `${
              !complete && !failed && !animated && processing ? percent : 100
            }%`,
          }}
        />
      </div>
    </div>
  );
}

const indeterminateProgressIndicatorProps = {
  containerProps: PropTypes.object.description(
    'Set of Properties attached to the main container',
  ),
  title: PropTypes.string.description('component s label'),
  processingCompleteTitle: PropTypes.string.description(
    'label to display when the process is completed',
  ),
  processingFailTitle: PropTypes.string.description(
    'label to display when the process has failed',
  ),
  complete: PropTypes.bool
    .description('Sets the process state to completed')
    .defaultValue(false),
  failed: PropTypes.bool
    .description('Sets the process state to failed')
    .defaultValue(false),
  processing: PropTypes.bool
    .description('Sets the process state to processing')
    .defaultValue(false),
  processingComplete: PropTypes.bool
    .description('Sets the process state to processing and then completed')
    .defaultValue(false),
  processingFail: PropTypes.bool
    .description('Sets the process state to processing and then failed')
    .defaultValue(false),
  animated: PropTypes.bool.description('animate or not').defaultValue(true),
  percent: PropTypes.number
    .description('completion percentage')
    .defaultValue(50),
};

DSIndeterminateProgressIndicator.propTypes = indeterminateProgressIndicatorProps;

const IndeterminateProgressIndicatorWithSchema = describe(
  DSIndeterminateProgressIndicator,
);

IndeterminateProgressIndicatorWithSchema.propTypes = indeterminateProgressIndicatorProps;

export { IndeterminateProgressIndicatorWithSchema };
export default DSIndeterminateProgressIndicator;
