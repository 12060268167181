/* eslint-disable react/prop-types */
/* eslint-disable max-lines */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-param-reassign */
/* eslint-disable react/display-name */
import React, { useMemo, useRef, useEffect } from 'react';
import { mergeRefs } from '@elliemae/ds-utilities/system';
import { ScrollSync, ScrollSyncPane } from '@elliemae/ds-shared/ScrollSync';
import createInstancePlugin from '@elliemae/ds-shared/createDataInstance/createInstancePlugin';
import { getScrollbarWidth } from '../../utilities/getScrollbarSize';

const SCROLLBAR_WIDTH = getScrollbarWidth(2);

const HeaderSyncHoc = (Component) => (props) => (
  <ScrollSyncPane>
    {({ registerPane }) => (
      <Component
        {...props}
        innerRef={mergeRefs(props.innerRef, registerPane)}
      />
    )}
  </ScrollSyncPane>
);
const BodySyncHoc = (Component, notPaginated, grid) => ({
  children,
  classProps,
  columns,
  getContainer,
  helperClass,
  innerBodyRef,
  innerRef,
  isPlaceholderActive,
  listProps,
  lockAxis,
  noResultsPlaceholder,
  numRowsVisible,
  onSortEnd,
  onSortOver,
  onSortStart,
  rowKey,
  rowRenderer,
  rows,
  useDragHandle,
  className,
  listComponent,
  ...rest
}) => {
  const t = useRef();
  const repositionToolbarBasedOnBodyScrollPosition = (
    widthDifference,
  ) => () => {
    if (notPaginated) {
      if (grid.refs.body.current && grid.refs.innerBody.current) {
        // virtualization
        if (
          t.current &&
          !t.current.offsetWidth + t.current.scrollLeft - 15 >
            t.current.scrollLeft + t.current.clientWidth
        )
          return;
        grid.refs.body.current.style.setProperty(
          '--toolbar-scroll',
          `${grid.refs.innerBody.current.clientWidth + widthDifference}px`,
        );
        return;
      }
      window.requestAnimationFrame(() =>
        repositionToolbarBasedOnBodyScrollPosition(-SCROLLBAR_WIDTH)(),
      );
    } else {
      // not virtualization
      if (!grid.refs.body.current) return;
      grid.refs.body.current.style.setProperty(
        '--toolbar-scroll-paginated',
        `${grid.refs.body.current.clientWidth + widthDifference}px`,
      );
    }
  };
  useEffect(() => {
    setTimeout(
      repositionToolbarBasedOnBodyScrollPosition(-SCROLLBAR_WIDTH),
      300,
    );
  }, []);
  useEffect(() => {
    window.addEventListener(
      'resize',
      repositionToolbarBasedOnBodyScrollPosition(-SCROLLBAR_WIDTH),
    );
    return () => {
      window.removeEventListener(
        'resize',
        repositionToolbarBasedOnBodyScrollPosition(-SCROLLBAR_WIDTH),
      );
    };
  });
  return (
    <ScrollSyncPane notPaginated={notPaginated}>
      {({ registerPane }) => (
        <Component
          ref={t}
          onScroll={(e) => {
            if (grid.refs.body.current && grid.refs.innerBody.current) {
              // virtualization
              if (
                !e.target.offsetWidth + e.target.scrollLeft - SCROLLBAR_WIDTH >
                e.target.scrollLeft + e.target.clientWidth
              )
                return;
              grid.refs.body.current.style.setProperty(
                '--toolbar-scroll',
                `${
                  grid.refs.innerBody.current.clientWidth +
                  e.target.scrollLeft -
                  SCROLLBAR_WIDTH
                  // e.target.offsetWidth + e.target.scrollLeft - SCROLLBAR_WIDTH
                }px`,
              );
              return;
            }
            // not virtualization
            if (
              !e.target.offsetWidth + e.target.scrollLeft >
              e.target.scrollLeft + e.target.clientWidth
            )
              return;
            grid.refs.body.current.style.setProperty(
              '--toolbar-scroll-paginated',
              `${e.target.offsetWidth + e.target.scrollLeft}px`,
            );
          }}
          classProps={classProps}
          className={className}
          listComponent={listComponent}
          columns={columns}
          getContainer={getContainer}
          helperClass={helperClass}
          innerBodyRef={innerBodyRef}
          // innerRef={innerRef}
          isPlaceholderActive={isPlaceholderActive}
          listProps={listProps}
          lockAxis={lockAxis}
          noResultsPlaceholder={noResultsPlaceholder}
          numRowsVisible={numRowsVisible}
          onSortEnd={onSortEnd}
          onSortOver={onSortOver}
          onSortStart={onSortStart}
          rowKey={rowKey}
          rowRenderer={rowRenderer}
          rows={rows}
          useDragHandle={useDragHandle}
          innerRef={mergeRefs(innerRef, registerPane, t)}
          {...rest}
        />
      )}
    </ScrollSyncPane>
  );
};

const TableSyncHoc = (Component) => (props) => (
  <ScrollSync proportional={false}>
    <Component {...props} />
  </ScrollSync>
);

const decorateRenderers = (renderers, grid) => {
  const HeaderWrapper = renderers.header.wrapper;
  const BodyWrapper = renderers.body.wrapper;
  const Table = renderers.table;
  const { paginated } = grid.props;

  renderers.table = useMemo(() => TableSyncHoc(Table), []);
  renderers.header.wrapper = useMemo(() => HeaderSyncHoc(HeaderWrapper), []);
  renderers.body.wrapper = useMemo(
    () => BodySyncHoc(BodyWrapper, !paginated, grid),
    [],
  );

  return renderers;
};

export const BodyHeaderScrollSyncPlugin = createInstancePlugin(
  'body-header-scroll-sync',
  {
    decorateRenderers,
  },
);
