import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { aggregatedClasses } from '@elliemae/ds-classnames';
import DSPortal from '../Portal';
import DSBackdrop from '../Backdrop';

const blockName = 'overlay';

const events = ['mousedown', 'touchstart'];

const OverlayContainer = aggregatedClasses('div')(
  blockName,
  null,
  ({ usePortal, isOpen }) => ({
    inline: !usePortal,
    opened: isOpen,
  }),
);

class DSOverlay extends Component {
  static overlayStack = [];
  static defaultProps = {
    isOpen: false,
    usePortal: true,
    hasBackdrop: false,
    backDropZIndex: 2, // https://jira.elliemae.io/browse/PUI-1712
    onClickOutside: () => null,
  };
  constructor(props) {
    super(props);
    this.overlayRef = React.createRef();

    this.handleDocumentClick = this.handleDocumentClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;
    if (isOpen !== prevProps.isOpen) {
      if (isOpen) {
        this.openOverlay();
      } else {
        this.closeOverlay();
      }
    }
  }

  addClickOutsideListeners() {
    events.forEach(event => {
      document.addEventListener(event, this.handleDocumentClick);
    });
  }

  removeClickOutsideListeners() {
    events.forEach(event => {
      document.removeEventListener(event, this.handleDocumentClick);
    });
  }

  handleDocumentClick(e) {
    const { onClickOutside, isOpen } = this.props;
    const { overlayStack } = DSOverlay;
    const overlayIndex = overlayStack.indexOf(this);
    const hasClickedOverlay = overlayStack
      .slice(overlayIndex)
      .some(({ overlayRef }) => {
        const elem = overlayRef.current;
        return elem && elem.contains(e.target) && !elem.isSameNode(e.target);
      });

    if (isOpen && !hasClickedOverlay) {
      onClickOutside(e); // giving opportunity for callers to operate on the event.
    }
  }

  openOverlay() {
    this.addClickOutsideListeners();
    DSOverlay.overlayStack.push(this);
  }

  closeOverlay() {
    const { overlayStack } = DSOverlay;
    const overlayIndex = overlayStack.indexOf(this);
    this.removeClickOutsideListeners();
    overlayStack.splice(overlayIndex, 1);
  }

  render() {
    const {
      usePortal,
      isOpen,
      hasBackdrop,
      children,
      backDropZIndex,
    } = this.props;

    if (!isOpen) return null;

    const overlay = (
      <OverlayContainer
        classProps={{ usePortal, isOpen }}
        innerRef={this.overlayRef}
      >
        {hasBackdrop && <DSBackdrop zIndex={backDropZIndex} />}
        {children}
      </OverlayContainer>
    );

    if (usePortal) {
      return <DSPortal>{overlay}</DSPortal>;
    }

    return overlay;
  }
}

DSOverlay.propTypes = {
  backDropZIndex: PropTypes.number,
  children: PropTypes.element,
  hasBackdrop: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClickOutside: PropTypes.func,
  usePortal: PropTypes.bool,
};

export default DSOverlay;
