/* eslint-disable max-lines */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-use-before-define */
/* eslint-disable jest/valid-describe */
/* eslint-disable jest/valid-title */
import React from 'react';
import { PropTypes, describe } from 'react-desc';
import { get } from 'lodash';
import styled from 'styled-components';
import { space, width, height, justifyContent, flexbox } from 'styled-system';
import {
  theme,
  fixSpaceGutter,
  mapGap,
  mapTemplateGrid,
  // gridAutoRows,
} from '@elliemae/ds-system';

import { positionIE11Grid } from './ie11';

/* eslint-disable complexity */
/* stylelint-disable property-no-vendor-prefix */
const GridItem = styled.div`
  display: grid;
  display: -ms-grid;
  ${flexbox}
  ${justifyContent}
  ${space}
  ${height}
  ${width}
  ${positionIE11Grid}
  ${(props) => {
    if (typeof props.cols === 'object' && !Array.isArray(props.cols)) {
      let data = '';
      for (const key in theme.mediaQueries) {
        // using grid approach
        if (props.cols[key] && Array.isArray(props.cols[key])) {
          const mtg = mapTemplateGrid(props.cols[key]).join(' ');
          const mtgs = mapTemplateGrid(props.cols[key])
            .map((c) => `minmax(0, ${c})`)
            .join(' ');
          data += `@media ${theme.mediaQueries[key]} {
            -ms-grid-columns: ${mtg || 'minmax(0px, 1fr)'};
            grid-template-columns: ${mtg || 'auto'};
            -ms-grid-columns: ${mtgs || 'minmax(0px, 1fr)'};
            grid-template-columns: ${mtgs || 'auto'};
          }`;
        } else if (props.isSpanParent) {
          data += `@media ${theme.mediaQueries[key]} {
              -ms-grid-columns: repeat(${props.cols[key]}, 1fr);
              grid-template-columns: repeat(${props.cols[key]}, 1fr);
            }`;
        }
      }
      return data;
    }
    if (Array.isArray(props.cols)) {
      const mtg = mapTemplateGrid(props.cols).join(' ');
      const mtgs = mapTemplateGrid(props.cols)
        .map((c) => `minmax(0, ${c})`)
        .join(' ');
      return `
      -ms-grid-columns: ${mtg || 'minmax(0px, 1fr)'};
      grid-template-columns: ${mtg || 'auto'};
      -ms-grid-columns: ${mtgs || 'minmax(0px, 1fr)'};
      grid-template-columns: ${mtgs || 'auto'};
      `;
    }
    if (props.isSpanParent) {
      return `
      -ms-grid-columns: repeat(${props.cols}, 1fr);
      grid-template-columns: repeat(${props.cols}, 1fr);`;
    }
    return '';
  }}
  ${(props) => {
    if (!props.isSpanParent) return '';
    if (typeof props.span === 'object') {
      let data = '';
      for (const key in theme.mediaQueries) {
        if (props.span[key] && theme.mediaQueries[key]) {
          data += `@media ${theme.mediaQueries[key]} {
            -ms-grid-column: auto / span ${props.span[key]};
            grid-column: auto / span ${props.span[key]};
          }`;
        }
      }
      return data;
    }
    return `
    -ms-grid-column: auto / span ${props.span};
    grid-column: auto / span ${props.span};`;
  }}
  -ms-grid-rows: ${(props) =>
    mapTemplateGrid(props.rows).join(' ') || 'minmax(0px, 1fr)'};
  grid-template-rows: ${(props) =>
    mapTemplateGrid(props.rows).join(' ') || 'auto'};
  column-gap: ${(props) => mapGap(props.gutter)};
  row-gap: ${(props) => mapGap(props.gutter)};
`;

/* stylelint-enable property-no-vendor-prefix */
/* eslint-enable complexity */

const Grid = React.forwardRef(
  (
    {
      rows = [],
      cols = [],
      alignItems,
      children,
      className = '',
      direction = 'row',
      justify = 'flex-start',
      gutter = 0,
      wrap = 'wrap',
      ...rest
    },
    ref,
  ) => {
    let isSpanParent = true;
    const renderChildren = React.Children.map(children, (child) => {
      if (
        get(child, 'child.type.name') === 'Grid' ||
        get(child, 'type.displayName') === 'Grid' ||
        parseInt(get(child, 'props.span', 0), 10) > 0
      ) {
        const { width: wc, height: hc, span } = child.props;
        if (!span) isSpanParent = false;
        return React.cloneElement(child, {
          ...child.props,
          width: fixSpaceGutter(wc),
          // eslint-disable-next-line max-lines
          height: fixSpaceGutter(hc),
        });
      }
      return child;
    });
    return (
      <GridItem
        alignItems={alignItems}
        className={className}
        cols={cols}
        direction={direction}
        gutter={gutter}
        isSpanParent={isSpanParent}
        justify={justify}
        rows={rows}
        wrap={wrap}
        childNumber={React.Children.count(children)}
        {...rest}
        ref={ref}
      >
        {renderChildren}
      </GridItem>
    );
  },
);

Grid.displayName = 'Grid';

const gridProps = {
  /** Row layout cells */
  rows: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ).description('Row layout cells'),
  /** Column layout cells */
  cols: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ).description('Column layout cells'),
  /** Grid span */
  span: PropTypes.number.description('Grid span'),
  /** flex-like align items prop */
  alignItems: PropTypes.oneOf([
    'flex-start',
    'center',
    'flex-end',
    'stretch',
    'baseline',
  ]).description('flex-like align items prop'),
  /** Children node inside grid cell */
  children: PropTypes.node.description('Children node inside grid cell'),
  /** CSS class */
  className: PropTypes.string.description('CSS class'),
  /** flex-like direction prop */
  direction: PropTypes.oneOf([
    'row',
    'row-reverse',
    'column',
    'column-reverse',
  ]).description('flex-like direction prop'),
  /** flex-like justify prop */
  justify: PropTypes.oneOf([
    'flex-start',
    'center',
    'flex-end',
    'space-between',
    'space-around',
    'space-evenly',
  ]).description('flex-like justify prop'),
  /** Space between cells prop */
  gutter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).description(
    'Space between cells prop',
  ),
  /** Wrap type */
  wrap: PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse']).description(
    'Wrap type',
  ),
};

Grid.propTypes = gridProps;

const DSGridWithSchema = describe(Grid).description(
  'Grid layout helper component',
);
DSGridWithSchema.propTypes = gridProps;

export default Grid;
export { DSGridWithSchema };
