/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { useState, useRef } from 'react';
import { DayPickerSingleDateController } from 'react-dates';
import moment from 'moment';
import {
  DatePickerDay,
  DatePickerNavigation,
  DatePickerHeader,
} from '@elliemae/ds-date-picker';
import ChevronLeft from '@elliemae/ds-icons/ChevronLeft';
import {
  aggregatedClasses,
  convertPropToCssClassName,
} from '@elliemae/ds-classnames';

const blockname = 'single-date';
const Wrapper = aggregatedClasses('div')(blockname, 'wrapper');

const { classNameElement, classNameBlock } = convertPropToCssClassName(
  'datepicker',
);

const DatePickerSingleDate = ({
  date,
  displayFormatDay = 'D',
  isDayHighlighted = () => false,
  enableOutsideDays = true,
  showHeader = true,
  onChange = () => null,
  ...rest
}) => {
  const [selectedDate, setSelectedDate] = useState(date);
  const [key, setKey] = useState(moment());
  const datePickerRef = useRef();

  const updateKeyDate = (newDate) => {
    setKey(newDate);
  };

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    setTimeout(() => onChange(newDate), 500);
  };

  return (
    <Wrapper>
      {showHeader && (
        <DatePickerHeader
          onInputChange={handleDateChange}
          selectedDate={selectedDate}
          updateKeyDate={updateKeyDate}
        />
      )}
      <div
        ref={datePickerRef}
        className={classNameBlock('wrapper-day-picker-single-date-controller')}
        data-testid={rest['data-testid'] || 'date-picker-single-date'}
      >
        <DayPickerSingleDateController
          key={key}
          date={selectedDate}
          enableOutsideDays={enableOutsideDays}
          focused
          hideKeyboardShortcutsPanel
          isDayHighlighted={isDayHighlighted}
          keepOpenOnDateSelect
          navNext={
            <DatePickerNavigation
              className={classNameElement('navigation-next')}
            />
          }
          navPrev={
            <DatePickerNavigation
              className={classNameElement('navigation-prev')}
              icon={ChevronLeft}
            />
          }
          onDateChange={handleDateChange}
          renderDayContents={(givenDate) => (
            <DatePickerDay
              className={classNameElement('day-contents')}
              date={givenDate.format(displayFormatDay)}
            />
          )}
        />
      </div>
    </Wrapper>
  );
};

export default DatePickerSingleDate;
