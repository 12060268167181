import React from 'react';
import PropTypes from 'prop-types';
import SearchableList from './MenuItems/SearchableList';
import Menu from './Menu';

function MenuCombobox({
  dropdownFilterOptions = {},
  options = [],
  onSelectMenuItem = () => null,
  innerRef,
  maxOptions = 10,
  loading = false,
  ...rest
}) {
  return (
    <Menu
      {...rest}
      classProps={{ menuComboBox: true }}
      innerRef={innerRef}
      visible
    >
      <SearchableList
        appendTermInList
        extraListProps={dropdownFilterOptions}
        items={options}
        onSelectMenuItem={onSelectMenuItem}
        maxOptions={maxOptions}
        loading={loading}
      />
    </Menu>
  );
}

MenuCombobox.propTypes = {
  /** Options */
  dropdownFilterOptions: PropTypes.shape({}),
  /** The option or menu items to render */
  options: PropTypes.arrayOf(PropTypes.object),
  /** Handler when a menu item is selected */
  onSelectMenuItem: PropTypes.func,
  /** Internal ref */
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  /** Max options to display in the list */
  maxOptions: PropTypes.number,
  /** Is loading */
  loading: PropTypes.bool,
};

export default MenuCombobox;
