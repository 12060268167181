/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M15 10.5c0 3.038-2.462 5.5-5.5 5.5S4 13.538 4 10.5 6.462 5 9.5 5 15 7.462 15 10.5zm-2.672-2.121l-.707-.707L9.5 9.793 7.379 7.672l-.707.707L8.793 10.5l-2.121 2.121.707.707L9.5 11.207l2.121 2.121.707-.707-2.121-2.121 2.121-2.121z',
  ],
  height: 20,
  width: 20,
});
