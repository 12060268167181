/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0zm-.459 4h.917c.291 0 .521.249.496.539L10.499 12h-1l-.455-7.461A.498.498 0 0 1 9.54 4zM10 16c-.571 0-1-.429-1-1s.429-1 1-1 1 .429 1 1-.429 1-1 1z',
  ],
  height: 20,
  width: 20,
});
