import React, { useMemo } from 'react';
import { PropTypes, describe } from 'react-desc';
import { useCheckableGroup } from '@elliemae/ds-shared/CheckableGroup';
import SearchableGroup from './SearchableGroup';
import { menuItemFactory } from './menuItemFactory';
import VirtualMenuList from '../VirtualMenuList';

const noop = () => null;

function SelectionGroup({
  multi = false,
  searchable = false,
  focusOnOpen = false,
  onSelect = noop,
  children,
  items = undefined,
  active = multi ? [] : '',
  width,
  height,
}) {
  const renderedItems = useMemo(
    () =>
      items &&
      items.map((item) => {
        const Component = menuItemFactory(multi ? 'checkbox' : 'radio');
        return (
          <Component
            {...item}
            key={item.name || item.id || item.key}
            item={item}
            name={item.id || item.name}
          />
        );
      }),
    [items],
  );
  const decoratedGroupChildren = useCheckableGroup({
    children: renderedItems || children,
    multi,
    active,
    onCheck: onSelect,
  });

  if (searchable) {
    return (
      <SearchableGroup
        focusOnOpen={focusOnOpen}
        height={height}
        items={items}
        width={width}
      >
        {decoratedGroupChildren}
      </SearchableGroup>
    );
  }
  return (
    <VirtualMenuList
      height={height}
      items={decoratedGroupChildren}
      width={width}
    />
  );
}

const props = {
  /** toggle on multi select */
  multi: PropTypes.bool.description('toggle on multi select'),
  /** toggle on searchable behavior */
  searchable: PropTypes.bool.description('toggle on searchable behavior'),
  /** toggle to focus component on open */
  focusOnOpen: PropTypes.bool.description('toggle to focus component on open'),
  /** callback that triggers when select happens */
  onSelect: PropTypes.func.description(
    'callback that triggers when select happens',
  ),
  children: PropTypes.node.description(''),
  /** selection group items  */
  items: PropTypes.array.description('selection group items'),
  /** array of values for multi and string for single selection */
  active: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).description(
    'array of values for multi and string for single selection',
  ),
  /** selection group width */
  width: PropTypes.number.description('selection group width'),
  /** selection group height */
  height: PropTypes.number.description('selection group height'),
};

SelectionGroup.propTypes = props;

const DSMenuSelectionGroupWithSchema = describe(SelectionGroup);
DSMenuSelectionGroupWithSchema.propTypes = props;

export default SelectionGroup;

export { DSMenuSelectionGroupWithSchema };
