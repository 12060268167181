import { useRef } from 'react';

export default function useShouldRecalculate(bool) {
  const prevValueRef = useRef(0);
  if (!bool) {
    // If we need to recalculate, change the value we return
    prevValueRef.current += 1;
  } // else we return the same value as the previous render, which won't trigger a recalculation
  return prevValueRef.current;
}
