/* eslint-disable no-shadow */
/* eslint-disable prettier/prettier */
/* eslint-disable max-lines */
// https://github.com/styled-components/babel-plugin-styled-components/issues/216#issuecomment-516941240
import { lighten, rgba } from 'polished';
import { reduce } from 'lodash';
import DSTheme, { toMobile, __UNSAFE_FONT_TO_DIMSUM } from './theme';

const styled = require('styled-components');

const { css } = styled;
const kfrm = styled.keyframes;
const { withTheme } = styled;
const { useTheme } = styled;
const { createGlobalStyle } = styled;
export { withTheme, createGlobalStyle, rgba, useTheme, kfrm };

export function truncate(width) {
  return (props) => css`
    ${!!width || props.width ? `width: ${props.width || width};` : ''}
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
}

// iterate through the sizes and create a media template
export const media = Object.keys(DSTheme.mediaQueries).reduce(
  (accumulator, label) => {
    // use em in breakpoints to work properly cross-browser and support users
    // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
    accumulator[label] = (...args) => css`
      @media ${(props) => props.theme.mediaQueries[label]} {
        ${css(...args)};
      }
    `;
    return accumulator;
  },
  {},
);

export function flexCenter() {
  return `
    display: flex;
    justify-content: center;
    align-items: center;
  `;
}

export function disabled() {
  return `
    cursor: not-allowed;
    pointer-events: none;
  `;
}

export function keyframes(obj) {
  return kfrm`${reduce(
    obj,
    (result, value, key) => `
    ${result}
    ${key}% {
      ${value}
    }
  `,
    '',
  )}
  `;
}

// eslint-disable-next-line max-params
export function boxShadow(top, left, blur, color, inset = false) {
  return `box-shadow: ${inset ? 'inset' : ''} ${top} ${left} ${blur} ${color};`;
}

export function color(variant = 'neutral', type = 400) {
  return css`
    color: ${(props) => props.theme.colors[variant][type]};
  `;
}

export function border(
  color = DSTheme.colors.brand['600'],
  size = '1px',
  type = 'solid',
) {
  return `${size} ${type} ${color}`;
}

export function animation(
  animationKeyframes,
  animationLength,
  animationTimingFn,
) {
  return (props) => css`
    animation: ${props.animationKeyframes || animationKeyframes}
      ${props.animationLength || animationLength}
      ${props.animationTimingFn || animationTimingFn};
  `;
}
// 0.0769
export function focus(color = DSTheme.colors.brand['600']) {
  return () => css`
    outline: none;
    border: 1px solid ${color};
    box-shadow: inset 0 0 0 1px ${lighten(0.3, color)};
    border-radius: 2px;
  `;
}

export function focusAfter(color) {
  return css`
    outline: none;
    position: relative;
    &:after {
      content: '';
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      ${focus(color)}
    }
  `;
}

export function active() {
  return (props) => css`
    outline: none;
    border: 1px solid ${props.theme.colors.brand['700']};
    border-radius: 2px;
  `;
}

export function hover() {
  return (props) => css`
    outline: 1px solid ${props.theme.colors.brand['600']};
    outline-offset: -1px;
  `;
}

export function textStyle(type, weight = 'regular') {
  // eslint-disable-next-line complexity
  return (props) => {
    let cssVar = `font-weight: ${props.theme.fontWeights[weight]};`;
    // eslint-disable-next-line default-case
    switch (type) {
      case 'h1':
        cssVar += `
        font-size: ${toMobile(
    props.theme.fontSizes[__UNSAFE_FONT_TO_DIMSUM(900)],
  )};
        line-height: normal;
      `;
        break;
      case 'h2':
        cssVar += `
        font-size: ${toMobile(
    props.theme.fontSizes[__UNSAFE_FONT_TO_DIMSUM(800)],
  )};
        line-height: normal;
      `;
        break;
      case 'h3':
        cssVar += `
        font-size: ${toMobile(
    props.theme.fontSizes[__UNSAFE_FONT_TO_DIMSUM(700)],
  )};
        line-height: 1.2;
      `;
        break;
      case 'h4':
        cssVar += `
        font-size: ${toMobile(
    props.theme.fontSizes[__UNSAFE_FONT_TO_DIMSUM(600)],
  )};
        line-height: normal;
      `;
        break;
      case 'h5':
        cssVar += `
        font-size: ${toMobile(
    props.theme.fontSizes[__UNSAFE_FONT_TO_DIMSUM(500)],
  )};
        line-height: normal;
      `;
        break;
      case 'section-header':
        cssVar += `
        font-size: ${toMobile(
    props.theme.fontSizes[__UNSAFE_FONT_TO_DIMSUM(500)],
  )};
        line-height: normal;
        text-transform: uppercase;
      `;
        break;
      case 'body':
        cssVar += `
        font-size: ${toMobile(
    props.theme.fontSizes[__UNSAFE_FONT_TO_DIMSUM(400)],
  )};
        line-height: normal;
      `;
        break;
      case 'body-small':
        cssVar += `
        font-size: ${toMobile(
    props.theme.fontSizes[__UNSAFE_FONT_TO_DIMSUM(300)],
  )};
        line-height: normal;
      `;
        break;
      case 'body-micro':
        cssVar += `
        font-size: ${toMobile(
    props.theme.fontSizes[__UNSAFE_FONT_TO_DIMSUM(200)],
  )};
        line-height: normal;
      `;
        break;
      case 'list':
        cssVar += `
        font-size: ${toMobile(
    props.theme.fontSizes[__UNSAFE_FONT_TO_DIMSUM(400)],
  )};
        line-height: normal;
      `;
        break;
      case 'link':
        cssVar += `
        line-height: ${props.theme.lineHeights[3]};
        color: ${props.theme.colors.brand['600']};
        cursor: pointer;
      `;
        break;
    }
    return cssVar;
  };
}

export function iconColor(variant = 'neutral', type = 400) {
  return css`
    fill: ${(props) => props.theme.colors[variant][type]};
  `;
}

export function fakeBorder() {
  return css`
    box-shadow: inset 0 0 0 1px ${(props) => props.theme.colors.neutral[200]};
    border-radius: 2px;
  `;
}

export function fakeActive() {
  return css`
    outline: none;
    box-shadow: inset 0 0 0 1px ${(props) => props.theme.colors.brand[700]};
    border-radius: 2px;
  `;
}

export function clearFocus() {
  return `
    border: none;
    box-shadow: none;
  `;
}

export function buttonLink() {
  return `
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
  `;
}

export function transition(t = 'all 1s ease') {
  return `
    transition: ${t};
  `;
}
