/* eslint-disable react/destructuring-assignment,max-lines,react/display-name, react/require-default-props */
import React from 'react';

const MultiSelectHeader = props => {
  const count = props.children.filter(child => child.props.isSelected).length;
  return (
    <div className="combobox-multi-select-header">{`${count} selected`}</div>
  );
};

export default MultiSelectHeader;
