/* eslint-disable react/prop-types */
/* eslint-disable max-lines */
import React from 'react';
import DSSeparator from '@elliemae/ds-basic/Separator';
import DSButton from '@elliemae/ds-basic/Button';
import styled from 'styled-components';
import { get } from '@elliemae/ds-utilities/utils';
import deferComponentRender from '@elliemae/ds-shared/defer-render-hoc';
import useDataGrid from '@elliemae/ds-shared/useDataGrid';
import { PaginationPlugin } from '../pagination';
import BodyCell from '../../components/BodyCell';
import { defaultRenderers } from '../../components/renderers';
import TableBody from '../../components/TableBody';
import Table from '../../components/Table';
import { ColumnSizingPlugin } from '../column-sizing';

const DetailGrid = deferComponentRender(
  ({ parentGrid, parentRowData, rows }) => {
    const { instanceRef, ...parentProps } = parentGrid.props;
    const grid = useDataGrid({
      uuid: parentGrid.getInstance().uuid,
      ...parentProps,
      columns: parentGrid
        .getInstance()
        .decoratedColumns.filter((col) => !col.expandableColumn)
        .map((c) => ({ ...c, expandableColumn: false })),
      rows,
      plugins: [ColumnSizingPlugin, PaginationPlugin],
      renderers: defaultRenderers,
      bindColumnsSizeTo: parentGrid.uuid,
      numRowsVisible: 'all',
      paginated: true,
      pagination: {
        perPage: rows.length,
        conditionalPagination: true,
      },
      parentRowData,
    });

    return (
      <Table {...grid}>
        <TableBody />
      </Table>
    );
  },
  null,
);

const DetailsWrapper = styled.span`
  flex: 1;
  width: ${(props) => props.width}px;
`;

const DetailGridWrapper = styled.div`
  display: flex;
  height: calc(100% - ${(props) => (props.allRowsVisible ? 18 : 60)}px);
  width: ${(props) => props.expandableGridDetailWidth}px;
  margin: 12px 0;
`;

function ExpandedRow({
  parentGrid,
  detailColumns,
  rowData,
  rows,
  rowProps,
  cellComponent: CellComponent,
  rowComponent: RowComponent,
}) {
  const {
    decoratedColumns,
    actions: { toggleShowAllRows },
    state: { expandedRows },
    props: {
      rowKey,
      showMoreRowsText = 'SHOW ALL ROWS',
      showLessRowsText = 'SHOW LESS ROWS',
      expandableSubrowsVisible,
    },
    columnSizeService: { expandableGridDetailWidth },
  } = parentGrid.getInstance();

  const { showAllRows, ...restRowProps } = rowProps;
  return (
    <RowComponent {...restRowProps}>
      <BodyCell
        column={decoratedColumns[0]}
        columnIndex={0}
        component={CellComponent}
        grid={parentGrid}
        rowProps={{ rowData, ...rowProps }}
      />
      <DetailsWrapper width={expandableGridDetailWidth}>
        <DetailGridWrapper
          allRowsVisible={
            rows.length < expandableSubrowsVisible ||
            get(expandedRows, [rowKey, 'showAllRows'])
          }
          expandableGridDetailWidth={expandableGridDetailWidth}
        >
          <DSSeparator dashed orientation="vertical" />
          <DetailGrid
            detailColumns={detailColumns}
            parentGrid={parentGrid.getInstance()}
            parentRowData={rowData}
            rows={
              showAllRows
                ? rows
                : (rows || []).slice(0, expandableSubrowsVisible)
            }
          />
        </DetailGridWrapper>
        {rows.length > expandableSubrowsVisible && (
          <DSButton
            buttonType="text"
            labelText={showAllRows ? showLessRowsText : showMoreRowsText}
            onClick={(e) => {
              e.stopPropagation();
              toggleShowAllRows(rowData);
            }}
            style={{ marginBottom: '10px' }}
          />
        )}
      </DetailsWrapper>
    </RowComponent>
  );
}

export default ExpandedRow;
