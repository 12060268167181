/* eslint-disable indent */
import { get, isFunction } from '@elliemae/ds-utilities/utils';
import createInstancePlugin from '@elliemae/ds-shared/createDataInstance/createInstancePlugin';
import { addFilterToColumn } from './addFilterToColumn';

import filterRowsByQuery, {
  filterRowsByQueryGroup,
} from './filtering-helper/filterRowsByQuery';
import { useFilterableState } from './useFilterableState';

const decorateColumn = (column, grid) => {
  const {
    props: {
      isFilterableColumnActive = (col) => col.searchable,
      onRemoveAllFilters,
    },
    actions: { addFilter, removeFilter },
    state: { query, filters },
  } = grid;

  return isFilterableColumnActive(column)
    ? addFilterToColumn(
        {
          grid,
          onAddFilter: addFilter,
          onRemoveFilter: removeFilter,
          onRemoveAllFilters,
          query,
          filters,
        },
        column,
      )
    : column;
};

const composeRows = (rows, grid) => {
  const {
    props: { isDataFiltered, groupedRows },
    state: { query },
    composedRows,
  } = grid.getInstance();
  const nextRows = !groupedRows
    ? filterRowsByQuery(rows, query)
    : filterRowsByQueryGroup(rows, query, composedRows);

  return !isDataFiltered ? nextRows : rows;
};

export const FilterablePlugin = createInstancePlugin('filterable', {
  registerStateHook: useFilterableState,
  decorateColumn,
  composeRows,
  registerHotKeys(grid) {
    return {
      key: 'ctrl+f',
      handler: ({ cellIndex }) => {
        const { decoratedColumns } = grid.getInstance();

        const column = decoratedColumns[cellIndex];
        const toggleMenuMethod = get(column, [
          'filterMenu',
          'current',
          'toggleMenu',
        ]);
        if (isFunction(toggleMenuMethod)) toggleMenuMethod();
      },
    };
  },
});
