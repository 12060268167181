import React from 'react';
import { describe, PropTypes } from 'react-desc';
import { aggregatedClasses } from '@elliemae/ds-classnames';
import DSOverlay from '../Overlay';
import DSSpinner from '../Spinner';

const blockName = 'loading-indicator';

const Container = aggregatedClasses('div')(blockName);

const DSLoadingIndicator = ({
  innerRef,
  size = 'l',
  loading = false,
  message = null,
  hasBackdrop = true,
  onClickOutside = () => null,
  spinner,
  ...rest
}) => {
  const spinnerComponent = spinner || (
    <DSSpinner innerRef={innerRef} message={message} size={size} {...rest} />
  );
  return (
    <DSOverlay
      backDropZIndex={200} // https://jira.elliemae.io/browse/PUI-1712
      hasBackdrop={hasBackdrop}
      isOpen={loading}
      onClickOutside={onClickOutside}
    >
      <Container>{spinnerComponent}</Container>
    </DSOverlay>
  );
};

const loadingIndicatorProps = {
  innerRef: PropTypes.func.description('Get spinner reference'),
  size: PropTypes.oneOf(['s', 'm', 'l'])
    .description('Size of the spinner')
    .defaultValue('l'),
  loading: PropTypes.bool
    .description('Whether to show the spinner or not')
    .defaultValue(false),
  message: PropTypes.string.description('Message below the spinner'),
  hasBackdrop: PropTypes.bool
    .description('Show a backdrop besides the spinner')
    .defaultValue(true),
  onClickOutside: PropTypes.func.description(
    'Handler when a user clicks the outside the spinner',
  ),
  spinner: PropTypes.element.description('Render a custom spinner (JSX)'),
};

DSLoadingIndicator.propTypes = loadingIndicatorProps;

const LoadingIndicatorWithSchema = describe(DSLoadingIndicator);
LoadingIndicatorWithSchema.propTypes = loadingIndicatorProps;

export { LoadingIndicatorWithSchema };
export default DSLoadingIndicator;
