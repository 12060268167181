import React from 'react';
import { PropTypes, describe } from 'react-desc';
import { PillGroup } from './PillGroup';
import { RemovablePill } from './RemovablePill';
import { Pill } from './Pill';

function LabeledPills({
  children,
  label = '',
  leftAddon,
  onFocusGroupSet,
  onFocusNextGroup,
  onFocusPreviousGroup,
}) {
  return (
    <PillGroup
      onFocusGroupSet={onFocusGroupSet}
      onFocusNextGroup={onFocusNextGroup}
      onFocusPreviousGroup={onFocusPreviousGroup}
    >
      <Pill
        label={label}
        leftAddon={leftAddon}
        variant="title"
        data-testid="pill-title"
      />
      {children}
    </PillGroup>
  );
}

const props = {
  /** labeled pill text label */
  label: PropTypes.string.isRequired.description('pill text label'),
  /** pill to add the label to */
  children: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf([
        PropTypes.instanceOf(Pill),
        PropTypes.instanceOf(RemovablePill),
      ]),
    }),
  ).isRequired.description('pill to add the label to'),
  /** addon component to left */
  leftAddon: PropTypes.node.description('addon component to left'),
  /** focus next group callback */
  onFocusNextGroup: PropTypes.func.description('focus next group callback'),
  /** focus prev group callback */
  onFocusPreviousGroup: PropTypes.func.description('focus prev group callback'),
  /** focus group set callback */
  onFocusGroupSet: PropTypes.func.description('focus group set callback'),
};

LabeledPills.propTypes = props;

const DSLabeledPillsWithSchema = describe(LabeledPills);
DSLabeledPillsWithSchema.propTypes = props;

export { LabeledPills, DSLabeledPillsWithSchema };
