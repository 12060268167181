export const RowSizes = {
  compact: 28,
  normal: 36,
  extend: 56,
};

export const RowSizesOptions = {
  compact: 'compact',
  normal: 'normal',
  extend: 'extend',
};

export const RowSizesOptionsArr = [
  RowSizesOptions.compact,
  RowSizesOptions.normal,
  RowSizesOptions.extend,
];
