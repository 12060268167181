import React from 'react';
import { convertPropToCssClassName } from '@elliemae/ds-classnames';

const { cssClassName } = convertPropToCssClassName('form-required-mark');
const RequiredMark = ({ required = false, optional = false }) => {
  if (!required && !optional) return null;
  if (required) {
    return <span className={`${cssClassName}`}>&#9679;</span>;
  }
  return <span className={`${cssClassName} optional-mark`}>&#9900;</span>;
};

export default RequiredMark;
