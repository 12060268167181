/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M20 .875L19.125 0 10 9.125.875 0 0 .875 9.125 10 0 19.125.875 20 10 10.875 19.125 20l.875-.875L10.875 10z',
  ],
  height: 20,
  width: 20,
});
