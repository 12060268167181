const ICONS_COLORS = {
  NEUTRAL: ['neutral', '900'],
  WHITE: ['neutral', '0'],
  DANGER: ['danger', '900'],
  WARNING: ['warning', '500'],
  SUCCESS: ['success', '900'],
  PRIMARY: ['brand-primary', '600'],
};

export default ICONS_COLORS;
