import React from 'react';
import InputImpl from './InputImpl';
import InputAddonWrapper from './InputAddonWrapper';

const DSInput = ({
  containerProps = {},
  id,
  autoFocus = false,
  disabled = false,
  className = '',
  clearable = false,
  name = '',
  maxLength = 255,
  minLength = 0,
  fluidWidth = false,
  disableTooltip = true,
  placeholder,
  value = '',
  hasError = false,
  readOnly = false,
  innerRef = null,
  type = 'text',
  min = undefined,
  max = undefined,
  leftComponent = null,
  rightComponent = null,
  required = false,
  customInputType = null,
  variant,
  isShowElipsisActive = true,
  ...otherProps
}) => (
  <InputImpl
    {...otherProps}
    aria-label={otherProps['aria-label']}
    autoFocus={autoFocus}
    className={`${className} ${disabled ? 'input-disabled' : ''}`}
    clearable={clearable}
    containerProps={containerProps}
    customInputType={customInputType}
    data-testid={otherProps['data-testid'] || 'ds-input_main-component'}
    disabled={disabled}
    disableTooltip={disableTooltip}
    fluidWidth={fluidWidth}
    hasError={hasError}
    id={id}
    innerRef={innerRef}
    isShowElipsisActive={isShowElipsisActive}
    leftComponent={leftComponent}
    max={max}
    maxLength={maxLength}
    min={min}
    minLength={minLength}
    name={name}
    placeholder={placeholder}
    readOnly={readOnly}
    required={required}
    rightComponent={rightComponent}
    type={type}
    value={value}
    variant={variant}
  />
);

export { InputAddonWrapper };

export default DSInput;
