import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { DatePickerSingleDate } from '@elliemae/ds-date-picker';
import DSPopper, {
  PopperPositions as Position,
} from '@elliemae/ds-basic/Popper';
import DatePickerIcon from '@elliemae/ds-icons/DatePicker2';
import DSButton from '@elliemae/ds-basic/Button';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from './defaultDateFormat';

const SingleDatePickerContainer = ({ onChange = () => null, showHeader }) => {
  const [date, setDate] = useState();

  const handleDateChange = useCallback((data) => {
    onChange(moment(data));
    setDate(data);
  }, []);

  return (
    <DatePickerSingleDate
      date={date}
      onChange={handleDateChange}
      showHeader={showHeader}
    />
  );
};

SingleDatePickerContainer.propTypes = {
  onChange: PropTypes.func,
  showHeader: PropTypes.bool,
};

const formatDate = (date, format = DEFAULT_DATE_FORMAT) => date.format(format);

function SingleDateFilterMenu({
  column,
  className,
  isMenuOpened = false,
  onOpenMenu = () => null,
  placement = Position.BOTTOM_END,
  onAddFilter = () => null,
  dateFormatter = formatDate,
  showHeader = false,
  menuProps,
}) {
  return (
    <DSPopper
      contentComponent={
        <div className={className} data-testid="date-single__wrapper">
          <SingleDatePickerContainer
            minimumNights={0}
            onChange={(date) => {
              const t = date.clone();
              onAddFilter(
                {
                  group: column.property,
                  label: column.label,
                  value: dateFormatter(date, column.dateFormat),
                  type: 'date',
                  operator: 'range',
                  filterParams: {
                    start: date.startOf('day'),
                    end: t.endOf('day'),
                  },
                  transformRowValue: (value) =>
                    moment(value, column.dateFormat || DEFAULT_DATE_FORMAT),
                },
                column,
              );
              onOpenMenu(false);
            }}
            showHeader={showHeader}
          />
        </div>
      }
      isOpen={isMenuOpened}
      onOpen={onOpenMenu}
      placement={placement}
      showArrow={false}
      triggerComponent={
        <DSButton
          buttonType="text"
          icon={<DatePickerIcon />}
          onClick={() => (!isMenuOpened ? onOpenMenu(true) : onOpenMenu(false))}
          size="s"
        />
      }
      zIndex={menuProps.zIndex}
    />
  );
}

SingleDateFilterMenu.propTypes = {
  column: PropTypes.any,
  className: PropTypes.string,
  isMenuOpened: PropTypes.bool,
  onOpenMenu: PropTypes.func,
  placement: PropTypes.string,
  onAddFilter: PropTypes.func,
  dateFormatter: PropTypes.any,
  showHeader: PropTypes.bool,
  menuProps: PropTypes.any,
};

export default SingleDateFilterMenu;
