/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable max-lines */
import { useEffect, useState, useCallback } from 'react';
import cx from 'obj-str';
import { isFunction } from '@elliemae/ds-utilities/utils';
import createInstancePlugin from '@elliemae/ds-shared/createDataInstance/createInstancePlugin';
import { selectionColumnCreator } from './addSelectableColumn';
import { useSelectableState } from './useSelectableState';
import { selectMultipleRow, selectSingleRow } from './helper';

// DECORATORS
const decorateColumns = (columns, grid) => {
  const {
    props: {
      selectable,
      multiSelectFilterLabel,
      multiSelectFilterOptions,
      multiSelectFilterLabels,
      multiSelectComponent,
    },
    actions: { selectAll: handleSelectAll, selectRow: handleSelectRow },
    state: { selection },
  } = grid;

  const createSelectionColumn = selectionColumnCreator({
    grid,
    multiple: selectable === 'multi',
    selectAll: selection.selectAll,
    onSelectAll: handleSelectAll,
    onSelectRow: handleSelectRow,
    multiSelectFilterLabel,
    multiSelectFilterOptions,
    multiSelectFilterLabels,
    multiSelectComponent,
  });

  const { showSelectColumn, selectionColumnDefinition } = grid.props;

  if (showSelectColumn) {
    const selectionColumn = createSelectionColumn(selectionColumnDefinition);
    return [selectionColumn, ...columns];
  }
  return columns;
};

const getRowProps = (rowProps, grid, { rowData }) => {
  const {
    props: { showSelectHighlight, showSelectColumn, rowKey },
    actions: { selectRow },
  } = grid.getInstance();

  const { _index } = rowData;
  const isSelected = rowData['metaData.selected'];

  if (!showSelectColumn) {
    rowProps.onClick = () => selectRow(rowData[rowKey], _index);
  }

  rowProps.className = cx({
    [rowProps.className]: true,
    selected: isSelected,
    'select-highlight': showSelectHighlight && isSelected,
  });

  return rowProps;
};

const composeRows = (rows, grid) => {
  const {
    props: { isRowSelected, multiple, rowKey },
    state: { selection },
  } = grid.getInstance();

  const selectionPredicate = (row) =>
    isFunction(isRowSelected)
      ? isRowSelected(row)
      : !!selection.selectedRows[row[rowKey]];

  return multiple
    ? selectMultipleRow(rows, selectionPredicate)
    : selectSingleRow(rows, selectionPredicate);
};

const decorateGrid = (grid) => {
  // const handler = useRef();
  const {
    props: { isRowSelected, rowKey },
    actions: { selectRow },
    state: { selection },
  } = grid.getInstance();
  const [shiftPressed, setShiftPressed] = useState(false);
  const [lastSelectedRow, setLastSelectedRow] = useState(null);

  const selectRowHandler = useCallback(
    (rowData, index) => () => selectRow(rowData[rowKey], index, shiftPressed),
    [isRowSelected, selection, shiftPressed],
  );

  useEffect(() => {
    const keyDownListener = (event) => {
      if (
        event.key === 'Shift' &&
        event.target.parentNode.className !== 'em-ds-combobox__input'
      ) {
        setShiftPressed(true);
      }
    };
    const keyUpListener = (event) => {
      if (
        event.key === 'Shift' &&
        event.target.parentNode.className !== 'em-ds-combobox__input'
      ) {
        setShiftPressed(false);
      }
    };
    window.addEventListener('keydown', keyDownListener);
    window.addEventListener('keyup', keyUpListener);
    return () => {
      window.removeEventListener('keydown', keyDownListener);
      window.removeEventListener('keyup', keyUpListener);
    };
  });

  return {
    shiftPressed,
    selectRowHandler,
    setLastSelectedRow,
    lastSelectedRow,
  };
};

export const SelectablePlugin = createInstancePlugin('selectable', {
  decorateGrid,
  registerStateHook: useSelectableState,
  decorateColumns,
  getRowProps,
  composeRows,
  registerHotKeys(grid) {
    return [
      {
        key: 'space',
        handler: ({ rowIndex }) => {
          const {
            composedRows,
            props: { rowKey },
            actions: { selectRow },
          } = grid.getInstance();
          const rows = composedRows.rows || composedRows;
          const row = rows[rowIndex];
          if (!row) return;
          selectRow(row[rowKey], rowIndex);
        },
      },
      {
        key: 'ctrl+a',
        handler: () => {
          const {
            state: { selection },
            actions: { selectAll },
          } = grid.getInstance();

          selectAll(!selection.selectAll);
        },
      },
    ];
  },
});
