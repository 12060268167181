/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M9.5 13a.503.503 0 0 1-.313-.109l-5-4a.5013.5013 0 0 1-.078-.703c.173-.214.488-.25.703-.078L9.5 11.86l4.688-3.75c.218-.172.531-.136.703.078.172.216.138.53-.078.703l-5 4A.501.501 0 0 1 9.5 13z',
  ],
  height: 20,
  width: 20,
});
