import React, { useRef } from 'react';
import { PropTypes, describe } from 'react-desc';
import { isFunction } from '@elliemae/ds-utilities/utils';
import CloseXsmall from '@elliemae/ds-icons/CloseXsmall';
import useFocusGroupItem from '@elliemae/ds-shared/FocusGroup/useFocusGroupItem';
import { Pill } from './Pill';

function RemovablePill({
  containerProps = {},
  label = '',
  onRemove,
  size = 'm',
  leftAddon,
}) {
  const ref = useRef(null);
  const { focusPrevious } = useFocusGroupItem(ref);
  return (
    <Pill
      className="removable-pill"
      data-testid="removable-pill"
      containerProps={containerProps}
      innerRef={ref}
      size={size}
      label={label}
      leftAddon={leftAddon}
      onKeyDown={e => {
        if (e.key === 'Backspace' && isFunction(onRemove)) {
          onRemove();
          if (isFunction(focusPrevious)) focusPrevious();
        }
      }}
      rightAddon={
        <CloseXsmall
          role="button"
          data-testid="remove-pill-button"
          className="remove-pill-button"
          onClick={onRemove}
        />
      }
      tabIndex={-1}
    />
  );
}

const props = {
  /** props injected to component wrapper */
  containerProps: PropTypes.object.description(
    'props injected to component wrapper',
  ),
  /** labeled pill text label */
  label: PropTypes.string.isRequired.description('pill text label'),
  /** remove click callback */
  onRemove: PropTypes.func.description('remove click callback'),
  /** pill size */
  size: PropTypes.oneOf(['m', 'l']).description('pill size'),
  /** addon component to left */
  leftAddon: PropTypes.node.description('addon component to left'),
};

RemovablePill.propTypes = props;
const DSRemovablePillWithSchema = describe(RemovablePill);
DSRemovablePillWithSchema.propTypes = props;

export { RemovablePill, DSRemovablePillWithSchema };
