import React, { Component } from 'react';
import DSComboBox from '@elliemae/ds-basic/form/ComboBox';

export default class ComboBox extends Component {
  static defaultProps = {
    onValue: () => null,
  };

  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }

  handleChange(value) {
    const { onValue } = this.props;

    // some how this is executed before handleBlur, and handleBlur before the value is set
    // causing the value to be the same as the prevProp
    // todo: find a better solution to this
    setTimeout(() => onValue(value), 1);
    this.setState({ value });
  }

  handleBlur() {
    const { onValue } = this.props;
    const { value } = this.state;
    onValue(value);
  }

  render() {
    const { value } = this.state;
    return (
      <DSComboBox
        {...this.props}
        menuIsOpen
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onClick={e => e.stopPropagation()}
        value={value}
      />
    );
  }
}
