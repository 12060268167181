/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unknown-property, jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { aggregatedClasses } from '@elliemae/ds-classnames';
import DSTruncatedTooltipText from '../../../TruncatedTooltipText';
import RequiredMark from '../../RequiredMark';

const blockName = 'em-ds-label';

const Text = aggregatedClasses('span')(blockName, 'text');

const DSLabel = ({
  children,
  label,
  htmlFor,
  required,
  optional,
  truncateText = true,
  isGroup = false,
}) => {
  const Tag = isGroup ? 'legend' : 'label';

  return (
    <Tag
      className={`${blockName}${isGroup ? ` ${blockName}--group` : ''}`}
      htmlFor={htmlFor}
    >
      {truncateText ? (
        <DSTruncatedTooltipText value={children || label} />
      ) : (
        <Text>{label}</Text>
      )}
      <RequiredMark optional={optional} required={required} />
    </Tag>
  );
};

DSLabel.propTypes = {
  children: PropTypes.string,
  label: PropTypes.string,
  htmlFor: PropTypes.string,
  required: PropTypes.bool,
  optional: PropTypes.bool,
  truncateText: PropTypes.bool,
  isGroup: PropTypes.bool,
};

export default DSLabel;
