import React, { useContext } from 'react';
import { TableContext } from './tableContext';

function TableHeader(props) {
  const {
    decoratedRenderers: renderers,
    decoratedColumns: columns,
    headerRowRenderer,
    getHeaderProps,
    refs,
  } = useContext(TableContext);

  const HeaderWrapper = renderers.header.wrapper;
  return (
    <HeaderWrapper
      {...getHeaderProps({
        ...props,
        innerRef: refs.header,
      })}
    >
      {headerRowRenderer(columns, {})}
    </HeaderWrapper>
  );
}

export default TableHeader;
