import { get } from '@elliemae/ds-utilities/utils';

export function getScrollbarSizeFromHeaderAndBody(refs) {
  const innerBodyOffsetWidth = get(refs, [
    'innerBody',
    'current',
    'offsetWidth',
  ]);
  const headerOffsetWidth = get(refs, ['header', 'current', 'offsetWidth']);

  return headerOffsetWidth - innerBodyOffsetWidth;
}

export const isScrollbarVisible = element =>
  element.scrollHeight > element.clientHeight;

export const getPercentageValue = percentString => {
  if (typeof percentString !== 'string') return null;
  const result = percentString.match(/(\d+|\d*[.,]\d{1,2})/);

  return result && Number(result[0]);
};

export const getColumnWidthSize = (width, minWidth, column) => {
  if (column.special) {
    return column.width;
  }
  if (minWidth && minWidth > width) return minWidth;
  return width;
};

export const calculateMinWidthForWrappedText = columns => {
  let longestWord = 0;

  columns.forEach(({ label }) => {
    const localLongestWord = label
      .split(' ')
      .sort((a, b) => b.length - a.length)[0].length;
    if (localLongestWord > longestWord) longestWord = localLongestWord;
  });

  return longestWord * 8.5;
};
