/* eslint-disable max-lines */
/* eslint-disable complexity */
import React from 'react';
import { describe, PropTypes } from 'react-desc';
import { aggregatedClasses } from '@elliemae/ds-classnames';
import { runAll } from '@elliemae/ds-utilities/utils';
import UploadFile from '@elliemae/ds-icons/UploadFile';
import DSButton, { BUTTON_VARIANT } from '@elliemae/ds-basic/Button';
import { useDropzone } from 'react-dropzone';
import DSTooltip from '@elliemae/ds-basic/Tooltip';

const blockName = 'uploader';
const blockNameInfo = `${blockName}-info`;
const blockNameDragActive = `${blockName}-drag-active`;
const blockNameWrapper = `${blockName}-wrapper`;

const UploaderWrapper = aggregatedClasses('div')(
  blockNameWrapper,
  null,
  ({ disabled }) => ({ disabled }),
);
const Uploader = aggregatedClasses('div')(
  blockName,
  null,
  ({ isDragActive }) => ({ 'drag-active': isDragActive }),
);
const UploaderDragActive = aggregatedClasses('div')(blockNameDragActive);
const UploaderIconArea = aggregatedClasses('div')(`${blockName}-icon-wrapper`);
const UploaderInfo = aggregatedClasses('div')(blockNameInfo);
const InfoText = aggregatedClasses('span')(blockNameInfo, 'text');
const UploaderActions = aggregatedClasses('div')(blockNameInfo, 'actions');
const AcceptedTypes = aggregatedClasses('div')(
  blockNameWrapper,
  'accepted-types',
);

const DividerVertical = aggregatedClasses('div')(
  'divider',
  null,
  ({ vertical }) => ({ vertical }),
);

// eslint-disable-next-line react/prop-types
function ActionButton({ labelText, ...otherProps }) {
  return <DSButton {...otherProps} buttonType="text" labelText={labelText} />;
}

const noop = () => null;

const DSUploader = ({
  containerProps = {},
  label = 'Browse', // todo: i18n
  icon: UploadFileIcon = UploadFile,
  defaultActionLabel = 'LOCAL DRIVE', // todo: i18n
  dragActiveInstructionalText = 'DROP FILES HERE',
  dragActiveIcon: DragActiveIcon = UploadFile,
  altActionLabel = 'DOCUMENTS', // todo: i18n
  acceptedTypesText = 'JPG, JPEG, PNG, PDF',
  disabledDefault = false,
  disabledAlt = false,
  onDefaultHandlerClick = noop,
  onAltHandlerClick = noop,
  actions = { defaultAction: 'LOCAL DRIVE' },
  showAltAction = false,
  defaultActionTooltipText,
  altActionTooltipText,
  ...dropzoneProps
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone(
    dropzoneProps,
  );
  const { onClick: onOpenFile, ...rootProps } = getRootProps();
  const disabledAll = disabledDefault && disabledAlt;

  const delfaultActionButton = (
    <ActionButton
      variant={disabledDefault && BUTTON_VARIANT.DISABLED}
      labelText={
        actions.defaultAction ? actions.defaultAction : defaultActionLabel
      }
      onClick={(...args) => {
        if (!disabledDefault)
          runAll(onDefaultHandlerClick, onOpenFile)(...args);
      }}
    />
  );

  const altActionButton = (
    <ActionButton
      variant={disabledAlt && BUTTON_VARIANT.DISABLED}
      labelText={actions.altAction ? actions.altAction : altActionLabel}
      onClick={(...args) => {
        if (!disabledAlt) onAltHandlerClick(...args);
      }}
    />
  );

  const defaultAction = !defaultActionTooltipText ? (
    delfaultActionButton
  ) : (
    <DSTooltip
      containerProps={{ id: 'default-action-tooltip' }}
      interactionType="hover"
      title={defaultActionTooltipText}
      triggerComponent={delfaultActionButton}
    />
  );

  const altAction = !altActionTooltipText ? (
    altActionButton
  ) : (
    <DSTooltip
      containerProps={{ id: 'alt-action-tooltip' }}
      interactionType="hover"
      title={altActionTooltipText}
      triggerComponent={altActionButton}
    />
  );

  return (
    <UploaderWrapper {...containerProps} classProps={{ disabled: disabledAll }}>
      <Uploader {...rootProps} classProps={{ isDragActive }}>
        <UploaderIconArea>
          <UploadFileIcon size="l" />
        </UploaderIconArea>
        <DividerVertical />
        <UploaderInfo>
          <InfoText>{label}</InfoText>
          <UploaderActions>
            {actions.defaultAction && defaultAction}
            {(actions.altAction || showAltAction) && (
              <>
                {actions.defaultAction && <DividerVertical />}
                {altAction}
              </>
            )}
          </UploaderActions>
        </UploaderInfo>
        {!disabledAll && (
          <UploaderDragActive>
            <DragActiveIcon />
            {dragActiveInstructionalText}
          </UploaderDragActive>
        )}
        {!disabledAll && <input {...getInputProps()} />}
      </Uploader>
      {typeof acceptedTypesText === 'string' && (
        <AcceptedTypes>{acceptedTypesText.toUpperCase()}</AcceptedTypes>
      )}
    </UploaderWrapper>
  );
};

DSUploader.actions = {
  defaultAction: 'LOCAL DRIVE',
  altAction: 'DOCUMENTS',
};

/** Inherits the props of react-dropzone https://react-dropzone.netlify.com/ */

const uploaderProps = {
  containerProps: PropTypes.object.description(
    'Set of Properties attached to the main container',
  ),
  dropzoneProps: PropTypes.object.description(
    'props of react-dropzone https://react-dropzone.netlify.com/',
  ),
  label: PropTypes.string.description('Uploader label').defaultValue('Browse'),
  icon: PropTypes.element
    .description('Uploader left icon')
    .defaultValue(UploadFile),
  actions: PropTypes.shape({
    defaultAction: PropTypes.string,
    altAction: PropTypes.string,
  })
    .description('Uploader actions')
    .defaultValue({ defaultAction: 'LOCAL DRIVE' }),
  defaultActionLabel: PropTypes.string.description('First action button label'),
  showAltAction: PropTypes.bool
    .description('whether to show alternate action button or not')
    .defaultValue(true),
  altActionLabel: PropTypes.string
    .description('Second action button label')
    .defaultValue('LOCAL DRIVE'),
  dragActiveInstructionalText: PropTypes.string
    .description(
      'informative text when user drags a file over the uploader zone',
    )
    .defaultValue('DROP FILES HERE'),
  dragActiveIcon: PropTypes.string.description(
    'Icon when user drags a file over the uploader zone',
  ),
  acceptedTypesText: PropTypes.string
    .description('Information text to show what types are supported')
    .defaultValue('JPG, JPEG, PNG, PDF'),
  disabledDefault: PropTypes.bool
    .description('Whether the default uploader is disabled or not')
    .defaultValue(false),
  disabledAlt: PropTypes.bool
    .description('Whether the alternative uploader is disabled or not')
    .defaultValue(false),
  onDefaultHandlerClick: PropTypes.func.description(
    'First action button click handler',
  ),
  onAltHandlerClick: PropTypes.func.description(
    'Second action button click handler',
  ),
  defaultActionTooltipText: PropTypes.string.description(
    'shows tooltip in default action button',
  ),
  altActionTooltipText: PropTypes.string.description(
    'Shows tooltip in alternate action button',
  ),
};

DSUploader.propTypes = uploaderProps;

const UploaderWithSchema = describe(DSUploader);
UploaderWithSchema.propTypes = uploaderProps;

export { UploaderWithSchema };
export default DSUploader;
