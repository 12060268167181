/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M7 3h2v2H7V3zM11 3h2v2h-2V3zM7 7h2v2H7V7zM11 7h2v2h-2V7zM7 11h2v2H7v-2zM11 11h2v2h-2v-2zM7 15h2v2H7v-2zM11 15h2v2h-2v-2z',
  ],
  height: 20,
  width: 20,
});
