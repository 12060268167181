import Popper from 'popper.js';
import { useEffect, useRef, useState } from 'react';

function calculateOffset({ offset, showArrow }) {
  const arrowSize = 10;
  const defaultPadding = 3;
  return {
    enabled: showArrow || offset,
    offset: Array.isArray(offset)
      ? `${offset[0]}, ${(offset[1] || offset[0]) + arrowSize + defaultPadding}`
      : `0, ${arrowSize + defaultPadding}`,
  };
}

export default function usePopper({
  offset,
  showArrow,
  placement,
  isOpen,
  modifiers,
  eventsEnabled = true,
  positionFixed,
  boundaries = 'scrollParent',
  referenceEl: refEl,
}) {
  const [popperData, setPopperData] = useState({});
  // this is util for deferred rendering like with portals
  const [dynamicPopperNode, setPopperNode] = useState(null);
  const referenceRef = useRef();
  const popperRef = useRef();
  const arrowRef = useRef();
  const popper = useRef();

  // initialize popper with refs and options
  useEffect(() => {
    const referenceEl = refEl || referenceRef.current;
    const popperEl = dynamicPopperNode || popperRef.current;
    const arrowEl = arrowRef.current;

    popper.current =
      referenceEl &&
      popperEl &&
      new Popper(referenceEl, popperEl, {
        placement,
        isOpen,
        eventsEnabled,
        positionFixed,
        modifiers: {
          preventOverflow: {
            padding: 0,
            boundariesElement: boundaries,
            ...modifiers.preventOverflow,
          },
          offset: calculateOffset({
            offset,
            showArrow,
          }),
          ...modifiers,
          arrow: {
            enabled: !!arrowEl,
            element: arrowEl,
          },
          applyStyle: { enabled: false },
          updateStateModifier: {
            order: 900,
            enabled: true,
            fn: (data) => {
              setPopperData(data);
              return data;
            },
          },
        },
      });

    return () => {
      if (popper.current) popper.current.destroy();
    };
  }, [
    refEl,
    dynamicPopperNode,
    placement,
    showArrow,
    offset,
    popperRef,
    eventsEnabled,
  ]);

  // update popper instance after initialization
  useEffect(() => {
    if (!popper.current) return;
    popper.current.update();
  }, [popper.current]);

  const arrow = {
    ref: arrowRef,
    style: popperData.arrowStyles,
  };
  const popperProps = {
    style: popperData.styles,
    ref: setPopperNode,
    placement: popperData.placement,
    'data-testid': 'popover-container',
  };
  const reference = {
    ref: referenceRef,
  };
  return {
    popperInstance: popper.current,
    popper: popperProps,
    arrow,
    reference,
    // setPopperNode,
    popperData,
  };
}
