import memoizeOne from 'memoize-one';
import { uniqBy, groupBy } from '@elliemae/ds-utilities/utils';

const getFieldValue = ({
  id,
  fixed = false,
  value = '',
  customValue = undefined,
  options = [],
  ...rest
}) => ({
  ...rest,
  id,
  fixed,
  options,
  value: customValue || value,
});

const getGroupedFilterValues = (result, values) => {
  const { group, label, options } = values.length ? values[0] : {};
  return [
    ...result,
    {
      type: options ? 'dropdown' : 'default',
      group,
      label,
      values: values.map(getFieldValue),
    },
  ];
};

export const prepareFilters = memoizeOne(filters => {
  const uniqFilters = uniqBy(filters, v => [v.group, v.value].join());
  const groupedFiltersObject = groupBy(
    uniqFilters,
    filter => `${filter.group}-${!!filter.options}`,
  );

  return Object.keys({
    ...(groupedFiltersObject.all && { all: groupedFiltersObject.all }),
    ...groupedFiltersObject,
  })
    .map(key => groupedFiltersObject[key])
    .reduce(getGroupedFilterValues, []);
});
