/* eslint-disable complexity */
/* eslint-disable max-lines */
import React, { Fragment } from 'react';
import { PropTypes, describe } from 'react-desc';
import { aggregatedClasses } from '@elliemae/ds-classnames';
import getComponentFromProps from '@elliemae/ds-utilities/getComponentFromProps';
import Group from '@elliemae/ds-shared/GroupContext/Group';
import DSInputGroup from '../InputGroup';
import DSFloatingLabelInput from '../FloatingLabelInput';
import DSTextBox from '../TextBox';
import DSLabel from './Label/DSLabel';
import Feedback from './Feedback';
import DSError from './Error/DSError';
import { Suffix, SuffixInputWrapper, SuffixWrapper } from './Suffix/Suffix';
// import { maskTypes } from '../InputMask/MaskTypes';

export { DSError };

const blockName = 'form-item';

const Wrapper = aggregatedClasses('div')(
  blockName,
  null,
  ({ leftLabel, variant }) => ({
    'with-left-label': leftLabel,
    [variant]: variant,
  }),
);
const WrapperContent = aggregatedClasses('div')(`${blockName}-content`);
const WrapperContentGroup = aggregatedClasses('fieldset')(
  `${blockName}-content`,
);

export const FORMITEM_LAYOUT_VARIANTS = {
  DEFAULT: 'variant-default',
  FOCUS: 'variant-focus',
  ACTIVE: 'variant-active',
  DISABLED: 'variant-disabled',
  READ_ONLY: 'variant-read-only',
  OPTIONAL: 'variant-optional',
};

const DSFormItemLayout = ({
  leftLabel = false,
  floatingLabel = false,
  name,
  id,
  value,
  inputComponent: InputComponent = DSTextBox,
  labelComponent: LabelComponent = DSLabel,
  feedbackComponent: FeedbackComponent = Feedback,
  labelText,
  truncateText,
  hasError = false,
  // orientation = 'vertical', // one of [ vertical, horizontal ]
  validationState, // one of [ success, error, warning ]
  validationMessage = '',
  feedbackMessage = '',
  readOnly,
  focused = false,
  type,
  autoFocus = () => {},
  disabled = false,
  onChange = () => {},
  onBlur = () => {},
  onFocus = () => {},
  onPaste = () => {},
  onKeyUp = () => {},
  extraInputProps = {},
  extraContent,
  leftAddon = null,
  rightAddon = null,
  required = false,
  optional = false,
  mask,
  useSubfix = '',
  suffix = '',
  variant = FORMITEM_LAYOUT_VARIANTS.DEFAULT,
  containerProps = {},
  ...rest
}) => {
  const isGroup = InputComponent.type === (<Group />).type;
  const floatingLabelOverride = floatingLabel === 'UNSAFE';
  const WrapperContentComp = !isGroup ? WrapperContent : WrapperContentGroup;

  // group the input props to not duplicate it inside the specific inputComponent (floating or not)
  const inputProps = {
    ...extraInputProps,
    disabled,
    hasError: hasError || validationState === 'error',
    id,
    name,
    type,
    autofocus: autoFocus,
    onBlur,
    onChange,
    onFocus,
    onPaste,
    onKeyUp,
    readOnly,
    value,
    mask,
    useSubfix,
    variant,
    required,
    ...rest,
  };

  const renderInput = (Input) =>
    leftAddon || rightAddon ? (
      <DSInputGroup
        leftAddon={leftAddon}
        rightAddon={rightAddon}
        variant={variant}
      >
        {Input}
      </DSInputGroup>
    ) : (
      Input
    );

  const renderFloating = (
    <DSFloatingLabelInput
      {...inputProps}
      extraInputProps={extraInputProps}
      focused={focused}
      inputComponent={InputComponent}
      labelComponent={LabelComponent}
      labelText={labelText}
      optional={optional}
      required={required}
      variant={variant}
    />
  );

  const InputWithLabel = floatingLabelOverride ? (
    renderInput(renderFloating)
  ) : (
    <>
      <LabelComponent
        htmlFor={id || name}
        label={labelText}
        optional={optional}
        required={required}
        isGroup={isGroup}
        truncateText={truncateText}
      />
      {renderInput(getComponentFromProps(InputComponent, inputProps))}
    </>
  );

  if (suffix) {
    return (
      <Wrapper
        classProps={{
          disabled,
          readOnly,
          hasError,
          leftLabel,
          variant,
        }}
        {...containerProps}
      >
        <SuffixWrapper className="suffix-wrapper">
          <SuffixInputWrapper className="suffix-input-wrapper">
            <WrapperContentComp>
              {InputWithLabel}
              {extraContent}
            </WrapperContentComp>
            <FeedbackComponent
              hasError={hasError}
              text={
                validationState || hasError
                  ? validationMessage
                  : feedbackMessage
              }
            />
          </SuffixInputWrapper>
          <Suffix
            className="suffix-label"
            label={!!labelText}
            withFeedback={Boolean(validationMessage || feedbackMessage)}
          >
            {suffix}
          </Suffix>
        </SuffixWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper
      classProps={{
        disabled,
        readOnly,
        hasError,
        leftLabel,
        variant,
      }}
      {...containerProps}
    >
      <WrapperContentComp>
        {InputWithLabel}
        {extraContent}
      </WrapperContentComp>
      <FeedbackComponent
        hasError={hasError}
        text={validationState || hasError ? validationMessage : feedbackMessage}
      />
    </Wrapper>
  );
};

const props = {
  /** props to inject to form item layout wrapper */
  containerProps: PropTypes.object.description(
    'props to inject to form item layout wrapper',
  ),
  /** position label on the left */
  leftLabel: PropTypes.bool.description('position label on the left'),
  /** turn on floating label */
  floatingLabel: PropTypes.bool.description('turn on floating label'),
  /** id for form item layout */
  id: PropTypes.string.description('id for form item layout'),
  /** html name prop for form tags */
  name: PropTypes.string.description('html name prop for form tags'),
  /** form item layout element value */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).description('form item layout element value'),
  /** Any input component */
  inputComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
  ]).description('Any input component'),
  /** Label component  */
  labelComponent: PropTypes.element.description('Label component'),
  /** feedback component to embed on form item */
  feedbackComponent: PropTypes.node.description(
    'feedback component to embed on form item',
  ),
  /** label text */
  labelText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).description('label text'),
  /** error state */
  hasError: PropTypes.bool.description('error state'),
  /** validation state */
  validationState: PropTypes.oneOf(['success', 'error', 'warning']).description(
    'validation state',
  ),
  /** validation message */
  validationMessage: PropTypes.string.description('validation message'),
  /** feedback message */
  feedbackMessage: PropTypes.string.description('feedback message'),
  /** read only form item */
  readOnly: PropTypes.bool.description('read only form item'),
  /** focused state */
  focused: PropTypes.bool.description('focused state'),
  /** input type */
  type: PropTypes.string.description('input type'),
  /** auto focus input */
  autoFocus: PropTypes.func.description('auto focus input'),
  /** disabled state */
  disabled: PropTypes.bool.description('disabled state'),
  /** on change handler */
  onChange: PropTypes.func.description('on change handler'),
  /** on blur handler */
  onBlur: PropTypes.func.description('on blur handler'),
  /** extra input props to inject */
  extraInputProps: PropTypes.object.description('extra input props to inject'),
  /** extra content */
  extraContent: PropTypes.element.description('extra content'),
  /** left addon for form item */
  leftAddon: PropTypes.element.description('left addon for form item'),
  /** right addon for form item */
  rightAddon: PropTypes.element.description('right addon for form item'),
  /** add required mark */
  required: PropTypes.bool.description('add required mark'),
  /** add optional mark */
  optional: PropTypes.bool.description('add optional mark'),
  /** on focus handler */
  onFocus: PropTypes.func.description('on focus handler'),
  /** on paste handler */
  onPaste: PropTypes.func.description('on paste handler'),
  /** add truncation and ellipsis */
  truncateText: PropTypes.bool.description('add truncation and ellipsis'),
  /** on key up event handler */
  onKeyUp: PropTypes.func.description('on key up event handler'),
  /** mask for input mask component */
  mask: PropTypes.oneOfType([
    // PropTypes.oneOf(maskTypes),
    PropTypes.string,
    PropTypes.func,
  ]).description('mask for input mask component'),
  /** add suffix to input */
  useSubfix: PropTypes.string.description('add suffix to input'),
  /** suffix for input */
  suffix: PropTypes.string.description('suffix for input'),
  /** form item layout type */
  variant: PropTypes.oneOf([
    FORMITEM_LAYOUT_VARIANTS.DEFAULT,
    FORMITEM_LAYOUT_VARIANTS.FOCUS,
    FORMITEM_LAYOUT_VARIANTS.ACTIVE,
    FORMITEM_LAYOUT_VARIANTS.DISABLED,
    FORMITEM_LAYOUT_VARIANTS.READ_ONLY,
    FORMITEM_LAYOUT_VARIANTS.OPTIONAL,
  ]).description('form item layout type'),
};

DSFormItemLayout.propTypes = props;
const DSFormItemLayoutWithSchema = describe(DSFormItemLayout);
DSFormItemLayoutWithSchema.propTypes = props;

export default DSFormItemLayout;
export { DSFormItemLayoutWithSchema };
