/* eslint-disable react/prop-types */
/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import DSTextBox from '@elliemae/ds-basic/form/TextBox';

// todo: redo with hooks
export default class TextBox extends Component {
  static defaultProps = {
    onValue: () => null,
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = { value: props.value };
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.inputRef && this.inputRef.current) {
        this.inputRef.current.focus();
      }
    }, 50);
  }

  handleKeyUp({ key, target: { value } }) {
    const { onValue } = this.props;
    if (key === 'Enter') {
      onValue(value);
    }
  }

  handleKeyDown(e) {
    // PUI-2553 prevent form submit on `Enter` keypress
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  handleBlur(e) {
    const { onValue } = this.props;
    const {
      target: { value },
    } = e;
    onValue(value);
  }

  handleChange({ target: { value } }) {
    this.setState({ value });
  }

  render() {
    const { value } = this.state;
    return (
      <DSTextBox
        data-testid="ds-datagrids-editable-text-box"
        {...this.props}
        innerRef={this.inputRef}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onClick={(e) => e.stopPropagation()}
        onKeyUp={this.handleKeyUp}
        onKeyDown={this.handleKeyDown}
        value={value}
      />
    );
  }
}
