import React from 'react';
import { aggregatedClasses } from '@elliemae/ds-classnames';
import Paginator from './Paginator';
import PerPageDropdown from './PerPageDropdown';

const PaginationControls = aggregatedClasses('div')(
  'data-grid',
  'controls-pagination',
);

const Pagination = ({
  paginated,
  pagination = {},
  onPerPage,
  onPageSelect,
  paginationStep = 5,
  maxPerPage = 10,
  minPerPage = 5,
}) => {
  const { perPage } = pagination;
  if (
    pagination &&
    pagination.conditionalPagination === true &&
    paginated.amount === 1
  )
    return null;
  return paginated.amount > 0 ? (
    <PaginationControls>
      <PerPageDropdown
        max={maxPerPage}
        min={minPerPage}
        onChange={onPerPage}
        step={paginationStep}
        value={perPage}
      />
      <Paginator
        onSelect={onPageSelect}
        pages={paginated.amount}
        pagination={pagination}
      />
    </PaginationControls>
  ) : null;
};

export { Pagination };
