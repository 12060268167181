import React from 'react';
import DSCheckbox from '../../form/Checkbox';
import MenuItemCheckable from './MenuItemCheckable';

function MenuItemCheckbox({ checked, ...rest }) {
  return (
    <MenuItemCheckable
      {...rest}
      leftAddon={<DSCheckbox checked={checked} />}
      role="menuitemcheckbox"
    />
  );
}

export default MenuItemCheckbox;
