/* eslint-disable react/no-array-index-key */
import React from 'react';
import { describe, PropTypes } from 'react-desc';
import { convertPropToCssClassName } from '@elliemae/ds-classnames';

/**
 * if we don't care about word break
 * const regx = new RegExp(`.{1,${maxCharacters}}`, 'g')
 * text.match(regx)
 *
 * @param {*} param0
 */
const DSTextWrapper = ({
  containerProps = {},
  className = '',
  style = {},
  text = '',
  maxCharacters = 0,
  maxWidth = '100%',
  textEllipsis = false,
  textAlignment = 'left',
  wordBreak = true,
}) => {
  const { cssClassName, classNameElement } = convertPropToCssClassName(
    'text-wrapperRef',
    className,
    {
      textEllipsis,
      textAlignment,
      wordBreak,
    },
  );
  const limitPerCharacter = () =>
    text.split(' ').reduce(
      (result, word) => {
        let resultIndex = result.length - 1;
        if (result[resultIndex].length + word.length > maxCharacters)
          resultIndex += 1;
        result[resultIndex] = `${result[resultIndex] || ''} ${word}`;
        return result;
      },
      [''],
    );

  let displayText = text;

  if (textEllipsis)
    displayText =
      maxCharacters > 0 ? displayText.substring(0, maxCharacters) : displayText;
  else if (maxCharacters > 0) {
    displayText = limitPerCharacter().map((textpart, index) => (
      <span key={index} className={classNameElement('line')}>
        {textpart}
      </span>
    ));
  }

  return (
    <span
      {...containerProps}
      className={cssClassName}
      style={{
        maxWidth: maxCharacters < 1 ? maxWidth : null,
        ...style,
      }}
    >
      {textEllipsis ? (
        <span className={classNameElement('ellipsis-wrapperRef')}>
          {displayText}
        </span>
      ) : (
        displayText
      )}
    </span>
  );
};

const textWrapperProps = {
  containerProps: PropTypes.object.description(
    'Set of Properties attached to the main container',
  ),
  className: PropTypes.string.description('css class'),
  style: PropTypes.object.description('css style'),
  text: PropTypes.string.description('Text to display').isRequired,
  maxCharacters: PropTypes.number
    .description('Maximum amount of charachters allowed')
    .defaultValue(0),
  maxWidth: PropTypes.string
    .description('Maximum width allowed')
    .defaultValue('100%'),
  textEllipsis: PropTypes.bool
    .description('Whether to show ellipsis or not')
    .defaultValue(false),
  textAlignment: PropTypes.oneOf(['left', 'center', 'right'])
    .description('Text alignement')
    .defaultValue('left'),
  wordBreak: PropTypes.bool
    .description('Whether to break the line or not')
    .defaultValue(true),
};

DSTextWrapper.propTypes = textWrapperProps;

const TextWrapperWithSchema = describe(DSTextWrapper);
TextWrapperWithSchema.propTypes = textWrapperProps;

export { TextWrapperWithSchema };
export default DSTextWrapper;
