import { toggleInObject } from '@elliemae/ds-utilities/utils';

export const checkAllRowsSelected = (rows, selectedRows) => {
  if (rows.length && selectedRows.length === rows.length) return true;
  if (selectedRows.length) return 'mixed';
  return false;
};

export const toggleSelectAll = (rows, checked) =>
  !checked
    ? {}
    : rows.reduce(
      (nextSelRows, row) => toggleInObject(nextSelRows, row.id, true),
        {},
    );

export const toggleSingleSelectable = (selectedRows, id) =>
  !selectedRows[id] ? toggleInObject({}, id, true) : {};

const mergeSelectedToMetaData = selected => row => ({
  ...row,
  'metaData.selected': selected,
});

export const selectSingleRow = (rows, filter) =>
  rows.map(row => mergeSelectedToMetaData(filter(row))(row));

export const selectMultipleRow = (rows, isRowSelectedPredicate) =>
  rows.map(row => mergeSelectedToMetaData(isRowSelectedPredicate(row))(row));
