/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M17.379 2.621c-.414-.414-.957-.621-1.5-.621s-1.086.207-1.5.621L4 13l-2 5 5-2L17.377 5.624c.001-.001.002-.001.003-.002.828-.828.828-2.172 0-3zM6.438 15.148l-2.643 1.057 1.057-2.643 9.173-9.173 1.586 1.586-9.173 9.173zm8.294-11.466l.354-.354c.212-.212.493-.328.793-.328s.581.117.793.328c.212.212.328.493.328.793 0 .29-.11.564-.309.773l-.022.022-.351.351-1.586-1.586z',
  ],
  height: 20,
  width: 20,
});
