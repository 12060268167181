/* eslint-disable react/prop-types */
import React from 'react';
import ChevronSmallDown from '@elliemae/ds-icons/ChevronSmallDown';
import DSButton from '../../../../Button';

const DropdownIndicator = ({
  innerRef,
  onKeyDown,
  clearValue,
  getStyles,
  getValue,
  setValue,
  hasStyle,
  hasValue,
  isMulti,
  isRtl,
  isDisabled,
  isFocused,
  selectOption,
  selectProps,
  innerProps,
  ...rest
}) => (
  <DSButton
    aria-label="dropdown-indicator"
    buttonType="text"
    className="dropdown-indicator"
    icon={<ChevronSmallDown />}
    innerRef={innerRef}
    onKeyDown={onKeyDown}
    {...rest}
  />
);

export default DropdownIndicator;
