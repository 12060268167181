/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M16.18 3H14v-.52c0-.27-.21-.48-.48-.48h-.05c-.26 0-.47.21-.47.48V3H7v-.52C7 2.21 6.79 2 6.52 2h-.04c-.27 0-.48.21-.48.48V3H3.82C2.82 3 2 3.82 2 4.82v10.36c0 1 .82 1.82 1.82 1.82h12.36c1 0 1.82-.82 1.82-1.82V4.82c0-1-.82-1.82-1.82-1.82zM17 15.18c0 .45-.37.82-.82.82H3.82c-.45 0-.82-.37-.82-.82V7h14v8.18zM3 6V4.82c0-.45.37-.82.82-.82H6v.52c0 .27.21.48.48.48h.05c.26 0 .47-.21.47-.48V4h6v.52c0 .27.21.48.48.48h.05c.26 0 .47-.21.47-.48V4h2.18c.45 0 .82.37.82.82V6H3z',
    'M5 9h2v2H5V9zM13 9h2v2h-2V9zM9 9h2v2H9V9zM5 12h2v2H5v-2zM9 12h2v2H9v-2z',
  ],
  height: 20,
  width: 20,
});
