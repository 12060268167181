/* eslint-disable react/destructuring-assignment,max-lines,react/display-name, react/require-default-props */
import React from 'react';
import DSTruncatedTooltipText from '../../../../TruncatedTooltipText';
import DSCheckbox from '../../../Checkbox';

const CustomOptionMulti = ({ index, data, style, isScrolling }) => {
  const {
    items,
    options: { useTruncatedText },
  } = data;

  const option = items[index];
  const optionProps = {
    ...option.props.innerProps,
    style,
    onClick: e => {
      e.stopPropagation();
      if (option.props.innerProps.onClick) option.props.innerProps.onClick(e);
    },
  };
  if (useTruncatedText && !isScrolling) {
    return React.cloneElement(
      option,
      {
        ...option.props,
        innerProps: optionProps,
      },
      <DSTruncatedTooltipText value={option.props.children} />,
    );
  }

  return React.cloneElement(option, {
    ...option.props,
    innerProps: optionProps,
    children: (
      <div className="combobox-v2-option-multiple-container">
        <DSCheckbox
          checked={option.props.isSelected}
          labelText={option.props.children}
        />
      </div>
    ),
  });
};

export default CustomOptionMulti;
