/* eslint-disable react/prop-types */
import React from 'react';
import cx from 'obj-str';
import convertPropToCssClassName from './classNameManager';
import DimsumConfig from '../../../dimsum.config';
import {
  isValidNativeAttribute,
  isNativeHTMLElement,
} from './validateNativeProps';

export const cssPrefix = DimsumConfig.classNamePrefix;

const generateClassNameWithModifier = (block, element, modifier) =>
  `${cssPrefix}-${block}${element ? `__${element}` : ''}--${modifier}`;

const appendBlockAndPrefixToObj = (obj, block, element) =>
  obj
    ? Object.keys(obj).reduce(
        (acc, key) => ({
          ...acc,
          [`${generateClassNameWithModifier(block, element, key)}`]: obj[key],
        }),
        {},
      )
    : [];

function aggregatedClasses(type, props) {
  // aggregatedClasses options parameters
  return (block, element, additionalModifiers, options = {}) =>
    React.forwardRef(
      (
        {
          innerRef,
          children,
          classProps,
          useComponent,
          className,
          as,
          ...rest
        },
        ref,
      ) => {
        // get the additional classNames either from a function or an object
        let otherModifiers = appendBlockAndPrefixToObj(
          additionalModifiers,
          block,
          element,
        );
        if (typeof additionalModifiers === 'function')
          otherModifiers = appendBlockAndPrefixToObj(
            additionalModifiers({ ...rest, ...classProps, className }),
            block,
            element,
          );

        const otherModifiersStr = cx(otherModifiers);
        const { prefix, propsToRemoveFromFinalElement = [] } = options;
        const { cssClassName: classNames } = convertPropToCssClassName(
          block,
          null,
          { ...classProps },
          { prefix, element },
        );
        const cleanedPropsPassedDown = { ...props, ...rest };
        propsToRemoveFromFinalElement.forEach((propKey) => {
          delete cleanedPropsPassedDown[propKey];
        });

        if (isNativeHTMLElement(block)) {
          Object.keys(cleanedPropsPassedDown).forEach((key) => {
            if (!isValidNativeAttribute(key))
              delete cleanedPropsPassedDown[key];
          });
        }

        return React.createElement(
          as || type,
          {
            ref: innerRef || ref,
            className: [classNames, otherModifiersStr, className].join(' '),
            ...cleanedPropsPassedDown,
          },
          children,
        );
      },
    );
}

export default aggregatedClasses;
