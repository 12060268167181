/* eslint-disable max-lines */
import React, { useState, useCallback, useEffect } from 'react';
import { PropTypes, describe } from 'react-desc';
import { runAll } from '@elliemae/ds-utilities/utils';
import DSInput from '../Input';
import DateInputImpl from './components/DateInputImpl';

const currentYear = new Date().getFullYear();

const noop = () => null;

const DSDateInput = ({
  innerRef,
  className = '',
  style = {},
  onFocus = noop,
  onBlur = noop,
  onClear = noop,
  onChange = noop,
  onKeyDown = noop,
  onError = noop,
  format = 'MM:DD:YYYY',
  value = '',
  clearable = true,
  disabled = false,
  yearMaxRange = currentYear + 100,
  yearMinRange = currentYear - 100,
  containerProps = {},
  // TODO remove and keep `true` behavior ( PUI-4141 )
  INTERNAL_V2_NO_MUTATION = false, // eslint-disable-line react/prop-types
}) => {
  const [valueDate, setValueDate] = useState(value);

  useEffect(() => {
    if (value) {
      setValueDate(value);
    }
  }, [value]);

  const handleOnClear = useCallback(() => {
    runAll(onClear, setValueDate(''));
  }, []);

  const handleKeyDown = useCallback((e, date, inputOnKeyDown) => {
    inputOnKeyDown(e, date);
  }, []);

  const handleOnChange = useCallback((date, inputOnChange) => {
    setValueDate(date);
    inputOnChange(date);
  }, []);

  return (
    <DSInput
      className={`${className} input-date-input `}
      clearable={clearable}
      containerProps={containerProps}
      customInputType={({
        onChange: inputOnChange,
        onKeyDown: inputOnKeyDown,
        ...restInputProps
      }) => (
        <DateInputImpl
          INTERNAL_V2_NO_MUTATION={INTERNAL_V2_NO_MUTATION}
          disabled={disabled}
          format={format}
          onChange={(date) => handleOnChange(date, inputOnChange)}
          onError={onError}
          onKeyDown={(e, date) => handleKeyDown(e, date, inputOnKeyDown)}
          value={valueDate}
          {...restInputProps}
          style={style}
          yearMaxRange={yearMaxRange}
          yearMinRange={yearMinRange}
          onFocus={onFocus}
        />
      )}
      disableTooltip={false}
      disabled={disabled}
      innerRef={innerRef}
      onBlur={onBlur}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onClear={handleOnClear}
      value={valueDate}
    />
  );
};

const props = {
  /** props injected to date input wrapper node */
  containerProps: PropTypes.object.description(
    'props injected to date input wrapper node',
  ),
  /** inner ref for input wrapper */
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).description('inner ref for input wrapper'),
  /** class for input */
  className: PropTypes.string.description('class for input'),
  /**
   * Set style for the date input
   */
  style: PropTypes.object.description('Set style for the date input'),
  /**
   * Triggers the onBlur when focus moves out of date input
   */
  onBlur: PropTypes.func.description(
    'Triggers the onBlur when focus moves out of date input',
  ),
  /**
   * Triggers the onFocus when the component gets the focus
   */
  onFocus: PropTypes.func.description(
    'Triggers the onFocus when the component gets the focus',
  ),
  /**
   * Triggers the onClear
   */
  onClear: PropTypes.func.description('Triggers the onClear'),
  /**
   * Triggers the onKeyDown
   */
  onKeyDown: PropTypes.func.description('Triggers the onKeyDown'),
  /**
   * Allows a function that is triggered once the date input changes
   */
  onChange: PropTypes.func.description(
    'Allows a function that is triggered once the date input changes',
  ),
  /**
   * Triggers the onError once one input date is wrong
   */
  onError: PropTypes.func.description(
    'Triggers the onError once one input date is wrong',
  ),
  /**
   * Format type for date input
   */
  format: PropTypes.string.description('Format type for date input'),
  /**
   * Default value once the component is initialized
   */
  value: PropTypes.string.description(
    'Default value once the component is initialized',
  ),
  /**
   * Whether the date input is clearable or not
   */
  clearable: PropTypes.bool.description(
    'Whether the date input is clearable or not',
  ),
  /**
   * Whether the date input is disabled or not
   */
  disabled: PropTypes.bool.description(
    'Whether the date input is disabled or not',
  ),
  /**
   * Max year that can be accepted by the date input
   */
  yearMaxRange: PropTypes.number.description(
    'Max year that can be accepted by the date input',
  ),
  /**
   * Min year that can be accepted by the date input
   */
  yearMinRange: PropTypes.number.description(
    'Min year that can be accepted by the date input',
  ),
};

DSDateInput.propTypes = props;
const DSDateInputWithSchema = describe(DSDateInput).deprecated(
  'use DateInputV2 instead',
);
DSDateInputWithSchema.propTypes = props;

export { DateInputImpl, DSDateInputWithSchema };

export default DSDateInput;
