import { useRef } from 'react';

export default function useExecutionDelay(startFn, cleanFn, delayTime) {
  const startTimer = useRef();
  const closeTimer = useRef();

  const startFunction = (...args) => {
    clearTimeout(closeTimer.current);
    startTimer.current = setTimeout(() => startFn(...args), delayTime);
  };

  const cleanFunction = (...args) => {
    clearTimeout(startTimer.current);
    closeTimer.current = setTimeout(() => cleanFn(...args), 30);
  };
  return [startFunction, cleanFunction];
}
