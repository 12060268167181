import { useCallback, useState } from 'react';

export default function(initialState = undefined) {
  const [visible, setVisible] = useState(initialState);

  const onShow = useCallback(() => setVisible(true), [visible]);
  const onHide = useCallback(() => setVisible(false), [visible]);
  const onToggle = useCallback(() => setVisible(!visible), [visible]);

  return {
    onShow,
    onHide,
    onToggle,
    visible: initialState !== undefined ? initialState : visible,
  };
}
