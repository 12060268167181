/* eslint-disable consistent-return */
import styled from 'styled-components';

export const Suffix = styled.div`
  height: 2.16rem;
  align-items: center;
  display: flex;
  ${props => {
    if (props.label && !props.withFeedback) {
      return `
      padding-top: 1.2rem;
      `;
    }
    if (!props.label && !props.withFeedback) {
      return `
      padding-top: 1rem;
      `;
    }
  }}
`;

export const SuffixInputWrapper = styled.div`
  padding-right: 0.32rem;
  flex: 1;
`;
export const SuffixWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default Suffix;
