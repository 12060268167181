import { useCallback, useRef } from 'react';

export default function useHoverHandlersDelay({
  onMouseOver,
  onMouseLeave,
  hideDelay = 300,
  showDelay = 300,
}) {
  const hideTimer = useRef();
  const showTimer = useRef();

  const delayedHide = useCallback(() => {
    clearTimeout(showTimer.current);
    hideTimer.current = setTimeout(onMouseLeave, hideDelay);
  }, []);

  const delayedShow = useCallback(() => {
    clearTimeout(hideTimer.current);
    showTimer.current = setTimeout(onMouseOver, showDelay);
  }, []);

  return {
    onMouseOver: delayedShow,
    onMouseLeave: delayedHide,
  };
}
