import React from 'react';
import { convertPropToCssClassName } from '@elliemae/ds-classnames';

const renderPaths = paths => paths.map((p, i) => <path key={i} d={p} />);

function SvgIcon({
  containerProps = {},
  size,
  color,
  className = '',
  component: Component = 'span',
  viewBox = '0 0 24 24',
  paths,
  fill,
  innerRef,
  ...rest
}) {
  const { cssClassName } = convertPropToCssClassName('icon', className, {
    size,
    color,
  });
  return (
    <Component
      ref={innerRef}
      className={cssClassName}
      {...rest}
      {...containerProps}
    >
      <svg fill={fill} viewBox={viewBox}>
        {renderPaths(paths)}
      </svg>
    </Component>
  );
}

export default SvgIcon;
