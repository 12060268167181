import React from 'react';
import DSSpinner from '@elliemae/ds-basic/Spinner';
import { SpinnerContainer } from './renderers/defaultClassedRenderers';

function RowsLoader({ size = 'm' }) {
  return (
    <SpinnerContainer>
      <DSSpinner size={size} />
    </SpinnerContainer>
  );
}

export default RowsLoader;
