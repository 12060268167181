/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M8.86 13c-.134-.918.134-1.967 1.073-2.885.805-.918 1.207-1.574 1.207-2.361 0-.918-.537-1.443-1.61-1.443-.671 0-1.341.262-1.744.525l-.537-1.18C7.786 5.263 8.859 5 9.798 5c2.012 0 2.951 1.18 2.951 2.492 0 1.18-.671 1.967-1.476 3.016-.939.918-1.207 1.574-1.073 2.492H8.859zM9.5 2c4.687 0 8.5 3.813 8.5 8.5S14.187 19 9.5 19 1 15.187 1 10.5 4.813 2 9.5 2zm0-1C4.275 1 0 5.275 0 10.5S4.275 20 9.5 20s9.5-4.275 9.5-9.5S14.725 1 9.5 1zm0 13c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z',
  ],
  height: 20,
  width: 20,
});
