import React from 'react';
import { Grid } from '@elliemae/ds-basic/Grid';
import WarningTriangle from '@elliemae/ds-icons/WarningTriangle';
import {
  __UNSAFE_SPACE_TO_DIMSUM,
  __UNSAFE_FONT_TO_DIMSUM,
  toMobile,
} from '@elliemae/ds-system';
import DSButton from '@elliemae/ds-basic/Button';
import styled from 'styled-components';

const EmptyStateWrapper = styled(Grid)`
  min-width: 132px; /* fixed size in px according to specs */
  max-width: 384px; /* fixed size in px according to specs */
  height: fit-content;
`;
const Icon = styled(WarningTriangle)`
  fill: ${props => props.theme.colors.neutral[300]};
  color: ${props => props.theme.colors.neutral[300]};
  justify-self: center;
`;
const PrimaryMessage = styled.p`
  text-align: center;
  font-weight: bold;
  font-size: ${props =>
    toMobile(props.theme.fontSizes[__UNSAFE_FONT_TO_DIMSUM(3)])};
  color: ${props => props.theme.colors.neutral[600]};
  margin-top: ${props => __UNSAFE_SPACE_TO_DIMSUM(props.theme.space.s)};
  margin-bottom: ${props => __UNSAFE_SPACE_TO_DIMSUM(props.theme.space.xs)};
`;
const SecondaryMessage = styled.p`
  text-align: center;
  font-size: ${props =>
    toMobile(props.theme.fontSizes[__UNSAFE_FONT_TO_DIMSUM(400)])}; /* 13px */
  color: ${props => props.theme.colors.neutral[500]};
  margin: 0;
`;
const Button = styled(DSButton)`
  margin-top: ${props => __UNSAFE_SPACE_TO_DIMSUM(props.theme.space.m)};
  justify-self: center;
  width: fit-content;
`;

export const EmptyState = ({
  primaryMessage = 'No Results Found',
  secondaryMessage,
  buttonLabel,
  onButtonClick = () => {},
}) => (
  <EmptyStateWrapper>
    <Icon size="xxl" />
    {primaryMessage && <PrimaryMessage>{primaryMessage}</PrimaryMessage>}
    {secondaryMessage && (
      <SecondaryMessage>{secondaryMessage}</SecondaryMessage>
    )}
    {buttonLabel && (
      <Button
        buttonType="secondary"
        labelText={buttonLabel}
        onClick={onButtonClick}
      />
    )}
  </EmptyStateWrapper>
);
