/* eslint-disable import/no-cycle */
/* eslint-disable react/destructuring-assignment,max-lines,react/display-name, react/require-default-props */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DSButton, { BUTTON_VARIANT } from '../../../../Button';
import DSSeparator from '../../../../Separator';
import { DropDownContext } from '../context';

const MultiSelectHeader = (props) => {
  const totalItems = props.children.length;
  const selectedItems = props.children.filter((child) => child.props.isSelected)
    .length;
  const { onSelectAll, selectAllDisabled } = useContext(DropDownContext);
  const isClearButtonDisabled = selectedItems === 0;
  const isAllButtonDisabled = selectedItems === totalItems;

  return (
    <HeaderWrapper>
      <ContentWrapper>
        <HeaderTitle>{`${selectedItems} selected`}</HeaderTitle>
        {!selectAllDisabled && (
          <ActionButtons>
            <StyledButton
              buttonType="text"
              labelText="All"
              size="s"
              onClick={() => onSelectAll(true)}
              aria-disabled={isAllButtonDisabled ? 'true' : 'false'}
              variant={isAllButtonDisabled && BUTTON_VARIANT.DISABLED}
            />
            <StyledSeparator
              orientation="vertical"
              position="center"
              type="group-level"
              margin="none"
            />
            <StyledButton
              buttonType="text"
              labelText="Clear"
              size="s"
              onClick={() => onSelectAll(false)}
              aria-disabled={isClearButtonDisabled ? 'true' : 'false'}
              variant={isClearButtonDisabled && BUTTON_VARIANT.DISABLED}
            />
          </ActionButtons>
        )}
      </ContentWrapper>
      <DSSeparator position="center" type="non-form" margin="none" />
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${(props) => props.theme.space.xs};
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: ${(props) => props.theme.space.s};
  padding-bottom: 4px;
`;

const HeaderTitle = styled.span`
  color: ${(props) => props.theme.colors.neutral[500]};
`;

const ActionButtons = styled.div`
  display: inline-flex;
  color: ${(props) => props.theme.colors.neutral[500]};
`;

const StyledSeparator = styled(DSSeparator)`
  height: auto;
  width: auto;
`;

const StyledButton = styled(DSButton)`
  padding: 0 ${(props) => props.theme.space.xxs};
`;

MultiSelectHeader.propTypes = {
  children: PropTypes.array,
};

export default MultiSelectHeader;
