import { get } from 'lodash';
import theme from './theme';

export function mapGap(gutter) {
  if (!gutter) return '0rem';
  if (String(gutter).includes('rem') || String(gutter).includes('px'))
    return gutter;
  return `${theme.space[gutter]}`;
}

export function mapGutter(gutter) {
  if (!gutter) return '0rem';
  return `${theme.space[gutter]} * 2`;
}

export function mapSpace(width) {
  if (typeof width === 'string')
    return get(theme, width) ? `${get(theme, width)}` : width;
  return `${width * 100}%`;
}

export function fixSpaceGutter(width, gutter) {
  if (!width) return '';
  if (Array.isArray(width))
    return width.map((w) => `calc(${mapSpace(w)} - (${mapGutter(gutter)}))`);
  return `calc(${mapSpace(width)} - (${mapGutter(gutter)}))`;
}

export function fixSpace(width) {
  if (!width) return '';
  if (Array.isArray(width)) return width.map((w) => mapSpace(w));
  return mapSpace(width);
}

/**
 * Grid
 *
 * @param grid
 */
export function numbersToFr(grid) {
  const den = grid.map((f) => (f < 1 ? Math.floor(1 / f) : f));
  return den.map((d) => `${d}fr`);
}
export function mapGrid(width) {
  if (get(theme, width)) return `${get(theme, width)}`;
  if (typeof width === 'string') return width;
  const den = width < 1 ? Math.floor(1 / width) : width;
  return `${den}fr`;
}

export function mapTemplateGrid(grid) {
  if (Array.isArray(grid)) {
    if (grid.some((w) => typeof w === 'string'))
      return grid.map((w) => mapGrid(w, grid));
    return numbersToFr(grid);
  }
  return mapGrid(grid);
}
