/* eslint-disable react/prop-types */
/* eslint-disable max-lines */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'react-dates/initialize';
import {
  DayPickerSingleDateController,
  SingleDatePickerPhrases,
} from 'react-dates';
import { convertPropToCssClassName } from '@elliemae/ds-classnames';
import ChevronLeft from '@elliemae/ds-icons/ChevronLeft';
import { renderMonthElement } from '../DatePickerRenderMonth/DatePickerRenderMonth';
import DatePickerNavigation from '../DatePickerNavigation/DatePickerNavigation';
import DatePickerDay from '../DatePickerDay/DatePickerDay';

const { classNameElement, classNameBlock } = convertPropToCssClassName(
  'datepicker-range',
);

export default class DSDatePickerController extends React.Component {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    date: '',
    value: '',
    enableOutsideDays: false,
    initialVisibleMonth: moment(),
    firstDayOfWeek: 0,
    keepOpenOnDateSelect: true,
    hideKeyboardShortcutsPanel: true,
    onPrevMonthClick: () => null,
    onNextMonthClick: () => null,
    onClose: () => null,
    onChange: () => null,
    onClickEscape: () => null,
    onDateChange: () => null,
    phrases: SingleDatePickerPhrases,
    // displayFormat: 'L',
    displayFormatDay: 'D',
    transitionDuration: 0,
    // verticalSpacing: 0,
    isDayBlocked: () => false,
    isOutsideRange: () => false,
    isDayHighlighted: () => false,
    numberOfMonths: 1,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedDate: this.getSafeValue(),
    };
  }

  // eslint-disable-next-line react/state-in-constructor
  state = {};

  componentDidMount() {
    this.datePicker.addEventListener('mouseup', this.handleMouseUp, false);
    document.addEventListener('keydown', this.handleKeyDown, false);

    setTimeout(() => {
      if (this.datePicker) {
        const [calendarDaySelected] = this.datePicker.getElementsByClassName(
          'CalendarDay__selected',
        );
        calendarDaySelected?.focus();
      }
    }, 400);
  }

  componentWillUnmount() {
    this.datePicker.removeEventListener('mouseup', this.handleMouseUp, false);
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  handleMouseUp = () => {
    const focusedElement = document.activeElement;

    focusedElement.blur();
  };

  handleKeyDown = (event) => {
    const [firstDay, ...restOfDays] = document
      .getElementsByClassName('CalendarMonthGrid_month__horizontal')[1]
      .getElementsByClassName('CalendarDay');
    const [lastDay] = restOfDays.slice(-1);
    const { onClickEscape } = this.props;

    switch (event.key) {
      case 'Home':
        firstDay.focus();
        break;
      case 'End':
        lastDay.focus();
        break;
      case 'Escape':
        onClickEscape();
        break;
      default:
        break;
    }
  };

  getSafeValue = () => {
    const { value, date, defaultValue } = this.props;
    const { selectedDate } = this.state;
    if (value) {
      return moment(value);
    }
    if (date) {
      return moment(date);
    }
    if (defaultValue) {
      return moment(defaultValue);
    }
    if (selectedDate) {
      return moment(selectedDate);
    }
    return null;
  };

  isControlled = () => {
    const { value, date } = this.props;
    return Boolean(value) || Boolean(date);
  };

  handleDateChange = (date) => {
    const { readOnly, onDateChange, onChange } = this.props;
    if (!readOnly) {
      if (!this.isControlled()) {
        this.setState({ selectedDate: date });
      }
      onDateChange(date);
      onChange(date);
    }
  };

  handleChange = (dataInput) => {
    const { onDateChange, onChange } = this.props;
    const momentvalue = moment(dataInput.target.value);
    this.setState({ selectedDate: momentvalue });
    onDateChange(momentvalue);
    onChange(momentvalue);
  };

  handleNavClick = (date, nav) => {
    const [prev, next] = document.getElementsByClassName(
      'DayPickerNavigation_button',
    );
    const { onPrevMonthClick, onNextMonthClick } = this.props;

    if (nav === 'prev') {
      prev.focus();
      onPrevMonthClick(date);
    } else {
      next.focus();
      onNextMonthClick(date);
    }
  };

  render() {
    const {
      numberOfMonths,
      enableOutsideDays,
      transitionDuration,
      initialVisibleMonth,
      firstDayOfWeek,
      keepOpenOnDateSelect,
      hideKeyboardShortcutsPanel,
      onClose,
      phrases,
      displayFormatDay,
      isDayBlocked,
      isOutsideRange,
      isDayHighlighted,
      onOutsideClick,
      key,
      calendarProps,
    } = this.props;

    const safedate = this.getSafeValue();
    const safeinitialMonth =
      safedate && safedate.isValid()
        ? () => safedate
        : () => initialVisibleMonth;

    return (
      <div>
        <div
          ref={(elem) => {
            this.datePicker = elem;
          }}
          className={classNameBlock(
            'wrapper-day-picker-single-date-controller',
          )}
          {...calendarProps}
          data-testid="calendar-wrapper"
        >
          <DayPickerSingleDateController
            key={key}
            date={safedate}
            enableOutsideDays={enableOutsideDays}
            firstDayOfWeek={firstDayOfWeek}
            focused
            hideKeyboardShortcutsPanel={hideKeyboardShortcutsPanel}
            initialVisibleMonth={safeinitialMonth}
            isDayBlocked={isDayBlocked}
            isDayHighlighted={isDayHighlighted}
            isOutsideRange={isOutsideRange}
            keepOpenOnDateSelect={keepOpenOnDateSelect}
            navNext={
              <DatePickerNavigation
                className={classNameElement('navigation-next')}
              />
            }
            navPrev={
              <DatePickerNavigation
                className={classNameElement('navigation-prev')}
                icon={ChevronLeft}
              />
            }
            renderMonthElement={renderMonthElement}
            numberOfMonths={numberOfMonths}
            onClose={onClose}
            onDateChange={this.handleDateChange}
            onFocusChange={this.handleFocus}
            onNextMonthClick={this.handleNavClick('next')}
            onOutsideClick={onOutsideClick}
            onPrevMonthClick={this.handleNavClick('prev')}
            phrases={phrases}
            renderDayContents={(date) => (
              <DatePickerDay
                className={classNameElement('day-contents')}
                date={date.format(displayFormatDay)}
              />
            )}
            transitionDuration={transitionDuration}
          />
        </div>
      </div>
    );
  }
}

DSDatePickerController.propTypes = {
  date: PropTypes.instanceOf(Date, moment),
  value: PropTypes.instanceOf(Date, moment),
  enableOutsideDays: PropTypes.bool,
  initialVisibleMonth: PropTypes.instanceOf(Date, moment),
  firstDayOfWeek: PropTypes.number,
  keepOpenOnDateSelect: PropTypes.bool,
  hideKeyboardShortcutsPanel: PropTypes.bool,
  onPrevMonthClick: PropTypes.func,
  onNextMonthClick: PropTypes.func,
  onClose: PropTypes.func,
  onClickEscape: PropTypes.func,
  onChange: PropTypes.func,
  onDateChange: PropTypes.func,
  phrases: SingleDatePickerPhrases,
  // displayFormat: PropTypes.string,
  displayFormatDay: PropTypes.string,
  transitionDuration: PropTypes.number,
  // verticalSpacing: PropTypes.number,
  isDayBlocked: PropTypes.func,
  isOutsideRange: PropTypes.func,
  isDayHighlighted: PropTypes.func,
  numberOfMonths: PropTypes.number,
};

DSDatePickerController.defaultProps = {
  date: '',
  value: '',
  enableOutsideDays: false,
  initialVisibleMonth: moment(),
  firstDayOfWeek: 0,
  keepOpenOnDateSelect: true,
  hideKeyboardShortcutsPanel: true,
  onPrevMonthClick: () => null,
  onNextMonthClick: () => null,
  onClose: () => null,
  onClickEscape: () => null,
  onChange: () => null,
  onDateChange: () => null,
  phrases: PropTypes.shape({
    calendarLabel: PropTypes.string,
    closeDatePicker: PropTypes.string,
    clearDates: PropTypes.string,
    focusStartDate: PropTypes.string,
    jumpToPrevMonth: PropTypes.string,
    jumpToNextMonth: PropTypes.string,
    keyboardShortcuts: PropTypes.string,
    showKeyboardShortcutsPanel: PropTypes.string,
    hideKeyboardShortcutsPanel: PropTypes.string,
    openThisPanel: PropTypes.string,
    enterKey: PropTypes.string,
    leftArrowRightArrow: PropTypes.string,
    upArrowDownArrow: PropTypes.string,
    pageUpPageDown: PropTypes.string,
    homeEnd: PropTypes.string,
    escape: PropTypes.string,
    questionMark: PropTypes.string,
    selectFocusedDate: PropTypes.string,
    moveFocusByOneDay: PropTypes.string,
    moveFocusByOneWeek: PropTypes.string,
    moveFocusByOneMonth: PropTypes.string,
    moveFocustoStartAndEndOfWeek: PropTypes.string,
    returnFocusToInput: PropTypes.string,
    keyboardNavigationInstructions: PropTypes.string,
    chooseAvailableStartDate: PropTypes.func,
    chooseAvailableEndDate: PropTypes.func,
    dateIsUnavailable: PropTypes.func,
    dateIsSelected: PropTypes.func,
  }),
  // displayFormat: 'L',
  displayFormatDay: 'D',
  transitionDuration: 0,
  // verticalSpacing: 0,
  isDayBlocked: () => false,
  isOutsideRange: () => false,
  isDayHighlighted: () => false,
  numberOfMonths: 1,
};
