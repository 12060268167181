import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from '@elliemae/ds-utilities/utils';

const withSearchable = (WrappedComponent) => {
  class Searchable extends Component {
    debouncedSearch = debounce(this.handleSearch, 200);

    constructor(props) {
      super(props);
      this.state = {
        value: props.value,
      };
      this.handleSearch = this.handleSearch.bind(this);
      this.handleKeyUp = this.handleKeyUp.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.buttonRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, { prevPropValue }) {
      if (nextProps.value !== prevPropValue) {
        return {
          value: nextProps.value,
          prevPropValue: nextProps.value,
        };
      }
      return null;
    }

    handleKeyUp(e) {
      const { onKeyUp, searchOnEnter } = this.props;
      const { key } = e;
      if (searchOnEnter && key === 'Enter') {
        this.handleSearch();
      }
      onKeyUp(e);
    }

    handleSearch() {
      const { onSearch, property, clearOnSearch, searchOnEnter } = this.props;
      const { value } = this.state;

      if (clearOnSearch) this.setState({ value: '' });
      if (searchOnEnter && !value) return;
      onSearch({
        query: { [property]: value },
        property,
        value,
      });
    }

    handleChange({ target }) {
      const { value } = target;
      const { searchOnEnter, onChange } = this.props;
      onChange(value);
      this.setState({ value }, () => {
        if (!searchOnEnter) this.debouncedSearch();
      });
    }

    render() {
      const { value } = this.state;
      const { containerProps, ...rest } = this.props;

      return (
        <WrappedComponent
          {...rest}
          buttonRef={this.buttonRef}
          containerProps={containerProps}
          onChange={this.handleChange}
          onKeyUp={this.handleKeyUp}
          onSearch={this.handleSearch}
          value={value}
        />
      );
    }
  }

  Searchable.defaultProps = {
    onChange: () => null,
    onSearch: () => null,
    onKeyUp: () => null,
    property: 'all',
    searchOnEnter: true,
    clearOnSearch: false,
    containerProps: {},
  };

  Searchable.propTypes = {
    containerProps: PropTypes.shape({}),
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    onKeyUp: PropTypes.func,
    property: PropTypes.string,
    searchOnEnter: PropTypes.bool,
    clearOnSearch: PropTypes.bool,
    value: PropTypes.string,
  };

  return Searchable;
};

export default withSearchable;
