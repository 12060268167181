function getDecoratorsFromHooks(hooks, decorators) {
  const nextDecorators = { ...decorators };

  Object.keys(hooks).forEach(decorator => {
    if (Array.isArray(decorators[decorator])) {
      nextDecorators[decorator] = [...decorators[decorator], hooks[decorator]];
    } else {
      nextDecorators[decorator] = hooks[decorator];
    }
  });

  return nextDecorators;
}

export default function createInstancePlugin(name, hooks) {
  return decorators => getDecoratorsFromHooks(hooks, decorators);
}
