/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/rules-of-hooks */
import { useRef } from 'react';
import { cx } from '@elliemae/ds-utilities/utils';
import createInstancePlugin from '@elliemae/ds-shared/createDataInstance/createInstancePlugin';
import { useStylesheetHelpers } from './useStylesheetHelpers';
import useColumnSizeService from './useColumnSizeService';
import { getTableProps } from './ext-points/getTableProps';
import { decorateColumn } from './ext-points/decorateColumn';

const getHeaderRowProps = (rowProps, { rowsStylesheet }) => {
  rowProps.className = `${rowsStylesheet.getRowClassName()}-header`;
  return rowProps;
};

const getRowProps = (rowProps, { rowsStylesheet }) => {
  rowProps.className = cx(rowProps.className, rowsStylesheet.getRowClassName());
  return rowProps;
};

const decorateColumns = (columns, grid) =>
  grid.columnsStylesheet.decorateColumns(columns, grid);

const decorateGrid = (grid) => {
  const isDragging = useRef(false);
  const tableRef = useRef();
  const resizedColumns = useRef({});
  const scrollbarWidth = useRef();
  const bodyWidth = useRef();
  const allColumnsHaveBeenFixed = useRef(false);
  const columnSizeService = useColumnSizeService(grid);
  const { columnsStylesheet, rowsStylesheet } = useStylesheetHelpers(grid);

  // row size side-effect
  const updateRowWidth = (width) => {
    const gridWidth = width || tableRef.current.offsetWidth;
    const columnsTotalSize = columnSizeService.computeColumnsTotalSize();

    if (columnsTotalSize > gridWidth + 5) {
      if (!allColumnsHaveBeenFixed.current && isDragging.current) {
        columnSizeService.setActualColumnsSizesFixed();
        allColumnsHaveBeenFixed.current = true;
      }
      rowsStylesheet.update(columnsTotalSize);
    } else {
      rowsStylesheet.update(undefined);
    }
  };

  return {
    isDragging,
    tableRef,
    bodyWidth,
    scrollbarWidth,
    columnSizeService,
    rowsStylesheet,
    columnsStylesheet,
    resizedColumns,
    updateRowWidth,
  };
};

export const ColumnSizingPlugin = createInstancePlugin('column-sizing', {
  decorateGrid,
  decorateColumn,
  getRowProps,
  getHeaderRowProps,
  getTableProps,
  decorateColumns,
});
