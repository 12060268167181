/* eslint-disable max-lines */
import React, { Component } from 'react';
import {
  isValidDrag,
  cleanDrag,
  calculateDrag,
  dragStartingPoints,
} from './toggleHelper';
import DSToggleRender from './DSToggleRender';
import { togglePropTypes } from './props';

class DSToggleImpl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.checked || false,
      inside: false,
      isDragging: false,
      // prevent draw of toggle before drag handle positioning calculations
      hide: true,
      drag: null,
      startPoint: null,
      circleWidth: null,
      boxWidth: null,
    };
    this.circle = React.createRef();
    this.box = React.createRef();
  }

  componentDidMount() {
    this.verifyElements();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.hide &&
      this.state.circleWidth &&
      this.state.boxWidth &&
      prevState.hide &&
      this.state.drag !== null
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ hide: false });
    }
    if (snapshot) {
      this.verifyElements();
    }
  }

  /**
   * ******************************************************
   * Drag
   * ****************************************************** *
   *
   * @param clientX
   */
  onDragStart = (clientX) => {
    const {
      startPoint,
      checked,
      isDragging,
      boxWidth,
      circleWidth,
    } = this.state;
    // const { checked: checkedProp, } = this.props
    const drag = calculateDrag(clientX, {
      startPoint,
      checked,
      isDragging,
      boxWidth,
      circleWidth,
    });
    if (drag) this.setState({ drag });
    // else this.setDragOnChange(checkedProp != null ? checkedProp : checked) // set initial position
    else this.setDragOnChange(checked); // set initial position
  };

  onDragStop = (e) => {
    const { checked } = this.state;
    // const { checked: checkedProp, } = this.props
    if (isValidDrag(this.state)) {
      this.onChange(e);
      this.setState(cleanDrag);
      // } else this.setDragOnChange(checkedProp != null ? checkedProp : checked) // set initial position
    } else this.setDragOnChange(checked); // set initial position
  };

  /**
   * ******************************************************
   * StandardEvents
   * ****************************************************** *
   *
   * @param e
   */
  onBlur = (e) => {
    const { onBlur, id } = this.props;
    if (onBlur) onBlur(e, id);
  };

  onFocus = (e) => {
    const { onFocus, id } = this.props;
    if (onFocus) onFocus(e, id);
  };

  onChange = (e) => {
    const { checked } = this.state;
    const {
      onChange,
      id,
      value,
      // checked: checkedProp,
    } = this.props;
    const newCheck = !checked;
    // this.setState({
    //   checked: checkedProp != null ? checkedProp : newCheck,
    // })
    this.setState({
      checked: newCheck,
    });
    e.target.value = value;
    e.target.checked = newCheck;
    e.checked = newCheck;
    onChange(e, id);
    // this.setDragOnChange(checkedProp != null ? checkedProp : newCheck) // set new position
    this.setDragOnChange(newCheck); // set new position
  };

  /** ******************************************************
      MouseEvents
   ******************************************************* * */
  onMouseEnter = () => {
    this.setState({ inside: true });
  };

  onMouseLeave = () => {
    this.setState({ inside: false });
  };

  onMouseMove = (event) => {
    event.preventDefault();
    this.onDragStart(event.clientX);
  };

  onMouseDown = (event) => {
    this.onDragStart(event.clientX);
    this.setState({ startPoint: event.clientX, isDragging: true });
    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('mouseup', this.onMouseUp);
  };

  onMouseUp = (event) => {
    this.onDragStop(event);
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
  };

  /** ******************************************************
      Touch
   ******************************************************* * */
  onTouchCancel = () => {
    this.setState({ inside: false });
  };

  onTouchEnd = (event) => {
    event.preventDefault();
    this.onDragStop(event);
  };

  onTouchMove = (event) => {
    this.onDragStart(event.touches[0].clientX);
  };

  onTouchStart = (event) => {
    this.onDragStart(event.touches[0].clientX);
    this.setState({ startPoint: event.touches[0].clientX, isDragging: true });
  };

  static getDerivedStateFromProps(props, state) {
    if (props.checked === true || props.checked === false) {
      return {
        checked: props.checked,
        ...dragStartingPoints(props.checked)(state),
      };
    }
    return null;
  }

  getSnapshotBeforeUpdate(prevProps) {
    const { size } = this.props;
    if (prevProps.size !== size) {
      return true;
    }
    return false;
  }

  /**
   * ******************************************************
   * other
   * ****************************************************** *
   *
   * @param currentCheckState
   */
  setDragOnChange(currentCheckState) {
    this.setState(dragStartingPoints(currentCheckState));
  }

  verifyElements() {
    const { checked } = this.state;
    const { checked: checkedProp } = this.props;
    const nodesInterval = setInterval(() => {
      // !important it checks when nodes are availables
      if (
        !this.circle ||
        !this.box ||
        !this.circle.current ||
        !this.box.current
      )
        return;
      const {
        current: { clientWidth: circleWidth },
      } = this.circle;
      const {
        current: { clientWidth: boxWidth },
      } = this.box;
      if (!circleWidth || !boxWidth || circleWidth === boxWidth) return;
      this.setState({
        boxWidth,
        circleWidth,
      });
      this.setDragOnChange(checkedProp != null ? checkedProp : checked);
      // this.setDragOnChange(checked)
      clearInterval(nodesInterval);
    }, 5);
  }

  render() {
    const { checked, drag, inside, hide } = this.state;
    const dragStyle =
      drag !== null || checked || this.props.checked
        ? { left: `${drag}px` }
        : null;
    return (
      <DSToggleRender
        {...this.props}
        defaultState={this.props.checked}
        checked={checked}
        containerRef={this.box}
        hide={hide}
        dragStyle={dragStyle}
        handRef={this.circle}
        inside={inside}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onMouseDown={this.onMouseDown}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onTouchCancel={this.onTouchCancel}
        onTouchEnd={this.onTouchEnd}
        onTouchMove={this.onTouchMove}
        onTouchStart={this.onTouchStart}
      />
    );
  }
}

DSToggleImpl.propTypes = togglePropTypes;

export default DSToggleImpl;
