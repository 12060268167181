import React from 'react';
import PropTypes from 'prop-types';
import { aggregatedClasses } from '@elliemae/ds-classnames';
import CheckmarkCircleFill from '@elliemae/ds-icons/CheckmarkCircleFill';
import WarningCircleFill from '@elliemae/ds-icons/WarningCircleFill';
import InfoCircleFill from '@elliemae/ds-icons/InfoCircleFill';
import { ToastType, toastTypes } from './ToastType';

const blockName = 'toast-content';
const Container = aggregatedClasses('div')(blockName);
const IconContainer = aggregatedClasses('div')(blockName, 'icon-container');
const MessageContent = aggregatedClasses('div')(blockName, 'message-content');
const MessageHeader = aggregatedClasses('div')(blockName, 'message-header');
const MessageText = aggregatedClasses('span')(blockName, 'message-text');

const getIconByToastType = (type) =>
  ({
    [ToastType.SUCCESS]: CheckmarkCircleFill,
    [ToastType.WARNING]: WarningCircleFill,
    [ToastType.INFO]: InfoCircleFill,
    [ToastType.ERROR]: WarningCircleFill,
    [ToastType.DEFAULT]: 'div',
  }[type]);

const ToastRender = ({
  type = ToastType.DEFAULT,
  messageTitle = '',
  messageText = '',
}) => {
  const IconMessage = aggregatedClasses(getIconByToastType(type))(
    blockName,
    'icon-message',
  );
  return (
    <Container data-testid="ds-toast-render">
      <IconContainer>
        <IconMessage size="m" />
      </IconContainer>
      <MessageContent>
        <MessageHeader>{messageTitle}</MessageHeader>
        <MessageText>{messageText}</MessageText>
      </MessageContent>
    </Container>
  );
};

ToastRender.propTypes = {
  /**
   * Toast title
   */
  messageTitle: PropTypes.string.isRequired,
  /**
   * Toast body, either a string or Action with ActionLink component
   */
  messageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * Toast type
   */
  type: PropTypes.oneOf(toastTypes).isRequired,
};

export default ToastRender;
