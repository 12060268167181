import React from 'react';
import { PropTypes, describe } from 'react-desc';
import AddonWrapper from './AddonWrapper';

const DSInputGroup = ({
  containerProps = {},
  innerRef = null,
  className = '',
  style = {},
  leftAddon = null,
  rightAddon = null,
  children: InputComponents, // as InputComponents,
}) => (
  <AddonWrapper
    ref={innerRef}
    className={className}
    containerProps={containerProps}
    leftAddon={leftAddon}
    rightAddon={rightAddon}
    style={style}
  >
    {InputComponents}
  </AddonWrapper>
);

const props = {
  /** props injected to wrapper of page header */
  containerProps: PropTypes.shape({}).description(
    'props injected to wrapper of page header',
  ),
  /** inner ref for input group wrapper */
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).description('inner ref for input group wrapper'),
  /** class for input group wrapper */
  className: PropTypes.string.description('class for input group wrapper'),
  /**
   * Set style for the input group
   */
  style: PropTypes.object.description('Set style for the input group'),
  /**
   * Component to be added at the left side of the input
   */
  leftAddon: PropTypes.element.description(
    'Component to be added at the left side of the input',
  ),
  /**
   * Component to be added at the right side of the input
   */
  rightAddon: PropTypes.element.description(
    'Component to be added at the right side of the input',
  ),
  /**
   * Input components to show
   */
  children: PropTypes.element.description('Input components to show'),
};

DSInputGroup.propTypes = props;
const DSInputGroupWithSchema = describe(DSInputGroup);

DSInputGroupWithSchema.propTypes = props;

export default DSInputGroup;
export { DSInputGroupWithSchema };
