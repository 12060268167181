/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M9.5 14.033a.4985.4985 0 0 1-.3-.1l-8-6c-.221-.166-.266-.479-.1-.7s.479-.266.7-.101l7.7 5.775 7.7-5.775c.22-.165.533-.12.7.101.165.221.12.534-.101.7l-8 6a.503.503 0 0 1-.3.1z',
  ],
  height: 20,
  width: 20,
});
