import { useEffect, useState } from 'react';
import raf from 'raf';
import ResizeObserver from 'resize-observer-polyfill';

export default function useResizeObserver(handler, el) {
  const [node, setRef] = useState(el);

  const resizeObserver = new ResizeObserver(entries => {
    if (!Array.isArray(entries)) return;
    if (!entries.length) return;
    const entry = entries[0];

    // execute on the next animation frame
    raf(() =>
      handler(
        {
          width: entry.target.offsetWidth,
          height: entry.target.offsetHeight,
        },
        entry.target,
      ),
    );
  });

  const htmlEl = el || node;

  useEffect(() => {
    if (htmlEl) resizeObserver.observe(htmlEl);
    return () => htmlEl && resizeObserver.unobserve(htmlEl);
  }, [htmlEl]);

  return [setRef];
}
