/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M19.675 9.26C18.105 7.87 14.355 5 10.005 5s-8.1 2.87-9.67 4.26c-.45.4-.45 1.07 0 1.47 1.57 1.4 5.32 4.27 9.67 4.27s8.1-2.87 9.67-4.26c.44-.4.44-1.08 0-1.48zM13.005 9c0 1.65-1.35 3-3 3s-3-1.35-3-3 1.35-3 3-3 3 1.35 3 3zm-3 5c-4.06 0-7.65-2.81-9-3.99.99-.88 3.19-2.62 5.88-3.49-.54.68-.88 1.54-.88 2.48 0 2.21 1.79 4 4 4s4-1.79 4-4c0-.94-.34-1.8-.88-2.48 2.7.87 4.89 2.6 5.88 3.47-1.35 1.2-4.94 4.01-9 4.01z',
  ],
  height: 20,
  width: 20,
});
