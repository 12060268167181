const basicAction = ({ color = null }) => {
  const cssModifier = [];
  if (color && color[0]) {
    if (color[1] !== null) cssModifier.push(`color-${color[0]}-${color[1]}`);
    else cssModifier.push(`color-${color[0]}`);
  }
  return cssModifier;
};

const instance = [basicAction];
const pipe = () => ({
  getInstance() {
    return instance;
  },
  add(customModifier) {
    instance.push(customModifier);
  },
  execute(props) {
    return instance.reduce((temp, action) => [...temp, ...action(props)], []);
  },
});

export default pipe;
