/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M11 15c0 .571-.429 1-1 1s-1-.429-1-1 .429-1 1-1 1 .429 1 1zM9.045 4.539L9.5 12h1l.455-7.461A.498.498 0 0 0 10.459 4h-.917c-.291 0-.521.249-.496.539z',
  ],
  height: 20,
  width: 20,
});
