/* eslint-disable max-lines */
import React from 'react';
import { MODAL_TYPE_V2 } from './types';
import { SelectionHeader } from './SelectionHeader';
import { DecisionHeader } from './DecisionHeader';
import { FormHeader } from './FormHeader';
import { FeedbackIcon } from './FeedbackIcon';
import { Footer } from './Footer';
import { Title } from './Title';
import {
  showDecision,
  showFeedBack,
  showFooter,
  showSelection,
  showForm,
} from '../helpers';
import { ModalContent, ModalWrapper } from '../blocks';

export const DSModalContent = ({
  containerProps = {},
  //
  modalType = MODAL_TYPE_V2.INFORMATION,
  modalSubType = null,
  modalTitle = '',
  showRejectButton = false,
  showClose = true,
  searchProps = {},
  // override
  actionsRef = () => null,
  additionalFooterCssClass,
  overridePropsConfirmButton = {},
  overridePropsRejectButton = {},
  // react modal
  children,
  onClose = () => null,
  onConfirm = () => null,
  onReject = () => null,
  rejectLabel = 'Discard',
  confirmLabel = 'Save',
  removePadding = false,
}) => (
  <ModalWrapper {...containerProps}>
    {showForm({ modalType, modalSubType }) && (
      <FormHeader
        modalTitle={modalTitle}
        modalType={modalType}
        onClose={onClose}
        showClose={showClose}
      />
    )}
    {showSelection({ modalType, modalSubType }) && (
      <SelectionHeader
        modalTitle={modalTitle}
        modalType={modalType}
        searchProps={searchProps}
        showClose={showClose}
      />
    )}
    {showDecision({ modalType, modalSubType }) && (
      <DecisionHeader onClose={onClose} showClose={showClose} />
    )}
    {showFeedBack({ modalType, modalSubType }) && (
      <FeedbackIcon modalSubType={modalSubType} />
    )}
    {showDecision({ modalType, modalSubType }) && (
      <Title modalTitle={modalTitle} modalType={modalType} />
    )}
    <ModalContent classProps={{ type: modalType, removePadding }}>
      {children}
    </ModalContent>
    {showFooter({ modalType, modalSubType }) && (
      <Footer
        actionsRef={actionsRef}
        additionalFooterCssClass={additionalFooterCssClass}
        confirmLabel={confirmLabel}
        onConfirm={onConfirm}
        onReject={onReject}
        overridePropsConfirmButton={overridePropsConfirmButton}
        overridePropsRejectButton={overridePropsRejectButton}
        rejectLabel={rejectLabel}
        showRejectButton={showRejectButton}
      />
    )}
  </ModalWrapper>
);
