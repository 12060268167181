/* eslint-disable no-console */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import DSDateInput from '@elliemae/ds-basic/form/DateInput';
import DSInputGroup from '@elliemae/ds-basic/dsinputgroup';
import { aggregatedClasses } from '@elliemae/ds-classnames';

const blockname = 'date-picker-header';
const Header = aggregatedClasses('div')(blockname);

const DatePickerHeader = ({
  onInputChange = () => null,
  selectedDate,
  updateKeyDate,
}) => {
  const [value, setValue] = useState(selectedDate);
  const dateInputRef = useRef();

  useEffect(() => {
    setValue(selectedDate);
  }, [selectedDate]);

  const handleOnChange = (newDate) => {
    dateInputRef.current.firstChild
      .getElementsByClassName('input-year')[0]
      .focus();
    setValue(newDate);
  };

  const handleOnKeyDown = (e, newDate) => {
    if (e.key === 'Enter') {
      onInputChange(newDate);
      updateKeyDate(newDate);
    }
  };

  return (
    <Header>
      <DSInputGroup>
        <DSDateInput
          clearable={false}
          innerRef={dateInputRef}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          value={value}
          containerProps={{ 'data-testid': 'start-date' }}
        />
      </DSInputGroup>
    </Header>
  );
};

DatePickerHeader.propTypes = {
  onInputChange: PropTypes.func,
  selectedDate: PropTypes.any,
  updateKeyDate: PropTypes.any,
};

export default DatePickerHeader;
