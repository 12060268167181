/* eslint-disable react/display-name */
import React, { Component } from 'react';

const findOption = (options, valueProperty = 'value') => value => {
  if (typeof value === 'object') return value;
  const index = options.findIndex(option => option[valueProperty] === value);
  return options[index];
};

const getOptionFromValue = ({ value, options, valueProperty, isMulti }) => {
  if (Array.isArray(value) && isMulti) {
    return value.map(findOption(options, valueProperty));
  }
  return findOption(options, valueProperty)(value);
};

const withSelectStringValueConverter = WrappedComponent =>
  class extends Component {
    static defaultProps = {
      valueProperty: 'value',
      labelProperty: 'label',
    };

    state = {};

    static getDerivedStateFromProps(nextProps, prevState) {
      if (
        typeof nextProps.value !== 'undefined' &&
        nextProps.value !== prevState.prevPropValue
      ) {
        return {
          prevPropValue: nextProps.value,
          value: getOptionFromValue(nextProps),
        };
      }
      return null;
    }

    render() {
      const { value } = this.state;
      return <WrappedComponent {...this.props} value={value} />;
    }
  };

export default withSelectStringValueConverter;
