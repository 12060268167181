import React from 'react';
import { PropTypes, describe } from 'react-desc';
import { ToastContainer, Slide } from 'react-toastify';
import CloseSmall from '@elliemae/ds-icons/CloseSmall';
import DSButton from '../Button';
import { toastsPositions } from './ToastPosition';

const CloseButton = ({ closeToast }) => (
  <DSButton
    buttonType="text"
    className="toast-close-button"
    icon={<CloseSmall />}
    onClick={closeToast}
    size="m"
  />
);

const DSToast = ({
  containerProps = {},
  position = 'bottom-left',
  autoClose = 5000, // can be false or a number in milliseconds
  showProgressBar = false,
  closeOnClick = false,
  enableMultiContainer = false,
  containerId = undefined,
  toastId = undefined,
}) => (
  <ToastContainer
    {...containerProps}
    autoClose={autoClose}
    className={(showProgressBar && 'with-progressbar') || ''}
    closeButton={<CloseButton />}
    closeOnClick={closeOnClick}
    containerId={containerId}
    enableMultiContainer={enableMultiContainer}
    hideProgressBar={!showProgressBar}
    newestOnTop
    position={position}
    toastId={toastId}
    transition={Slide}
  />
);

const props = {
  /** inject props to wrapper */
  containerProps: PropTypes.object.description('inject props to wrapper'),
  /** Position on the screen to show the toast */
  position: PropTypes.oneOf(toastsPositions).description(
    'Position on the screen to show the toast',
  ),
  /** ms to autoclose the toast */
  autoClose: PropTypes.number.description('ms to autoclose the toast'),
  /** Whether to show a progress bar indicating when the toast is going to be closed */
  showProgressBar: PropTypes.bool.description(
    'Whether to show a progress bar indicating when the toast is going to be closed',
  ),
  /** Close toast handler */
  closeOnClick: PropTypes.bool.description('Close toast handler'),
  /** Allows instantiating multiple Toast containers */
  enableMultiContainer: PropTypes.bool.description(
    'Allows instantiating multiple Toast containers',
  ),
  /** unique id for multi container */
  containerId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).description('unique id for multi container'),
  /**  id for the toast */
  toastId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).description('id for the toast'),
};

DSToast.propTypes = props;
const DSToastWithSchema = describe(DSToast);
DSToastWithSchema.propTypes = props;

export default DSToast;
export { DSToastWithSchema };
