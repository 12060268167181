import React from 'react';
import { PropTypes, describe } from 'react-desc';
import { aggregatedClasses } from '@elliemae/ds-classnames';

const pillBlockName = 'pill';

const PillWrapper = aggregatedClasses('div')(
  pillBlockName,
  null,
  ({ size, variant }) => ({
    [variant]: !!variant,
    [size]: !!size,
  }),
);
const PillLabel = aggregatedClasses('span')(pillBlockName, 'label');
const PillAddon = aggregatedClasses('div')(pillBlockName, 'addon');

function Pill({
  containerProps = {},
  label = '',
  leftAddon,
  rightAddon,
  variant = 'value',
  size = 'm',
  ...otherProps
}) {
  return (
    <PillWrapper
      {...containerProps}
      classProps={{ variant, size }}
      {...otherProps}
    >
      {leftAddon && <PillAddon>{leftAddon}</PillAddon>}
      <PillLabel>{label}</PillLabel>
      {rightAddon && <PillAddon>{rightAddon}</PillAddon>}
    </PillWrapper>
  );
}

const props = {
  /** props to inject to pill wrapper */
  containerProps: PropTypes.object.description(
    'props to inject to pill wrapper',
  ),
  /** pill text label */
  label: PropTypes.string.isRequired.description('pill text label'),
  /** pill size */
  size: PropTypes.oneOf(['m', 'l']).description('pill size'),
  /** pill variant */
  variant: PropTypes.oneOf(['value', 'title']).description('pill variant'),
  /** addon component to left */
  leftAddon: PropTypes.node.description('addon component to left'),
  /** addon component to right */
  rightAddon: PropTypes.node.description('addon component to right'),
};

Pill.propTypes = props;
const DSPillWithSchema = describe(Pill);
DSPillWithSchema.propTypes = props;

export { Pill, DSPillWithSchema };
