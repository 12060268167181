/* eslint-disable react/prop-types */
import React from 'react';
import DSCheckbox from '@elliemae/ds-basic/form/Checkbox';
import {
  DatePickerRangeHeader,
  DatePickerHeader,
} from '@elliemae/ds-date-picker';
import { aggregatedClasses } from '@elliemae/ds-classnames';

const blockname = 'date-switcher';
const Header = aggregatedClasses('div')(blockname, 'header');

const SingleRangeDateSwitcher = ({
  onStartInputChange,
  onEndInputChange,
  onSingleInputChange,
  onFocusChange,
  selectedStartDate,
  selectedEndDate,
  selectedSingleDate,
  isDateRange,
  setIsDateRange,
  handleDatesChange,
  updateKeyDate,
  focusedInput,
}) => {
  const SingleDateInput = (
    <DatePickerHeader
      onInputChange={onSingleInputChange}
      selectedDate={selectedSingleDate}
      updateKeyDate={updateKeyDate}
    />
  );
  const DateRangeInput = (
    <DatePickerRangeHeader
      focusedInput={focusedInput}
      onFocusChange={onFocusChange}
      onEndInputChange={onEndInputChange}
      onStartInputChange={onStartInputChange}
      selectedEndDate={selectedEndDate}
      selectedStartDate={selectedStartDate}
      handleDatesChange={handleDatesChange}
    />
  );

  return (
    <Header data-testid="date-switch-header">
      <DSCheckbox
        checked={isDateRange}
        className="switch-header__checkbox"
        labelText="Range"
        onChange={() => setIsDateRange((prevIsDateRange) => !prevIsDateRange)}
      />
      {isDateRange ? DateRangeInput : SingleDateInput}
    </Header>
  );
};

export default SingleRangeDateSwitcher;
