import React from 'react';
import { PropTypes, describe } from 'react-desc';
import { aggregatedClasses } from '@elliemae/ds-classnames';

const blockName = 'separator';

const MARGIN_SIZE = {
  S: 's',
  M: 'm',
  L: 'l',
  none: 'none',
};

const POSITION = {
  INITIAL: 'initial',
  CENTER: 'center',
  END: 'end',
};

const SeparatorWrapper = aggregatedClasses('div')(
  'separator-wrapper',
  null,
  ({ margin, orientation, dashed, position }) => ({
    dashed,
    [margin]: margin,
    [orientation]: orientation,
    [position]: position,
  }),
);

const Separator = aggregatedClasses('div')(
  blockName,
  null,
  ({ dashed, margin, orientation, type }) => ({
    dashed,
    [margin]: margin,
    [orientation]: orientation,
    [type]: type,
  }),
);

const types = ['category-level', 'group-level', 'non-form'];

const DSSeparator = ({
  containerProps = {},
  dashed = false,
  margin = MARGIN_SIZE.S,
  orientation = 'horizontal',
  type = 'category-level',
  position = POSITION.INITIAL,
  className,
  style,
}) => (
  <SeparatorWrapper
    {...containerProps}
    className={className}
    classProps={{
      margin,
      orientation,
      dashed,
      position,
    }}
    style={style}
  >
    <Separator
      classProps={{
        dashed,
        margin,
        orientation,
        type,
      }}
    />
  </SeparatorWrapper>
);

const props = {
  /** class for separator */
  className: PropTypes.string.description('class for separator'),
  /** style object for separator */
  style: PropTypes.object.description('style object for separator'),
  /** Injected props to wrapper element of component */
  containerProps: PropTypes.object.description(
    'Injected props to wrapper element of component',
  ),
  /**
   * If the separator has to be dashed or solid
   */
  dashed: PropTypes.bool.description(
    'If the separator has to be dashed or solid',
  ),
  /**
   * Amount of margin to be added to the separator
   */
  margin: PropTypes.oneOf(['s', 'm', 'l', 'none']).description(
    'Amount of margin to be added to the separator',
  ),
  /**
   * Orientation 'horizontal' or 'vertical'
   */
  orientation: PropTypes.string.description(
    "Orientation 'horizontal' or 'vertical'",
  ),
  /**
   * ['category-level', 'group-level', 'non-form']
   */
  type: PropTypes.oneOf(types).description(
    "['category-level', 'group-level', 'non-form']",
  ),
  /**
   * ['initial', 'center', 'end']
   */
  position: PropTypes.oneOf(['initial', 'center', 'end']).description(
    "['initial', 'center', 'end']",
  ),
};

DSSeparator.propTypes = props;

const DSSeparatorWithSchema = describe(DSSeparator);

DSSeparatorWithSchema.propTypes = props;

export default DSSeparator;
export { DSSeparatorWithSchema };
