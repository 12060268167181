/* eslint-disable complexity */
/* eslint-disable max-lines */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { aggregatedClasses } from '@elliemae/ds-classnames';
import CloseCircle from '@elliemae/ds-icons/CloseCircle';
import DSButton from '../../Button';
import DSTooltip from '../../Tooltip';
import InputAddonWrapper from './InputAddonWrapper';
import { showEllipsis } from './showEllipsis';

const blockName = 'input';
const Input = aggregatedClasses('input')(
  blockName,
  null,
  ({ clearable, value }) => ({
    clearable,
    value,
  }),
);
const WrapperForTooltipInput = aggregatedClasses('div')(
  blockName,
  'tooltip-ref',
);
const InputCustomWrapper = aggregatedClasses('div')(
  blockName,
  null,
  ({ clearable, value }) => ({
    clearable,
    value,
  }),
);
const ClearableButton = aggregatedClasses(DSButton)(blockName, 'clearable');

const getValidComponents = (component) => component;

const noop = () => null;
class InputImpl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: -1,
      active: false,
      showTooltip: false,
    };
    this.ref = React.createRef();
    this.refTooltip = React.createRef();
  }

  componentDidMount() {
    this.setWidth();
    window.addEventListener('resize', this.setWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setWidth);
  }

  handleOnChange = (e) => {
    const { onChange, max } = this.props;
    const { value } = e.target;
    if (max && value > max) return;
    onChange(e);
  };

  handleBlur = (e) => {
    const { onBlur, onChange, min } = this.props;
    const { value } = e.target;
    if (min && value < min && value !== '') {
      onChange({
        target: {
          value: min.toString(),
        },
      });
    }
    onBlur(e);
    this.setState({
      active: false,
    });
  };

  handleFocus = (e) => {
    const { onFocus } = this.props;
    onFocus(e);
    this.setState({
      active: true,
    });
  };

  handleMouseEnter = () => {
    if (!this.state.showTooltip) {
      this.setState({
        showTooltip: true,
      });
    }
  };

  handleMouseLeave = () => {
    if (this.state.showTooltip) {
      this.setState({
        showTooltip: false,
      });
    }
  };

  setWidth = () => {
    const client = this.refTooltip.current.getBoundingClientRect();
    this.setState({
      width: client.width,
    });
  };

  handleClear() {
    const { onClear, onChange, clearable } = this.props;
    if (clearable && onClear) {
      onClear();
    } else {
      onChange({ target: { value: '' } });
    }

    if ((this.ref || {}).current) this.ref.current.focus();
  }

  renderClearableComponent() {
    const { clearable, value, disabled } = this.props;
    if (disabled) return null;
    return clearable && value ? (
      <ClearableButton
        buttonType="text"
        data-testid="ds-input_clearable-btn"
        disabled={disabled}
        icon={<CloseCircle />}
        onClick={this.handleClear}
      />
    ) : null;
  }

  render() {
    const {
      role,
      id,
      isShowElipsisActive,
      autoFocus,
      className,
      name,
      innerRef,
      maxLength,
      minLength,
      placeholder,
      leftComponent,
      rightComponent,
      disableTooltip,
      readOnly,
      disabled,
      value,
      onClick,
      onChange,
      onKeyDown,
      onKeyUp,
      onFocus,
      onBlur,
      onPaste,
      required,
      type,
      customInputType,
      min,
      max,
      clearable,
      onMouseLeave,
      onMouseOut,
      style,
      containerProps,
      tabIndex,
      hasError,
      ...rest
    } = this.props;
    const { width, active, showTooltip } = this.state;
    const rightComponents = [
      this.renderClearableComponent(),
      rightComponent,
    ].filter(getValidComponents);
    const leftComponents = [leftComponent].filter(getValidComponents);

    const inputProps = {
      'aria-label': rest['aria-label'],
      'aria-placeholder': placeholder,
      'aria-required': required,
      'aria-multiline': false,
      'aria-autocomplete': 'none',
      'data-testid': rest['data-testid'],
      role,
      id,
      ref: this.ref,
      className,
      autoFocus,
      disabled: disabled || readOnly,
      innerRef,
      maxLength,
      minLength,
      name,
      onBlur,
      onChange,
      onClick,
      onFocus,
      onKeyDown,
      onKeyUp,
      onPaste,
      onMouseLeave,
      disableTooltip,
      onMouseOut,
      placeholder,
      required,
      type,
      value,
      min,
      max,
      style,
      tabIndex,
      ...containerProps,
    };

    let InputComponent = (
      <WrapperForTooltipInput ref={this.refTooltip} style={{ width: '100%' }}>
        {!customInputType ? (
          <Input
            {...omit(inputProps, ['disableTooltip'])}
            autoComplete="off"
            clearable={`${clearable}`}
            onChange={this.handleOnChange}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            // using mouseOver instead of mouseEnter (doesn't trigger on disabled inputs)
            // https://github.com/facebook/react/issues/4251
            onMouseOver={this.handleMouseEnter}
            // using mouseOut instead of mouseLeave (doesn't trigger on disabled inputs)
            // https://github.com/facebook/react/issues/4251
            onMouseOut={this.handleMouseLeave}
            value={value}
          />
        ) : (
          <InputCustomWrapper
            className={className}
            {...containerProps}
            clearable={clearable}
            value={value}
          >
            {customInputType({
              ...inputProps,
            })}
          </InputCustomWrapper>
        )}
      </WrapperForTooltipInput>
    );
    if (isShowElipsisActive) {
      InputComponent = (
        <DSTooltip
          isOpen={
            disableTooltip &&
            showEllipsis(width, value) &&
            !active &&
            showTooltip &&
            width
          }
          title={String(value)}
          zIndex={11}
          triggerComponent={
            <WrapperForTooltipInput style={{ width: '100%' }}>
              {InputComponent}
            </WrapperForTooltipInput>
          }
        />
      );
    }

    const renderInput =
      rightComponents.length || leftComponents.length || clearable ? (
        <InputAddonWrapper
          className={className}
          leftComponents={leftComponents}
          rightComponents={rightComponents}
          hasError={hasError}
          {...containerProps}
        >
          {InputComponent}
        </InputAddonWrapper>
      ) : (
        InputComponent
      );

    return renderInput;
  }
}

InputImpl.defaultProps = {
  onBlur: noop,
  onFocus: noop,
};

InputImpl.propTypes = {
  className: PropTypes.string,
  customInputType: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isShowElipsisActive: PropTypes.bool,
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseOut: PropTypes.func,
  onPaste: PropTypes.func,
  onClear: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  role: PropTypes.string,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  leftComponent: PropTypes.element,
  rightComponent: PropTypes.element,
  disableTooltip: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  type: PropTypes.string,
  clearable: PropTypes.bool,
  containerProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // eslint-disable-next-line jsdoc/require-returns
  /**
   * Should be of type string if using ellipsis tooltip [isShowElipsisActive] prop
   *
   * @param props
   * @param propName
   * @param componentName
   */
  // eslint-disable-next-line consistent-return
  value(props, propName, componentName) {
    // eslint-disable-line consistent-return
    if (!!props.isShowElipsisActive && typeof props[propName] !== 'string') {
      return new Error(
        `Invalid prop \`${propName}\` of type \`${typeof props[
          propName
        ]}\` supplied to` +
          ` \`${componentName}\`. Prop \`value\` should be of type \`string\` if prop \`isShowElipsisActive\` is set to \`true\`.` +
          ' Either set `isShowElipsisActive` to `false`, or properly format your `value` prop to `string`.',
      );
    }
  },
};

export default InputImpl;
