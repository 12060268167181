import React from 'react';
import { describe, PropTypes } from 'react-desc';
import SearchBoxImpl from './SearchBoxImpl';

const DSSearchBox = ({
  containerProps = {},
  innerRef = undefined,
  className = '',
  disabled = false,
  disableButton = false,
  style = {},
  property = 'all',
  placeholder = 'Search all', // todo: i18nize this
  searchOnEnter = true,
  clearOnSearch = false,
  onKeyUp = () => null,
  onSearch = () => null,
  onChange = () => null,
  onBlur = () => null,
  readOnly = false,
  value = undefined,
  showIcon = true,
}) => (
  <SearchBoxImpl
    className={className}
    clearOnSearch={clearOnSearch}
    containerProps={containerProps}
    disabled={disabled}
    disableButton={disableButton}
    innerRef={innerRef}
    onKeyUp={onKeyUp}
    onSearch={onSearch}
    onChange={onChange}
    onBlur={onBlur}
    placeholder={placeholder}
    property={property}
    readOnly={readOnly}
    searchOnEnter={searchOnEnter}
    showIcon={showIcon}
    style={style}
    value={value}
  />
);

const searchBoxProps = {
  containerProps: PropTypes.object.description(
    'Set of Properties attached to the main container',
  ),
  className: PropTypes.string.description('html class attribute'),
  innerRef: PropTypes.object.description('ref to the components container'),
  style: PropTypes.object.description('Set style to the search box'),
  property: PropTypes.string
    .description('search property')
    .defaultValue('property'),
  placeholder: PropTypes.string
    .description('Placeholder for the search box')
    .defaultValue('Search All'),
  searchOnEnter: PropTypes.bool
    .description('Whether to trigger the search once the enter is pressed')
    .defaultValue(true),
  clearOnSearch: PropTypes.bool
    .description('Whether to clear the input on search')
    .defaultValue(false),
  onKeyUp: PropTypes.func.description(
    'function triggered once in the search box a keyboard key is released',
  ),
  onSearch: PropTypes.func.description(
    'function triggered once the search icon is clicked',
  ),
  onChange: PropTypes.func.description('function that is triggered on change'),
  onBlur: PropTypes.func.description('Function that is triggered on blur'),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).description('searchbox input value'),
  showIcon: PropTypes.bool
    .description('Whether to show an icon or not')
    .defaultValue(true),
  disabled: PropTypes.bool
    .description('Disables the search input and button')
    .defaultValue(false),
  disableButton: PropTypes.bool
    .description('Disables the search button')
    .defaultValue(false),
  readOnly: PropTypes.bool
    .description('whether the input is read only or not')
    .defaultValue(false),
};

DSSearchBox.propTypes = searchBoxProps;

const SearchBoxWithSchema = describe(DSSearchBox);
SearchBoxWithSchema.propTypes = searchBoxProps;

export { SearchBoxWithSchema };
export default DSSearchBox;
