import * as sortabular from 'sortabular/dist';
import useDerivedStateFromProps from '@elliemae/ds-utilities/hooks/useDerivedStateFromProps';
import { checkIfSortable } from './checkIfSortable';

export function useSortableState(grid) {
  const { onSort, sortingColumns: sortingColumnsProp, pagination } = grid.props;

  const [sortingColumns, setSortingColumns] = useDerivedStateFromProps(
    sortingColumnsProp,
  );

  const handleSort = selectedColumn => {
    // support for pagination
    const { composedRows = {}, props } = grid.getInstance();
    const { allRows, rows } = composedRows;
    const serverSidePagination =
      pagination &&
      composedRows.amount !== (composedRows.rows && composedRows.rows.length);
    if (
      props.serverSideData ||
      serverSidePagination ||
      checkIfSortable(selectedColumn, allRows || rows || composedRows)
    ) {
      setSortingColumns(prevSortingColumns => {
        const nextSortingColumns = sortabular.byColumn({
          sortingColumns: prevSortingColumns,
          sortingOrder: {
            FIRST: 'asc',
            asc: 'desc',
            desc: 'asc',
          },
          selectedColumn,
        });
        const { columns } = grid.getInstance();
        let columnSorted = columns.find(col => col.property === selectedColumn);
        columnSorted = {
          ...columnSorted,
          originalProperty: columnSorted.property,
        };
        onSort(nextSortingColumns, selectedColumn, columnSorted);
        return nextSortingColumns;
      });
    }
  };

  return {
    state: { sortingColumns },
    actions: {
      sort: handleSort,
    },
  };
}
