/* eslint-disable import/no-unresolved */
/* eslint-disable max-lines */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DayPickerRangeController as RDRangeController } from 'react-dates';
import {
  DatePickerDay,
  DatePickerNavigation,
  renderMonthElement,
} from '@elliemae/ds-date-picker';
import ChevronLeft from '@elliemae/ds-icons/ChevronLeft';
import { convertPropToCssClassName } from '@elliemae/ds-classnames';

const { classNameElement, classNameBlock } = convertPropToCssClassName(
  'datepicker-range',
);

const DayPickerRangeController = ({
  endDate = undefined,
  startDate = undefined,
  displayFormatDay = 'D',
  focusedInput = undefined,
  enableOutsideDays = false,
  onDatesChange = () => null,
  onFocusChange = () => null,
  onOutsideClick = () => null,
  isDayBlocked = () => null,
  isDayHighlighted = () => null,
  isOutsideRange = () => null,
  onNextMonthClick = () => null,
  onPrevMonthClick = () => null,
  ...otherProps
}) => {
  const datePickerRef = useRef();

  useEffect(() => {
    const handleMouseUp = () => {
      const focusedElement = document.activeElement;

      focusedElement.blur();
    };
    const handleKeyDown = (event) => {
      const [firstDay, ...restOfDays] = document
        .getElementsByClassName('CalendarMonthGrid_month__horizontal')[1]
        .getElementsByClassName('CalendarDay');
      const [lastDay] = restOfDays.slice(-1);
      const focusedElement = document.activeElement;
      const [prev, next] = document.getElementsByClassName(
        'DayPickerNavigation_button',
      );

      switch (event.key) {
        case 'Home':
          event.stopPropagation();
          firstDay.focus();
          break;
        case 'End':
          event.stopPropagation();
          lastDay.focus();
          break;
        case 'Enter':
        case ' ':
          if (focusedElement === prev) {
            event.stopPropagation();
            setTimeout(() => {
              prev.focus();
            }, 240);
          } else if (focusedElement === next) {
            event.stopPropagation();
            setTimeout(() => {
              next.focus();
            }, 240);
          }
          break;
        case 'Tab':
          if (
            firstDay === focusedElement ||
            restOfDays.includes(focusedElement)
          ) {
            event.preventDefault();
            prev.focus();
          }
          break;
        default:
          break;
      }
    };

    datePickerRef.current.addEventListener('mouseup', handleMouseUp, false);

    datePickerRef.current.addEventListener('keydown', handleKeyDown, false);

    return () => {
      if (datePickerRef.current) {
        datePickerRef.current.removeEventListener(
          'mouseup',
          handleMouseUp,
          false,
        );

        datePickerRef.current.removeEventListener(
          'keydown',
          handleKeyDown,
          false,
        );
      }
    };
  }, []);

  return (
    <div
      ref={datePickerRef}
      className={classNameBlock('wrapper-day-picker-single-date-controller')}
      data-testid={otherProps['data-testid'] || 'date-range-picker-controller'}
    >
      <RDRangeController
        {...otherProps}
        isDayBlocked={isDayBlocked}
        isDayHighlighted={isDayHighlighted}
        isOutsideRange={isOutsideRange}
        enableOutsideDays={enableOutsideDays}
        endDate={endDate}
        focused
        focusedInput={focusedInput}
        hideKeyboardShortcutsPanel
        navNext={
          <DatePickerNavigation
            className={classNameElement('navigation-next')}
          />
        }
        navPrev={
          <DatePickerNavigation
            className={classNameElement('navigation-prev')}
            icon={ChevronLeft}
          />
        }
        onDatesChange={onDatesChange}
        onFocusChange={onFocusChange}
        onOutsideClick={onOutsideClick}
        onNextMonthClick={onNextMonthClick}
        onPrevMonthClick={onPrevMonthClick}
        renderDayContents={(date) => (
          <DatePickerDay
            className={classNameElement('day-contents')}
            date={date.format(displayFormatDay)}
          />
        )}
        renderMonthElement={renderMonthElement}
        startDate={startDate}
      />
    </div>
  );
};

DayPickerRangeController.propTypes = {
  endDate: PropTypes.instanceOf(Date, moment),
  startDate: PropTypes.instanceOf(Date, moment),
  displayFormatDay: PropTypes.string,
  focusedInput: PropTypes.bool,
  onDatesChange: PropTypes.func,
  onFocusChange: PropTypes.func,
  onOutsideClick: PropTypes.func,
  onNextMonthClick: PropTypes.func,
  onPrevMonthClick: PropTypes.func,
  enableOutsideDays: PropTypes.bool,
  isDayBlocked: PropTypes.func,
  isDayHighlighted: PropTypes.func,
  isOutsideRange: PropTypes.func,
};

export default DayPickerRangeController;
