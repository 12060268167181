import { safeCall } from '@elliemae/ds-utilities/utils';

export default class VolatileRowsListener {
  observers = [];

  observe(listener) {
    this.observers.push(listener);
  }

  notify(nextRows) {
    this.observers.forEach(observer => safeCall(observer, nextRows));
    this.observers = [];
  }
}
