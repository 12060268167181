/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { cx } from '@elliemae/ds-utilities/utils';
import { components } from 'react-select';
import { Popper } from 'react-popper';
import { PopperPositions } from '../../../../Popper';

// todo: the dropdown menu should be separated to a basic component and then import it here
// use this for the moment but this needs to be changed
import { Menu } from '../../../../Menu';
import DSOverlay from '../../../../Overlay';

const SelectMenu = React.memo(props => {
  const {
    inlineMenu,
    selectMeasure: { bounds },
    expandMenuToContainer,
    expandMenuOutsideContainer,
    zIndex,
  } = props.selectProps;

  if (inlineMenu) {
    return <components.Menu {...props} />;
  }

  const styleMenu = expandMenuToContainer ? { width: bounds.width } : {};
  if (expandMenuOutsideContainer) {
    styleMenu.width = 'auto';
  }

  return (
    <DSOverlay
      containerProps={{
        className: 'combobox-menu-overlay',
      }}
      isOpen
    >
      <Popper
        containerProps={{
          className: 'combobox-menu-overlay-popper',
        }}
        modifiers={{
          preventOverflow: {
            enabled: true,
            padding: 0,
            boundariesElement: 'window',
          },
        }}
        placement={PopperPositions.BOTTOM_START}
      >
        {({ ref, style }) => (
          <div
            ref={ref}
            className={cx(
              'combobox-menu-wrapper-external',
              expandMenuOutsideContainer &&
                'combobox-menu-wrapper-external-outside-container',
            )}
            style={{
              zIndex,
              ...style,
              ...(expandMenuOutsideContainer ? { width: 'auto' } : {}),
            }}
          >
            <Menu
              className={cx(
                'combobox-menu-menu',
                expandMenuOutsideContainer &&
                  'combobox-menu-menu-external-outside-container',
              )}
              style={styleMenu}
              visible
            >
              <components.Menu {...props} />
            </Menu>
          </div>
        )}
      </Popper>
    </DSOverlay>
  );
});

export default SelectMenu;
