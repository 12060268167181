import { BodyHeaderScrollSyncPlugin } from './plugins/body-header-scroll-sync';
import { ResizablePlugin } from './plugins/resizable';
import { CustomRendererPlugin } from './plugins/custom-cell-renderer';
import { ColumnSizingPlugin } from './plugins/column-sizing';

export const defaultPlugins = [
  BodyHeaderScrollSyncPlugin,
  ResizablePlugin,
  CustomRendererPlugin,
  ColumnSizingPlugin,
];
