export const modalTypes = [
  'default',
  'alert',
  'confirm',
  'notification',
  'warning',
  'error',
  'success',
  'help',
  'info',
];

export const sizes = ['xsmall', 'small', 'medium', 'large', 'xlarge'];

export const iconSizes = ['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl'];

export const iconColors = [
  ['neutral', '900'],
  ['neutral', '0'],
  ['danger', '900'],
  ['warning', '500'],
  ['success', '900'],
  ['brand-primary', '600'],
];
