/* eslint-disable max-lines */
import useDerivedStateFromProps from '@elliemae/ds-utilities/hooks/useDerivedStateFromProps';
import { toggleInObject, hashArray, omit } from '@elliemae/ds-utilities/utils';
import {
  checkAllRowsSelected,
  toggleSelectAll,
  toggleSingleSelectable,
} from './helper';

function deriveSelectedStateFromPropsOnUpdate(rows) {
  return (nextProp = {}, prevProp = {}) => {
    if (nextProp.selectAll !== prevProp.selectAll) {
      const nextSelectedRows = nextProp.selectAll
        ? toggleSelectAll(rows, true)
        : hashArray(nextProp.selectedRows || []);
      return {
        selectedRows: nextSelectedRows,
        selectAll: checkAllRowsSelected(rows, Object.keys(nextSelectedRows)),
      };
    }
    return {
      selectedRows: hashArray(nextProp.selectedRows || []),
      selectAll: checkAllRowsSelected(rows, Object.keys(nextProp.selectedRows)),
    };
  };
}

export function useSelectableState(grid) {
  const {
    props: {
      rowKey = 'id',
      selectedRows,
      selectAll,
      selectable,
      onSelectRow,
      onSelectAll,
    },
    rows,
    setLastSelectedRow,
  } = grid.getInstance();

  const multiple = selectable === 'multi';
  const [selection, setSelectedRowsState] = useDerivedStateFromProps(
    { selectedRows, selectAll },
    { onUpdate: deriveSelectedStateFromPropsOnUpdate(rows) },
  );

  // ACTIONS
  const handleSelectRow = (
    id,
    index,
    shift = false,
    lastSelectedRow = null,
    // eslint-disable-next-line max-params
  ) => {
    setSelectedRowsState((prevState) => {
      const { rows: nextRows } = grid.getInstance();
      const wasSelected = Object.keys(prevState.selectedRows).some(
        // we need to match softly as the id may be a number
        // eslint-disable-next-line eqeqeq
        (elem) => elem == id,
      );
      let nextSelectedRows = multiple
        ? toggleInObject(prevState.selectedRows, id, true)
        : toggleSingleSelectable(prevState.selectedRows, id);
      setLastSelectedRow({ index, mode: wasSelected ? 'deselect' : 'select' });
      if (shift && lastSelectedRow) {
        const prevSmallest = lastSelectedRow.index < index + 1;
        const sliceStart = prevSmallest ? lastSelectedRow.index : index;
        const sliceEnd = prevSmallest ? index + 1 : lastSelectedRow.index + 1;
        const rowSegment = nextRows
          .slice(sliceStart, sliceEnd)
          .map((row) => row[rowKey]);
        if (lastSelectedRow.mode === 'deselect') {
          nextSelectedRows = omit(prevState.selectedRows, rowSegment);
        } else {
          const selectedSegment = rowSegment.reduce(
            (o, key) => ({ ...o, [key]: true }),
            {},
          );
          nextSelectedRows = {
            ...prevState.selectedRows,
            ...selectedSegment,
          };
        }
      }

      const nextState = {
        selectedRows: nextSelectedRows,
        selectAll: checkAllRowsSelected(
          nextRows,
          Object.keys(nextSelectedRows),
        ),
      };
      if (index !== null && index !== undefined) {
        onSelectRow(
          nextRows.filter((row) => nextState.selectedRows[row[rowKey]]),
          nextState.selectAll,
          index,
        );
      }

      return nextState;
    });
  };

  const handleSelectAll = (checked) => {
    if (!multiple) return;
    setLastSelectedRow(null);
    setSelectedRowsState(() => {
      const { composedRows: nextRows } = grid.getInstance();

      const nextState = {
        selectAll: checked,
        selectedRows: toggleSelectAll(nextRows, checked),
      };

      onSelectAll(nextState.selectAll);
      onSelectRow(checked ? nextRows : [], nextState.selectAll);

      return nextState;
    });
  };

  return {
    actions: {
      selectRow: handleSelectRow,
      selectAll: handleSelectAll,
    },
    state: {
      selection,
    },
  };
}
