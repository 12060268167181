import { useState, useEffect, useRef } from 'react';
import { throttle } from 'lodash';
import { useExecutionTimer } from '@elliemae/ds-utilities/hooks';

function usePopoverProviderState({
  initialState = {
    reference: null,
    visible: false,
    value: '',
    options: {},
  },
  delay,
}) {
  const triggerRef = useRef();
  const [tooltipState, setTooltipState] = useState(initialState);

  const show = state => {
    setTooltipState(prevState => ({
      ...prevState,
      ...state,
      visible: true,
    }));
  };
  const hide = state => {
    setTooltipState(prevState => ({
      ...prevState,
      ...state,
      visible: false,
    }));
  };

  const [showTooltip, hideTooltip] = useExecutionTimer(show, hide, delay);

  const handleShowTooltip = (state, e) => {
    triggerRef.current = e.target;
    showTooltip({ ...state, reference: e.target });
  };

  const handleHideTooltip = state => {
    hideTooltip(state);
  };

  useEffect(() => {
    window.addEventListener('scroll', throttle(handleHideTooltip, 300), true);
    window.addEventListener('click', handleHideTooltip, true);
    return () => {
      window.removeEventListener(
        'scroll',
        throttle(handleHideTooltip, 300),
        true,
      );
      window.addEventListener('click', handleHideTooltip, true);
    };
  }, [tooltipState.reference]);

  return {
    show: handleShowTooltip,
    hide: handleHideTooltip,
    state: tooltipState,
  };
}

export default usePopoverProviderState;
