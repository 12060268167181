import React, { useContext, useEffect, useMemo, useState } from 'react';
import getComponentFromProps from '@elliemae/ds-utilities/getComponentFromProps';
import DSPopper from '@elliemae/ds-basic/Popper';
import { Menu } from '@elliemae/ds-basic/Menu';
import { convertPropToCssClassName } from '@elliemae/ds-classnames';
import { runAll, isFunction } from '@elliemae/ds-utilities/utils';
import DropdownMenuContext from './DropdownMenuContext';
import { renderOptions, factory } from './decoratedMenuItemFactory';
import LoadingIndicator from './DropdownMenuLoadingIndicator';

const blockName = 'menu';

const getMax = options => {
  let max = 0;
  options.forEach(o => {
    if (o && o.label && String(o.label).length > max)
      max = String(o.label).length;
  });
  return max;
};

const DropdownMenuRenderer = ({
  containerProps,
  className,
  options = [],
  triggerComponent,
  placement,
  interactionType,
  preventOverflow,
  customMenu,
  contentOffset,
  contentStyle,
  isOpen,
  children,
  menuProps,
  referenceNode,
  focusOnOpen: focusOnOpenProp,
  onClickOutsideMenu,
  responsiveHeight,
  itemsRenderer,
  maxWidth,
  minWidth,
  zIndex,
  type,
  loading,
}) => {
  const [focusOnOpen, setFocusOnOpenMenu] = useState(focusOnOpenProp);
  const { isMenuOpen, openMenu, closeMenu } = useContext(DropdownMenuContext);
  const maxOption = useMemo(() => {
    let max = 0;
    options.forEach(o => {
      if (o && o.label && String(o.label).length > max)
        max = String(o.label).length;
      if (o && o.items) {
        const nestedMax = getMax(o.items);
        if (nestedMax > max) max = nestedMax;
      }
    });
    return max;
  }, [options]);

  const optionsRendererd = useMemo(() => {
    if (options[0] && options[0].type === 'separator') {
      options[0].showSeparator = false;
    }
    return isFunction(itemsRenderer)
      ? itemsRenderer(options, factory)
      : renderOptions(options);
  }, [options]);

  const dropdownBlockName = convertPropToCssClassName(blockName);

  const menuContent = () => {
    if (loading) return <LoadingIndicator />;
    if (children) return children;
    if (optionsRendererd) return optionsRendererd;
  };

  const MenuComponent = customMenu ? (
    getComponentFromProps(customMenu, {
      options,
      focusOnOpen,
      maxOption,
      ...menuProps,
      closeMenu,
      loading,
    })
  ) : (
    <Menu
      closeMenu={closeMenu}
      focusOnOpen={focusOnOpen}
      maxOption={type === 'phone' ? maxOption : undefined}
      maxWidth={maxWidth}
      minWidth={minWidth}
      onClickOutside={onClickOutsideMenu}
      onExitFocusGroup={closeMenu}
      responsiveHeight={responsiveHeight}
      type={type}
      visible
      {...menuProps}
    >
      {menuContent()}
    </Menu>
  );

  useEffect(() => {
    setFocusOnOpenMenu(focusOnOpenProp);
  });

  return (
    <DSPopper
      blockName={dropdownBlockName}
      containerProps={containerProps}
      contentComponent={MenuComponent}
      contentOffset={contentOffset}
      contentStyle={contentStyle}
      interactionType={interactionType}
      isOpen={isOpen !== undefined ? isOpen : isMenuOpen}
      onOpen={opening => (opening ? openMenu() : closeMenu())}
      placement={placement}
      preventOverflow={preventOverflow}
      referenceNode={referenceNode}
      showArrow={false}
      triggerComponent={getComponentFromProps(triggerComponent, {
        onClick:
          interactionType === 'hover'
            ? runAll(openMenu, triggerComponent.props.onClick)
            : runAll(
                !isMenuOpen ? openMenu : closeMenu,
                triggerComponent.props.onClick,
              ),
        onMouseEnter: interactionType === 'hover' ? openMenu : undefined,
        onKeyDown: e => {
          if (e.key === 'ArrowDown' || e.key === ' ') {
            e.preventDefault();
            setFocusOnOpenMenu(true);
            openMenu();
          }
        },
        className: `${triggerComponent.props.className} ${className}`,
      })}
      zIndex={zIndex || menuProps.zIndex}
    />
  );
};

export default DropdownMenuRenderer;
