import React from 'react';
import { isFunction, addOrRemove } from '@elliemae/ds-utilities/utils';
import { PillGroup } from '../PillGroup';
import { RemovablePill } from '../RemovablePill';
import { DropdownPill } from '../DropdownPill';

function renderMenuItems(options, factory) {
  return options.map((option, index) => {
    const ItemComponent = factory('radio');

    if (option.type === 'submenu') {
      const SubmenuComponent = factory('submenu');
      const ChildComponent = factory('selection-group');
      return (
        <SubmenuComponent {...option} key={option.id} item={option}>
          <ChildComponent
            {...option}
            id={option.id}
            item={option}
            items={option.items}
            itemValueAccessor={item => item}
            multi
          />
        </SubmenuComponent>
      );
    }

    return <ItemComponent {...option} key={option.id || index} item={option} />;
  });
}

const getSubmenuOptionLabelById = (options, id) => {
  const index = options.findIndex(option => option.id === id);
  return index ? options[index].label : '';
};

export default function LabeledDropdownCascadeMenuPill({
  label,
  options,
  value: optionValue,
  onChange,
  onRemove,
  value: selection = {},
  placeholder = '---',
  onFocusNextGroup,
  onFocusPreviousGroup,
  onFocusGroupSet,
  maxWidth,
  minWidth,
  ...menuProps
}) {
  const selectionId = Object.keys(selection)[0];
  const selectionValues = selection[selectionId] || '';
  const dropdownLabel = Array.isArray(selectionValues)
    ? getSubmenuOptionLabelById(options, selectionId)
    : selectionValues;

  const handleSelectChange = ({ item, group }) => {
    if (isFunction(onChange)) {
      const nextSelection = group
        ? {
            [group.id]: addOrRemove(
              [...(selection[group.id] || [])],
              item,
              o => o.id,
            ),
          }
        : {
            [item.id]: item.label,
          };
      onChange(nextSelection);
    }
  };

  const removeOption = option => {
    if (isFunction(onChange)) {
      const nextSelection = {
        [selectionId]: selectionValues.filter(val => option.id !== val.id),
      };
      onChange(nextSelection);
    }
    onRemove(option);
  };

  return (
    <PillGroup
      onFocusGroupSet={onFocusGroupSet}
      onFocusNextGroup={onFocusNextGroup}
      onFocusPreviousGroup={onFocusPreviousGroup}
    >
      <DropdownPill
        {...menuProps}
        itemsRenderer={renderMenuItems}
        label={dropdownLabel || placeholder}
        maxWidth={maxWidth}
        minWidth={minWidth}
        onSelectChange={handleSelectChange}
        options={options}
        selection={{
          [selectionId]: Array.isArray(selectionValues)
            ? selectionValues.map(v => v.id)
            : selectionValues,
        }}
        singleGroupSelection
        variant="title"
      />
      {Array.isArray(selectionValues) &&
        selectionValues.map(option => (
          <RemovablePill
            key={option.id}
            label={option.label}
            onRemove={() => removeOption(option)}
          />
        ))}
    </PillGroup>
  );
}
