/* eslint-disable max-lines */
/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import { describe, PropTypes } from 'react-desc';
import { convertPropToCssClassName } from '@elliemae/ds-classnames';
import { cx } from '@elliemae/ds-utilities/utils';
import CheckMark from './elements/CheckMark';
import { CHECKBOX_VARIANT, checkboxVariants } from '../../utils/prop-types';

function Checkbox({
  className,
  onChange,
  checked,
  innerRef,
  onKeyDown = () => null,
  readOnly,
  value,
  disabled,
  style,
  variant,
  tabIndex = 0,
  name,
  onMouseEnter,
  onMouseLeave,
  ...rest
}) {
  const checkRef = useRef(null);
  return (
    <span onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <CheckMark
        {...rest}
        ref={innerRef}
        data-testid={rest['data-testid'] || 'ds-checkbox'}
        aria-checked={checked}
        aria-disabled={disabled}
        className={`${className} ${variant || ''} ${
          disabled ? 'disabled' : ''
        }`}
        data="checkmark"
        onClick={(e) => {
          e.target = checkRef.current;
          if (readOnly) return;
          onChange(e);
        }}
        onKeyDown={(e) => {
          e.target = checkRef.current;
          if (readOnly) return;
          if (e.key === 'Enter' || e.keyCode === 32) {
            onChange(e);
            onKeyDown(e);
          }
        }}
        role="checkbox"
        style={style}
        tabIndex={tabIndex}
        value={value}
      >
        {/* todo: I want to remove this and add an abstraction for this kind of input. @marc */}
        {/* check reakit checkbox */}
        {/* https://github.com/reakit/reakit/blob/master/packages/reakit/src/Checkbox/Checkbox.ts */}
        <input
          ref={checkRef}
          checked={checked}
          disabled={disabled}
          name={name}
          readOnly={readOnly}
          style={{ visibility: 'hidden', pointerEvents: 'none' }}
          type="checkbox"
          value={value}
          onChange={() => {}} // this suppress the warning, we manage onChange on another item level
        />
      </CheckMark>
    </span>
  );
}

const DSCheckbox = ({
  labelText = undefined,
  className = undefined,
  htmlFor = undefined,
  hasError = false,
  readOnly = false,
  disabled = false,
  checked = false,
  onChange = () => null,
  variant = CHECKBOX_VARIANT.DEFAULT,
  children = null,
  containerProps = {},
  innerRef,
  name = '',
  ...otherProps
}) => {
  const { cssClassName, classNameElement } = convertPropToCssClassName(
    'form-element-checkbox',
    className,
    {
      hasError,
      readOnly,
      disabled,
    },
  );

  const checkbox = (
    <Checkbox
      {...otherProps}
      checked={checked}
      className={cx(
        `${classNameElement('input-button')} checkbox-${String(checked)}`,
        className,
      )}
      disabled={disabled}
      innerRef={innerRef}
      name={name}
      onChange={onChange}
      readOnly={readOnly}
      variant={variant}
    />
  );

  if (!labelText) return checkbox;

  return (
    <div className={`${cssClassName} ${variant}`} {...containerProps}>
      <label className={classNameElement('label')} htmlFor={htmlFor}>
        {checkbox}
        <span className={classNameElement('label-text')}>{labelText}</span>
      </label>
      {children && (
        <div className={classNameElement('checkbox-children')}>{children}</div>
      )}
    </div>
  );
};

const checkboxProps = {
  containerProps: PropTypes.object.description(
    'Set of Properties attached to the main container',
  ),
  labelText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).description('Displayable label for the checkbox'),
  htmlFor: PropTypes.element.description('html for attr'),
  hasError: PropTypes.bool
    .description('Whether the checkbox has error or not')
    .defaultValue(false),
  readOnly: PropTypes.bool
    .description('Whether the checkbox is read only or not')
    .defaultValue(false),
  disabled: PropTypes.bool
    .description('Whether the checkbox is disabled or not')
    .defaultValue(false),
  checked: PropTypes.oneOfType([PropTypes.oneOf(['mixed']), PropTypes.bool])
    .description('Whether the checkbox is checked, mixed or unchecked')
    .defaultValue(false),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).description('Value that takes the checkbox if it is checked'),
  variant: PropTypes.oneOf(checkboxVariants)
    .description('checbkox variant')
    .defaultValue(CHECKBOX_VARIANT.DEFAULT),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).description('children elements'),
  onChange: PropTypes.func.description(
    'function executed when the checkbox changes',
  ),
  name: PropTypes.string.description('checkbox s name'),
};

DSCheckbox.propTypes = checkboxProps;

const CheckboxWithSchema = describe(DSCheckbox);
CheckboxWithSchema.propTypes = checkboxProps;

export { CHECKBOX_VARIANT, CheckboxWithSchema };
export default DSCheckbox;
