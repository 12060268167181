import React from 'react';
import createInstancePlugin from '@elliemae/ds-shared/createDataInstance/createInstancePlugin';
import { appendCellFormatter } from '@elliemae/ds-shared/useDataGrid/initColumnDefinition';
import { RowRenderer } from './RowRenderer';

const decorateColumns = (columns) => {
  const lastColumn = columns[columns.length - 1];
  return [
    ...columns.slice(0, -1),
    appendCellFormatter(
      [
        (value) => (
          <>
            {value}
            <div style={{ width: 34, maxWidth: 34, overflow: 'hidden' }} />
          </>
        ),
      ],
      lastColumn,
      'fixedFormatters',
    ),
  ];
};

const decorateRenderers = (renderers, grid) => {
  const Row = renderers.body.row;
  renderers.body.row = RowRenderer(Row, grid);
  return renderers;
};

export const ToolbarPlugin = createInstancePlugin('toolbar', {
  decorateRenderers,
  decorateColumns,
});
