import React from 'react';
import { components } from 'react-select';
import { Reference } from 'react-popper';

const Control = props => (
  <Reference>
    {({ ref }) => (
      <div ref={ref}>
        <components.Control {...props} />
      </div>
    )}
  </Reference>
);

export default Control;
