/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment,max-lines,react/display-name, react/require-default-props */
import React, { useContext } from 'react';
import { get } from 'lodash';
import CloseXsmall from '@elliemae/ds-icons/CloseXsmall';
import DSButton from '../../../../Button';
import { DropDownContext } from '../context';

const ClearIndicator = (props) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
    innerRef,
  } = props;
  const c = useContext(DropDownContext);

  return (
    <div
      {...restInnerProps}
      ref={ref}
      aria-hidden="false"
      style={getStyles('clearIndicator', props)}
    >
      <DSButton
        {...props}
        aria-label="clear-indicator"
        buttonType="text"
        tabOrder="2"
        className="clear-indicator"
        icon={<CloseXsmall size="m" />}
        innerRef={innerRef}
        onKeyDown={(e) => {
          if (e.keyCode === 32 || e.keyCode === 13) {
            e.preventDefault();
            e.stopPropagation();
            props.clearValue();
            const select = get(c, 'select.current');
            if (select && select.focus) select.focus();
          }
          if (props.onKeyDown) props.onKeyDown(e);
        }}
      />
    </div>
  );
};

export default ClearIndicator;
