/* eslint-disable max-lines */
/* eslint-disable import/no-cycle */
import React, { useRef } from 'react';
import { PropTypes, describe } from 'react-desc';
import ChevronRight from '@elliemae/ds-icons/ChevronSmallRight';
import VerticalElipsis from '@elliemae/ds-icons/MoreOptionsVert';
import { useFocusGroupItem } from '@elliemae/ds-shared/FocusGroup';
import DSButton, { BUTTON_VARIANT } from '../../Button';
import DSPopper from '../../Popper';
import { interactions } from '../../Popper/interaction';
import { useHiddenController } from '../../Hidden';
import Menu from '../Menu';
import MenuItem from './MenuItem';

function SubMenu({
  noAddon,
  leftAddon = undefined,
  label = undefined,
  interactionType = 'hover',
  children: subitems,
  rightAddonType,
  disabledAddonInactive,
  checkableProps = {},
  customRenderer,
  onClick,
  closeMenu,
  id,
}) {
  const itemRef = useRef(null);
  const focusOnOpen = useRef(false);
  const { visible = false, onShow, onHide } = useHiddenController(undefined, {
    interaction: 'click',
  });

  const { focusItemByNode } = useFocusGroupItem();
  // todo: create a menu state to handle all related menu things
  const handleShowSubItemsWithMouse = (e) => {
    e.stopPropagation();
    onShow();
    focusOnOpen.current = true;
  };
  const handleShowWithMouse = (e) => {
    if (onClick) onClick(e);
  };
  const handleShowWithMouseHover = () => {
    onShow();
    focusOnOpen.current = false;
  };

  const handleShowWithKeyboard = () => {
    onShow();
    focusOnOpen.current = true;
  };

  const hoverHandlers =
    interactionType === 'hover'
      ? {
          onMouseEnter: handleShowWithMouseHover,
          onMouseLeave: onHide,
        }
      : {
          onClick: handleShowWithMouse,
        };

  const rightAddon =
    rightAddonType === 'elipsis' ? (
      <DSButton
        aria-label="vertical-elipsis"
        buttonType="text"
        leftIcon={
          <VerticalElipsis
            className="submenu-arrow"
            color={['brand-primary', 600]}
            size="s"
          />
        }
        onClick={(e) => handleShowSubItemsWithMouse(e)}
        size="m"
        variant={BUTTON_VARIANT.DEFAULT}
      />
    ) : (
      <ChevronRight
        className="submenu-arrow"
        color={['brand-primary', 600]}
        size="sm"
      />
    );

  return (
    <>
      <DSPopper
        contentComponent={
          <Menu
            focusKeyBindings={{
              ArrowLeft: [() => focusItemByNode(itemRef.current), 'exit'],
            }}
            focusOnOpen={focusOnOpen.current}
            onExitFocusGroup={onHide}
            {...hoverHandlers}
            closeMenu={closeMenu}
            visible={visible}
          >
            {subitems}
          </Menu>
        }
        interactionType={interactionType}
        isOpen={visible}
        onOpen={(opening) => (opening ? onShow() : onHide())}
        placement="right-start"
        showArrow={false}
        triggerComponent={
          <MenuItem
            customRenderer={customRenderer}
            disabledAddonInactive={disabledAddonInactive}
            id={id}
            innerRef={itemRef}
            label={label}
            leftAddon={leftAddon}
            noAddon={noAddon}
            {...hoverHandlers}
            onKeyDown={(e) => {
              if (
                e.key === 'ArrowRight' ||
                e.key === 'Enter' ||
                e.keyCode === 32
              ) {
                handleShowWithKeyboard();
              }
            }}
            rightAddon={rightAddon}
            {...checkableProps}
          />
        }
      />
    </>
  );
}

const props = {
  /** dont show addon for submenu */
  noAddon: PropTypes.bool.description('dont show addon for submenu'),
  /** left addon */
  leftAddon: PropTypes.node.description('left addon'),
  /** submenu label */
  label: PropTypes.string.description('submenu label'),
  /** A type indicating how to open/close the tooltip */
  interactionType: PropTypes.oneOf(interactions).description(
    'A type indicating how to open/close the tooltip',
  ),
  /** sub menu items */
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).description(
    'sub menu items',
  ),
  /** right addon, ellipsis or undefined */
  rightAddonType: PropTypes.oneOf(['ellipsis']).description(
    'right addon, ellipsis or undefined',
  ),
  disabledAddonInactive: PropTypes.bool.description(''),
  /** props passed to MenuItem component */
  checkableProps: PropTypes.object.description(
    'props passed to MenuItem component',
  ),
  /** custom renderer for menu item */
  customRenderer: PropTypes.func.description('custom renderer for menu item'),
  /** on click handler */
  onClick: PropTypes.func.description('on click handler'),
  closeMenu: PropTypes.any.description(''),
  /** component id */
  id: PropTypes.string.description('component id'),
};

SubMenu.propTypes = props;

const DSSubMenuWithSchema = describe(SubMenu);

DSSubMenuWithSchema.propTypes = props;

export default SubMenu;

export { DSSubMenuWithSchema };
