/* eslint-disable max-lines */
import React from 'react';
import { PropTypes, describe } from 'react-desc';
import { TooltipTextProvider } from '@elliemae/ds-basic/TruncatedTooltipText';
// todo: docgen handlers at the moment don't get data from alias
import { positions, Position } from '../../../ds-basic/src/Popper/positions';
import {
  interactions,
  Interaction,
} from '../../../ds-basic/src/Popper/interaction';
import DropdownMenuWrapper from './DropdownMenuWrapper';

const noop = () => null;

const DSDropdownMenu = ({
  containerProps = {},
  className = '',
  contentStyle = null,
  options = [],
  onSelectMenuItem = noop,
  onOpenMenu = noop,
  onClickOutsideMenu = noop,
  preventOverflow = 'viewport',
  placement = Position.BOTTOM_START,
  interactionType = Interaction.CLICK,
  triggerComponent = noop,
  customMenu = null,
  isOpen = undefined,
  selection = {},
  menuProps = {},
  referenceNode = undefined,
  focusOnOpen = false,
  onClose = noop,
  itemsRenderer = undefined,
  responsiveHeight = false,
  singleGroupSelection = false,
  onSelectChange = noop,
  minWidth = undefined,
  maxWidth = undefined,
  zIndex,
  type,
  loading = false,
}) => (
  <TooltipTextProvider>
    <DropdownMenuWrapper
      className={className}
      containerProps={containerProps}
      contentStyle={contentStyle}
      customMenu={customMenu}
      focusOnOpen={focusOnOpen}
      interactionType={interactionType}
      isOpen={isOpen}
      itemsRenderer={itemsRenderer}
      maxWidth={maxWidth}
      menuProps={menuProps}
      minWidth={minWidth}
      onClickOutsideMenu={onClickOutsideMenu}
      onClose={onClose}
      onOpenMenu={onOpenMenu}
      onSelectChange={onSelectChange}
      onSelectMenuItem={onSelectMenuItem}
      options={options}
      placement={placement}
      preventOverflow={preventOverflow}
      referenceNode={referenceNode}
      responsiveHeight={responsiveHeight}
      selection={selection}
      singleGroupSelection={singleGroupSelection}
      triggerComponent={triggerComponent}
      type={type}
      zIndex={zIndex}
      loading={loading}
    />
  </TooltipTextProvider>
);

const dropdownMenuProps = {
  containerProps: PropTypes.shape({}).description(
    'Set of Properties attached to the main container',
  ),
  contentStyle: PropTypes.shape({}).description(
    'Object style passed to the menu',
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.string,
      label: PropTypes.string,
    }),
  ).description('Options items for the dropdown menu. Check menu item types'),
  onSelectMenuItem: PropTypes.func.description(
    'Handler when user clicks on a menu item',
  ),
  onOpenMenu: PropTypes.func.description('Handler when the menu shows up'),
  /**  Handler when user clicks outside the menu */
  onClickOutsideMenu: PropTypes.func.description(
    'Handler when user clicks outside the menu',
  ),
  /** Prevent to move the menu outside of the container defined when interacting with the page  */
  preventOverflow: PropTypes.oneOf([
    'viewport',
    'scrollParent',
    'window',
  ]).description(
    'Prevent to move the menu outside of the container defined when interacting with the page',
  ),
  /** Menu placement */
  placement: PropTypes.oneOf(positions).description('Menu placement'),
  /** How to open the menu with click, hover */
  interactionType: PropTypes.oneOf(interactions).description(
    'How to open the menu with click, hover',
  ),
  /** The component that is going to open / close the menu */
  triggerComponent: PropTypes.element.description(
    'The component that is going to open / close the menu',
  ),
  /** Custom menu component */
  customMenu: PropTypes.element.description('Custom menu component'),
  /** Whether the menu is open or not */
  isOpen: PropTypes.bool.description('Whether the menu is open or not'),
  /** Selection state. Ex: { [itemId]: '{bool}', [idMultiSelectionItem]: '{string}' } */
  selection: PropTypes.shape({}).description(
    'Selection state. Ex: { [itemId]: {bool}, [idMultiSelectionItem]: {string} }',
  ),
  /** Props object passed to the menu component */
  menuProps: PropTypes.shape({}).description(
    'Props object passed to the menu component',
  ),
  /** Customize the items render */
  itemsRenderer: PropTypes.func.description('Customize the items render'),
  /** Customize menu format. Can be undefined or 'phone' */
  type: PropTypes.string.description(
    'Customize menu format. Can be undefined or "phone"',
  ),
  /** Customize menu minWidth. Can be undefined or 'number' */
  minWidth: PropTypes.number.description(
    'Customize menu minWidth. Can be undefined or "number"',
  ),
  /** Customize menu maxWidth. Can be undefined or 'number' */
  maxWidth: PropTypes.number.description(
    'Customize menu maxWidth. Can be undefined or "number"',
  ),
  /** Make the menu responsive, it uses a margin of 120 px */
  responsiveHeight: PropTypes.bool.description(
    'Make the menu responsive, it uses a margin of 120 px',
  ),
  /** Customize menu zIndex. Can be undefined or 'number' */
  zIndex: PropTypes.number.description(
    'Customize menu zIndex. Can be undefined or "number"',
  ),
  className: PropTypes.string.description('html class attribute'),
  focusOnOpen: PropTypes.bool
    .description('focus element on open or not')
    .defaultValue(true),
  loading: PropTypes.bool
    .description('is loading content or not')
    .defaultValue(false),
  referenceNode: PropTypes.any.description('reference node'),
  onClose: PropTypes.func.description('function called on close mene'),
  onSelectChange: PropTypes.func.description(
    'function called when selection changes',
  ),
  singleGroupSelection: PropTypes.bool.description(
    'select items in same group or not',
  ),
};

DSDropdownMenu.propTypes = dropdownMenuProps;

const DropdownMenuWithSchema = describe(DSDropdownMenu);
DropdownMenuWithSchema.propTypes = dropdownMenuProps;

export { DropdownMenuWithSchema };
export default DSDropdownMenu;
