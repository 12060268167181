import {
  isFunction,
  uniqBy,
  get,
  groupBy,
  property,
} from '@elliemae/ds-utilities/utils';

export const getFilterLabel = (column, value) =>
  column.filterLabels ? column.filterLabels[value] || value : value;

export const getFilterLabels = (column, rows) =>
  uniqBy(rows, property(column.searchBy || column.property))
    .map((row) => {
      const value = get(row, column.searchBy || column.property);
      if (value === undefined) return {};
      const rowColumnValue = String(
        get(row, column.searchBy || column.property),
      );
      if (isFunction(column.valueTransformation)) {
        const nextValue = column.valueTransformation(rowColumnValue);
        return { id: nextValue, label: nextValue };
      }
      const label = getFilterLabel(column, rowColumnValue);
      return { id: rowColumnValue || label, label };
    })
    .filter((row) => row.id)
    .filter((row) => {
      if (row.id.startsWith) return !row.id.startsWith('grouped-by');
      return true;
    });

export const getFilterLabelsGroup = (column, rows) => {
  let flat = [];
  rows.forEach((r) => {
    if (r.children) {
      flat = [...flat, ...r.children];
    }
  });
  return getFilterLabels(column, flat);
};

export function getFilterValue(filter, column) {
  const { value } = filter;

  if (!column) return value;

  const { filterOptions, filterLabels } = column;

  if (filterOptions) {
    const foundOption = column.filterOptions.filter(
      (option) => option.id === value,
    )[0];
    if (foundOption) return foundOption.label;
    return value;
  }
  if (filterLabels) {
    return filterLabels[value];
  }

  return value;
}

export function buildFilterArray(newFilter, filters) {
  // if (newFilter.type && newFilter.type === 'date') return [...filters.slice(1), newFilter]
  return uniqBy([...filters, newFilter], (v) => [v.group, v.value].join());
}

export const groupFilters = (filters) =>
  filters.filter((filter) => !filter.fixed);

function parseFilterToRule(filter) {
  return {
    ...filter,
    value: filter.filterParams || filter.value,
    field: filter.group,
  };
}

export function parseFiltersToQuery(filters) {
  const groupedFilters = groupBy(
    groupFilters(filters),
    (filter) => filter.group,
  );
  return Object.keys(groupedFilters).reduce(
    (queryResult, field) => {
      const filter = groupedFilters[field];
      if (Array.isArray(filter) && filter.length > 1) {
        // create query group with 'OR' condition
        queryResult.rules.push({
          condition: 'OR',
          rules: filter.map(parseFilterToRule),
        });
      } else {
        queryResult.rules.push(parseFilterToRule(filter[0]));
      }
      return queryResult;
    },
    { condition: 'AND', rules: [] },
  );
}
