/* eslint-disable max-lines */
/* eslint-disable max-statements */
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
// import ReactDOM from 'react-dom'
import { mergeRefs } from '@elliemae/ds-utilities/system';
import { debounce, isNaN } from '@elliemae/ds-utilities/utils';
import NoResults from '../../components/NoResults';
import VirtualizedBodyRow from './VirtualizedBodyRow';
import { RowSizes } from '../../rowSizes';
import { isSafari } from './helper';

const wrapperSafari = {};
const wrapperGeneric = { overflowY: 'auto', overflowX: 'visible' };

const VirtualizedBody = (props) => {
  const {
    autoScrollToId,
    columns,
    component: Component,
    expandable,
    innerRef,
    innerBody,
    isPlaceholderActive,
    listProps,
    noResultsPlaceholder,
    overscanCount,
    rows,
    rowHeight: defaultRowHeight,
    rowKey,
    rowRenderer,
    rowSize,
    setHasScroll,
  } = props;
  const rowHeight = defaultRowHeight || RowSizes[rowSize];
  const [scrollTop, setScrollTop] = useState(0);
  const [visibleHeight, setVisibleHeight] = useState(0);
  const [hasScrollBar, setHasScrollBar] = useState(false);
  const rowStyleCache = useRef({});
  const [renderedIndexes, setRenderedIndexes] = useState([0, 30]);

  const getCalculatedHeightBetweenIndexes = (start, end) => {
    const { itemSize = () => rowHeight } = listProps;
    if (expandable) {
      return rows
        .slice(start, end)
        .map((_, idx) => idx)
        .map((idx) => itemSize(idx))
        .reduce((acc, curr) => acc + curr, 0);
    }
    return (end - start) * rowHeight;
  };

  const generateRowPositionMap = () =>
    rows.map((row, idx) => getCalculatedHeightBetweenIndexes(0, idx));

  const rowPositionMap = useRef(generateRowPositionMap());
  const [virtualizedBodyRef, setVirtualizedBodyRef] = useState(null);
  const handlerRef = useRef();

  const autoScrollTo = useCallback(
    (scrollHeight) => {
      const { itemSize = () => rowHeight } = listProps;
      const idx = rows.findIndex((item) => item[rowKey] === autoScrollToId);
      let val = rowPositionMap.current[idx] || 0;
      const lastPositionIdx = rowPositionMap.current.length - 1;
      let lastPosition = 0;
      if (lastPositionIdx > -1) {
        lastPosition =
          rowPositionMap.current[lastPositionIdx] -
          scrollHeight +
          itemSize(lastPositionIdx);
      }
      if (lastPosition < val) {
        val = lastPosition;
      }
      if (virtualizedBodyRef) {
        if (idx > -1) {
          setScrollTop(val);
          virtualizedBodyRef.scrollTop = val;
        }
      }
    },
    [rows, virtualizedBodyRef],
  );

  useEffect(() => {
    autoScrollTo(visibleHeight);
  }, [autoScrollToId, visibleHeight]);

  const handler = useCallback(
    debounce(({ target }) => {
      // Update coordinates
      setScrollTop(target.scrollTop || 0);
    }, 100),
    [setScrollTop],
  );

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useLayoutEffect(() => {
    let top = Math.ceil(scrollTop / rowHeight);
    let bot = Math.ceil((scrollTop + visibleHeight) / rowHeight);
    if (expandable) {
      // updateMapperFirst
      rowPositionMap.current = generateRowPositionMap();
      top = rowPositionMap.current.findIndex((item) => item > scrollTop);
      bot = rowPositionMap.current.findIndex(
        (item) => item > scrollTop + visibleHeight,
      );
      if (bot === -1) bot = rowPositionMap.current.length;
    }
    let upperBound = top - 1 - overscanCount;
    let lowerBound = bot - 1 + overscanCount;
    if (upperBound < 0) {
      upperBound = 0;
    }
    if (lowerBound - upperBound < overscanCount) {
      lowerBound = upperBound + overscanCount;
    }
    if (lowerBound > rows.length) {
      lowerBound = rows.length;
    }
    setHasScrollBar(visibleHeight < getHeight());
    setHasScroll(visibleHeight < getHeight());
    setRenderedIndexes([upperBound, lowerBound]);
  }, [visibleHeight, scrollTop, rows]);

  useLayoutEffect(() => {
    if (virtualizedBodyRef) {
      const parsedVisibleHeight = parseFloat(
        window
          .getComputedStyle(virtualizedBodyRef, null)
          .getPropertyValue('height'),
      );
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(parsedVisibleHeight)) {
        setVisibleHeight(parsedVisibleHeight);
        autoScrollTo(parsedVisibleHeight);
      }
    }
  }, [rows, virtualizedBodyRef]);

  useEffect(() => {
    if (virtualizedBodyRef) {
      const eventListener = (event) => handlerRef.current(event);
      virtualizedBodyRef.addEventListener('scroll', eventListener);

      return () => {
        virtualizedBodyRef.removeEventListener('scroll', eventListener);
      };
    }
    return () => null;
  }, [virtualizedBodyRef]);

  const getHeight = () => {
    let returnHeight = 0;
    if (expandable) {
      returnHeight = getCalculatedHeightBetweenIndexes(0, rows.length) + 8;
    } else {
      returnHeight = rows.length * rowHeight + 8;
    }
    // if (visibleHeight > returnHeight) {
    //   return visibleHeight;
    // }
    if (
      virtualizedBodyRef &&
      virtualizedBodyRef.scrollHeight > virtualizedBodyRef.clientHeight
    ) {
      return returnHeight + 10;
    }
    return returnHeight;
  };

  const getRowStyle = (row, index, specificRowHeight) => {
    const { itemSize } = listProps;

    if (!expandable && rowStyleCache.current[index]) {
      return rowStyleCache.current[index];
    }
    rowStyleCache.current[index] = {
      height: itemSize ? itemSize(index) : specificRowHeight,
      left: 0,
      right: 0,
      top: getCalculatedHeightBetweenIndexes(0, index),
      position: 'absolute',
    };
    return rowStyleCache.current[index];
  };

  const generateRenderedRows = () =>
    rows.slice(renderedIndexes[0], renderedIndexes[1] + 1).map((_, index) => (
      <VirtualizedBodyRow
        data={{
          rows,
          columns,
          rowKey,
          renderer: { rowRenderer },
        }}
        index={renderedIndexes[0] + index}
        key={rows[renderedIndexes[0] + index][rowKey]}
        style={getRowStyle(
          rows[renderedIndexes[0] + index],
          renderedIndexes[0] + index,
          rowHeight,
        )}
      ></VirtualizedBodyRow>
    ));

  const wrapperStyle = {
    height: '100%',
    ...(isSafari ? wrapperSafari : wrapperGeneric),
  };
  const bodyStyle = {
    height: getHeight(),
    willChange: 'transform',
    backfaceVisibility: 'hidden',
  };

  return (
    <Component {...props} innerRef={innerRef}>
      {!isPlaceholderActive ? (
        <div
          className="virtualized-body-wrapper"
          style={wrapperStyle}
          ref={(elem) => {
            if (elem) {
              if (isSafari) {
                setVirtualizedBodyRef(elem.parentElement);
                // eslint-disable-next-line no-param-reassign
                elem.parentElement.scrollTop = scrollTop;
                mergeRefs(listProps.outerRef)(elem.parentElement);
              } else {
                setVirtualizedBodyRef(elem);
                mergeRefs(listProps.outerRef)(elem);
              }
            }
          }}
        >
          <div
            data-testid="virtualized-body"
            className={`virtualized-body${
              hasScrollBar ? ' with-scrollbar' : ''
            }`}
            style={bodyStyle}
            ref={(r) => {
              mergeRefs(innerBody)(r);
            }}
          >
            {generateRenderedRows()}
          </div>
        </div>
      ) : (
        <NoResults innerRef={innerRef} rowRenderer={rowRenderer}>
          {noResultsPlaceholder}
        </NoResults>
      )}
    </Component>
  );
};

VirtualizedBody.defaultProps = {
  rows: [],
  overscanCount: 10,
};

VirtualizedBody.propTypes = {
  autoScrollToId: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  component: PropTypes.element,
  expandable: PropTypes.bool,
  innerRef: PropTypes.any,
  innerBody: PropTypes.any,
  isPlaceholderActive: PropTypes.bool,
  listProps: PropTypes.shape({
    itemSize: PropTypes.func,
    outerRef: PropTypes.any,
  }),
  noResultsPlaceholder: PropTypes.oneOf([PropTypes.string, PropTypes.element]),
  overscanCount: PropTypes.number,
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  rowHeight: PropTypes.number,
  rowKey: PropTypes.string,
  rowRenderer: PropTypes.element,
  rowSize: PropTypes.any,
  setHasScroll: PropTypes.func,
};

export default VirtualizedBody;
