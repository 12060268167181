/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M8 13.85l-3-3 .7-.7 2.3 2.3 6.3-6.3.7.7-7 7zM10 1c4.963 0 9 4.037 9 9s-4.037 9-9 9-9-4.037-9-9 4.037-9 9-9zm0-1C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0z',
  ],
  height: 20,
  width: 20,
});
