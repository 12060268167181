/* eslint-disable react/destructuring-assignment,max-lines,react/display-name, react/require-default-props */
import React from 'react';
import { get } from 'lodash';
import DSTruncatedTooltipText from '../../../../TruncatedTooltipText';

const CustomOption = ({ index, data, style, isScrolling }) => {
  const {
    items,
    options: { useTruncatedText },
  } = data;
  const option = items[index];
  const optionProps = {
    ...option.props.innerProps,
    style,
    onClick: e => {
      e.stopPropagation();
      if (option.props.innerProps.onClick) option.props.innerProps.onClick(e);
    },
  };
  if (
    useTruncatedText &&
    !isScrolling &&
    get(option, 'props.selectProps.menuIsOpen')
  ) {
    return React.cloneElement(
      option,
      {
        ...option.props,
        innerProps: optionProps,
      },
      <DSTruncatedTooltipText value={option.props.children} />,
    );
  }
  return React.cloneElement(option, {
    ...option.props,
    innerProps: optionProps,
  });
};

export default CustomOption;
