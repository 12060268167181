/* eslint-disable no-loops/no-loops,no-plusplus */
import React, { useCallback, useMemo, useEffect } from 'react';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import HeaderCell from './HeaderCell';
import BodyCell from './BodyCell';
import { TableContext } from './tableContext';

const { Provider } = TableContext;

function Table(props) {
  const {
    decoratedRenderers: renderers,
    decoratedColumns: columns,
    getRowProps,
    getHeaderRowProps,
    getTableProps,
    children,
    tableRef,
    props: { wrapText },
    style,
    onDefaultFiltersLoad = () => null,
  } = props;

  useEffect(() => {
    onDefaultFiltersLoad(props.composedRows);
  }, [onDefaultFiltersLoad]);

  const headerRowRenderer = () => {
    const Row = renderers.header.row;
    const Cell = renderers.header.cell;
    const cells = [];

    // optimize the rendering using for loop
    for (let index = 0; index < columns.length; index++) {
      cells.push(
        <HeaderCell
          key={index}
          classProps={{ wrapText }}
          column={columns[index]}
          columnIndex={index}
          component={Cell}
          grid={props}
        />,
      );
    }

    const rowProps = getHeaderRowProps({}, {});
    return <Row {...rowProps}>{cells}</Row>;
  };

  const bodyRowRenderer = useCallback(
    (rowData, rowProps, extraParams) => {
      const Row = renderers.body.row;
      const Cell = renderers.body.cell;

      const nextRowProps = getRowProps(rowProps, { ...extraParams, rowData });

      // get cells from columns api
      const cells = [];

      // optimize the rendering using for loop
      for (let index = 0; index < columns.length; index++) {
        cells.push(
          <BodyCell
            key={index}
            classProps={{ wrapText }}
            column={columns[index]}
            columnIndex={index}
            component={Cell}
            grid={props}
            rowIndex={extraParams.rowIndex}
            rowProps={{ rowData, ...nextRowProps }}
          />,
        );
      }

      return (
        <Row {...nextRowProps} rowData={rowData}>
          {cells}
        </Row>
      );
    },
    [columns, renderers],
  );

  const TableComponent = renderers.table;

  const value = { ...props, bodyRowRenderer, headerRowRenderer };

  return (
    <Provider value={value}>
      <TableComponent
        {...getTableProps({
          innerRef: tableRef,
          style,
        })}
      >
        {children}
      </TableComponent>
    </Provider>
  );
}

Table.Header = TableHeader;
Table.Body = TableBody;

export default Table;
