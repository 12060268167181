import React from 'react';
import DropdownMenuProvider from './DropdownMenuProvider';
import DropdownMenuRenderer from './DropdownMenuRenderer';

function DropdownMenuWrapper(props) {
  return (
    <DropdownMenuProvider {...props}>
      <DropdownMenuRenderer {...props} />
    </DropdownMenuProvider>
  );
}

export default DropdownMenuWrapper;
