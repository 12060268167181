/* eslint-disable */
import createIcon from './utils/createIcon';

export default createIcon({
  paths: [
    'M12 10.5a.503.503 0 0 0-.109-.313l-4-5a.5013.5013 0 0 0-.703-.078c-.214.173-.25.488-.078.703l3.75 4.688-3.75 4.688c-.172.218-.136.531.078.703.216.172.53.138.703-.078l4-5A.501.501 0 0 0 12 10.5z',
  ],
  height: 20,
  width: 20,
});
