import { useLayoutEffect, useRef } from 'react';
import { mergeRefs } from '@elliemae/ds-utilities/system';

export const columnMeasurerTransformer = columnSizeService => (
  value,
  { column, grid },
  props = {},
) => {
  const { registerColumn } = columnSizeService;
  const cellRef = useRef();

  useLayoutEffect(() => {
    const { columns, expandedToContentColumns } = columnSizeService;
    const {
      props: { minColumnWidth },
      resizedColumns,
    } = grid.getInstance();
    if (cellRef.current) {
      // FIX: merge
      // if (column.width && column.width !== 'string' && !initialUsedSpace[column.property]) {
      //   increaseUsedSpace(getColumnWidthSize(column.width, minColumnWidth, column), column.property)
      // }
      if (
        column.expandWidthToContent &&
        !expandedToContentColumns[column.uuid]
      ) {
        // measure the content and reset to flex
        const originalWidth = cellRef.current.offsetWidth;
        cellRef.current.style.flex = 'initial';
        cellRef.current.style.minWidth = 'initial';
        const calcWidth = cellRef.current.offsetWidth + 20;
        if (originalWidth + 20 <= calcWidth) {
          resizedColumns.current[column.uuid] = calcWidth;
          columns[column.uuid].minWidth = calcWidth;
        } else {
          columns[column.uuid].minWidth = originalWidth;
        }
        expandedToContentColumns[column.uuid] = true;
        cellRef.current.style.minWidth = null;
        cellRef.current.style.flex = 1;
        column.node = cellRef.current;
      }
    }
  });

  return {
    ...props,
    innerRef: mergeRefs(cellRef, props.innerRef, registerColumn(column)),
  };
};
