import { aggregatedClasses } from '@elliemae/ds-classnames';

const blockName = 'modal-v2';

export const ModalHeader = aggregatedClasses('div')(
  blockName,
  'modal-header',
  ({ type, showClose }) => ({
    [type]: type,
    'with-close': showClose,
  }),
);

export const ModalHeaderTopRight = aggregatedClasses('div')(
  blockName,
  'modal-header-close',
  ({ type }) => ({
    [type]: type,
  }),
);

export const ModalFeedback = aggregatedClasses('div')(
  blockName,
  'modal-feedback',
  ({ type }) => ({
    [type]: type,
  }),
);

export const ModalTitle = aggregatedClasses('div')(
  blockName,
  'modal-title',
  ({ type }) => ({
    [type]: type,
  }),
);

export const ModalContent = aggregatedClasses('div')(
  blockName,
  'modal-content',
  ({ type, removePadding }) => ({
    [type]: type,
    'remove-padding': removePadding,
  }),
);

export const ModalWrapper = aggregatedClasses('div')(
  blockName,
  'modal-wrapper',
  ({ type, removePadding }) => ({
    [type]: type,
    'remove-padding': removePadding,
  }),
);

export const ModalFooter = aggregatedClasses('div')(
  blockName,
  'modal-footer',
  ({ type, additionalFooterCssClass }) => ({
    [type]: type,
    [additionalFooterCssClass]: additionalFooterCssClass,
  }),
);

export const ModalFooterActions = aggregatedClasses('div')(
  blockName,
  'modal-footer-actions',
  ({ type }) => ({
    [type]: type,
  }),
);
