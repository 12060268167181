import { useMemo } from 'react';

const calculateHeight = (itemHeight, amountItemsInWindow, itemsLength) => {
  const realAmountItemsInWindow =
    itemsLength < amountItemsInWindow ? itemsLength : amountItemsInWindow;
  return itemHeight * realAmountItemsInWindow;
};

export default function useHeightByAmountOfItems({
  itemHeight,
  amountItems,
  items,
}) {
  const calculatedHeight = useMemo(
    () => calculateHeight(itemHeight, amountItems, items.length),
    [amountItems, items],
  );

  return calculatedHeight;
}
