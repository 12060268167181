/* eslint-disable max-lines */
/* eslint-disable jest/valid-describe */
import React from 'react';
import { PropTypes, describe } from 'react-desc';
import { MASK_TYPES } from './MaskTypes';
import { MASK_PIPES } from './MaskPipes';
import DSInputMaskImpl from './DSInputMaskImpl';

const DSInputMask = ({
  autoFocus = false,
  style = {},
  disabled = false,
  className = '',
  name = '',
  maxLength = 255,
  minLength = 0,
  fluidWidth = false,
  placeholder,
  onKeyDown = () => null,
  onClick = () => null,
  onChange = () => null,
  onFocus = () => null,
  onBlur = () => null,
  onPaste = () => null,
  onKeyUp = () => null,
  value = '',
  hasError = false,
  readOnly = false,
  innerRef = null,
  type = 'text',
  clearable = false,
  leftComponent = null,
  rightComponent = null,
  mask = MASK_TYPES.DATE,
  pipe = null,
  useSubfix = '',
  ...rest
}) => (
  <DSInputMaskImpl
    {...rest}
    aria-label={rest['aria-label'] || 'Input Mask'}
    autoFocus={autoFocus}
    className={className}
    clearable={clearable}
    disabled={disabled}
    fluidWidth={fluidWidth}
    hasError={hasError}
    innerRef={innerRef}
    leftComponent={leftComponent}
    mask={mask}
    maxLength={maxLength}
    minLength={minLength}
    name={name}
    onBlur={onBlur}
    onChange={onChange}
    onClick={onClick}
    onFocus={onFocus}
    onKeyDown={onKeyDown}
    onKeyUp={onKeyUp}
    onPaste={onPaste}
    pipe={pipe}
    placeholder={placeholder}
    readOnly={readOnly}
    rightComponent={rightComponent}
    style={style}
    type={type}
    useSubfix={useSubfix}
    value={value}
  />
);

const inputProps = {
  /** Should component focus automatically */
  autoFocus: PropTypes.bool.description('Should component focus automatically'),
  /**
   * Set style for the input mask
   */
  style: PropTypes.object.description('Set style for the input mask'),
  /**
   * Whether the input mask is disabled or not
   */
  disabled: PropTypes.bool.description(
    'Whether the input mask is disabled or not',
  ),
  /** css class */
  className: PropTypes.string.description('css class'),
  /** html name prop for form */
  name: PropTypes.string.description('html name prop for form'),
  /**
   * Max length for the input mask
   */
  maxLength: PropTypes.number.description('Max length for the input mask'),
  /**
   * Min length for the input mask
   */
  minLength: PropTypes.number.description('Min length for the input mask'),
  /**
   * Whether to make the content of the input mask fit the container or not
   */
  fluidWidth: PropTypes.bool.description(
    'Whether to make the content of the input mask fit the container or not',
  ),
  /**
   * Component that works as placeholder
   */
  placeholder: PropTypes.node.description(
    'Component that works as placeholder',
  ),
  /**
   * Allows a function that is triggered once a key is being pressed
   */
  onKeyDown: PropTypes.func.description(
    'Allows a function that is triggered once a key is being pressed',
  ),
  /**
   * Allows a function that is triggered once the input mask is clicked
   */
  onClick: PropTypes.func.description(
    'Allows a function that is triggered once the input mask is clicked',
  ),
  /**
   * Allows a function that is triggered once the input mask changes
   */
  onChange: PropTypes.func.description(
    'Allows a function that is triggered once the input mask changes',
  ),
  /**
   * Allows a function that is triggered once the input mask is focused
   */
  onFocus: PropTypes.func.description(
    'Allows a function that is triggered once the input mask is focused',
  ),
  /**
   * Allows a function that is triggered once the input mask loses focus
   */
  onBlur: PropTypes.func.description(
    'Allows a function that is triggered once the input mask loses focus',
  ),
  /**
   * Allows a function that is triggered once the input mask is pasted
   */
  onPaste: PropTypes.func.description(
    'Allows a function that is triggered once the input mask is pasted',
  ),
  /**
   * Allows a function that is triggered once in the input mask a keyboard key is released
   */
  onKeyUp: PropTypes.func.description(
    'Allows a function that is triggered once in the input mask a keyboard key is released',
  ),
  /**
   * Default value once the component is initialized
   */
  value: PropTypes.string.description(
    'Default value once the component is initialized',
  ),
  /**
   * Whether the input mask has error or not
   */
  hasError: PropTypes.bool.description(
    'Whether the input mask has error or not',
  ),
  /**
   * Whether the input mask is read only or not
   */
  readOnly: PropTypes.bool.description(
    'Whether the input mask is read only or not',
  ),
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).description('Whether the input mask is read only or not'),
  /**
   * Type of input. Ex: 'text'
   */
  type: PropTypes.string.description("Type of input. Ex: 'text'"),
  /**
   * Whether the input mask is clearable or not
   */
  clearable: PropTypes.bool.description(
    'Whether the input mask is clearable or not',
  ),
  /**
   * Component to be added at the right side of the input
   */
  leftComponent: PropTypes.node.description(
    'Component to be added at the right side of the input',
  ),
  /**
   * Component to be added at the right side of the input
   */
  rightComponent: PropTypes.node.description(
    'Component to be added at the right side of the input',
  ),
  /**
   * Mask that has to match with the input information entered
   * ['DATE', 'PHONE', 'PHONE_INTENATIONAL', 'SSN', 'US_ZIP_CODE', 'NUMBER', 'PERCENT']
   */
  mask: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.func,
  ]).description(
    "Mask that has to match with the input information entered. ['DATE', 'PHONE', 'PHONE_INTENATIONAL', 'SSN', 'US_ZIP_CODE', 'NUMBER', 'PERCENT']",
  ),
  /**
    Expects pipe functions. i.e. autoCorrectedDatePipe
    link a docu de text-mask
   */
  pipe: PropTypes.func.description(
    'Expects pipe functions. i.e. autoCorrectedDatePipe',
  ),
  /**
   * Put a sufix after the input mask
   */
  useSubfix: PropTypes.string.description('Put a sufix after the input mask'),
};

DSInputMask.propTypes = inputProps;

const DSInputMaskWithSchema = describe(DSInputMask).description(
  'Masking input for custom formats',
);
DSInputMaskWithSchema.propTypes = inputProps;

export { MASK_TYPES, MASK_PIPES, DSInputMaskWithSchema };
export default DSInputMask;
