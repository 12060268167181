const letterFactor = 6;
const fixedSpace = 48;
export function calculateWidth(options) {
  const longest = options.reduce((a, b) => {
    if (a && a.label && b && b.label)
      return a.label.length > b.label.length ? a : b;
    return 0;
  });
  if (longest && longest.label && longest.label.length) {
    return longest.label.length * letterFactor + fixedSpace;
  }
  return 0;
}
