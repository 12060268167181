/* eslint-disable react/prop-types */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events,react/display-name */
import React, { forwardRef, useEffect, useRef } from 'react';
import { isFunction } from '@elliemae/ds-utilities/utils';
import DSCheckbox from '@elliemae/ds-basic/form/Checkbox';
import DSRadio from '@elliemae/ds-basic/form/Radio';

const SelectableWrapper = ({ className, children }) => (
  <div className={className} onClick={(e) => e.stopPropagation()}>
    {children}
  </div>
);

const SelectableComponent = React.memo(
  forwardRef(
    (
      { className, isMultiSelect, isHeader, onSelect, isSelected, value },
      ref,
    ) => {
      const SelectComponent = isMultiSelect || isHeader ? DSCheckbox : DSRadio;

      return (
        <SelectableWrapper className={className}>
          <SelectComponent
            checked={isSelected}
            data-testid="column-selectable-component"
            innerRef={ref}
            onChange={onSelect}
            tabIndex={-1}
            value={value}
          />
        </SelectableWrapper>
      );
    },
    (prev, next) => next.isScrolling,
  ),
);

const selectableFormatter = (
  { isMultiSelect = false, renderer, className, isHeader, onSelect },
  grid,
) => (valueRender, extraParams) => {
  const { registerFocus } = extraParams;
  const ref = useRef();
  const {
    props: { rowKey },
    state: { selection },
    shiftPressed,
    lastSelectedRow,
  } = grid.getInstance();
  const { rowData, isScrolling } = extraParams;

  useEffect(() => {
    if (registerFocus) registerFocus(ref.current);
  }, [ref.current]);

  const isSelected = isHeader
    ? selection.selectAll
    : !!selection.selectedRows[rowData[rowKey]];

  const handleSelect = isHeader
    ? () => onSelect(!isSelected)
    : () =>
        onSelect(
          rowData[rowKey],
          extraParams.rowIndex,
          shiftPressed,
          lastSelectedRow,
        );

  if (isFunction(renderer)) {
    return (
      <SelectableWrapper className={className}>
        {renderer({
          rowData: {},
          ...extraParams,
          value: isSelected,
          isHeader,
          onSelect: handleSelect,
        })}
      </SelectableWrapper>
    );
  }

  return (
    <SelectableComponent
      ref={ref}
      className={className}
      isMultiSelect={isMultiSelect}
      isScrolling={isScrolling}
      isSelected={isSelected}
      onSelect={handleSelect}
      value={!isHeader ? rowData[rowKey] : 'header-select-all'}
    />
  );
};

export default selectableFormatter;
