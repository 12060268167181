import React from 'react';
import PropTypes from 'prop-types';
import {
  evaluateFormatters,
  evaluateTransforms,
} from '@elliemae/ds-shared/createDataInstance/utils';
import DSTruncatedTooltipText from '@elliemae/ds-basic/TruncatedTooltipText';

function HeaderCell({
  column,
  columnIndex,
  component: Component,
  classProps,
  grid,
}) {
  const { property, header = {}, props = {} } = column;

  const evaluatedProperty = property || (header && header.property);

  const { label, transforms = [], formatters = [] } = header;

  // subscribe hook effects into header cell
  if (column.headerHookEffects)
    column.headerHookEffects.forEach((hook) => hook());

  const extraParameters = {
    columnIndex,
    property: evaluatedProperty,
    column,
    grid,
  };

  const withTooltip = (v) =>
    classProps && classProps.wrapText ? (
      v
    ) : (
      <DSTruncatedTooltipText value={v} />
    );
  const value = withTooltip(label);
  const transformedProps = evaluateTransforms(
    transforms,
    value,
    extraParameters,
  );
  const formattedValue = evaluateFormatters(formatters, value, extraParameters);

  const testId = column.headerDataId || 'data-grid-header__cell';

  return (
    <Component
      key={`${evaluatedProperty}-header`}
      data-testid={testId}
      index={columnIndex}
      classProps={classProps}
      {...props}
      {...(header && header.props)}
      {...transformedProps}
    >
      {transformedProps.children || formattedValue}
    </Component>
  );
}

HeaderCell.propTypes = {
  column: PropTypes.any,
  columnIndex: PropTypes.number,
  component: PropTypes.any,
  classProps: PropTypes.any,
  grid: PropTypes.any,
};

export default HeaderCell;
