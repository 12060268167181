/* eslint-disable no-multi-assign,no-loops/no-loops, no-plusplus */
if (
  global &&
  global.Element &&
  global.Element.prototype &&
  global.Element.prototype.remove === undefined
) {
  window.Element.prototype.remove = function() {
    this.parentElement.removeChild(this);
  };
  NodeList.prototype.remove = HTMLCollection.prototype.remove = function() {
    for (let i = this.length - 1; i >= 0; i--) {
      if (this[i] && this[i].parentElement) {
        this[i].parentElement.removeChild(this[i]);
      }
    }
  };
}
