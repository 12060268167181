/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { aggregatedClasses } from '@elliemae/ds-classnames';
import { get, debounce } from '@elliemae/ds-utilities/utils';
import useResizeObserver from '@elliemae/ds-utilities/hooks/useResizeObserver';
import theme from '@elliemae/ds-system/theme';
import getFilterMenuByType from './filterable-menus/getFilterMenuByType';

const blockName = 'data-grid-filter-header';

const Container = aggregatedClasses('div')(
  blockName,
  null,
  ({ showFilter }) => ({
    'show-filter': showFilter,
  }),
);
const FilterHeaderValue = aggregatedClasses('div')(blockName, 'value');
const FilterHeaderButton = aggregatedClasses('div')(blockName, 'button');

export default function FilterableHeader({
  column,
  value,
  columnData,
  onAddFilter,
  onRemoveFilter,
  filterMenuRef,
  onFilterMenuClose,
  onFilterMenuOpen,
  filters,
  isMulti,
}) {
  const [isMenuOpened, setMenuOpened] = useState(false);
  const [headerWidth, setHeaderWidth] = useState();
  const debouncedWidthSetter = debounce(setHeaderWidth, 200);
  const { filterMenuMinWidth, filterMenuMaxWidth } = column;
  const dropdownMinWidth =
    filterMenuMinWidth || Number(theme.space.xl.replace('px', '')) * 2;

  const defineHeaderWidth = ({ width }) => {
    let nextWidth = width + 16; // todo: use dimsum space once we convert it to JS
    if (nextWidth < dropdownMinWidth) nextWidth = dropdownMinWidth;
    if (filterMenuMaxWidth && nextWidth > filterMenuMaxWidth)
      nextWidth = filterMenuMaxWidth;
    return !isMenuOpened
      ? debouncedWidthSetter(nextWidth)
      : setHeaderWidth(nextWidth);
  };

  useEffect(() => {
    if (filterMenuRef) {
      filterMenuRef.current = {
        toggleMenu: () => setMenuOpened((prevState) => !prevState),
      };
    }
  }, [filterMenuRef]);

  useEffect(() => {
    if (!isMenuOpened) onFilterMenuClose(column);
    else onFilterMenuOpen(column);
  });

  const [ref] = useResizeObserver(defineHeaderWidth);
  const handleOpenMenu = useCallback((open) => {
    setMenuOpened(open);
    if (open) onFilterMenuOpen();
  }, []);
  const FilterMenuComponent = getFilterMenuByType(
    get(column, ['filter', 'type']),
  );
  return (
    <Container ref={ref} classProps={{ showFilter: isMenuOpened }}>
      <FilterHeaderValue className="header-value">{value}</FilterHeaderValue>
      <FilterHeaderButton data-testid="data-grid-filter-header__button">
        <FilterMenuComponent
          className="filter-header-menu"
          column={column}
          filters={filters}
          isMenuOpened={isMenuOpened}
          isMulti={isMulti}
          menuProps={{
            options: columnData || [],
            style: { minWidth: headerWidth, maxWidth: filterMenuMaxWidth },
            zIndex: 100,
          }}
          onAddFilter={onAddFilter}
          onRemoveFilter={onRemoveFilter}
          onOpenMenu={handleOpenMenu}
          onClose={onFilterMenuClose}
          width={headerWidth}
          {...get(column, ['filter'], {})}
        />
      </FilterHeaderButton>
    </Container>
  );
}

FilterableHeader.propTypes = {
  column: PropTypes.any,
  value: PropTypes.any,
  columnData: PropTypes.any,
  onAddFilter: PropTypes.func,
  onRemoveFilter: PropTypes.func,
  filterMenuRef: PropTypes.any,
  onFilterMenuClose: PropTypes.func,
  onFilterMenuOpen: PropTypes.func,
  filters: PropTypes.arrayOf(PropTypes.shape({})),
  isMulti: PropTypes.bool,
};
