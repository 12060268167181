import React from 'react';
import PropTypes from 'prop-types';
import ChevronRight from '@elliemae/ds-icons/ChevronRight';
import { DSIconColors, DSIconSizes } from '@elliemae/ds-basic/Icon';
import { iconSizes, iconColors } from '../../../../utils/prop-types';

const Navigation = ({
  className = 'date-picker-navigation',
  icon: Icon = ChevronRight,
  color = DSIconColors.NEUTRAL,
  size = DSIconSizes.S,
  innerRef,
}) => (
  <div>
    <Icon className={className} color={color} innerRef={innerRef} size={size} />
  </div>
);
Navigation.propTypes = {
  className: 'date-picker-navigation',
  icon: PropTypes.element,
  color: PropTypes.oneOf(iconColors),
  size: PropTypes.oneOf(iconSizes),
  innerRef: PropTypes.any,
};

export default Navigation;
